import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import { QuizContext } from "../../App";

const FormEditQuizLangForLang = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Form style={{ textAlign: "center", marginTop: "30px" }}>
      <Form.Label>
        {quizContext.state.oldQuizType === "PUBLIC"
          && quizContext.state.quizType === "PUBLIC"
          ? <span>Edit quiz language</span>
          : <span>Add quiz language</span>
        }
      </Form.Label>

      <div key={"inline-radio"} className="mb-3">
        <table style={{ width: "100%" }}><thead>
          <tr>
            <th style={{ width: "50%", textAlign: "left" }}>
              <Form.Check
                inline
                label="One language"
                name="group1"
                type="radio"
                value="One"
                id={1}
                onChange={(e) => quizContext.dispatch({
                  type: "setNumOfLang",
                  payload: e.target.value
                })}
              />
            </th>
            <th style={{ width: "50%", textAlign: "right" }}>
              <Form.Check
                inline
                label="Multilanguage"
                name="group1"
                type="radio"
                value="Multilanguage"
                id={2}
                onChange={(e) => quizContext.dispatch({
                  type: "setNumOfLang",
                  payload: e.target.value
                })}
              />
            </th>
          </tr>
          {quizContext.state.langStatus === true
            ? <tr>
              <th>
                <Form.Select
                  data-bs-theme={theme}
                  style={{
                    border: theme === "dark"
                      ? "1px solid #77797c"
                      : "1px solid #212529"
                  }}
                  onChange={(e) => quizContext.dispatch({
                    type: "editQuizLang",
                    payload: e.target.value
                  })}
                >
                  <option value="- Select language -">- Select Language -</option>
                  {quizContext.state.langArr.map((el, i) =>
                    <option key={i} value={el}>{el}</option>
                  )}
                </Form.Select>
              </th>
              <th></th>
            </tr>
            : null
          }
        </thead></table>
      </div>
    </Form>
  )
}

export default FormEditQuizLangForLang