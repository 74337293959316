import axios from "axios";

// ButtonAddQuizToFavorite (saves the link to a public or team quiz, created by other user)

const addToFav = async (dispatch, state) => {

  let error = state.error;
  const teamId = state.teamData.filter(el => el.quiz_id === state.quiz.id).length === 1
    ? state.teamData[0].team_id
    : null

  try {
    await axios.post('/quiz/addtofav', {
      quiz_id: state.quiz.id,
      renter_id: state.currentUser.id,
      team_id: teamId
    })
  } catch (err) {
    error = err.response.data.message;
    console.log("addToFav err:", error);
  }

  return dispatch({
    type: "addToFav",
    error
  });
};

export default addToFav