import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import { QuizContext } from "../../App";

const FormEditUsername = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Form>
      <Form.Control
        type="text"
        data-bs-theme={theme}
        style={{
          border: theme === "dark"
            ? "1px solid #77797c"
            : "1px solid #212529",
          textAlign: "center"
        }}
        value={quizContext.state.username}
        onChange={(e) => quizContext.dispatch({
          type: "editUserName",
          payload: e.target.value
        })}
      />
    </Form>
  )
}

export default FormEditUsername