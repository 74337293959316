// ModalAddRemoveColumn ModalAddRemoveRow

const hideAlertEditTable = (state) => {
  return {
    ...state,
    alertTable: false,
    alertStatus: null
  }
}

export default hideAlertEditTable