import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { QuizContext } from "../../App";
import ButtonMyPage from "../buttons/ButtonMyPage";

const ModalForDeleteTeam = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Modal
      data-bs-theme={theme}
      show={quizContext.state.alert}
    >
      <Modal.Body
        className={`${theme}-modal-body`}
        style={{ textAlign: "center" }}
      >
        Команда<br />
        <span style={{ fontWeight: "600" }}>{quizContext.state.team.teamname}</span><br />
        удалена

        <div style={{
          textAlign: "center",
          marginTop: "30px"
        }}
        >
          <ButtonMyPage />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ModalForDeleteTeam