import React, { useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { QuizContext } from '../App';
import ErrorRU from "./ErrorRU";
import Loading from "../componentsRU/Loading";
import ButtonTeam from "../componentsRU/buttons/ButtonTeam";
import ButtonMyPage from "../componentsRU/buttons/ButtonMyPage";
import TextNotYourTeam from "../componentsRU/text/TextNotYourTeam";
import AccordionTeamEdit from "../componentsRU/accordions/AccordionTeamEdit";

const TeamEdit = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const { id } = useParams();
  const dispatch = quizContext.dispatch;


  //Get Team Edit Page all information
  useEffect(() => {
    const fecthData = async () => {
      try {
        const res = await axios.get(`/team/edit/${id}`);
        // console.log("res.data", res.data);
        const teamEditPage = res.data;
        // console.log("teamEditPage:", teamEditPage);
        dispatch({ type: "fetchTeamEditPage", teamEditPage });
      } catch (err) {
        const error = err.response.data.message;
        console.log("fetchTeamEditPageErr:", err);
        dispatch({ type: "fetchTeamEditPageErr", error });
      }
    }
    fecthData();
  }, [id,
    dispatch,
    quizContext.state.editSettingsStatus,
    quizContext.state.addQuizStatus,
    quizContext.state.removeQuizStatus,
    quizContext.state.changeTeamMemberTypeStatus,
    quizContext.state.removeTeamMemberStatus,
    quizContext.state.invStatus
  ]);

  return (
    <div>
      {quizContext.state.loading
        ? <Loading />
        : quizContext.state.errorMySQL
          || quizContext.state.errorAuth
          || quizContext.state.error === "404"
          ? <ErrorRU />
          : quizContext.state.teamMembers.filter(el => el.user_id === quizContext.state.currentUser.id).length === 0
            ? <TextNotYourTeam />
            : <>
              <div className="header" >
                <span className="header-tq">РЕДАКТИРОВАТЬ КОМАНДУ: </span><Link
                  to={`/team/${quizContext.state.team.id}`}
                  style={{
                    textDecoration: "none",
                    color: theme === "dark"
                      ? "#4da2f7"
                      : "#0a70d6"
                  }}
                  onClick={() => quizContext.dispatch({
                    type: "backToMainPage"
                  })}
                >
                  <span className="header-tq-title">
                    {quizContext.state.team.teamname}
                  </span></Link>
              </div>

              <AccordionTeamEdit />
              <div
                style={{
                  textAlign: "center",
                  marginTop: "25px",
                  gap: "20px"
                }}
              >
                <ButtonMyPage /> <ButtonTeam />
              </div>
            </>
      }
    </div>
  )
}

export default TeamEdit