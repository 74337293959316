import React, { useContext } from "react";
import { QuizContext } from "../../App";
import Card from "react-bootstrap/Card";
import TextTeamNameAndSettings from "../text/TextTeamNameAndSettings";
import ButtonSubmitEditedTeamSettings from "../buttons/ButtonSubmitEditedTeamSettings";
import FormTeamEditName from "../forms/FormTeamEditName";
import FormTeamSetTypeAddQuiz from "../forms/FormTeamSetTypeAddQuiz";
import FormTeamSetTypeAddMemb from "../forms/FormTeamSetTypeAddMemb";

const CardTeamEditSettings = () => {

  const quizContext = useContext(QuizContext);

  return (
    <Card><Card.Body>
      {quizContext.state.editSettingsStatus === "show"
        ? <TextTeamNameAndSettings />
        : <div style={{textAlign: "center"}}>
          <FormTeamEditName />
          <p style={{ fontWeight: "600", marginTop: "20px" }}>
            Edit who can add quizzes to the team
          </p>
          <FormTeamSetTypeAddQuiz />
          <hr />
          <p style={{ fontWeight: "600" }}>
            Edit who can invite users to the team
          </p>
          <FormTeamSetTypeAddMemb />

          <hr />
          
          <div style={{ margin: "25px 0 5px 0" }}>
            <ButtonSubmitEditedTeamSettings />
            {/* <ButtonCancelAndStay /> */}
          </div>

        </div>
      }</Card.Body>
    </Card>
  )
}

export default CardTeamEditSettings