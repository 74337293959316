import React, { useContext } from "react"
import { QuizContext } from "../../App";

const TextQuizHeader = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <>
      <h2 className="header"><span className="header-tq">QUIZ: </span>

        {quizContext.state.quiz.id === undefined
          ? <><span className="header-tq-title">
            {quizContext.state.quizTitle}
          </span><br /></>
          : quizContext.state.quiz.id !== undefined
            && (quizContext.state.quizStatus === "initial"
              || quizContext.state.quizStatus === "quiz")
            ? <><span className="header-tq-title">
              {quizContext.state.quiz.title}
            </span><br /></>
            : <><span
              className="header-tq-title"
              style={{
                color: theme === "dark"
                  ? "#4da2f7"
                  : "#0a70d6",
                cursor: "pointer"
              }}
              onClick={() => quizContext.dispatch({
                type: "backToQuizTitlePage"
              })}
            >
              {quizContext.state.quiz.title}
            </span><br /></>
        }
        <span className="header-tq">{quizContext.state.numOfTasks} tasks</span>
      </h2>
    </>
  )
}

export default TextQuizHeader