import getInitialState from "../../../state/GetInitialState";

// TableTeamQuizzes

const goToFavQuiz = (state, action) => {

  const errorAuth = state.errorAuth;
  const errorMySQL = state.errorMySQL;
  const theme = state.theme;
  const isRussia = state.isRussia;
  const websiteLang = state.websiteLang;

  const quizId = action.el.quiz_id;
  const teamData = action.el.author_id === state.currentUser.id
    ? state.teamData.filter(el => el.quiz_id === quizId)
    : [{ team_id: action.el.team_id, teamname: action.el.teamname }];


    // const teamData = !action.el.team_id && action.el.author_id === state.currentUser.id
    // ? state.teamData.filter(el => el.quiz_id === quizId)
    // : [{ team_id: action.el.team_id, teamname: action.el.teamname }];

  return {
    ...getInitialState(),
    errorAuth,
    errorMySQL,
    theme,
    isRussia,
    websiteLang,
    teamData
  };

}

export default goToFavQuiz