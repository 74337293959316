import React, { useContext } from "react";
import Image from 'react-bootstrap/Image';
import { QuizContext } from "../App";
import ModalContact from "./modals/ModalContact";
import { TelegramIcon } from "react-share";

const FooterRU = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const alertStatus = "contact";

  const handleClick = () => {
    window.location.href = "https://t.me/+yOM4Bhlt-GE5Yjky";
  };

  return (
    <div className={`${theme}-footer`}>
      <p>
        <TelegramIcon round size={15} /> <span
          className={`${theme}-footer-links`}
          style={{ marginRight: "10px" }}
          onClick={handleClick}
        >Телеграм чат</span> | <Image fluid
          src={require('./images/email_icon.png')}
          alt="email"
          style={{ width: "1em", marginLeft: "10px" }}
        /> <span
          className={`${theme}-footer-links`}
          onClick={() => quizContext.dispatch({
            type: "showModalContactSupport",
            alertStatus
          })}
        >
          Служба поддержки
        </span><br />

        <span className={`${theme}-footer-copyright`}>&#169; eKat. 2023. Все права защищены.</span>
      </p>

      {
        quizContext.state.alertStatus === "contact"
        && <ModalContact />
      }

    </div >
  )
}

export default FooterRU