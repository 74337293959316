// FormEditQuizLangForLang

const setNumOfLang = (state, action) => {
  const numOfLang = action.payload;
  let langId = state.langId;

  if (numOfLang === "Multilanguage") {
    langId = state.lang.filter(el => el.lang === "Multilanguage")[0].id
    return { ...state, langId, langStatus: false }
  }

  if (numOfLang === "One") {
    langId = null;
    return { ...state, langId, langStatus: true };
  }

}

export default setNumOfLang