import axios from "axios";

const switchToGetOTP = async (dispatch, state) => {

  let error = state.error;
  const email = state.email;

  if (email !== "") {

    const inputs = {email};

    try {
      await axios.post("/auth/checkemail", inputs);
    } catch (err) {
      error = err.response.data.message;
      console.log("switchToGetOTP err:", error);
    };

    return dispatch({
      type: "switchToGetOTP",
      error
    });

  } else {
    return dispatch({
      type: "switchToGetOTP",
      error
    });
  }
}

export default switchToGetOTP