// ButtonsTab

const switchTabLeft = (state) => {
  return {
    ...state,
    video: false,
    tabStatus: "left"
  }
}

export default switchTabLeft