import React, { useContext } from "react";
import Navbar from "react-bootstrap/Navbar";
import { QuizContext } from "../App";
import { Image } from "react-bootstrap";

const NavBarQuiz = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Navbar
      className="justify-content-center"
      bg="dark"
    >
      <div style={{ width: "2.5em", marginRight: "5em" }}></div>

      <button
        className={`${theme}-stop-quiz-btn`}
        onClick={() => quizContext.dispatch({
          type: "showModalStopQuiz"
        })}
      >
        STOP QUIZ
      </button>


      <div style={{ width: "2.5em", marginLeft: "5em" }}>
        <Image fluid
          src={theme === "dark"
            ? require('./images/themeDark.png')
            : require('./images/themeLight.png')
          }
          alt="Light/Dark"
          onClick={() => quizContext.dispatch({
            type: "toggleTheme"
          })}
        />
      </div>

    </Navbar >
  )
}

export default NavBarQuiz