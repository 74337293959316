import React, { useContext, } from "react";
import { QuizContext } from "../../App";
import { Card } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import FormEditQuizTitle from "../forms/FormEditQuizTitle";
import FormEditQuizDesc from "../forms/FormEditQuizDesc";
import FormEditQuizType from "../forms/FormEditQuizType";
import FormEditQuizCat from "../forms/FormEditQuizCat";
import ButtonsSubmitEditedQuiz from "../buttons/ButtonsSubmitEditedQuiz";
import FormEditQuizLang from "../forms/FormEditQuizLang";
import FormEditQuizLangForLang from "../forms/FormEditQuizLangForLang";
import ButtonDeleteQuiz from "../buttons/ButtonDeleteQuiz";
import TextQuizEditAddLink from "../text/TextQuizEditAddLink";

// import FormQuizFile from "../forms/FormQuizFile";


const AccordionQuizEditSettings = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Card
      data-bs-theme={theme}
      style={{ boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px" }}
    >
      <div className={`${theme}-main-card`}>
        <Accordion data-bs-theme={theme}>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              Edit quiz title
            </Accordion.Header>
            <Accordion.Body>
              <FormEditQuizTitle />
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            {!quizContext.state.quizDescAuthor
              ? <Accordion.Header>
                Add quiz description
              </Accordion.Header>
              : <Accordion.Header>
                Edit quiz description
              </Accordion.Header>
            }
            <Accordion.Body>
              {quizContext.state.alertStatus === "editingDesc"
                && <TextQuizEditAddLink />
              }
              <FormEditQuizDesc />
            </Accordion.Body>
          </Accordion.Item>


          {quizContext.state.quiz.type !== "TEAM"
            && <>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Edit quiz type
                </Accordion.Header>
                <Accordion.Body>
                  <FormEditQuizType />
                  {quizContext.state.oldQuizType === "PRIVATE"
                    && quizContext.state.quizType === "PUBLIC"
                    ? <div style={{ marginTop: "35px" }}>
                      <FormEditQuizCat />
                      {quizContext.state.catName === "Languages"
                        ? <FormEditQuizLangForLang />
                        : <FormEditQuizLang />
                      }
                    </div>
                    : null
                  }
                </Accordion.Body>
              </Accordion.Item>

              {quizContext.state.oldQuizType === "PUBLIC"
                && quizContext.state.quizType === "PUBLIC"
                ? <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    Edit quiz category
                  </Accordion.Header>
                  <Accordion.Body>
                    <>
                      <FormEditQuizCat />
                      {quizContext.state.catName === "Languages"
                        ? <FormEditQuizLangForLang />
                        : <FormEditQuizLang />
                      }
                    </>
                  </Accordion.Body>
                </Accordion.Item>
                : null
              }
            </>
          }

          <Accordion.Item eventKey="4">
            <Accordion.Header>
              Delete quiz
            </Accordion.Header>
            <Accordion.Body>
              <div style={{ textAlign: "center" }}>
                <ButtonDeleteQuiz />
              </div>
            </Accordion.Body>
          </Accordion.Item>

        </Accordion>


        {quizContext.state.errorMessage === true
          && <p className="error">Provide quiz category and language<br />
            or change quiz type to PRIVATE</p>
        }

        <div
          style={{
            textAlign: "center",
            marginTop: "35px"
          }}
        >
          <ButtonsSubmitEditedQuiz />
        </div>
      </div>
    </Card>
  )
}

export default AccordionQuizEditSettings