import axios from "axios";

// FormRemoveTeamMember

const removeMemberFromTeam = async (dispatch, state) => {

  let error = state.error;
  const teamMemberId = state.teamMemberId;
  const teamUsersId = state.teamUsersId;
  const teamId = state.team.id;

  try {
    await axios.post(`/team/deletememb/${teamUsersId}`, {
      teamMemberId,
      teamId
    });
  } catch (err) {
    error = err.response.data.message;
    console.log("removeMemberFromTeam err:", error);
  }

  return dispatch({
    type: "removeMemberFromTeam",
    error
  });
}

export default removeMemberFromTeam