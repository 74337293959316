import React, { useContext } from "react";
import { QuizContext } from "../../App";

const ButtonsEditProfileLogOut = () => {

  const quizContext = useContext(QuizContext);
  const alertStatus = "log out";
  const theme = quizContext.state.theme;

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <button
        className={`${theme}-edit-prof-btn`}
        onClick={() => quizContext.dispatch({
          type: "goToEditProfile"
        })}
      >
        РЕДАКТИРОВАТЬ ПРОФИЛЬ
      </button>
      <button
        className={`${theme}-edit-prof-btn`}
        onClick={() => quizContext.dispatch({
          type: "showModal",
          alertStatus
        })}
      >
        ВЫЙТИ
      </button>
    </div>
  )
}

export default ButtonsEditProfileLogOut