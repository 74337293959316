import React, { useContext } from "react";
import { QuizContext } from "../App";
import ButtonQuizCreate from "../componentsRU/buttons/ButtonQuizCreate";
import ButtonHome from "../componentsRU/buttons/ButtonHome";
import ButtonQuizLibrary from "../componentsRU/buttons/ButtonQuizLibrary";
import { Link } from "react-router-dom";

const Error = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <div className={`${theme}-wrapper`}>
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        {quizContext.state.error === "404"
          ? <>
            <h2 className="error-h2">
              СТРАНИЦА НЕ НАЙДЕНА
            </h2>
            <div style={{ marginTop: "25px" }}>
              <ButtonQuizLibrary />
            </div>
            <div style={{ marginTop: "15px" }}>
              <ButtonQuizCreate />
            </div>
            <div style={{ marginTop: "15px" }}>
              <ButtonHome />
            </div>
          </>

          : quizContext.state.error === "500"
            || quizContext.state.errorMySQL
            ? <>
              <h2 className="error-h2">
                Ой!
              </h2>
              <p className="error-p">
                Что-то пошло не так<br />
                Мы делаем всё возможное, чтобы исправить ошибку.<br />
                Несмотря на проблему, Вы по-прежнему можете можете создавать квизы без сохранения их.
              </p>
              <div style={{ marginTop: "15px" }}>
                <ButtonQuizCreate />
              </div>
              <div style={{ marginTop: "15px" }}>
                <ButtonHome />
              </div>
            </>
            : quizContext.state.errorAuth
              ? <>
                <h2 className="error-h2">
                  ОШИБКА АУТЕНТИФИКАЦИИ
                </h2>
                <p className="error-p">
                  Чтобы решить проблему,<Link to="/login"><button
                    className={`${theme}-small-btn`}
                    onClick={() => quizContext.dispatch({ type: "switchToLogin" })}
                  >
                    ВОЙДИТЕ
                  </button></Link> заново.</p>
                <p className="error-p">Несмотря на проблему,<br />
                  Вы по-прежнему можете пользоваться БИБЛИОТЕКОЙ КВИЗОВ<br />
                  и создавать квизы, не сохраняя их.
                </p>
                <div style={{ marginTop: "25px" }}>
                  <ButtonQuizLibrary />
                </div>
                <div style={{ marginTop: "15px" }}>
                  <ButtonQuizCreate />
                </div>
                <div style={{ marginTop: "15px" }}>
                  <ButtonHome />
                </div>
              </>
              : <>
                <h2 className="error-h2">
                  СТРАНИЦА НЕ НАЙДЕНА
                </h2>
                <div style={{ marginTop: "25px" }}>
                  <ButtonQuizLibrary />
                </div>
                <div style={{ marginTop: "15px" }}>
                  <ButtonQuizCreate />
                </div>
                <div style={{ marginTop: "15px" }}>
                  <ButtonHome />
                </div>
              </>
        }

      </div>
    </div>
  )
}

export default Error