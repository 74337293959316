import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { QuizContext } from "../../App";
import { Link } from "react-router-dom";

const ModalForStartTeam = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (

    <Modal
      data-bs-theme={theme}
      show={quizContext.state.alert}
    >
      <Modal.Header className={`${theme}-modal-header`}>
        <Modal.Title>
          <span className={`${theme}-congrat`}>
            CONGRATULATIONS!
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={`${theme}-modal-body`}
        style={{ textAlign: "center" }}
      >
        <p>
          <span style={{ fontSize: "1.2rem", fontWeight: "600" }}>You've started <span className={`${theme}-congrat`}>
            {quizContext.state.teamName}
          </span> Team!</span><br />
          To see your team,<br />
          go to TEAM<br />
          To add quizzes and invite users to your team,<br />
          go to EDIT TEAM
        </p>
        <div>
          <Link
            to={`/team/${quizContext.state.teamId}`}
            style={{ textDecoration: "none" }}
          >
            <button
              className={`${theme}-main-btn`}
              onClick={() => quizContext.dispatch({
                type: "backToMainPage"
              })}
            >
              TEAM
            </button>
          </Link>
          <Link
            to={`/edit_team/${quizContext.state.teamId}`}
            style={{ textDecoration: "none" }}
          >
            <button
              className={`${theme}-main-btn`}
              onClick={() => quizContext.dispatch({
                type: "backToMainPage"
              })}
            >
              EDIT TEAM
            </button>
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ModalForStartTeam