import axios from "axios";
import getUserMembPlan from "../userActions/GetUserMembPlan";

// ButtonResult

const getResult = async (dispatch, state, id) => {

  let error = state.error;
  let errorMySQL = state.errorMySQL;

  const timesTaken = state.quiz.type === "PUBLIC"
    && (state.currentUser === null || state.quiz.author_id !== state.currentUser.id)
    ? state.timesTaken + 1
    : state.timesTaken;
  const authorScore = state.quiz.type === "PUBLIC"
    && (state.currentUser === null || state.quiz.author_id !== state.currentUser.id)
    ? state.quiz.score + 1
    : state.quiz.score;

  const authorNumPubQuizzes = state.quiz.num_pub_quizzes;

  const authorMem = state.quiz.type === "PUBLIC"
    && state.quiz.payment === "FALSE"
    && ((state.currentUser && state.quiz.author_id !== state.currentUser.id) || !state.currentUser)
    ? getUserMembPlan(authorNumPubQuizzes, authorScore, state.quiz.mem, state)
    : state.quiz.mem;

  const quizId = id;
  const authorId = state.quiz.author_id;

  if (quizId !== "unsaved" && state.quiz.type === "PUBLIC") {
    try {
      await axios.post(`/quiz/timestaken/${quizId}`, {
        times_taken: timesTaken,
        score: authorScore,
        mem: authorMem,
        author_id: authorId
      })
    } catch (err) {
      error = err.response.data.message;
      console.log("getResult err:", error);
    }

    errorMySQL = error === "MySQL error"
      ? true
      : false;

    return dispatch({
      type: "getResult",
      error,
      errorMySQL,
      timesTaken,
      quizId
    })
  } else {
    return dispatch({
      type: "getResult",
      quizId,
      errorMySQL
    })
  }
}

export default getResult 