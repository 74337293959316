// CardCreateTeam

const setTypeAddQuiz = (state, action) => {
  const typeAddQuiz = action.payload;

  return {
    ...state,
    typeAddQuiz,
  };
}

export default setTypeAddQuiz