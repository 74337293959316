import React, { useContext } from "react";
import { QuizContext } from "../../App";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";

const AccordionComments = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const com = [...quizContext.state.comments];
  const commentsList = com.reverse();


  return (
    <>

      <Accordion data-bs-theme={theme}>
        <Accordion.Item eventKey="0">
          <Accordion.Header style={{ color: "black" }}>
          КОММЕНТАРИИ
          </Accordion.Header>
          <Accordion.Body>
            <table style={{ width: "100%" }}>
              {commentsList.filter(el => el.comment !== null).map((el, i) => (
                <tbody key={i}>
                  <tr>
                    <th
                      style={{
                        paddingTop: "20px",
                        fontWeight: "500",
                        textAlign: "left",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "600"
                        }}
                      >
                        <Link
                          to={`/user/${el.user_took_id}`}
                          style={{
                            textDecoration: "none",
                          }}
                        >
                          {el.username}
                        </Link>
                      </span> {el.recom === "REC"
                        ? <span  className={`${theme}-rec`}>рекомендует</span>
                        : <span className="unrec">не рекомендует</span>
                      } этот квиз
                    </th>
                  </tr>

                  <tr>
                    <th
                      style={{
                        paddingBottom: "20px",
                        fontWeight: "500",
                        fontSize: "1.1rem",
                        borderBottom: "1px solid #dadada",
                        textAlign: "left",
                      }}
                    >
                      {el.comment}
                    </th>
                  </tr>
                </tbody>
              ))}
            </table>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  )
}

export default AccordionComments