import React from "react";
import Spinner from "react-bootstrap/Spinner";

const Loading = () => {
  return (
    <div
      style={{
        textAlign: "center",
        marginTop: "100px"
      }}>
      <Spinner animation="border" />
    </div>
  )
}

export default Loading