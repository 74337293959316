// SubmitEditedQuiz (an action file)

const getUserMembPlan = (pubQ, score, mem, state) => {

  const stuQ = state.membPlan.student.quizzes;
  const stuP = state.membPlan.student.points;
  const brzQ = state.membPlan.bronze.quizzes;
  const brzP = state.membPlan.bronze.points;
  const slvQ = state.membPlan.silver.quizzes;
  const slvP = state.membPlan.silver.points;
  const gldQ = state.membPlan.gold.quizzes;
  const glsP = state.membPlan.gold.points;
  const guruQ = state.membPlan.guru.quizzes;
  const guruP = state.membPlan.guru.points;

  const getUserMem = mem === "ADM"
  ? "ADM"
  : ((pubQ >= stuQ && pubQ < brzQ) && score >= stuP)
    || (pubQ >= stuQ && (score >= stuP && score < brzP))
    ? "STU"
    : ((pubQ >= brzQ && pubQ < slvQ) && score >= brzP)
      || (pubQ >= brzQ && (score >= brzP && score < slvP))
      ? "BRZ"
      : ((pubQ >= slvQ && pubQ < gldQ) && score >= slvP)
        || (pubQ >= slvQ && (score >= slvP && score < glsP))
        ? "SLV"
        : ((pubQ >= gldQ && pubQ < guruQ) && score >= glsP)
          || (pubQ >= gldQ && (score >= glsP && score < guruP))
          ? "GLD"
          : pubQ >= guruQ && score >= guruP
            ? "GURU"
            : "STD";

  return getUserMem
}

export default getUserMembPlan