import React, { useContext } from "react";
import { QuizContext } from "../../App";
// import switchToGetOTPsignup from "../reducer/actions/userActions/SwitchToGetOTPsignup";

const ButtonSignUp = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <>
      {quizContext.state.formFilled === true
        ? <button
          className={`${theme}-main-btn`}
          type="submit"
        // onClick={(e) => {
        //   e.preventDefault()
        //   switchToGetOTPsignup(quizContext.dispatch, quizContext.state)
        //   quizContext.dispatch({ type: "switchToGetOTPsignup" })
        // }}
        >
          СОЗДАТЬ АККАУНТ
        </button>
        : <button className={`${theme}-main-btn-inactive`}>
          <span style={{ cursor: "crosshair" }}>
            СОЗДАТЬ АККАУНТ
          </span>
        </button>
      }
    </>
  )
}

export default ButtonSignUp