// FormTeamInviteUsers

const getUserIdForSearch = (state, action) => {
  
  const invUserId = action.payload;

  if(!invUserId){
    return{
      ...state
    }
  } else {

  return {
    ...state,
    invUserId,
    invStatus: "type",
    error: null,
  }
}
}

export default getUserIdForSearch