// ModalAddRemoveRow

const removeRowEdit = (state) => {

  const regRow = /tempRowId/;
  const num = state.rowNum - 1;
  const quizRowsEdit = [...state.quizRowsEdit];
  let quizRowsToDelete = [...state.quizRowsToDelete];
  const removed = quizRowsEdit.splice(num, 1);

    if (!regRow.test(removed[0][Object.keys(removed[0])[0]][1].quiz_row_id)) {
      quizRowsToDelete.push(removed[0][Object.keys(removed[0])[0]][1].quiz_row_id);
    }

  return {
    ...state,
    alertTable: false,
    alertStatus: "changes made",
    quizRowsEdit,
    quizRowsToDelete
  }
}

export default removeRowEdit