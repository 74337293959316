import React, { useContext } from "react";
import Accordion from "react-bootstrap/Accordion";
import TextRequirementsXLSX from "../text/TextRequirementsXLSX";
import TextRequirementsTable from "../text/TextRequirementsTable";
import { QuizContext } from "../../App";
import TextHowToEditQuiz from "../text/TextHowToEditQuiz";

const AccordionFAQquizzes = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Accordion
      data-bs-theme={theme}
      className="card-faq"
    >

      <Accordion.Item eventKey="0">
        <Accordion.Header style={{ color: "black" }}>
          Как создать квиз?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>Перейдите на страницу СОЗДАТЬ КВИЗ.</p>

          <p><img src={require('../images/instruction-uploadFile-1.jpg')} style={{ width: "100%", }} alt={'instr'} /></p>

          <p>У Вас есть два варианта создания квиза на выбор:</p>

          <ol>
            <li>Создать квиз из xlsx файла</li>
            <p><img src={require('../images/instruction-create-from-xlsx.jpg')} style={{ width: "100%", }} alt={'instr'} /></p>
            <li>Создать квиз из таблице на сайте.</li>
            <p><img src={require('../images/instruction-create-from-table.jpg')} style={{ width: "100%", }} alt={'instr'} /></p>
          </ol>

          <p>Если Вы не авторизированный пользователь, Вы сможете создать квиз и проходить его, но не сможете сохранить его.</p>
          <p>Если Вы авторизированный пользователь, у Вас есть выбор: сохранить квиз на сайте или пройти его без сохранения.</p>
          <p>Если Вы сохраните свой квиз, он получит автоматически сгенерированное описание, которые Вы сможете изменить впоследствие на странице РЕДАКТИРОВАТЬ КВИЗ.</p>
          <p>Если Вы сохраните свой квиз, он по умолчанию будет сохранён, как ЛИНЧЫЙ. Это значит, что только Вы сможете открывать его и проходить. Впоследствие, Вы сможете изменить его тип на ОБЩЕДОСТУПНЫЙ или КОМАНДНЫЙ.</p>
          <p>ОБЩЕДОСТУПНЫЕ квизы доступны для всех зарегистрированных и незарегистрированных пользователей в БИБЛИОТЕКЕ КВИЗОВ.</p>
          <p>КОМАНДНЫЕ квизы доступны тольк для челонов комнды, в которую Вы его добавили.</p>
          <p>Квиз может быть либо ЛИЧНЫМ, либо ОБЩЕДОСТУПНЫМ, либо КОМАНДНЫМ. Вы можете добавить квиз только в одну команду.</p>
          <p>Вы можете изменить тип любого Вашего ОБЩЕДОСТПНОГО или КОМАНДНОГО квиза на ЛИЧНЫЙ и наоборот в любое.</p>
          <p>Чтобы изменить тип квиза с ОБЩЕДОСТПНОГО на КОМАНДНЫЙ или с КОМАНДНОГО на ОБЩЕДОСТУПНЫЙ, нужно сначала изменить его на ЛИЧНЫЙ.</p>

        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1">
        <Accordion.Header>
          Как подготовить xlsx файл для создания квиза?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <TextRequirementsXLSX />
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header>
          Как заполнить таблицу на сайте для создания квиза?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <TextRequirementsTable />
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3">
        <Accordion.Header>
          Как редактировать квиз?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <TextHowToEditQuiz />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default AccordionFAQquizzes