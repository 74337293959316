import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import { QuizContext } from "../../App";

const FormRecommendQuiz = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (

    <Form>
      <div key={"inline-radio"} className="mb-3">
        <Form.Check
          data-bs-theme={theme}
          label="Я рекомендую этот квиз другим пользователям"
          name="recomendation"
          type="radio"
          checked={quizContext.state.recom === "REC" ? true : false}
          value="REC"
          id={1}
          onChange={(e) => quizContext.dispatch({
            type: "setRecommendQuiz",
            payload: e.target.value
          })}
        />
        <Form.Check
          data-bs-theme={theme}
          label="Я не рекомендую этот квиз другим пользователям"
          name="recomendation"
          type="radio"
          checked={quizContext.state.recom === "UNREC" ? true : false}
          value="UNREC"
          id={2}
          onChange={(e) => quizContext.dispatch({
            type: "setRecommendQuiz",
            payload: e.target.value
          })}
        />
      </div>
    </Form>
  )
}

export default FormRecommendQuiz