import React, { useContext } from "react";
import { QuizContext } from "../../App";
import submitEditedProfile from "../../reducer/actions/userActions/SubmitEditedProfile";
import ButtonCancelAndStay from "./ButtonCancelAndStay";

const ButtonsSubmitEditedProfile = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <div>
      {quizContext.state.username === quizContext.state.currentUser.username
        && quizContext.state.userTheme === quizContext.state.currentUser.theme
        
        ? <button className={`${theme}-main-btn-inactive`}>
          <span style={{ cursor: "crosshair" }}>
            СОХРАНИТЬ
          </span>
        </button>

        : <button
          className={`${theme}-main-btn`}
          onClick={(e) => {
            e.preventDefault()
            submitEditedProfile(
              quizContext.dispatch,
              quizContext.state
            )
            quizContext.dispatch({
              type: "submitEditedProfile"
            })
          }}
        >
          СОХРАНИТЬ
        </button>
      }
      <ButtonCancelAndStay />
    </div>
  )
}

export default ButtonsSubmitEditedProfile