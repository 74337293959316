import axios from "axios";

// ButtonSubmitCreatedTeam

const submitCreatedTeam = async (dispatch, state) => {

  let error = state.error;
  const userNumTeams = state.currentUser.num_teams + 1;
  let newTeam;

  try {
    newTeam = await axios.post('/team/add', {
      teamname: state.teamName,
      admin_id: state.currentUser.id,
      type_add_quiz: state.typeAddQuiz,
      type_add_memb: state.typeAddMemb,
      user_id: state.currentUser.id,
      user_type: "ADMIN",
      num_teams: userNumTeams
    });
  } catch (err) {
    error = err.response.data.message;
    console.log("submitCreatedTeam err:", error);
  }

  const teamId = newTeam.data.id;

  return dispatch({
    type: "submitCreatedTeam",
    error,
    teamId
  });

}

export default submitCreatedTeam