import React, { useContext } from "react";
import { QuizContext } from "../../App";

const TextTeamNameAndSettings = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  const admin = "Только я могу"
  const some = "Только я и мои ассистенты можем"
  const all = "Все члены команды могут"

  return (
    <>
      <table style={{ width: "100%" }}><tbody>
        <tr
          style={{
            borderBottom: theme === "dark"
              ? "1px solid #d0d2d4"
              : "1px solid #212529"
          }}
        >
          <th
            style={{
              fontWeight: "500",
              fontSize: "1rem",
              textAlign: "left",
              width: "25%",
              padding: "2% 0"
            }}
          >
            Навзание команды:
          </th>
          <th
            style={{
              fontWeight: "500",
              fontSize: "1rem",
              textAlign: "left",
              padding: "2% 0"
            }}
          >
            {quizContext.state.team.teamname}
          </th>
        </tr>
        <tr>
          <th
            style={{
              fontWeight: "500",
              fontSize: "1rem",
              textAlign: "left",
              padding: "2% 0"
            }}
          >
            Установки:
          </th>
          <th
            style={{
              fontWeight: "500",
              fontSize: "1rem",
              textAlign: "left",
              padding: "2% 0"
            }}
          >
            {quizContext.state.team.type_add_quiz === 'ALL'
              ? all
              : quizContext.state.team.type_add_quiz === 'SOME'
                ? some
                : admin
            } добавлять квизы в команду
          </th>
        </tr>
        <tr
          style={{
            borderBottom: theme === "dark"
              ? "1px solid #d0d2d4"
              : "1px solid #212529"
          }}
        >
          <th>
          </th>
          <th
            style={{
              fontWeight: "500",
              fontSize: "1rem",
              textAlign: "left",
              padding: "0 0 2% 0"
            }}
          >
            {quizContext.state.team.type_add_memb === 'ALL'
              ? all
              : quizContext.state.team.type_add_memb === 'SOME'
                ? some
                : admin
            } приглашать пользователей в команду
          </th>
        </tr>
      </tbody></table>

      <div style={{ margin: "25px 0 5px 0", textAlign: "center" }}>
        <button
          className={`${theme}-main-btn`}
          onClick={() => quizContext.dispatch({
            type: "goToEditNameSettings"
          })}
        >
          РЕДАКТИРОВАТЬ
        </button>
      </div>


    </>
  )
}

export default TextTeamNameAndSettings