import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Table from 'react-bootstrap/Table';
import { QuizContext } from "../../App";

const ModalInvitations = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Modal
      data-bs-theme={theme}
      show={quizContext.state.alert}
      onHide={() => quizContext.dispatch({
        type: "hideAlert"
      })}
    >
      <Modal.Header closeButton data-bs-theme={theme}
        className={`${theme}-modal-header`}
      >
        ПРИГЛАШЕНИЯ В ГРУППЫ
      </Modal.Header>
      <Modal.Body
        className={`${theme}-modal-body`}
        style={{
          maxHeight: "400px",
          overflowX: "hidden",
        }}
      >
        <Table
          striped
          style={{ width: "100%" }}
        >
          {!quizContext.state.userInvitations.length
            ? <tbody><tr><th style={{ color: "red" }}>
              У Вас пока нет приглашений в группы
            </th ></tr></tbody>
            : <tbody>
              {quizContext.state.userInvitations.map((el, i) => (
                <tr key={i}>
                  <th style={{ textAlign: "left" }}>
                    <Link
                      to={`/team/${el.team_id}`}
                      style={{
                        textDecoration: "none",
                        color: theme === "dark"
                          ? "#4da2f7"
                          : "#0a70d6"
                      }}
                      onClick={() => quizContext.dispatch({
                        type: "backToMainPage"
                      })}
                    >
                      {el.teamname}
                    </Link>
                  </th>
                  <th style={{ textAlign: "right" }}>
                    <span
                      style={{
                        fontSize: "1.5rem",
                        cursor: "pointer"
                      }}
                      onClick={() => quizContext.dispatch({ type: "goToAcceptTeamInv", el })}
                    >
                      &#9989;
                    </span>
                    <span
                      style={{
                        fontSize: "1.4rem",
                        marginLeft: "25px",
                        cursor: "pointer"
                      }}
                      onClick={() => quizContext.dispatch({ type: "goToDeclineTeamInv", el })}
                    >
                      &#10060;
                    </span>
                  </th>
                </tr>
              ))}
            </tbody>
          }
        </Table>
      </Modal.Body>
    </Modal>
  )
}

export default ModalInvitations