import React, { useContext } from "react";
import { QuizContext } from "../../App";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import ButtonRunSavedQuiz from "../buttons/ButtonRunSavedQuiz";
import descAuth from "../../reducer/actions/quizActions/DescAuth";


const TableQuizCharacteristics = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const date = new Date(quizContext.state.quiz.created_at);
  const year = date.getFullYear();
  const monthName = date.toLocaleString('ru-RU', { month: 'long' });

  const opNum = quizContext.state.quiz.quizOptions.length === 12
    || quizContext.state.quiz.quizOptions.length === 13
    || quizContext.state.quiz.quizOptions.length === 14
    ? quizContext.state.quiz.quizOptions.length
    : Number(quizContext.state.quiz.quizOptions.length.toString().split("").pop());

  const wordOpt = opNum === 2 || opNum === 3 || opNum === 4
    ? "опции"
    : "опций";

  const desc = `Этот квиз содержит ${quizContext.state.quiz.quizOptions.length} ${wordOpt}: ${quizContext.state.quiz.quizOptions.join(" -|- ")}.`


  // https://www.cluemediator.com/find-urls-in-string-and-make-a-link-using-javascript - how to make link in desc
  const quizDesc = !quizContext.state.quizDescAuthor
    ? desc
    : descAuth(quizContext.state.quizDescAuthor);

  const quizType = quizContext.state.quiz.type === "PUBLIC"
    ? "ОБЩЕДОСТУПНЫЙ"
    : quizContext.state.quiz.type === "TEAM"
      ? "КОМАНДНЫЙ"
      : "ЛИЧНЫЙ";

  const timesTaken = quizContext.state.timesTaken === 12
    || quizContext.state.timesTaken === 13
    || quizContext.state.timesTaken === 14
    ? quizContext.state.timesTaken
    : Number(quizContext.state.timesTaken.toString().split("").pop());

  const word = timesTaken === 2 || timesTaken === 3 || timesTaken === 4 ?
    "раза" :
    "раз";


  return (
    <Table
      striped
      variant={theme}
      className="quiz-title-table"
    >
      <tbody>
        <tr>
          <td colSpan="2" style={{ textAlign: "center" }}>
            <ButtonRunSavedQuiz />
          </td>
        </tr>
        <tr>
          <td className="table-fontsize" style={{ width: "25%" }}>
            Описание:
          </td>
          <td className="table-fontsize" style={{ whiteSpace: "pre-line" }} >
            <span dangerouslySetInnerHTML={{ __html: quizDesc }} />
          </td>
        </tr>

        <tr>
          <td className="table-fontsize" style={{ width: "25%" }}>
            Тип:
          </td>
          <td className="table-fontsize">
            {theme === "dark"
              ? <img
                src={quizContext.state.quiz.type === "PUBLIC"
                  ? require("../images/publicW.png")
                  : quizContext.state.quiz.type === "TEAM"
                    ? require("../images/teamW.png")
                    : require("../images/privateW.png")
                }
                style={{
                  width: "15px",
                  marginBottom: "3px",
                  marginRight: "5px"
                }}
                alt={"type"}
              />
              : <img
                src={quizContext.state.quiz.type === "PUBLIC"
                  ? require("../images/public.png")
                  : quizContext.state.quiz.type === "TEAM"
                    ? require("../images/team.png")
                    : require("../images/private.png")
                }
                style={{
                  width: "15px",
                  marginBottom: "3px",
                  marginRight: "5px"
                }}
                alt={"type"}
              />}{quizType}
          </td>
        </tr>

        {quizContext.state.quiz.type === "PUBLIC" &&
          <>
            <tr>
              <td className="table-fontsize" style={{ width: "25%" }}>
                Категория:
              </td>
              <td className="table-fontsize">
                <Link
                  to={`/category/${quizContext.state.quiz.cat_id}`}
                  style={{
                    textDecoration: "none",
                    color: theme === "dark"
                      ? "#4da2f7"
                      : "#0a70d6"
                  }}
                  onClick={() => quizContext.dispatch({
                    type: "backToMainPage"
                  })}
                >
                  {quizContext.state.quiz.catname_ru}
                </Link>
              </td>
            </tr>
            <tr>
              <td className="table-fontsize" style={{ width: "25%" }}>
                Язык:
              </td>
              <td className="table-fontsize">
                {quizContext.state.quiz.lang_ru}
              </td>
            </tr>
            <tr>
              <td className="table-fontsize" style={{ width: "25%" }}>
                Рейтинг:
              </td>
              <td className="table-fontsize">
                <span className={`${theme}-rec`}>рекомендован: {quizContext.state.quiz.rec_quiz}</span><br />
                <span className="unrec">не рекомендован: {quizContext.state.quiz.unrec_quiz}</span>
              </td>
            </tr>
            <tr>
              <td className="table-fontsize" style={{ width: "25%" }}>
                Пройден:
              </td>
              <td className="table-fontsize">
                {quizContext.state.timesTaken} {word}
              </td>
            </tr>
          </>
        }
        {quizContext.state.quiz.type === "TEAM" &&
          <tr>
            <td className="table-fontsize" style={{ width: "25%" }}>
              {quizContext.state.teamData.length === 1
                ? <span>Команда:</span>
                : <span>Команды:</span>
              }
            </td>
            <td className="table-fontsize">
              {quizContext.state.teamData.map((el, i) => (
                <Link
                  to={`/team/${el.team_id}`}
                  style={{
                    textDecoration: "none",
                    color: theme === "dark"
                      ? "#4da2f7"
                      : "#0a70d6",
                    marginRight: "20px"
                  }}
                  key={i}
                >
                  {el.teamname}
                </Link>
              ))}
            </td>
          </tr>
        }
        <tr>
          <td className="table-fontsize" style={{ width: "25%" }}>
            Создан:
          </td>
          <td className="table-fontsize">
            {monthName} {year}
          </td>
        </tr>
      </tbody>
    </Table>
  )
}

export default TableQuizCharacteristics