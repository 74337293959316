//FormTeamInviteUsers, FormTeamRemoveMember

const notInvite = (state) => {

  return {
    ...state,
    invUser: null,
    teamMemberName: "",
    removeTeamMemberStatus: "choose",
    invUserId: ""
  }
}

export default notInvite