// ModalInvitations

const goToAcceptTeamInv = (state, action) => {

  const teamUsersId = action.el.id;
  const teamId = action.el.team_id;
  const teamName = action.el.teamname;
  const teamMemberId = action.el.user_id;
  const teamMemberType = action.el.user_type;

  return {
    ...state,
    alert: true,
    alertStatus: "accept inv",
    teamUsersId,
    teamName,
    teamId,
    teamMemberId,
    teamMemberType
  }

}

export default goToAcceptTeamInv