import React, { useContext } from "react";
import { QuizContext } from "../../App";

const CardMembershipStandard = () => {

  const quizContext = useContext(QuizContext);

  return (
    <div className="card-membership">
      <div className="membership">
        {quizContext.state.currentUser.mem === "STD"
          && <span>&#9679;</span>} STANDARD
      </div>


      <div className="conditions">
        <p><span className="tic">&#10003;</span> Access to Quiz Library</p>
        <p><span className="tic">&#10003;</span> Creating quizzes</p>
        <p><span className="tic">&#10003;</span> Joining teams</p>
        <p style={{ marginBottom: "12%" }}><span className="tic" style={{ color: "red" }}>&times;</span> <span style={{ textDecoration: "line-through" }}>Starting teams</span>
        </p>
      </div>
      <div className="price-area">
        <span className="free-stand">
          FREE
        </span>
      </div>


      {/* <div className="price-area"style={{margin: "35px 0"}}>
        <span className="free-stand">
          FREE
        </span><br />
        <span
          style={{
            fontSize: "1rem",
            lineHeight: "0.1rem",
          }}
        >
          for everyone
        </span>
      </div> */}

    </div>
  )
}

export default CardMembershipStandard