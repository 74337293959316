import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CardMembershipStandard from "../components/cards/CardMembershipStandard";
import CardMembershipBronze from "../components/cards/CardMembershipBronze";
import CardMembershipSilver from "../components/cards/CardMembershipSilver";
import CardMembershipGold from "../components/cards/CardMembershipGold";
import CardMembershipGuru from "../components/cards/CardMembershipGuru";


const UpgradeMembership = () => {
  return (
    <div className="wrapper">
      <div
        style={{
          textAlign: "center",
          fontSize: "1.5rem",
          margin: "5px 0",
          // marginBottom: "25px"
        }}
      >
        MEMBERSHIP PLANS
      </div>
     
      <Row className="justify-content-center">
        <Col sm={12} md={6} lg={3}>
          <CardMembershipStandard />
        </Col>
        <Col sm={12} md={6} lg={3}>
          <CardMembershipBronze />
        </Col>
        <Col sm={12} md={6} lg={3}>
          <CardMembershipSilver />
        </Col>
        <Col sm={12} md={6} lg={3}>
          <CardMembershipGold />
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col sm={12} md={12} lg={12}>
          <CardMembershipGuru />
        </Col>
      </Row>
    </div>
  )
}

export default UpgradeMembership