import axios from 'axios';

// ButtonsDeleteTeam

const deleteTeam = async (dispatch, state) => {
  let error = state.error;
  const teamId = state.team.id;
  const userNumTeams = state.team.num_teams - 1;
 
  try {
await axios.post(`/team/delete/${teamId}`, {num_teams: userNumTeams});
  } catch (err) {
    error = err.response.data.message;
    console.log("deleteTeam err:", error);
  }
  
  return dispatch({
    type: "deleteTeam",
    error
  });
}

export default deleteTeam