import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import { QuizContext } from "../../App";

const FormSelectQuestion = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Form.Select
      data-bs-theme={theme}
      style={{
        marginBottom: "15px",
        border: theme === "dark"
          ? "1px solid #77797c"
          : "1px solid #212529"
      }}
      onChange={(e) => quizContext.dispatch({
        type: "selectQuestion",
        payload: e.target.value
      })}
    >
      <option value={quizContext.state.question}>{quizContext.state.question}</option>
      {quizContext.state.quizOptions.map((el, i) =>
        <option key={i} value={el}>{el}</option>
      )}
    </Form.Select>
  )
}

export default FormSelectQuestion