import React, { useContext } from 'react'
import { QuizContext } from '../../App';
import Form from 'react-bootstrap/Form';

const FormSearchItem = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Form>

      <div>
        <Form.Control
          type="text"
          size="sm"
          data-bs-theme={theme}
          style={{
            border: theme === "dark"
              ? "1px solid #77797c"
              : "1px solid #c6c6c7"
          }}
          placeholder="Поиск"
          onChange={(e) => quizContext.dispatch({
            type: "getItemForSearch",
            payload: e.target.value
          })}
        />
      </div>

    </Form>
  )
}

export default FormSearchItem