import React, { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Table from 'react-bootstrap/Table';
import { QuizContext } from "../../App";
import ModalAddRemoveColumn from "../modals/ModalAddRemoveColumn";
import ModalAddRemoveRow from "../modals/ModalAddRemoveRow";
import ButtonsResizeFontCell from "./ButtonsResizeFontCell";


const ButtonModalFullScreenTableCreateQuiz = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const fontSize = quizContext.state.tableFontSize;
  const cellSize = quizContext.state.cellSize;

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button
        className={`${theme}-full-screen-btn`}
        onClick={handleShow}
      >
        ПОЛНОЭКРАННЫЙ РЕЖИМ
      </button>

      <Modal
        data-bs-theme={theme}
        show={show}
        fullscreen={true}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton
          data-bs-theme={theme}
          className={`${theme}-modal-header`}
        >
          <ButtonsResizeFontCell />
        </Modal.Header>
        <Modal.Header
          className={`${theme}-modal-header`}
          style={{
            border: "none",
            paddingTop: "3px",
            paddingBottom: "1px"
          }}
        >
          Чтобы добавить/удалить столбец/строку нажмите её номер
        </Modal.Header>
        <Modal.Body className={`${theme}-modal-body`}>
          <Table
            className="table w-auto"
            variant={theme}
            style={{
              margin: "0 auto 0 auto",
              width: "100%",
              border: "1px solid #babfc4",
            }}
          >
            <thead>
              <tr>
                {quizContext.state.headers.map((el, i) =>
                  <td
                    key={i}
                    style={{
                      border: "1px solid #babfc4",
                      textAlign: "center",
                      cursor: "pointer"
                    }}>
                    {el === "#"
                      ? null
                      : <button className={`${theme}-col-num-btn`}
                        onClick={() => quizContext.dispatch({
                          type: "showModalAddRemoveColumn",
                          num: i
                        })}
                      >
                        {i}
                      </button>
                    }
                  </td>)}
              </tr>
              <tr>
                {quizContext.state.headers.map((el, i) =>
                  <th key={i} style={{ border: "2px solid #babfc4" }}>
                    {el === "#"
                      ? <>{el}</>
                      : <Form.Control
                        type="text"
                        size="sm"
                        data-bs-theme={theme}
                        value={el}
                        placeholder="Заголовок"
                        style={{
                          textAlign: "center",
                          border: "none",
                          fontWeight: "600",
                          width: "auto",
                          color: theme === "dark"
                            ? "white"
                            : "black",
                          fontSize: `${fontSize}rem`
                        }}
                        onChange={(e) => quizContext.dispatch({
                          type: "getHeader",
                          payload: e.target.value,
                          key: i
                        })}
                      />
                    }
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {quizContext.state.values.map((v, vi) =>
                <tr key={vi} style={{ border: "1px solid #babfc4" }}>
                  {quizContext.state.headers.map((h, hi) =>
                    <td key={hi} style={{ border: "1px solid #babfc4" }}>
                      {h === "#"
                        ? <button className={`${theme}-row-num-btn`}
                          onClick={() => quizContext.dispatch({
                            type: "showModalAddRemoveRow",
                            num: vi + 1
                          })}
                        >
                          {vi + 1}
                        </button>
                        :
                        <Form.Control
                          type="text"
                          as="textarea"
                          data-bs-theme={theme}
                          rows={cellSize}
                          placeholder="Значение"
                          value={v[hi - 1]}
                          style={{
                            textAlign: "left",
                            border: "none",
                            width: "auto",
                            minWidth: "100%",
                            color: theme === "dark"
                              ? "white"
                              : "black",
                            fontSize: `${fontSize}rem`
                          }}
                          onChange={(e) => quizContext.dispatch({
                            type: "getValues",
                            payload: e.target.value,
                            key: vi,
                            objKey: hi - 1,
                          })}
                        />
                      }
                    </td>
                  )}
                </tr>
              )}
            </tbody>
          </Table>

          {quizContext.state.alertStatus === "column"
            ? <ModalAddRemoveColumn />
            : quizContext.state.alertStatus === "row"
              ? <ModalAddRemoveRow />
              : null
          }
        </Modal.Body>
      </Modal>

    </>
  )
}

export default ButtonModalFullScreenTableCreateQuiz