import React, { useContext, useEffect } from "react";
import axios from "axios";
import { QuizContext } from "../App";
import Error from "./Error";
import CardCreateTeam from "../components/cards/CardCreateTeam";
import TextBanTeamStart from "../components/text/TextBanTeamStart";
import ModalForStartTeam from "../components/modals/ModalForStartTeam";
import { banTeamStart } from "../reducer/actions/groupActions/BanTeam";
import Loading from "../components/Loading";


const TeamStart = () => {

  const quizContext = useContext(QuizContext);
  const ban = banTeamStart(
    quizContext.state.currentUser.mem,
    quizContext.state.currentUser.num_teams,
    quizContext.state
  );

  const dispatch = quizContext.dispatch;

  // Check Server
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/auth/check");
        // console.log("res.data", res.data)
        const checkServer = res.data
        // console.log("checkServer", checkServer)
        dispatch({ type: "fetchCheckServer", checkServer });

      } catch (err) {
        const error = err.response.data.message;
        console.log("Check Server error:", error);
        dispatch({ type: "fetchCheckServerErr", error });
      }
    }
    fetchData();
  }, [dispatch]);

  return (
    <div style={{paddingBottom: "310px"}}>
      {quizContext.state.loading
        ? <Loading />
        : quizContext.state.errorMySQL
          || quizContext.state.errorAuth
          ? <Error />
          : ban === true
            ? <TextBanTeamStart />
            : <>
              <h2 className="header">
                START TEAM
              </h2>
              <CardCreateTeam />
            </>
      }

      {quizContext.state.alert
        && <ModalForStartTeam />
      }
    </div>
  )
}

export default TeamStart