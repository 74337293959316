import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import { QuizContext } from "../../App";

const FormEditQuizLang = () => {
  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Form style={{ textAlign: "center", marginTop: "30px" }}>
      <Form.Label>
        {quizContext.state.oldQuizType === "PUBLIC"
          && quizContext.state.quizType === "PUBLIC"
          ? <span>Редактировать язык</span>
          : <span>Добавить язык</span>
        }

      </Form.Label>

      <Form.Select
        data-bs-theme={theme}
        style={{
          border: theme === "dark"
            ? "1px solid #77797c"
            : "1px solid #212529"
        }}
        onChange={(e) => quizContext.dispatch({
          type: "editQuizLang",
          payload: e.target.value
        })}
      >
        {quizContext.state.languageRU === null
          ? <option value="- Select language -">
            - Выбрать язык -
          </option>
          : <option value={quizContext.state.languageRU}>
            {quizContext.state.languageRU}
          </option>
        }
        {quizContext.state.langArrRU.map((el, i) =>
          <option key={i} value={el}>{el}</option>
        )}
      </Form.Select>
    </Form>
  )
}

export default FormEditQuizLang