import React, { useContext } from "react";
import { QuizContext } from "../../App";
// import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
// import Table from 'react-bootstrap/Table';
import TableTeamQuizzes from "../tables/TableTeamQuizzes";
import TableTeamMembers from "../tables/TableTeamMembers";
import TableTeamInvited from "../tables/TableTeamInvited";
import FormSearchItem from "../forms/FormSearchItem";

const AccordionTeam = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Accordion
      data-bs-theme={theme}
      style={{ boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px" }}
    >
      <Accordion.Item eventKey="0">
        <Accordion.Header style={{ color: "black" }}>
          Quizzes: {quizContext.state.teamQuizzes.length}
        </Accordion.Header>
        <Accordion.Body className="accor">
          {quizContext.state.teamQuizzes.length > quizContext.state.itemArrLength && <FormSearchItem />}
          <TableTeamQuizzes />
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1">
        <Accordion.Header>
          Members: {quizContext.state.members.length}
        </Accordion.Header>
        <Accordion.Body className="accor">
          {quizContext.state.members.length > quizContext.state.itemArrLength && <FormSearchItem />}
          <TableTeamMembers />
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header>
          Invitated: {quizContext.state.invited.length}
        </Accordion.Header>
        <Accordion.Body className="accor">
          {quizContext.state.invited.length > quizContext.state.itemArrLength && <FormSearchItem />}
          <TableTeamInvited />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default AccordionTeam