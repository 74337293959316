// AuthLogIn, QuizCreate, CardNavLogInSignUp

const switchToSignup = (state) => {
  return {
    ...state,
    loading: true,
    video: false,
    auth: "signup",
    currentUser: null,
    error: null
  }
}

export default switchToSignup