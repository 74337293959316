import React, { useContext } from "react";
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { QuizContext } from "../../App";

const TableLibrary = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <div className="table">
      <Table
        striped hover
        variant={theme}
        style={{
          border: theme === "dark"
            ? "1px solid black"
            : "1px solid #dadada",
        }}
      >
        <thead>
          <tr>
            <th style={{ fontWeight: "500" }}>Category</th>
            <th style={{ fontWeight: "500" }}>Quizzes</th>
          </tr>
        </thead>
        <tbody>
          {quizContext.state.cat.map((el, i) => (
            <tr key={i}>
              <th
                style={{
                  cursor: "pointer",
                  textAlign: "left",
                  width: "75%"
                }}
              >
                <Link
                  to={`/category/${el.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <button className={`${theme}-link-btn`}
                    onClick={() => quizContext.dispatch({
                      type: "openCatQuizzes", el
                    })}
                  >
                    {el.catname}
                  </button>
                </Link>
              </th>

              <th
                style={{
                  cursor: "context-menu",
                  textAlign: "center",
                  fontWeight: "500"
                }}
              >
                {el.num_of_quizzes}
              </th>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default TableLibrary