// ButtonsChangeFontSize

export const reduceQuestionFontSize = (state) => {

  const questionFontSize = state.questionFontSize - 1;

  return {
    ...state,
    questionFontSize
  }
};

export const increaseQuestionFontSize = (state) => {

  const questionFontSize = state.questionFontSize + 1;

  return {
    ...state,
    questionFontSize
  }
};


export const reduceAnswerFontSize = (state) => {

  const answerFontSize = state.answerFontSize - 1;

  return {
    ...state,
    answerFontSize
  }
};

export const increaseAnswerFontSize = (state) => {

  const answerFontSize = state.answerFontSize + 1;

  return {
    ...state,
    answerFontSize
  }
};

export const reduceTableFontSize = (state) => {

  const tableFontSize = state.tableFontSize - 0.5;

  return {
    ...state,
    tableFontSize
  }
};

export const increaseTabeFontSize = (state) => {

  const tableFontSize = state.tableFontSize + 0.5;

  return {
    ...state,
    tableFontSize
  }
};

export const reduceCellSize = (state) => {

  const cellSize = state.cellSize - 1;

  return {
    ...state,
    cellSize
  }
};

export const increaseCellSize = (state) => {

  const cellSize = state.cellSize + 1;

  return {
    ...state,
    cellSize
  }
};
