import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import { QuizContext } from "../../App";


const FormTeamSetTypeAddQuiz = () => {

  const quizContext = useContext(QuizContext);

  return (
    <div key={"inline-radio"} className="mb-3">

      {quizContext.state.radioValues.map((el, i) =>
        <Form.Check
          inline
          label={el.label}
          key={i}
          name="team quiz"
          type="radio"
          checked={
            quizContext.state.typeAddQuiz === el.value
              ? true : false}
          value={el.value}
          id={i + 1}
          onChange={(e) => quizContext.dispatch({
            type: "setTypeAddQuiz",
            payload: e.target.value
          })}
        />
      )}
    </div>
  )
}

export default FormTeamSetTypeAddQuiz