import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import { QuizContext } from "../../App";
import ButtonAddColumn from "../buttons/ButtonAddColumn";
import ButtonAddRow from "../buttons/ButtonAddRow";

const FormTableEditQuizContents = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <>
      <Table
        className="table w-auto"
        variant={theme}
        style={{
          margin: "0 auto 0 auto",
          width: "100%",
          border: "1px solid #babfc4",
        }}
      >
        <tbody>
          <tr>
          <th
              colSpan={quizContext.state.quizOptionsEdit.length-1}
              style={{ textAlign: "left", width: "70%", fontSize: "0.8rem", fontWeight: "400" }}
            >
              Чтобы удалить столбец/строку нажмите её номер.
            </th>
            <th
              style={{ textAlign: "right" }}
            >
              <ButtonAddColumn />
            </th>
          </tr>
          <tr>
            {quizContext.state.quizOptionsEdit.map((el, i) =>
              <th key={i} style={{ border: "1px solid #babfc4", textAlign: "center", cursor: "pointer" }}>
                {el === "#"
                  ? null
                  : quizContext.state.quizOptionsEdit.length > 3
                    ? <button className={`${theme}-col-num-btn`}
                      onClick={() => quizContext.dispatch({
                        type: "showModalAddRemoveColumn",
                        num: i
                      })}
                    >
                      {i}
                    </button>
                    : <span style={{ fontWeight: "500" }}>{i}</span>
                }
              </th>
            )}
          </tr>
          <tr>
            {quizContext.state.quizOptionsEdit.map((el, i) =>
              <td key={i} style={{ border: "2px solid #babfc4" }}>
                {el === "#"
                  ? <>{el}</>
                  : <Form.Control
                    data-bs-theme={theme}
                    size="sm"
                    type="text"
                    style={{
                      textAlign: "center",
                      border: "none",
                      fontWeight: "600",
                      width: "auto"
                    }}
                    value={el.header}
                    onChange={(e) => quizContext.dispatch({
                      type: "getOptionValue",
                      payload: e.target.value,
                      key: i,
                      objKey: el.id
                    })}
                  />
                }
              </td>

            )}
          </tr>
        </tbody>
        <tbody>
          {quizContext.state.quizRowsEdit.map((v, vi) =>
            <tr key={vi} style={{ border: "1px solid #babfc4" }}>
              {quizContext.state.quizOptionsEdit.map((h, hi) =>
                <td key={hi} style={{ border: "1px solid #babfc4" }}>
                  {h === "#" && quizContext.state.quizRowsEdit.length > 5
                    ? <button className={`${theme}-row-num-btn`}
                      onClick={() => quizContext.dispatch({
                        type: "showModalAddRemoveRow",
                        num: vi + 1
                      })}
                    >{vi + 1}
                    </button>
                    : h === "#" && quizContext.state.quizRowsEdit.length <= 5
                      ? <span style={{ fontWeight: "500" }}>{vi + 1}</span>
                      : <Form.Control
                        data-bs-theme={theme}
                        size="sm"
                        type="text"
                        as="textarea"
                        rows={1}
                        style={{
                          textAlign: "left",
                          border: "none",
                          width: "auto"
                        }}
                        value={v[h.id][0]}
                        onChange={(e) => quizContext.dispatch({
                          type: "getRowValue",
                          payload: e.target.value,
                          key: vi,
                          objKey: h.id,
                          objKeyInd: hi
                        })}
                      />
                  }
                </td>
              )}
            </tr>
          )}
          <tr style={{ border: "none" }}>
            <th
              colSpan={quizContext.state.quizOptionsEdit.length + 1}
              style={{ textAlign: "left", border: "none" }}
            >
              <ButtonAddRow />
            </th>
          </tr>
        </tbody>
      </Table>
    </>
  )
}

export default FormTableEditQuizContents