const showModalContactSupport = (state, action) => {

  const alertStatus = action.alertStatus;

  const usernameCont = state.currentUser !== null
    ? state.currentUser.username
    : state.usernameCont;

  const emailCont = state.currentUser !== null
    ? state.currentUser.email
    : state.emailCont;

  return {
    ...state,
    alert: true,
    alertStatus,
    usernameCont,
    emailCont
  }
}

export default showModalContactSupport