import React, { useContext } from "react";
import { QuizContext } from "../../App";
import Accordion from "react-bootstrap/Accordion";

const AccordionFAQlibrary = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const changeType = quizContext.state.quizPoints.changeType;

  return (
    <Accordion
      data-bs-theme={theme}
      className="card-faq"
    >

      <Accordion.Item eventKey="0">
        <Accordion.Header style={{ color: "black" }}>
          How to take QUIZ LIBRARY quizzes?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>Go to QUIZ LIBRARY:</p>

          <p><img src={require('../images/instruction-QuizLibrary-1.jpg')} style={{ width: "100%", }} alt={'instr'} /></p>

          <p>Choose a category and click. Choose a quiz and click. You’ll get to the Quiz Title Page. Over there you can read the quiz description, see how many times this quiz has been taken, see how many users recommend this quiz, see how many users do not recommended this quiz, and read users’ comments. Click OPEN QUIZ button and enjoy the quiz.</p>
          <p>If you’re an authorized user, after you solve the quiz, you can add it to Favorite. That is, if you click the ADD TO FAVORITE button, you’ll find the link to this quiz on your profile page in the tab FAVORITE QUIZZES. Also, you can upvote or downvote the quiz; that is to choose whether you recommend or not recommend this quiz to other users and comment it.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1" >
        <Accordion.Header>
          How to contribute a quiz to QUIZ LIBRARY?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>Contributing your quiz to QUIZ LIBRARY, you earn {changeType} points.</p>
          <p>Profile &#8594; MY QUIZZES &#8594; {theme === "dark"
            ? <img
              src={require('../images/editW.png')}
              style={{ width: "15px" }}
              alt={'edit'} />
            : <img
              src={require('../images/edit.png')}
              style={{ width: "15px" }}
              alt={'edit'} />} &#8594; Edit quiz type: change PRIVATE to PUBLIC &#8594; Select category &#8594; Select language</p>
          <p>If you select category “Languages”, you’ll be asked to choose whether your quiz represents one language (say, a word and its definition in the same language) or multiple languages (say, a word and its translation to another language).</p>
          <p>Also, changing your quiz type to PUBLIC, you might want to edit its title and description, which was generated automatically when you created your quiz, and contains only the number of options and their names (headers of the quiz columns in your xlsx file or the table you've filled in on the website).</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2" >
        <Accordion.Header>
          How to remove a quiz from QUIZ LIBRARY?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>Removing your quiz from QUIZ LIBRARY, you lose all the points your quiz earned you. Also, all the votes and comments to this quiz will be permanently deleted. So, if you contribute this quiz to the QUIZ LIBRARY again, you’ll have to earn points and get votes and comments from scratch.</p>
          <p>Profile &#8594; MY QUIZZES &#8594; {theme === "dark"
            ? <img
              src={require('../images/editW.png')}
              style={{ width: "15px" }}
              alt={'edit'} />
            : <img
              src={require('../images/edit.png')}
              style={{ width: "15px" }}
              alt={'edit'} />} &#8594; Edit quiz type: change PUBLIC to PRIVATE</p>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default AccordionFAQlibrary