import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import { QuizContext } from "../../App";

function FormEditQuizCat() {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Form style={{ textAlign: "center", marginTop: "5px" }}>
      <Form.Label>
        {quizContext.state.oldQuizType === "PUBLIC"
          && quizContext.state.quizType === "PUBLIC"
          ? <span>Edit quiz category</span>
          : <span>Add quiz category</span>
        }
      </Form.Label>

      <Form.Select
        data-bs-theme={theme}
        style={{
          border: theme === "dark"
            ? "1px solid #77797c"
            : "1px solid #212529"
        }}
        onChange={(e) => quizContext.dispatch({
          type: "editQuizCat",
          payload: e.target.value
        })}
      >{quizContext.state.catName === null
        ? <option value="- Select Category -">
          - Select category -
        </option>
        : <option value={quizContext.state.catName}>
          {quizContext.state.catName}
        </option>
        }
        {quizContext.state.catArr.map((el, i) =>
          <option key={i} value={el}>{el}</option>
        )}
      </Form.Select>
    </Form>
  )
}

export default FormEditQuizCat