import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { QuizContext } from "../../App";

export const ModalQuizCreatedUnsaved = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Modal
      size="sm"
      data-bs-theme={theme}
      show={quizContext.state.alert}
    >
      <Modal.Header className={`${theme}-modal-header`}>
        <Modal.Title>
          <span className={`${theme}-congrat`}>
            ПОЗДРАВЛЯЕМ!
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={`${theme}-modal-body`}
        style={{ textAlign: "center" }}
      >
        Квиз создан!

        <div style={{ margin: "20px 0 5px 0" }}>

          <Link
            to={"/quiz/unsaved"}
          >
            <button
              className={`${theme}-main-btn`}
              onClick={() => quizContext.dispatch({
                type: "goToUnsavedQuiz"
              })}
            >
              ОТКРЫТЬ КВИЗ
            </button>
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export const ModalQuizCreatedSaved = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Modal
      size="sm"
      data-bs-theme={theme}
      show={quizContext.state.alert}
    >
      <Modal.Header className={`${theme}-modal-header`}>
        <Modal.Title>
          <span className={`${theme}-congrat`}>
            ПОЗДРАВЛЯЕМ!
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={`${theme}-modal-body`}
        style={{ textAlign: "center" }}
      >
        Квиз создан и сохранён!

        <div style={{ margin: "20px 0 5px 0" }}>

          <Link
            to={`/quiz/${quizContext.state.quizId}`}
            style={{ textDecoration: 'none' }}
          >
            <button
              className={`${theme}-main-btn`}
              onClick={() => quizContext.dispatch({
                type: "goToNewQuiz"
              })}
            >
              ОТКРЫТЬ КВИЗ
            </button>
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export const ModalQuizCreatedWithError = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Modal
      data-bs-theme={theme}
      show={quizContext.state.alert}
    >
      <Modal.Header className={`${theme}-modal-header`}>
        <Modal.Title>
          <span className="error">
            Sorry!
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={`${theme}-modal-body`}
        style={{ textAlign: "center" }}
      >
        <span className="error">Something went wrong on our end.</span><br />
        We’re doing our best to fix the issue.<br />
        The quiz has been created <br />
        but <span className="error">NOT SAVED.</span><br />
        Still, <span className="congrat">YOU CAN TAKE IT</span>.

        <div style={{ margin: "20px 0 5px 0" }}>
          <Link
            to={"/quiz/unsaved"}
            style={{ textDecoration: 'none' }}
          >
            <button
              className={`${theme}-main-btn`}
              onClick={() => quizContext.dispatch({
                type: "goToUnsavedQuiz"
              })}
            >
              GO TO QUIZ
            </button>
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  )
}