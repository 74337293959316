import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Table from 'react-bootstrap/Table';
import { QuizContext } from "../../App";
import FormSearchItem from "../forms/FormSearchItem";

const ModalMyQuizzes = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Modal
      data-bs-theme={theme}
      show={quizContext.state.alert}
      onHide={() => quizContext.dispatch({
        type: "hideAlert"
      })}
    >
      <Modal.Header closeButton data-bs-theme={theme}
        className={`${theme}-modal-header`}
      >
        MY QUIZZES
      </Modal.Header>
      <Modal.Body
        className={`${theme}-modal-body`}
        style={{
          maxHeight: "400px",
          overflowX: "hidden",
        }}
      >

        {quizContext.state.createdQuizzes.length > quizContext.state.itemArrLength && <FormSearchItem />}

        <Table
          striped
          variant={theme}
          style={{ width: "100%" }}
        >
          {!quizContext.state.createdQuizzes.length
            ? <tbody><tr><th style={{ color: "red" }}>
              Nothing to show yet
            </th ></tr></tbody>
            : <tbody>
              {quizContext.state.createdQuizzes
                .filter((el) => quizContext.state.searchItem.toLowerCase() === ""
                  ? el
                  : el.title.toLowerCase().includes(quizContext.state.searchItem.toLowerCase()))
                .map((el, i) => (
                  <tr key={i}>
                    <th style={{ textAlign: "left", width: "80%" }}>
                      <Link
                        to={`/quiz/${el.id}`}
                        style={{
                          textDecoration: "none",
                          color: theme === "dark"
                            ? "#4da2f7"
                            : "#0a70d6"
                        }}
                        onClick={() => quizContext.dispatch({
                          type: "goToMyQuiz",
                          el
                        })}
                      >
                        {theme === "dark"
                          ? <img
                            src={el.type === "PUBLIC"
                              ? require('../images/publicW.png')
                              : el.type === "TEAM"
                                ? require('../images/teamW.png')
                                : require('../images/privateW.png')
                            }
                            style={{
                              width: "15px",
                              marginBottom: "3px",
                              marginRight: "5px"
                            }}
                            alt={'type'}
                          />
                          : <img
                            src={el.type === "PUBLIC"
                              ? require('../images/public.png')
                              : el.type === "TEAM"
                                ? require('../images/team.png')
                                : require('../images/private.png')
                            }
                            style={{
                              width: "15px",
                              marginBottom: "3px",
                              marginRight: "5px"
                            }}
                            alt={'type'}
                          />}  {el.title}
                      </Link>
                    </th>
                    <th style={{ textAlign: "right" }}>
                      <Link
                        to={`/edit_quiz/${el.id}`}
                        style={{ textDecoration: 'none' }}
                        onClick={() => quizContext.dispatch({
                          type: "hideAlert"
                        })}
                      >
                        {theme === "dark"
                          ? <img
                            src={require('../images/editW.png')}
                            style={{ width: "20px" }}
                            alt={'edit'}
                          />
                          : <img
                            src={require('../images/edit.png')}
                            style={{ width: "20px" }}
                            alt={'edit'}
                          />}
                      </Link>
                    </th>
                  </tr>
                ))}
            </tbody>
          }
        </Table>
      </Modal.Body>
    </Modal>
  )
}

export default ModalMyQuizzes