import React from "react";
import ButtonMyPage from "../buttons/ButtonMyPage";

const TextNotYourTeam = () => {

  return (
    <div style={{
      paddingBottom: "420px",
      marginTop: "40px",
      width: "100%",
      textAlign: "center",
      fontSize: "1.2rem"
    }}>
      <div style={{ fontSize: "3rem", fontWeight: "600" }}>Sorry!</div>
      <div style={{ marginTop: "20px" }}>
        Your are not a member of this team.<br />
        You can't open it.
      </div>

      <div style={{ marginTop: "20px" }}>
        Search for available teams<br />
        on your page.
      </div>

      <div
        style={{
          textAlign: "center",
          marginTop: "30px"
        }}
      >
        <ButtonMyPage />
      </div>
    </div>
  )
}

export default TextNotYourTeam