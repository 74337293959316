import React, { useContext } from "react";
import { QuizContext } from "../../App";
import Container from "react-bootstrap/Container";
import ModalForDeleteTeam from "../modals/ModalForDeleteTeam";
import deleteTeam from "../../reducer/actions/groupActions/DeleteTeam";
import ButtonCancelAndGoToPrevPage from "./ButtonCancelAndGoToPrevPage";

const ButtonsDeleteTeam = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;


  return (
    <Container fluid='md'>
      <button
        className={`${theme}-sec-btn`}
        onClick={(e) => {
          e.preventDefault()
          deleteTeam(
            quizContext.dispatch,
            quizContext.state
          )
          quizContext.dispatch({
            type: "deleteTeam"
          })
        }}
      >
        УДАЛИТЬ КОМАНДУ
      </button>

      <ModalForDeleteTeam />

      <ButtonCancelAndGoToPrevPage />
    </Container>
  )
}

export default ButtonsDeleteTeam