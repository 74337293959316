import React, { useContext } from "react";
import { QuizContext } from "../../App";
import Error from "../../pages/Error";

const TextRecommendationResult = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <>
      {quizContext.state.error
        ? <Error />

        : <>
          {quizContext.state.recom === "REC"
            ? <p>You recommend this quiz to other users<br />
              Your comment: <span style={{ fontStyle: "italic", fontWeight: "600" }}>{quizContext.state.comment}</span></p>

            : quizContext.state.recom === "UNREC"
              ? <p>You do not recommend this quiz to other users<br />
                Your comment: <span style={{ fontStyle: "italic" }}>{quizContext.state.comment}</span></p>

              : <p>You did not evaluate this quiz yet</p>
          }
        </>
      }

      <div style={{ textAlign: "center", marginTop: "15px" }}>
        <button
          className={`${theme}-main-btn`}
          onClick={() => quizContext.dispatch({ type: "goToEditComment" })}
        >
          EDIT
        </button>
      </div>
    </>
  )
}

export default TextRecommendationResult