import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import { QuizContext } from "../../App";

const FormCommentQuiz = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Form
      data-bs-theme={theme}
      style={{ fontWeight: "600", padding: "5%" }}
    >
      <Form.Label>
      КОММЕНТАРИЙ <span style={{
          fontSize: "1rem",
          fontWeight: "400",
          fontStyle: "italic"
        }}>
          необязательно
        </span>
      </Form.Label>
      {!quizContext.state.comment
        ? <>
          {quizContext.state.recom === "REC"
            || quizContext.state.recom === "UNREC"
            ? <Form.Control
              type="text"
              as="textarea"
              rows={2}
              data-bs-theme={theme}
              style={{
                border: theme === "dark"
                  ? "1px solid #77797c"
                  : "1px solid #212529"
              }}
              placeholder={quizContext.state.recom === "REC"
                ? "Поблагодарите автора квиза и/или расскажите, почему рекомендуете квиз."
                : "Собщите автору квиза об ошибках и/или расскажите, почему не рекомендуете квиз."
              }
              onChange={(e) => quizContext.dispatch({
                type: "getComment",
                payload: e.target.value
              })}
            />
            : null
          }
        </>
        : <>
          {quizContext.state.recom === "REC"
            || quizContext.state.recom === "UNREC"
            ? <Form.Control
              type="text"
              as="textarea"
              rows={2}
              data-bs-theme={theme}
              style={{
                border: theme === "dark"
                  ? "1px solid #77797c"
                  : "1px solid #212529"
              }}
              value={quizContext.state.comment}
              onChange={(e) => quizContext.dispatch({
                type: "getComment",
                payload: e.target.value
              })}
            />
            : null
          }
        </>
      }

    </Form>
  )
}

export default FormCommentQuiz