import React, { useContext } from "react";
import { QuizContext } from "../App";
import Card from "react-bootstrap/Card";
import Error from "../pages/Error";
import ButtonCancelAndGoToPrevPage from "./buttons/ButtonCancelAndGoToPrevPage";
import FormPassword from "./forms/FormPassword";
import FormPasswordConfirm from "./forms/FormPasswordConfirm";
import submitNewPassword from "../reducer/actions/userActions/SubmitNewPassword";
import ModalForNewPassword from "./modals/ModalForNewPassword";

const PasswordRecoveryNewPassword = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <div>
      {quizContext.state.errorNewPassword === "500"
        ? <Error />
        : <>
          <h2 className="header">
            NEW PASSWORD
          </h2>

          <Card
            data-bs-theme={theme}
            style={{
              padding: "25px",
              boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px"
            }}
          >
            {quizContext.state.enterData
              ? <p className="error">Fill in all the fields of the form</p>
              : quizContext.state.wrongData
                ? <p className="error">The password doesn't match</p>
                : quizContext.state.errorNewPassword !== null
                  && quizContext.state.errorNewPassword !== "500"
                  ? <p className="error">{quizContext.state.errorNewPassword}</p>
                  : null
            }
            <div>
              <div style={{ textAlign: "left", marginBottom: "0.3rem" }}>New password</div>
              <FormPassword />
            </div>
            <div style={{marginTop: "25px"}}>
              <div style={{ textAlign: "left", marginBottom: "0.3rem" }}>Confirm password</div>
              <FormPasswordConfirm />
            </div>

              <div style={{ textAlign: "center", marginTop: "45px" }}>
                <button
                  className={`${theme}-main-btn`}
                  onClick={(e) => {
                    e.preventDefault()
                    submitNewPassword(quizContext.dispatch, quizContext.state)
                    quizContext.dispatch({ type: "submitNewPassword" })
                  }}
                >
                  SUBMIT
                </button>

                <ButtonCancelAndGoToPrevPage />
              </div>
          </Card>

          {quizContext.state.alert === true
            && quizContext.state.alertStatus === "password changed"
            && <ModalForNewPassword />
          }
        </>
      }
    </div>
  )
}

export default PasswordRecoveryNewPassword