import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import { QuizContext } from "../../App";

const FormUsername = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (

    // <Form>
    <Form.Control
      type="text"
      required
      data-bs-theme={theme}
      style={{
        border: theme === "dark"
          ? "1px solid #77797c"
          : "1px solid #212529",
      }}
      placeholder="Ваше имя"
      onChange={(e) => quizContext.dispatch({
        type: "getUsername",
        payload: e.target.value
      })}
    />
    // </Form>
  )
}

export default FormUsername