import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import { QuizContext } from "../../App";

const FormSelectQuizLength = () => {

  const quizContext = useContext(QuizContext);
  const longQuiz = `${quizContext.state.quizRows.length} tasks`;
  const shortQuiz = `${Math.floor(quizContext.state.quizRows.length / 4)} tasks`;

  return (
    <div key={"inline-radio"} className="mb-3">

      <table style={{ width: "100%" }}><tbody><tr>
        <td>
          <Form>
            <Form.Label>Long quiz:</Form.Label><br />
            <Form.Check
              inline
              label={longQuiz}
              name="length"
              type="radio"
              value="long"
              checked={quizContext.state.quizLengthStatus === "long" ? true : false}
              id={1}
              onChange={(e) => quizContext.dispatch({
                type: "setQuizLength",
                payload: e.target.value
              })}
            />
          </Form>
        </td>
        <td>
          <Form>
            <Form.Label>Short quiz:</Form.Label><br />
            <Form.Check
              inline
              label={shortQuiz}
              name="length"
              type="radio"
              value="short"
              checked={quizContext.state.quizLengthStatus === "short" ? true : false}
              id={2}
              onChange={(e) => quizContext.dispatch({
                type: "setQuizLength",
                payload: e.target.value
              })}
            />
          </Form>
        </td>
      </tr></tbody></table>
    </div>
  )
}

export default FormSelectQuizLength