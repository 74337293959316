import React, { useContext } from "react";
import { QuizContext } from "../../App";
import Error from "../../pages/Error";

const TextRecommendationResult = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <>
      {quizContext.state.error
        ? <Error />

        : <>
          {quizContext.state.recom === "REC"
            ? <p>Вы рекомендуете этот квиз другим пользователям<br />
              Ваш комментарий: <span style={{ fontStyle: "italic", fontWeight: "600" }}>{quizContext.state.comment}</span></p>

            : quizContext.state.recom === "UNREC"
              ? <p>Вы не рекомендуете этот квиз другим пользователям<br />
                Ваш комментарий: <span style={{ fontStyle: "italic" }}>{quizContext.state.comment}</span></p>

              : <p>Вы ещё не оценили этот квиз</p>
          }
        </>
      }

      <div style={{ textAlign: "center", marginTop: "15px" }}>
        <button
          className={`${theme}-main-btn`}
          onClick={() => quizContext.dispatch({ type: "goToEditComment" })}
        >
          РЕДАКТИРОВАТЬ
        </button>
      </div>
    </>
  )
}

export default TextRecommendationResult