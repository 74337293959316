// ButtonSubmitRecommendation

const cancelRecCom = (state) => {

  const recStatus = state.currUserComment.length === 1
    ? "done"
    : "rec";
  const recom = state.comments.filter(el => el.user_took_id === state.currentUser.id).length !== 0
    ? state.comments.filter(el => el.user_took_id === state.currentUser.id)[0].recom
    : "NONE";

  return {
    ...state,
    recStatus,
    recom
  }
}

export default cancelRecCom