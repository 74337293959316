import React, { useContext } from "react";
import { QuizContext } from "../../App";

const ButtonsTabCreate = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const styleLink = {
    fontSize: "0.9rem",
    marginBottom: "20px",
    color: theme === "dark"
      ? "#4da2f7"
      : "#0a70d6"
  };
  const styleOn = {
    fontSize: "0.9rem",
    marginBottom: "20px",
    color: theme === "dark"
      ? "#d0d2d4"
      : "#212529"
  };


  return (
    <>

      {quizContext.state.tabStatus === "right"
        ? <>
          <button
            className="tab-btn-link-left"
            style={styleLink}
            onClick={() => quizContext.dispatch({
              type: "switchTabLeft"
            })}
          >
              Создать из xlsx файла
          </button>
          <button
            className="tab-btn-on-right"
            style={styleOn}
          >
            Создать из таблицы
          </button>
        </>
        : <>
          <button
            className="tab-btn-on-left"
            style={styleOn}
          >
            Создать из xlsx файла
          </button>
          <button
            className="tab-btn-link-right"
            style={styleLink}
            onClick={() => quizContext.dispatch({
              type: "switchTabRight"
            })}
          >
            Создать из таблицы
          </button>
        </>
      }
    </>
  )
}

export default ButtonsTabCreate