import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { QuizContext } from "../../App";

const TableQluizHeader = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <>
      <h2 className="header">
        <span className="header-tq">
          QUIZ: </span>
        <span className="header-tq-title">
          {quizContext.state.quiz.title}
        </span>
      </h2>

      <table style={{ width: "100%" }}><tbody><tr>
        <th>
          <span style={{ fontWeight: "400" }}>By: </span>
          <Link
            to={`/user/${quizContext.state.quiz.author_id}`}
            style={{
              textDecoration: "none",
              color: theme === "dark"
                ? "#4da2f7"
                : "#0a70d6",
              fontWeight: "500"
            }}
          >
            <span onClick={() => quizContext.dispatch({ type: "backToMainPage" })}>
              {quizContext.state.quiz.username}
            </span>
          </Link>
        </th>
        <th style={{ textAlign: "right", width: "10%" }}>
          {quizContext.state.currentUser !== null
            && quizContext.state.quiz.author_id === quizContext.state.currentUser.id
            && <Link
              to={`/edit_quiz/${quizContext.state.quiz.id}`}
              style={{ textDecoration: 'none' }}
            >
              <img
                src={theme === "dark"
                  ? require("../images/editW.png")
                  : require("../images/edit.png")
                }
                className={`${theme}-edit-icon`}
                alt={"edit"}
              />
            </Link>
          }
        </th>
      </tr></tbody></table>
    </>
  )
}

export default TableQluizHeader