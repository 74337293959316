import React, { useContext } from "react";
import { QuizContext } from "../App";
import AuthLogIn from "../componentsRU/AuthLogIn";
import AuthSignUp from "../componentsRU/AuthSignUp";
// import AuthGetOTP from "../components/PasswordRecoveryGetOTP";
// import AuthNewPassword from "../components/PasswordRecoveryNewPassword";
import AuthSignUpGetOTP from "../componentsRU/AuthSignUpGetOTP";

const LogInSignUp = () => {

  const quizContext = useContext(QuizContext);

  return (
    <div className="wrapper">
      {quizContext.state.auth === "signup"
        ? <AuthSignUp />
        : quizContext.state.auth === "getOTP"
          ? <AuthSignUpGetOTP />
          : <AuthLogIn />
      }
    </div>
  )
}

export default LogInSignUp