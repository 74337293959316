import React, { useContext } from "react";
import { QuizContext } from "../../App";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import ButtonRunSavedQuiz from "../buttons/ButtonRunSavedQuiz";
import descAuth from "../../reducer/actions/quizActions/DescAuth";

const TableQuizCharacteristics = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const date = new Date(quizContext.state.quiz.created_at);
  const year = date.getFullYear();
  const monthName = date.toLocaleString('en-US', { month: 'long' });

  const desc = `This quiz has ${quizContext.state.quiz.quizOptions.length} options: ${quizContext.state.quiz.quizOptions.join(" -|- ")}.`

  const quizDesc = !quizContext.state.quizDescAuthor
    ? desc
    : descAuth(quizContext.state.quizDescAuthor);

  return (
    <Table
      striped
      variant={theme}
      className="quiz-title-table"
    >
      <tbody>
        <tr>
          <td colSpan="2" style={{ textAlign: "center" }}>
            <ButtonRunSavedQuiz />
          </td>
        </tr>
        <tr>
          <td className="table-fontsize" style={{ width: "25%" }}>
            Description:
          </td>
          <td className="table-fontsize" style={{ whiteSpace: "pre-line" }} >
            <span dangerouslySetInnerHTML={{ __html: quizDesc }} />
          </td>
        </tr>

        <tr>
          <td className="table-fontsize" style={{ width: "25%" }}>
            Type:
          </td>
          <td className="table-fontsize">
            {theme === "dark"
              ? <img
                src={quizContext.state.quiz.type === "PUBLIC"
                  ? require("../images/publicW.png")
                  : quizContext.state.quiz.type === "TEAM"
                    ? require("../images/teamW.png")
                    : require("../images/privateW.png")
                }
                style={{
                  width: "15px",
                  marginBottom: "3px",
                  marginRight: "5px"
                }}
                alt={"type"}
              />
              : <img
                src={quizContext.state.quiz.type === "PUBLIC"
                  ? require("../images/public.png")
                  : quizContext.state.quiz.type === "TEAM"
                    ? require("../images/team.png")
                    : require("../images/private.png")
                }
                style={{
                  width: "15px",
                  marginBottom: "3px",
                  marginRight: "5px"
                }}
                alt={"type"}
              />}{quizContext.state.quiz.type}
          </td>
        </tr>

        {quizContext.state.quiz.type === "PUBLIC" &&
          <>
            <tr>
              <td className="table-fontsize" style={{ width: "25%" }}>
                Category:
              </td>
              <td className="table-fontsize">
                <Link
                  to={`/category/${quizContext.state.quiz.cat_id}`}
                  style={{
                    textDecoration: "none",
                    color: theme === "dark"
                      ? "#4da2f7"
                      : "#0a70d6"
                  }}
                  onClick={() => quizContext.dispatch({
                    type: "backToMainPage"
                  })}
                >
                  {quizContext.state.quiz.catname}
                </Link>
              </td>
            </tr>
            <tr>
              <td className="table-fontsize" style={{ width: "25%" }}>
                Language:
              </td>
              <td className="table-fontsize">
                {quizContext.state.quiz.lang}
              </td>
            </tr>
            <tr>
              <td className="table-fontsize" style={{ width: "25%" }}>
                Rating:
              </td>
              <td className="table-fontsize">
                <span className={`${theme}-rec`}>upvotes: {quizContext.state.quiz.rec_quiz}</span><br />
                <span className="unrec">downvotes: {quizContext.state.quiz.unrec_quiz}</span>
              </td>
            </tr>
            <tr>
              <td className="table-fontsize" style={{ width: "25%" }}>
                Statistics:
              </td>
              <td className="table-fontsize">
                taken {quizContext.state.timesTaken} {quizContext.state.timesTaken === 1 ? "time" : "times"}
              </td>
            </tr>
          </>
        }
        {quizContext.state.quiz.type === "TEAM" &&
          <tr>
            <td className="table-fontsize" style={{ width: "25%" }}>
            {quizContext.state.teamData.length === 1
                ? <span>Team:</span>
                : <span>Teams:</span>
              }
            </td>

            <td className="table-fontsize">
              {quizContext.state.teamData.map((el, i) => (
                <Link
                  to={`/team/${el.team_id}`}
                  style={{
                    textDecoration: "none",
                    color: theme === "dark"
                      ? "#4da2f7"
                      : "#0a70d6",
                    marginRight: "20px"
                  }}
                  key={i}
                >
                  {el.teamname}
                </Link>
              ))}
            </td>
          </tr>
        }
        <tr>
          <td className="table-fontsize" style={{ width: "25%" }}>
            Created in:
          </td>
          <td className="table-fontsize">
            {monthName} {year}
          </td>
        </tr>
      </tbody>
    </Table>
  )
}

export default TableQuizCharacteristics