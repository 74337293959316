import React from "react";
import ButtonMyPage from "../buttons/ButtonMyPage";

const TextNotYourTeam = () => {

  return (
    <div style={{
      paddingBottom: "420px",
      marginTop: "40px",
      width: "100%",
      textAlign: "center",
      fontSize: "1.2rem"
    }}>
      <div style={{ fontSize: "3rem", fontWeight: "600" }}>Извините!</div>
      <div style={{ marginTop: "20px" }}>
        Вы не являетесь членом этой команды,<br />
        а потому не можете её открыть.
      </div>

      <div style={{ marginTop: "20px" }}>
        Ищите доступные команды<br />
        на своей странице.
      </div>

      <div
        style={{
          textAlign: "center",
          marginTop: "30px"
        }}
      >
        <ButtonMyPage />
      </div>
    </div>
  )
}

export default TextNotYourTeam