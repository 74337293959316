import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import QuizAdjust from "../components/QuizAdjust";
import QuizRun from "../components/QuizRun";
import QuizResult from "../components/QuizResult";
import { QuizContext } from "../App";
import QuizTitlePage from "../components/QuizTitlePage";
import Loading from "../components/Loading";
import Error from "./Error";
import TextNotYourQuiz from "../components/text/TextNotYourQuiz";


const Quiz = () => {

  const quizContext = useContext(QuizContext);
  const { id } = useParams();
  const dispatch = quizContext.dispatch;
  const userId = quizContext.state.currentUser
    ? quizContext.state.currentUser.id
    : "unauthorized";

  // Get all Quiz info
  useEffect(() => {
    if (id === "unsaved") {
      dispatch({ type: "fetchQuizPage", id });
    } else {
      const fecthData = async () => {
        try {
          const res = await axios.get(`/quiz/page/${id}`, userId);
          // console.log("res.data:", res.data);
          const quizPage = res.data;
          // console.log("quizPage:", quizPage);
          dispatch({ type: "fetchQuizPage", quizPage, id });
        } catch (err) {
          const error = err.response.data.message;
          console.log("fetchQuizPageErr:", error);
          dispatch({ type: "fetchQuizPageErr", error });
        }
      }
      fecthData();
    };
  }, [id,
    userId,
    dispatch,
    quizContext.state.quizStatus,
    quizContext.state.recStatus,
    quizContext.state.favQuizStatus,
    quizContext.state.alert
  ]);

  return (
    <div>
      {quizContext.state.loading
        ? <Loading />
        : quizContext.state.errorMySQL
          || quizContext.state.error === "404"
          ? <Error />
          : <>
            {quizContext.state.quizStatus === "adjust" ? <QuizAdjust />
              : quizContext.state.quizStatus === "quiz" ? <QuizRun />
                : quizContext.state.quizStatus === "result" ? <QuizResult />
                  : quizContext.state.removeComments === true ? <TextNotYourQuiz />
                    : <QuizTitlePage />
            }
          </>
      }
    </div>
  )
}

export default Quiz