import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import { QuizContext } from "../../App";

const FormPasswordConfirm = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (

    <Form>
      <Form.Control
        type="password"
        data-bs-theme={theme}
        required
        style={{
          border: theme === "dark"
            ? "1px solid #77797c"
            : "1px solid #212529",
        }}
        placeholder="Password"
        onChange={(e) => quizContext.dispatch({
          type: "confirmPassword",
          payload: e.target.value
        })}
      />
    </Form>
  )
}

export default FormPasswordConfirm