// FormEditQuizContents

const getOptionValue = (state, action) => {

  const quizOptionsEdit = state.quizOptionsEdit.map((el, i) => {
    return i === action.key ? { ...el, header: action.payload, changes: "changed" } : el;
  });

  const quizRowsEdit = state.quizRowsEdit.map((val, i) => {
    return { ...val, [action.objKey]: [val[action.objKey][0], { ...val[action.objKey][1], header: action.payload }] };
  });

  return {
    ...state,
    errorMessage: false,
    alertStatus: "changes made",
    quizOptionsEdit,
    quizRowsEdit
  };
}

export default getOptionValue