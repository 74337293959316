import React, { useContext } from "react";
import { QuizContext } from "../../App";
import { Link } from "react-router-dom";

const ButtonQuizLibrary = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Link to="/quiz_library">
      <button
        className={`${theme}-main-btn`}
        onClick={() => quizContext.dispatch({
          type: "backToMainPage"
        })}
      >
        БИБЛИОТЕКА КВИЗОВ
      </button>
    </Link>
  )
}

export default ButtonQuizLibrary