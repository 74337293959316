import React, { useContext } from "react";
import { QuizContext } from "../App";
import { useParams } from "react-router-dom";
import OtherUserPageRU from "./OtherUserPageRU";
import LogInSignUpRU from "./LogInSignUpRU";
import User from "../componentsRU/User";
import EditProfile from "../componentsRU/EditProfile";
import TextBanTeamAccess from "../componentsRU/text/TextBanTeamAccess";

const UserPageRU = () => {

  const quizContext = useContext(QuizContext);
  const { id } = useParams();
  const currentUserId = quizContext.state.currentUser ? quizContext.state.currentUser.id : null
  const otherUserId = Number(id)

  return (
    <div>
      {otherUserId === currentUserId || !id
        ? <>
          {quizContext.state.currentUser
            && quizContext.state.userPageStatus === "edit profile"
            ? <EditProfile />
            : quizContext.state.currentUser
              && quizContext.state.userPageStatus === "edit teams"
              ? <TextBanTeamAccess />
              : quizContext.state.errorAuth
                ? <LogInSignUpRU />
                : <User />
          }
        </>
        : <OtherUserPageRU />
      }
    </div>
  )
}

export default UserPageRU