import React, { useContext } from "react";
import { QuizContext } from "../../App";
import submitCreatedTeam from "../../reducer/actions/groupActions/SubmitCreatedTeam";

const ButtonSubmitCreatedTeam = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <>
      {!quizContext.state.teamName || !quizContext.state.typeAddQuiz || !quizContext.state.typeAddMemb
        ? <button className={`${theme}-main-btn-inactive`}>
          <span style={{ cursor: "crosshair" }}>
            СОЗДАТЬ КОМАНДУ
          </span>
        </button>
        : <button
          className={`${theme}-main-btn`}
          onClick={(e) => {
            e.preventDefault()
            submitCreatedTeam(
              quizContext.dispatch,
              quizContext.state
            )
            quizContext.dispatch({
              type: "submitCreatedTeam"
            })
          }}
        >
          СОЗДАТЬ КОМАНДУ
        </button>
      }
    </>
  )
}

export default ButtonSubmitCreatedTeam