// ModalAddRemoveColumn

const removeColumnEdit = (state) => {

  const regCol = /tempColId/;
  const num = state.columnNum;
  const id = state.quizOptionsEdit[num].id;
  const quizOptionsEdit = [...state.quizOptionsEdit];
  const quizOptionsToDelete = [...state.quizOptionsToDelete];
  const removed = quizOptionsEdit.splice(num, 1);
  if (!regCol.test(removed[0].id)) {
    quizOptionsToDelete.push(removed[0].id);
  }

  const quizRowsEdit = [];
  const quizRows = [...state.quizRowsEdit];
  quizRows.map((el, i) => {
    const { [id]: data, ...newObject } = el;
    quizRowsEdit.push(newObject);
    return el
  });

  return {
    ...state,
    alertTable: false,
    alertStatus: "changes made",
    quizOptionsEdit,
    quizOptionsToDelete,
    quizRowsEdit
  }
}

export default removeColumnEdit