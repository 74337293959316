// FormSelectQuizLength

const setQuizLength = (state, action) => {
  const value = action.payload;
  const quizLengthStatus = value === "long"
    ? "long"
    : "short";

  const numOfTasks = value === "long"
    ? state.quizRows.length
    : Math.floor(state.quizRows.length / 4);

  return {
    ...state,
    quizLengthStatus,
    numOfTasks
  }

}

export default setQuizLength