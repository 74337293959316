

// ButtonConfirmQuizTable

import findMistake from "./FindMistake";

const confirmQuizTable = (state) => {

  const headers = [...state.headers];
  headers.shift();
  const quizOptions = [...headers];

  const values = [...state.values];

  const quizRows = values.map((val) => {
    const entries = val.map((el, i) => {
      const newKey = headers[i];
      return { [newKey]: val[i] };
    });
    return Object.assign(...entries);
  });

  const numOfTasks = quizRows.length;

  const mistake = findMistake(quizOptions, quizRows);

  if (!mistake) {
    return {
      ...state,
      alertStatus: "changes confirmed",
      errorMessage: false,
      video: false,
      fileName: null,
      quizOptions,
      quizRows,
      numOfTasks
    }
  } else {
    return {
      ...state,
      alertStatus: null,
      video: false,
      errorMessage: "table mistake",
      mistake
    }
  }


}

export default confirmQuizTable