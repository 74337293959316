import React, { useContext } from "react";
import { QuizContext } from '../../App';
import { Link } from "react-router-dom";

const ButtonMyPage = () => {
  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <>
      {quizContext.state.currentUser !== null
        ? <Link
          to={`/user/${quizContext.state.currentUser.id}`}
        >
          <button
            className={`${theme}-main-btn`}
            onClick={() => quizContext.dispatch({ type: "backToMainPage" })}
          >
            MY PAGE
          </button>
        </Link>
        : <Link
          to="/login"
        >
          <button
            className={`${theme}-main-btn`}
            onClick={() => quizContext.dispatch({
              type: "backToMainPage"
            })}
          >
            MY PAGE
          </button>
        </Link>
      }
    </>
  )
}

export default ButtonMyPage