// CreateQuiz

const showInstruction = (state) => {
  return {
    ...state,
    alert: true,
    alertStatus: "instruction"
  }
}

export default showInstruction