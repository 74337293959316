import React, { useContext } from "react";
import { QuizContext } from "../../App";
import { Link } from "react-router-dom";
import submitLogOut from "../../reducer/actions/userActions/SubmitLogOut";

const ButtonChangePassword = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <>
      <div style={{marginBottom: "5px"}}>
        <span
          style={{
            color: "red",
            fontWeight: "600"
          }}
        >
          ВНИМАНИЕ!!!
        </span> Вы выйдите из своего аккаунта.
      </div>
      <Link to="/password">
        <button
          className={`${theme}-sec-btn`}
          onClick={() => {
            submitLogOut(quizContext.dispatch, quizContext.state)
            quizContext.dispatch({ type: "submitLogOut" })
          }}
        >
          ИЗМЕНИТЬ ПАРОЛЬ
        </button>
      </Link>
    </>
  )
}

export default ButtonChangePassword