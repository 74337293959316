// ModalAddRemoveColumn

const removeColumn = (state) => {

  const num = state.columnNum;
  const headers = [...state.headers];
  headers.splice(num, 1);

  const values = [...state.values];
  values.map((el, i) => {
    if (el.length - headers.length === 0)
      el.splice(num - 1, 1)
    return el
  });

  return {
    ...state,
    alertTable: false,
    alertStatus: null,
    headers,
    values
  }
}

export default removeColumn