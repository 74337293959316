import React, { useContext, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import TextRequirementsXLSX from "../text/TextRequirementsXLSX";
import { QuizContext } from "../../App";

const ButtonModalPrepareQuizFile = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button
        className={`${theme}-q-btn`}
        onClick={handleShow}
      >
        КАК ПОДГОТОВИТЬ XLSX ФАЙЛ
      </button>


      <Modal
        size="lg"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton data-bs-theme={theme}
          className={`${theme}-modal-header`}
        >
          <Modal.Title id="example-modal-sizes-title-lg">Как подготовить xlsx файл</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className={`${theme}-modal-body`}
          style={{
            maxHeight: "700px",
            overflowX: "hidden",
            padding: "3%"
          }}
        >
          <TextRequirementsXLSX />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ButtonModalPrepareQuizFile