// FormSearchQuiz

const getItemForSearch = (state, action) => {
  
  const searchItem = action.payload;

  return {
    ...state,
    searchItem
  }
}

export default getItemForSearch