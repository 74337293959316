import React, { useContext } from "react";
import { QuizContext } from "../../App";
import { Link } from "react-router-dom";
import TableMembershipPlans from "../tables/TableMembershipPlans";
import ButtonMyPage from "../buttons/ButtonMyPage";
import TabelEarnLosePoints from "../tables/TabelEarnLosePoints";

const TextBanTeamAccess = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <>
      {quizContext.state.team.admin_id === quizContext.state.currentUser.id
        || quizContext.state.userPageStatus === "edit teams"
        ? <>
          <h2 className="header">Доступ в Ваши команды заблокирован</h2>
          <div style={{ textAlign: "center" }}>
            <p> Снизилось количество ОБЩЕДОСТУПНЫХ квизов и/или очков рейтинга автора квизов. Их стало недостаточно для статуса пользователя, который у Вас был. Поэтому доступ в Ваши команды был заблокирован. Однако Вы можете редактировать сови команды.
            </p>
            <p>Для того чтобы разблокировать доступ, увеличьте количество ОБЩЕДОСТУПНЫХ квизов и зарабатывайте очки рейтинга автора квизов
            </p>
          </div>
          <TableMembershipPlans />
          <TabelEarnLosePoints />
          <div style={{ marginTop: "15px", textAlign: "center" }}>
            <ButtonMyPage />
          </div>
        </>
        : <div style={{
          paddingBottom: "200px",
          marginTop: "40px",
          width: "100%",
          textAlign: "center",
          fontSize: "1.2rem"
        }}>
          <div style={{
            fontSize: "3rem",
            fontWeight: "600",
            lineHeight: "3.5rem",
          }}>
            <span style={{ color: "red" }}>Вход на страницу команды</span><br />
            {quizContext.state.teamName.toUpperCase()}<br />
            <span style={{ color: "red" }}>заблокирован</span></div>

          <div style={{ marginTop: "35px" }}>Для восстановления доступа,<br />
            админ команды <Link
              to={`/user/${quizContext.state.team.admin_id}`}
              style={{
                textDecoration: "none",
                color: theme === "dark"
                  ? "#4da2f7"
                  : "#0a70d6",
                fontWeight: "600"
              }}
              onClick={() => quizContext.dispatch({
                type: "backToMainPage"
              })}
            >
              {quizContext.state.team.username}
            </Link><br />
            должен повысить свой статус пользователя.</div>
          <div style={{ marginTop: "35px" }}>
            Вы можете помочь ему это сделать,<br />
            проходя созданные им ОБЩЕДОСТУПНЫЕ квизы из БИБИЛИОТЕКИ КВИЗОВ,<br />
            благодаря чему он заработает очки рейтинга автора квизов<br />
            необходимые для повышения статуса пользователя.
          </div>
          <div style={{ marginTop: "35px", textAlign: "center" }}>
            <ButtonMyPage />
          </div>
        </div>
      }

    </>
  )
}

export default TextBanTeamAccess