import React, { useContext } from "react";
import { QuizContext } from "../../App";
import submitContactSupport from "../../reducer/actions/userActions/SubmitContactSupport";

const ButtonSubmitContactSupport = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <>
      {quizContext.state.formFilled === true && !quizContext.state.errorMySQL
        ? <button
          className={`${theme}-main-btn`}
          onClick={() => {
            submitContactSupport(quizContext.dispatch, quizContext.state)
            quizContext.dispatch({ type: "submitContactSupport" })
          }}
        >
          ОТПРАВИТЬ
        </button>

        : <button className={`${theme}-main-btn-inactive`}>
          <span style={{ cursor: "crosshair" }}>
            ОТПРАВИТЬ
          </span>
        </button>
      }
    </>
  )
}

export default ButtonSubmitContactSupport