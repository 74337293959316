import React, { useContext } from "react";
import { QuizContext } from "../../App";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const CardMembershipGuru = () => {

  const quizContext = useContext(QuizContext);

  return (
    <div className="card-membership-guru-big">

      <div
        style={{
          textAlign: "center",
          fontSize: "1.5rem",
          // margin: "15px 0",
          color: "#212529",
          fontWeight: "700"
        }}
      >
        SPECIAL OFFER FOR EDUCATORS
      </div>

      <div className="membership">
        {quizContext.state.currentUser.mem === "GURU"
          && <span>&#9679;</span>} GURU
      </div>

      <Row className="justify-content-center">
        <Col sm={12} md={12} lg={6}>
          <div className="card-conditions">
            <div className="guru-conditions">
              <p style={{ fontWeight: "600", fontSize: "1.2rem", marginBottom: "25px" }}>Requirements to qualify for the plan: </p>
              <p><span className="tic">&#10003;</span> at least {quizContext.state.guruPoints} rating points</p>
              <p><span className="tic">&#10003;</span> at least {quizContext.state.guruQuizzes} quizzes, contributed to Quiz Library</p>
              {/* <p><span className="tic">&#10003;</span> at least 8 of the quizzes must have at least 5 votes each</p>
              <p><span className="tic">&#10003;</span> at least 80% of the votes must be "recommended"</p> */}
            </div>
          </div>
        </Col>
        <Col sm={12} md={6} lg={3}>
          <div className="card-membership-guru">
            <div className="guru-conditions">
              <p><span className="tic">&#10003;</span> Access to Quiz Library</p>
              <p><span className="tic">&#10003;</span> Creating quizzes</p>
              <p><span className="tic">&#10003;</span> Joining teams</p>
              <p><span className="tic">&#10003;</span> Starting up to <b>{quizContext.state.guruTeams}</b> teams<br /> up to <b>{quizContext.state.guruMembers}</b> members each</p>
            </div>
            <div className="price-area">
              <span className="free-stand">
                FREE
              </span>
            </div>
          </div>
        </Col>
        <Col sm={12} md={6} lg={3}>
          <div className="card-membership-guru">
            <div className="guru-conditions">
              <p>
                <span className="tic">&#10003;</span> Access to Quiz Library
              </p>
              <p>
                <span className="tic">&#10003;</span> Creating quizzes
              </p>
              <p>
                <span className="tic">&#10003;</span> Joining teams
              </p>
              <p>
                <span className="tic">&#10003;</span> Starting up to <b>{quizContext.state.goldTeams}</b> teams<br /> up to <b>{quizContext.state.goldMembers}</b> members each
              </p>
            </div>

            <div className="price-area">

              <table style={{ width: "100%" }}><tbody>
                <tr>
                  <th style={{ width: "50%" }}>
                    <span className="price">${quizContext.state.guruGoldPayMonth}</span> <span style={{ fontWeight: "400", fontSize: "0.8rem" }}>a month</span> <br />
                    <span className="price">${quizContext.state.guruGoldPayYear}</span> <span style={{ fontWeight: "400", fontSize: "0.8rem" }}>a year</span>
                  </th>
                  <th style={{ width: "50%" }}>
                    {quizContext.state.currentUser.mem === "GURU"
                      ? <button className="buy-btn">BUY</button>
                      : <button className="buy-btn-inactive">BUY</button>
                    }
                  </th>
                </tr>
              </tbody></table>
            </div>
          </div>
        </Col>
        <Col sm={12} md={6} lg={3}>

        </Col>
      </Row>



    </div>
  )
}

export default CardMembershipGuru