// ButtonRunSavedQuiz

const runSavedQuiz = (state) => {

  const quizOptions = state.quiz.quizOptions;
  const quizRows = state.quiz.quizRows;
  const numOfTasks = state.quizLengthStatus === "long"
    ? quizRows.length
    : Math.floor(quizRows.length / 4);
  const quizTitle = state.quiz.title;

  return {
    ...state,
    quizStatus: "adjust",
    quizRows,
    quizOptions,
    numOfTasks,
    quizTitle
  }

}

export default runSavedQuiz