import React, { useContext } from "react";
import { QuizContext } from "../App";
import PasswordRecoveryEmail from "../componentsRU/PasswordRecoveryEmail";
import PasswordRecoveryGetOTP from "../componentsRU/PasswordRecoveryGetOTP";
import PasswordRecoveryNewPassword from "../componentsRU/PasswordRecoveryNewPassword";

const PasswordRecovery = () => {

  const quizContext = useContext(QuizContext);

  return (
    <div className="wrapper">
      {quizContext.state.passwordRecovery === "getOTP"
          ? <PasswordRecoveryGetOTP />
          : quizContext.state.passwordRecovery === "new password"
            ? <PasswordRecoveryNewPassword />
            : <PasswordRecoveryEmail />
      }     
    </div>
  )
}

export default PasswordRecovery