import React, { useContext } from "react";
import { QuizContext } from '../../App';
import Accordion from "react-bootstrap/Accordion";
import FormTeamRemoveQuiz from '../forms/FormTeamRemoveQuiz';
import FormTeamRemoveYourQuiz from "../forms/FormTeamRemoveYourQuiz";
import FormTeamAddQuiz from '../forms/FormTeamAddQuiz';
import FormTeamInviteUsers from '../forms/FormTeamInviteUsers';
import FormTeamEditMemberType from "../forms/FormTeamEditMemberType";
import FormTeamRemoveMember from "../forms/FormTeamRemoveMember";
import TextTeamDeleteMessage from "../text/TextTeamDeleteMessage";
import ButtonsDeleteTeam from "../buttons/ButtonsDeleteTeam";
import ButtonsLeaveTeam from "../buttons/ButtonsLeaveTeam";
import CardTeamEditSettings from "../cards/CardTeamEditSettings";
import AccordionTeamQuizEdit from "./AccordionTeamQuizEdit";


const AccordionTeamEdit = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const currentUserType = quizContext.state.currentUserType;
  const typeAddQuiz = quizContext.state.typeAddQuiz;
  const typeAddMemb = quizContext.state.typeAddMemb;

  return (
    <Accordion
      data-bs-theme={theme}
      style={{ boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px" }}
    >

      {currentUserType === "ADMIN"
        && <Accordion.Item eventKey="0">
          <Accordion.Header style={{ color: "black" }}>
            Edit team name and settings
          </Accordion.Header>
          <Accordion.Body>
            <CardTeamEditSettings />
          </Accordion.Body>
        </Accordion.Item>
      }

      <Accordion.Item eventKey="1">
        <Accordion.Header style={{ color: "black" }}>
          Edit team members
        </Accordion.Header>
        <Accordion.Body>
          {currentUserType === "ADMIN"
            || typeAddMemb === "ALL"
            || (currentUserType === "ASST" && typeAddMemb === "SOME")
            ? <FormTeamInviteUsers />
            : null
          }
          {quizContext.state.teamMembers.filter(el => el.user_type !== "INV").length > 1
            && currentUserType === "ADMIN"
            && quizContext.state.team.mem !== "STD"
            ? <FormTeamEditMemberType />
            : null
          }
          {currentUserType === "ADMIN"
            && quizContext.state.teamMembers.length > 1
            ? <FormTeamRemoveMember />
            : currentUserType !== "ADMIN"
              ? <div style={{ textAlign: "center" }}>
                <ButtonsLeaveTeam />
              </div>
              : null
          }
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header style={{ color: "black" }}>
          Edit team quizzes
        </Accordion.Header>
        <Accordion.Body>
          {quizContext.state.teamQuizzes.filter((el, i) =>
            el.author_id === quizContext.state.currentUser.id
          ).length
            ? <AccordionTeamQuizEdit />
            : null
          }
          {currentUserType === "ADMIN"
            || typeAddQuiz === "ALL"
            || (currentUserType === "ASST" && typeAddQuiz === "SOME")
            ? <FormTeamAddQuiz />
            : null
          }

          {currentUserType === "ADMIN" && quizContext.state.teamQuizzes.length
            ? <FormTeamRemoveQuiz />
            : currentUserType !== "ADMIN" && quizContext.state.teamQuizzes.length
              ? <FormTeamRemoveYourQuiz />
              : null
          }
        </Accordion.Body>
      </Accordion.Item>

      {currentUserType === "ADMIN"
        && <Accordion.Item eventKey="3">
          <Accordion.Header style={{ color: "black" }}>
            Delete team
          </Accordion.Header>
          <Accordion.Body>
            {!quizContext.state.teamQuizzes.length && quizContext.state.teamMembers.length === 1
              ? <div style={{ textAlign: "center" }}>
                <ButtonsDeleteTeam />
              </div>
              : <div style={{ textAlign: "center", marginTop: "15px" }}>
                <TextTeamDeleteMessage />
              </div>
            }
          </Accordion.Body>
        </Accordion.Item>
      }
    </Accordion>
  )
}

export default AccordionTeamEdit