import React, { useContext } from "react";
import { QuizContext } from "../../App";
import { Link } from "react-router-dom";

const ButtonHome = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Link to="/">
      <button
        className={`${theme}-main-btn`}
        onClick={() => quizContext.dispatch({ type: "backToMainPage" })}>
        HOME
      </button>
    </Link>
  )
}

export default ButtonHome