import React, { useContext } from "react";
import { QuizContext } from "../../App";

// functions file: ResizeFontCell.js

const ButtonsResizeQuizFont = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <>
      <div style={{ marginTop: "45px" }}>

        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td>

              </td>
              <td style={{ width: "50%" }}>
                <span>Question<br />
                  Font Size:</span>
                {quizContext.state.questionFontSize === 1
                  ? <button className={`${theme}-up-down-btn-inactive`}>
                    <span style={{ cursor: "crosshair" }}>
                      &#9660;
                    </span>
                  </button>
                  : <button className={`${theme}-up-down-btn`}
                    onClick={() => quizContext.dispatch({
                      type: "reduceQuestionFontSize"
                    })}
                  >
                    &#9660;
                  </button>
                }
                {quizContext.state.questionFontSize === 3
                  ? <button className={`${theme}-up-down-btn-inactive`}>
                    <span style={{ cursor: "crosshair" }}>
                      &#9650;
                    </span>
                  </button>
                  : <button className={`${theme}-up-down-btn`}
                    onClick={() => quizContext.dispatch({
                      type: "increaseQuestionFontSize"
                    })}
                  >
                    &#9650;
                  </button>
                }
              </td>
              <td style={{ width: "50%" }}>
                <span>Answer<br />
                  Font Size:</span>
                {quizContext.state.answerFontSize === 1
                  ? <button className={`${theme}-up-down-btn-inactive`}>
                    <span style={{ cursor: "crosshair" }}>
                      &#9660;
                    </span>
                  </button>
                  : <button className={`${theme}-up-down-btn`}
                    onClick={() => quizContext.dispatch({
                      type: "reduceAnswerFontSize"
                    })}
                  >
                    &#9660;
                  </button>
                }
                {quizContext.state.answerFontSize === 3
                  ? <button className={`${theme}-up-down-btn-inactive`}>
                    <span style={{ cursor: "crosshair" }}>
                      &#9650;
                    </span>
                  </button>
                  : <button className={`${theme}-up-down-btn`}
                    onClick={() => quizContext.dispatch({
                      type: "increaseAnswerFontSize"
                    })}
                  >
                    &#9650;
                  </button>
                }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default ButtonsResizeQuizFont
