// FormRecommendQuiz

const setRecommendQuiz = (state, action) => {
  
  const recom = action.payload;

  return {
    ...state,
    recom,
  };
}

export default setRecommendQuiz