// ModalAddRemoveRow

const removeRow = (state) => {

  const num = state.rowNum - 1;
  const values = [...state.values];
  values.splice(num, 1);

    return {
      ...state,
      alertTable: false,
      alertStatus: null,
      values
    }
}

export default removeRow