import React, { useContext, useEffect } from "react";
import axios from "axios";
import { QuizContext } from "../App";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";
import Error from "./Error";
import ButtonsTabCreate from "../components/buttons/ButtonsTabCreate";
import ModalInstruction from "../components/modals/ModalInstruction";
import {
  ModalQuizCreatedSaved,
  ModalQuizCreatedUnsaved,
  ModalQuizCreatedWithError
} from "../components/modals/ModalQuizCreated";
import CardQuizCreateFromFile from "../components/cards/CardQuizCreateFromFile";
import CardQuizCreateFromTable from "../components/cards/CardQuizCreateFromTable";
import VideoCreateQuizFromTable from "../components/videos/VideoCreateQuizFromTable";
import VideoCreateQuizFromFile from "../components/videos/VideoCreateQuizFromFile";

const QuizCreate = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const dispatch = quizContext.dispatch;

  // Check Server
  useEffect(() => {
    const fetchData = async () => {
      if (quizContext.state.currentUser) {
        try {
          const res = await axios.get("/auth/check");
          // console.log("res.data", res.data)
          const checkServer = res.data
          // console.log("checkServer", checkServer)
          dispatch({ type: "fetchCheckServer", checkServer });

        } catch (err) {
          const error = err.response.data.message;
          console.log("Check Server error:", error);
          dispatch({ type: "fetchCheckServerErr", error });
        }
      } else {
        dispatch({ type: "fetchCheckServer" });
      }
    }
    fetchData();
  }, [dispatch, quizContext.state.currentUser]);

  return (
    <div style={{ paddingBottom: "100px" }}>

      {quizContext.state.loading
        ? <Loading />
        : quizContext.state.error === "404"
          ? <Error />
          : <>

            {quizContext.state.errorMySQL
              && <p style={{ textAlign: "center", marginTop: "15px" }}>
                <span className="error">Sorry, something went wrong on our end.</span><br />
                We’re doing our best to fix the issue.<br />
                Meanwhile, you still can create and take quizzes without saving them.
              </p>
            }

            {quizContext.state.errorAuth
              && <p style={{ textAlign: "center", marginTop: "15px" }}>
                <span className="error">AUTHENTICATION FAILURE</span><br />
                To solve the problem,<Link to="/login"><button
                  className={`${theme}-small-btn`}
                  onClick={() => quizContext.dispatch({ type: "switchToLogin" })}
                >
                  LOG IN
                </button></Link> anew.<br />
                Despite the issue, you still can create quizzes without saving them.
              </p>
            }

            <h2 className="header">
              CREATE QUIZ
            </h2>

            <div className="tab">
              <ButtonsTabCreate />
            </div>

            {quizContext.state.tabStatus === "left"
              ? <CardQuizCreateFromFile />
              : <CardQuizCreateFromTable />
            }

            {quizContext.state.currentUser === null
              && !quizContext.state.errorMySQL
              ? <div
                style={{
                  textAlign: "center",
                  marginTop: "5px"
                }}
              >
                <p> To save your quiz <Link
                  to="/login"
                  style={{
                    textDecoration: "none",
                    color: theme === "dark"
                      ? "#4da2f7"
                      : "#0a70d6"
                  }}
                ><span
                  onClick={() => quizContext.dispatch({
                    type: "switchToLogin"
                  })}
                >
                    log in
                  </span></Link>.<br />
                  If you don't have an account <Link
                    to="/login"
                    style={{
                      textDecoration: "none",
                      color: theme === "dark"
                        ? "#4da2f7"
                        : "#0a70d6"
                    }}
                  ><span
                    onClick={() => quizContext.dispatch({
                      type: "switchToSignup"
                    })}
                  >
                      sign up
                    </span></Link>.</p>
              </div>
              : null
            }

            {quizContext.state.tabStatus === "left"
              ? <VideoCreateQuizFromFile />
              : <VideoCreateQuizFromTable />
            }

            {quizContext.state.alertStatus === "saved quiz created"
              ? <ModalQuizCreatedSaved />
              : quizContext.state.alertStatus === "unsaved quiz created"
                ? <ModalQuizCreatedUnsaved />
                : quizContext.state.alertStatus === "quiz created with error"
                  ? <ModalQuizCreatedWithError />
                  : quizContext.state.alertStatus === "instruction"
                    ? <ModalInstruction />
                    : null
            }
          </>
      }
    </div>
  )
}

export default QuizCreate