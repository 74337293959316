import React, { useContext } from "react";
import { QuizContext } from "../../App";

const ButtonCancelAndHideAlert = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <>
      {quizContext.state.alertStatus === "contact"
        ? <button
          className={`${theme}-sec-btn`}
          onClick={() => quizContext.dispatch({
            type: "hideAlert"
          })}
        >
          CANCEL
        </button>
        : <button
          className={`${theme}-sec-btn`}
          onClick={() => quizContext.dispatch({
            type: "hideAlert"
          })}
        >
          CANCEL
        </button>
      }
    </>
  )
}

export default ButtonCancelAndHideAlert