import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Table from 'react-bootstrap/Table';
import { QuizContext } from "../../App";
import FormSearchItem from "../forms/FormSearchItem";

const ModalMyTeams = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Modal
      data-bs-theme={theme}
      show={quizContext.state.alert}
      onHide={() => quizContext.dispatch({
        type: "hideAlert"
      })}
    >
      <Modal.Header closeButton data-bs-theme={theme}
        className={`${theme}-modal-header`}
      >
        MY TEAMS
      </Modal.Header>
      <Modal.Body
        className={`${theme}-modal-body`}
        style={{
          maxHeight: "400px",
          overflowX: "hidden",
        }}
      >

        {quizContext.state.userTeams.length > quizContext.state.itemArrLength && <FormSearchItem />}

        <Table
          striped
          style={{ width: "100%" }}
        >
          {!quizContext.state.userTeams.length
            ? <tbody><tr><th style={{ color: "red" }}>
              Nothing to show yet
            </th ></tr></tbody>
            : <tbody>
              {quizContext.state.userTeams
                .filter((el) => quizContext.state.searchItem.toLowerCase() === ""
                  ? el
                  : el.teamname.toLowerCase().includes(quizContext.state.searchItem.toLowerCase()))
                .map((el, i) => (
                  <tr key={i}>
                    <th style={{ textAlign: "left", width: "65%" }}>
                      <Link
                        to={`/team/${el.team_id}`}
                        style={{
                          textDecoration: "none",
                          color: theme === "dark"
                            ? "#4da2f7"
                            : "#0a70d6"
                        }}
                        onClick={() => quizContext.dispatch({
                          type: "backToMainPage"
                        })}
                      >
                        {el.teamname}
                      </Link>
                    </th>

                    <th style={{ textAlign: "right", width: "10%" }}>
                      <Link
                        to={`/edit_team/${el.team_id}`}
                        style={{ textDecoration: "none" }}
                        onClick={() => quizContext.dispatch({
                          type: "hideAlert"
                        })}
                      >
                        {theme === "dark"
                          ? <img
                            src={require("../images/editW.png")}
                            style={{ width: "20px" }}
                            alt={"edit"}
                          />
                          : <img
                            src={require("../images/edit.png")}
                            style={{ width: "20px" }}
                            alt={"edit"}
                          />}
                      </Link>
                    </th>

                    <th style={{ textAlign: "right", fontSize: "0.8rem", fontWeight: "500", width: "20%" }}>
                      {el.user_type === "ADMIN"
                        ? <span>admin</span>
                        : el.user_type === "ASST"
                          ? <span>assistant</span>
                          : <span>member</span>
                      }
                    </th>
                  </tr>
                ))}
            </tbody>
          }
        </Table>
      </Modal.Body>
    </Modal>
  )
}

export default ModalMyTeams