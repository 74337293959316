import * as XLSX from "xlsx/xlsx.mjs";
import findMistake from "../FindMistake";

// FormQuizFile

const getQuizFile = async (file, dispatch, state) => {

  const fileUrl = file;
  const data = await file.arrayBuffer();
  const workbook = XLSX.read(data);

  const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  const quizRows = XLSX.utils.sheet_to_json(worksheet);
  const getHeaders = XLSX.utils.sheet_to_json(worksheet, {
    header: 1,
    defaultValue: "",
  });
  const quizOptions = getHeaders[0];
  const numOfTasks = quizRows.length;
  const fileName = file.name;
  const quizTitle = state.quizTitle
    ? state.quizTitle
    : fileName.slice(0, -5);


  // Checking for mistakes

  const mistake = findMistake(quizOptions, quizRows);

  return dispatch({
    type: "getQuizFile",
    mistake,
    fileUrl,
    quizRows,
    quizOptions,
    numOfTasks,
    fileName,
    quizTitle
  })

}

export default getQuizFile