import React, { useContext } from "react";
// import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from 'react-bootstrap/Button';
import { QuizContext } from "../../App";
import searchUser from "../../reducer/actions/groupActions/SearchUser";
import inviteUser from "../../reducer/actions/groupActions/InviteUser";
import { banInvUsers } from "../../reducer/actions/groupActions/BanTeam";

const FormTeamInviteUsers = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const variant = theme === "dark"
    ? "outline-light"
    : "outline-dark";
  const ban = banInvUsers(
    quizContext.state.team.mem,
    quizContext.state.teamMembers.length,
    quizContext.state
  );

  return (
    <>
      <Form>
        <Form.Label
          style={{
            fontWeight: "600"
          }}
        >
          Invite users to the team
        </Form.Label>
        {ban === true
          ? <p style={{ color: "red", fontWeight: "600" }}>The limit on the number of members is reached.</p>
          : <InputGroup>
            <Form.Control
              type="text"
              data-bs-theme={theme}
              style={{
                border: theme === "dark"
                  ? "1px solid #77797c"
                  : "1px solid #212529"
              }}
              placeholder="User ID"
              value={quizContext.state.invUserId}
              onChange={(e) => quizContext.dispatch({
                type: "getUserIdForSearch",
                payload: e.target.value
              })}
            />
            <Button
              variant={variant}
              onClick={(e) => {
                e.preventDefault()
                searchUser(quizContext.dispatch, quizContext.state)
                quizContext.dispatch({ type: "searchUser" })
              }}
            >
              SEARCH
            </Button>
          </InputGroup>

        }
      </Form>

      {quizContext.state.invUser !== null && quizContext.state.invStatus === "invite"
        ? <div
          style={{
            textAlign: "left",
            marginTop: "15px"
          }}>
          {quizContext.state.teamMembers.filter(el => el.user_id === quizContext.state.invUser.id).length !== 0
            || quizContext.state.invUser.id === quizContext.state.currentUser.id
            ? <span>User is already invited or a member of the team</span>
            : quizContext.state.invUser.id === "not found"
              ? <span>The user is not found</span>
              : <>
                <span
                  style={{ fontSize: "1.2rem" }}
                >
                  {quizContext.state.invUsername}
                </span>
                <button
                  className={`${theme}-fourth-btn`}
                  onClick={(e) => {
                    e.preventDefault()
                    inviteUser(quizContext.dispatch, quizContext.state)
                    quizContext.dispatch({ type: "inviteUser" })
                  }}
                >
                  INVITE
                </button>
                <button
                  className="third-btn"
                  onClick={() => quizContext.dispatch({ type: "notInvite" })}
                >
                  NO
                </button>
              </>
          }
        </div>
        : null
      }


      {ban === false
        ? <div style={{ textAlign: "left", lineHeight: "0.5rem" }}>
          <p style={{ fontWeight: "600", marginTop: "20px" }}>
            Invited users:</p>
          {quizContext.state.invUsers.filter((el, i) =>
            quizContext.state.invUsers.indexOf(el) === i).map((el, i) =>
              <p key={i} style={{ marginLeft: "15px" }}>{el}</p>)}
        </div>
        : null
      }
    </>
  )
}

export default FormTeamInviteUsers