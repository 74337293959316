import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import { QuizContext } from "../../App";

const FormEditQuizDesc = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const quizDesc = !quizContext.state.quizDescAuthor
    ? "Расскажите пользователям о своём квизе"
    : quizContext.state.quizDescAuthor;

  return (
    <>
      {!quizContext.state.quizDescAuthor
        ? <Form.Control
          type="text"
          as="textarea"
          rows={2}
          data-bs-theme={theme}
          style={{
            border: theme === "dark"
              ? "1px solid #77797c"
              : "1px solid #212529"
          }}
          placeholder={quizDesc}
          onChange={(e) => quizContext.dispatch({
            type: "editQuizDesc",
            payload: e.target.value
          })}
        />
        : <Form.Control
          type="text"
          as="textarea"
          rows={2}
          data-bs-theme={theme}
          style={{
            border: theme === "dark"
              ? "1px solid #77797c"
              : "1px solid #212529"
          }}
          value={quizDesc}
          onChange={(e) => quizContext.dispatch({
            type: "editQuizDesc",
            payload: e.target.value
          })}
        />
      }
    </>
  )
}

export default FormEditQuizDesc