// FormEditQuizContents

const getRowValue = (state, action) => {


  const quizRowsEdit = state.quizRowsEdit.map((val, i) => {
    return i === action.key ? { ...val, [action.objKey]: [action.payload, { ...val[action.objKey][1], changes: "changed" }] } : val;
  });

  return {
    ...state,
    errorMessage: false,
    alertStatus: "changes made",
    quizRowsEdit
  };
}

export default getRowValue