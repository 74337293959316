import React, { useContext } from "react"
import { QuizContext } from "../../App";
import submitQuizAndSave from "../../reducer/actions/quizActions/SubmitQuizAndSave";

export const ButtonSubmitQuizAct = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <button
      className={`${theme}-sec-btn`}
      onClick={() => quizContext.dispatch({ type: "submitQuiz" })}
    >
      SUBMIT (no saving)
    </button>
  )
}

export const ButtonSubmitQuizInAct = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <button
      className={`${theme}-sec-btn-inactive`}
    >
      <span style={{ cursor: "crosshair" }}>
        SUBMIT (no saving)
      </span>
    </button>
  )
}

export const ButtonSubmitQuizAndSaveAct = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <button
      className={`${theme}-main-btn`}
      onClick={(e) => {
        e.preventDefault()
        submitQuizAndSave(
          quizContext.dispatch,
          quizContext.state
        )
        quizContext.dispatch({
          type: "submitQuizAndSave"
        })
      }}
    >
      SUBMIT & SAVE
    </button>
  )
}

export const ButtonSubmitQuizAndSaveInAct = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <button
      className={`${theme}-main-btn-inactive`}
    >
      <span style={{ cursor: "crosshair" }}>
        SUBMIT & SAVE
      </span>
    </button>
  )
}
