import React, { useContext } from 'react';
import { QuizContext } from '../../App';

const ButtonRunSavedQuiz = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <button
      className={`${theme}-main-btn`}
      onClick={() => quizContext.dispatch({ type: "runSavedQuiz" })}
    >
      OPEN QUIZ
    </button>
  )
}

export default ButtonRunSavedQuiz