import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { QuizContext } from "../../App";
import ButtonMyPage from "../buttons/ButtonMyPage";

const ModalForLeaveTeam = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Modal
      size="sm"
      data-bs-theme={theme}
      show={quizContext.state.alert}
    >
      <Modal.Header data-bs-theme={theme}
        className={`${theme}-modal-header`}
      >
        <div>You have left the team<br />
          <span style={{ fontWeight: "600" }}>
            {quizContext.state.team.teamname}
          </span>
        </div>
      </Modal.Header>
      <Modal.Body
        className={`${theme}-modal-body`}
        style={{ textAlign: "center" }}
      >
        <ButtonMyPage />
      </Modal.Body>
    </Modal>
  )
}

export default ModalForLeaveTeam