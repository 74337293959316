import React, { useEffect, useReducer } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import reducer from "./reducer/Reducer";
import getInitialState from "./state/GetInitialState";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import NavBar from "./components/NavBar";
import Home from "./pages/Home";
import QuizCreate from "./pages/QuizCreate";
import UserPage from "./pages/UserPage";
import Help from "./pages/Help";
import Error from "./pages/Error";
import Quiz from "./pages/Quiz";
import TeamStart from "./pages/TeamStart";
import Team from "./pages/Team";
import TeamEdit from "./pages/TeamEdit";
import LogInSignUp from "./pages/LogInSignUp";
import QuizEdit from "./pages/QuizEdit";
import Category from "./pages/Category";
import Library from "./pages/Library";
import UpgradeMembership from "./pages/UpgradeMembership";
import PasswordRecovery from "./pages/PasswordRecovery";
import NavBarQuiz from "./components/NavBarQuiz";
import Footer from "./components/Footer";
import FooterRU from "./componentsRU/FooterRU";
import HomeRU from "./pagesRU/HomeRU";
import LibraryRU from "./pagesRU/LibraryRU";
import QuizCreateRU from "./pagesRU/QuizCreateRU";
import UserPageRU from "./pagesRU/UserPageRU";
import LogInSignUpRU from "./pagesRU/LogInSignUpRU";
import PasswordRecoveryRU from "./pagesRU/PasswordRecoveryRU";
import TeamRU from "./pagesRU/TeamRU";
import TeamStartRU from "./pagesRU/TeamStartRU";
import TeamEditRU from "./pagesRU/TeamEditRU";
import QuizEditRU from "./pagesRU/QuizEditRU";
import QuizRU from "./pagesRU/QuizRU";
import CategoryRU from "./pagesRU/CategoryRU";
import HelpRU from "./pagesRU/HelpRU";
import ErrorRU from "./pagesRU/ErrorRU";
import NavBarQuizRU from "./componentsRU/NavBarQuizRU";
import NavBarRU from "./componentsRU/NavBarRU";


export const QuizContext = React.createContext();

function App() {

  const [state, dispatch] = useReducer(reducer, getInitialState());

  // console.log("window.location.href:", window.location.href);
  // console.log("state.loading:", state.loading);
  // console.log("state.quizTitle:", state.quizTitle);
  // console.log("state.addQuizzes:", state.teamData);
  // console.log("state.teamData:", state.teamData);
  // console.log("state.teamName:", state.teamName);

  const theme = state.theme;


  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(state.currentUser));
  }, [state.currentUser]);


  // Check Server
  useEffect(() => {
    const fetchData = async () => {
        try {
          const res = await axios.get("/auth/country");
          // console.log("res.data", res.data)
          const isRussia = res.data.isRussia
          // console.log("isRussia", isRussia)
          dispatch({ type: "fetchCountry", isRussia });

        } catch (err) {
          // const error = err.response.data.message;
          console.log("Check Country err:", err);
          dispatch({ type: "fetchCountryErr", err });
        }
    }
    fetchData();
  }, []);


  return (
    <div className={`${theme}-wrapper`}>
      <QuizContext.Provider value={{ state: state, dispatch: dispatch }}>

        {state.websiteLang === "ru"
         ? <BrowserRouter>
          {state.quizStatus === "quiz"
            ? <NavBarQuizRU />
            : <nav><NavBarRU /></nav>
          }
          <Routes>

            <Route path="/" element={
              <Container>
                <Row className="justify-content-center">
                  <Col sm={12} md={8} lg={6}>
                    <HomeRU />
                  </Col>
                </Row>
              </Container>
            } />

            <Route path="login" element={
              state.currentUser
                ? <Navigate to={`/user/${state.currentUser.id}`} />
                : <Container>
                  <Row className="justify-content-center">
                    <Col sm={12} md={8} lg={6}>
                      <LogInSignUpRU />
                    </Col>
                  </Row>
                </Container>
            } />

            <Route path="password" element={
              <Container>
                <Row className="justify-content-center">
                  <Col sm={12} md={8} lg={6}>
                    <PasswordRecoveryRU />
                  </Col>
                </Row>
              </Container>
            } />

            <Route path="user/:id" element={
              state.errorMySQL
                ? <Navigate to="/create_quiz" />
                : <Container>
                  <Row className="justify-content-center">
                    <Col sm={12} md={8} lg={6}>
                      <UserPageRU />
                    </Col>
                  </Row>
                </Container>
            } />

            <Route path="team/:id" element={
              state.errorMySQL === true
                ? <Navigate to="/*" />
                : <Container>
                  <Row className="justify-content-center">
                    <Col sm={12} md={8} lg={6}>
                      <TeamRU />
                    </Col>
                  </Row>
                </Container>
            } />

            <Route path="start_team" element={
              state.errorMySQL === true
                ? <Navigate to="/*" />
                : <Container>
                  <Row className="justify-content-center">
                    <Col sm={12} md={8} lg={6}>
                      <TeamStartRU />
                    </Col>
                  </Row>
                </Container>
            } />

            <Route path="edit_team/:id" element={
              state.errorMySQL === true
                ? <Navigate to="/*" />
                : <Container>
                  <Row className="justify-content-center">
                    <Col sm={12} md={8} lg={6}>
                      <TeamEditRU />
                    </Col>
                  </Row>
                </Container>
            } />

            <Route path="create_quiz" element={
              <Container>
                <Row className="justify-content-center">
                  <Col sm={12} md={8} lg={6}>
                    <QuizCreateRU />
                  </Col>
                </Row>
              </Container>
            } />

            <Route path="edit_quiz/:id" element={
              state.errorMySQL === true
                ? <Navigate to="/create_quiz" />
                : <Container>
                  <Row className="justify-content-center">
                    <Col sm={12} md={8} lg={6}>
                      <QuizEditRU />
                    </Col>
                  </Row>
                </Container>
            } />

            <Route path="quiz/:id" element={
              state.errorMySQL === true
                ? <Navigate to="/*" />
                :
                <Container>
                  <Row className="justify-content-center">
                    <Col sm={12} md={8} lg={6}>
                      <QuizRU />
                    </Col>
                  </Row>
                </Container>
            } />

            <Route path="quiz_library" element={
              state.errorMySQL
                ? <Navigate to="/create_quiz" />
                : <Container>
                  <Row className="justify-content-center">
                    <Col sm={12} md={8} lg={6}>
                      <LibraryRU />
                    </Col>
                  </Row>
                </Container>
            } />

            <Route path="category/:id" element={
              state.errorMySQL === true
                ? <Navigate to="/create_quiz" />
                : <Container>
                  <Row className="justify-content-center">
                    <Col sm={12} md={8} lg={6}>
                      <CategoryRU />
                    </Col>
                  </Row>
                </Container>
            } />

            <Route path="help" element={
              <Container>
                <Row className="justify-content-center">
                  <Col sm={12} md={10} lg={8}>
                    <HelpRU />
                  </Col>
                </Row>
              </Container>
            } />

            {/* <Route path="membership" element={
              <Container>
                <UpgradeMembership />
              </Container>
            } /> */}

            <Route path="*" element={
              <Container>
                <Row className="justify-content-center">
                  <Col sm={12} md={8} lg={6}>
                    <ErrorRU />
                  </Col>
                </Row>
              </Container>
            } />

          </Routes>

          <FooterRU />

        </BrowserRouter>
        : <BrowserRouter>
        {state.quizStatus === "quiz"
          ? <NavBarQuiz />
          : <nav><NavBar /></nav>
        }
        <Routes>

          <Route path="/" element={
            <Container>
              <Row className="justify-content-center">
                <Col sm={12} md={8} lg={6}>
                  <Home />
                </Col>
              </Row>
            </Container>
          } />

          <Route path="login" element={
            state.currentUser
              ? <Navigate to={`/user/${state.currentUser.id}`} />
              : <Container>
                <Row className="justify-content-center">
                  <Col sm={12} md={8} lg={6}>
                    <LogInSignUp />
                  </Col>
                </Row>
              </Container>
          } />

          <Route path="password" element={
            <Container>
              <Row className="justify-content-center">
                <Col sm={12} md={8} lg={6}>
                  <PasswordRecovery />
                </Col>
              </Row>
            </Container>
          } />

          <Route path="user/:id" element={
            state.errorMySQL
              ? <Navigate to="/create_quiz" />
              : <Container>
                <Row className="justify-content-center">
                  <Col sm={12} md={8} lg={6}>
                    <UserPage />
                  </Col>
                </Row>
              </Container>
          } />

          <Route path="team/:id" element={
            state.errorMySQL === true
              ? <Navigate to="/*" />
              : <Container>
                <Row className="justify-content-center">
                  <Col sm={12} md={8} lg={6}>
                    <Team />
                  </Col>
                </Row>
              </Container>
          } />

          <Route path="start_team" element={
            state.errorMySQL === true
              ? <Navigate to="/*" />
              : <Container>
                <Row className="justify-content-center">
                  <Col sm={12} md={8} lg={6}>
                    <TeamStart />
                  </Col>
                </Row>
              </Container>
          } />

          <Route path="edit_team/:id" element={
            state.errorMySQL === true
              ? <Navigate to="/*" />
              : <Container>
                <Row className="justify-content-center">
                  <Col sm={12} md={8} lg={6}>
                    <TeamEdit />
                  </Col>
                </Row>
              </Container>
          } />

          <Route path="create_quiz" element={
            <Container>
              <Row className="justify-content-center">
                <Col sm={12} md={8} lg={6}>
                  <QuizCreate />
                </Col>
              </Row>
            </Container>
          } />

          <Route path="edit_quiz/:id" element={
            state.errorMySQL === true
              ? <Navigate to="/create_quiz" />
              : <Container>
                <Row className="justify-content-center">
                  <Col sm={12} md={8} lg={6}>
                    <QuizEdit />
                  </Col>
                </Row>
              </Container>
          } />

          <Route path="quiz/:id" element={
            state.errorMySQL === true
              ? <Navigate to="/*" />
              :
              <Container>
                <Row className="justify-content-center">
                  <Col sm={12} md={8} lg={6}>
                    <Quiz />
                  </Col>
                </Row>
              </Container>
          } />

          <Route path="quiz_library" element={
            state.errorMySQL
              ? <Navigate to="/create_quiz" />
              : <Container>
                <Row className="justify-content-center">
                  <Col sm={12} md={8} lg={6}>
                    <Library />
                  </Col>
                </Row>
              </Container>
          } />

          <Route path="category/:id" element={
            state.errorMySQL === true
              ? <Navigate to="/create_quiz" />
              : <Container>
                <Row className="justify-content-center">
                  <Col sm={12} md={8} lg={6}>
                    <Category />
                  </Col>
                </Row>
              </Container>
          } />

          <Route path="help" element={
            <Container>
              <Row className="justify-content-center">
                <Col sm={12} md={10} lg={8}>
                  <Help />
                </Col>
              </Row>
            </Container>
          } />

          <Route path="membership" element={
            <Container>
              <UpgradeMembership />
            </Container>
          } />

          <Route path="*" element={
            <Container>
              <Row className="justify-content-center">
                <Col sm={12} md={8} lg={6}>
                  <Error />
                </Col>
              </Row>
            </Container>
          } />

        </Routes>

        <Footer />

      </BrowserRouter>
        }
      </QuizContext.Provider >
    </div>
  );
}

export default App