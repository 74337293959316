import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { QuizContext } from "../../App";
import { Card } from "react-bootstrap";

const CardNavUser = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;


  return (
    <Link
      to={`/user/${quizContext.state.currentUser.id}`}
      style={{ textDecoration: 'none' }}
    >
       <Card
        data-bs-theme={theme}
        className="card-home"
      >
        <img
          src={theme === "dark"
            ? require('../images/user.png')
            : require('../images/user1.png')
          }
          className="card-icon"
          alt={'User_icon'}
        />
        <p className="card-text">
          {quizContext.state.currentUser.username.toUpperCase()}
        </p>

      </Card>
    </Link>
  )
}

export default CardNavUser