import React, { useContext } from "react";
import { QuizContext } from "../../App";
import TextMistake from "../text/TextMistake";

const ButtonConfirmQuizTable = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <>
      <div>
        <span style={{ fontWeight: "600" }}>
          ШАГ 3:</span> когда Ваш квиз готов, отправьте его на проверку
      </div>
      {quizContext.state.errorMessage === "table mistake"
        && <TextMistake />
      }
      <div style={{ textAlign: "center", marginTop: "5px" }}></div>
      <div style={{
        textAlign: "center",
        marginTop: "5px"
      }}
      >
        {quizContext.state.alertStatus === "changes made"
          ? <button
            className={`${theme}-main-btn`}
            onClick={() => quizContext.dispatch({
              type: "confirmQuizTable"
            })}
          >
            ПРОВЕРИТЬ
          </button>
          : <button className={`${theme}-main-btn-inactive`}>
            <span style={{ cursor: "crosshair" }}>
              ПРОВЕРИТЬ
            </span>
          </button>
        }
      </div>
    </>
  )
}

export default ButtonConfirmQuizTable