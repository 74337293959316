import React, { useContext } from "react";
import { QuizContext } from "../../App";
import { useNavigate } from "react-router-dom";

const ButtonCancelAndGoToPrevPage = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const navigate = useNavigate();

  return (
    <span onClick={() => navigate(-1)}>
      <button
        className={`${theme}-sec-btn`}
        onClick={() => quizContext.dispatch({
          type: "backToMainPage"
        })}
      >
        CANCEL
      </button>
    </span>
  )
}

export default ButtonCancelAndGoToPrevPage