
const themeDefault = "light";

const getInitialState = () => ({

  //------General
  isRussia: false,
  websiteLang: "en", // "ru"
  theme: JSON.parse(localStorage.getItem("user")) && JSON.parse(localStorage.getItem("user")).theme
    ? JSON.parse(localStorage.getItem("user")).theme
    : themeDefault,
  defaultTheme: themeDefault,
  radioTheme: [
    { value: "light", label: "Light", labelRU: "Светлая" },
    { value: "dark", label: "Dark", labelRU: "Тёмная" }
  ],
  userTheme: "default", // dark, light

  alert: false,
  alertStatus: null, // "logged in" "delete team", "accept inv", "decline inv", "my quizzes", "favorite quizzes", "my teams", "invitations", "edited quiz", "delete quiz", "profile edited" "another quiz" "add to favorite" "accept inv" "decline inv" "no quizzes in category" "create quiz error" "saved quiz created" "unsaved quiz created" "left team" "wrongOTP" "password changed" "changes made" "changes confirmed" "editing" "error"
  alertTable: false,
  alertStopQuiz: false,

  video: false,

  //----CONTACT SUPPORT
  usernameCont: "",
  emailCont: "",
  subjectCont: "",
  messageCont: "",
  messageSent: false,
  formFilled: false,

  tabStatus: "left", // "right" "left"

  error: null, // "404" "500"
  errorMySQL: false,
  errorAuth: false,
  errorMessage: false, // "table mistake", "file mistake"
  mistake: null,
  errorSignUp: null,
  errorLogIn: null,
  errorSearchUser: null,
  errorVerifyOTP: null,
  errorNewPassword: null,

  //for useEffect
  loading: true,
  //Pages
  quizPage: null,
  userPage: null,
  otherUserPage: null,
  quizEditPage: null,

  //Countdown stopwatch
  isRunning: false,
  sec: 90,

  //MEMBERSHIP PLAN

  // studTeams: 1,
  // studMembers: 10,
  // studQuizzes: 3,
  // studPoints: 750,

  // bronzeTeams: 4,
  // bronzeMembers: 15,
  // bronzeQuizzes: 7,
  // bronzePoints: 1750,
  // bronzePayMonth: 2,
  // bronzePayYear: 20,

  // silverTeams: 7,
  // silverMembers: 20,
  // silverQuizzes: 11,
  // silverPoints: 2750,
  // silverPayMonth: 5,
  // silverPayYear: 50,

  // goldTeams: 10,
  // goldMembers: 25,
  // goldQuizzes: 15,
  // goldPoints: 3750,
  // goldPayMonth: 15,
  // goldPayYear: 150,

  // guruTeams: 15,
  // guruMembers: 40,
  // guruQuizzes: 20,
  // guruPoints: 5000,
  // guruGoldPayMonth: 7,
  // guruGoldPayYear: 70,

  quizPoints: {
    changeType: 10, // earned if the type changes from PRIVATE to PUBLIC and lost if the type changes from PUBLIC to PRIVATE
    numVote: 10, // the min sum of upvotes and downvotes where the greater numbe of downvotes does not matter
    vote: 3, // number of points quiz author earns if their quiz it upvoted and loses if their quiz is downvoted
    votePercent: 60 // min admissible percent of downvotes
  },

  membPlan: {
    student: {
      quizzes: 3, // 3
      points: 200, // 30
      teams: 1,
      members: 10, // 10
      payMonth: 0,
      payYear: 0
    },
    bronze: {
      quizzes: 10, // 10
      points: 1000, // 1000
      teams: 5, // 5
      members: 20, // 20
      payMonth: 0,
      payYear: 0
    },
    silver: {
      quizzes: 15, // 15
      points: 2000, // 2000
      teams: 10, // 10
      members: 30, // 30
      payMonth: 0,
      payYear: 0
    },
    gold: {
      quizzes: 20, // 20
      points: 3000, // 3000
      teams: 15, // 15
      members: 40, // 40
      payMonth: 0,
      payYear: 0
    },
    guru: {
      quizzes: 30, // 30
      points: 5000, // 5000
      teams: 50, // 50
      members: 100, // 100
      payMonth: 0,
      payYear: 0
    }
  },


  // USER 
  //-----auth
  auth: "login", //  "signup"
  passwordRecovery: "email", // "getOTP" "new password"
  username: "",
  email: "",
  password: "",
  passwordConfirm: "",
  verifiedData: null,
  enterData: false,
  wrongData: false,
  checkUser: false,
  sentOTP: null,
  typedOTP: "",
  userScore: 0,
  authorScore: 0,
  authorMem: "STD",
  authorBan: "TRUE",
  authorId: null,
  userId: null,
  userMem: "STD", // "STU", "BRZ", "SLV", "GLD", "GURU", "ADM"
  userBan: "TRUE",
  userPayment: "FALSE",
  userNumPubQuizzes: 0,
  userNumTeams: 0, // num_teams
  currentUser: JSON.parse(localStorage.getItem("user")) || null,


  //------User page
  userPageStatus: "main", // "edit profile"
  createdQuizzes: [],
  imgFile: null,
  imgUrl: "",
  userTeams: [],
  userInvitations: [],
  submitEditedProfileStatus: false,


  //----------Other user
  otherUser: null,
  userPublicQuizzes: [],


  //TEAMS

  // teamStatus: "create", // "open", //

  //-------Create | Edit Team
  createTeamMessage: "",
  radioValues: [{ value: "ADMIN", label: "Only I", labelRU: "Только я" },
  { value: "SOME", label: "I & my assistants", labelRU: "Я и мои ассистенты" },
  { value: "ALL", label: "All members", labelRU: "Все" }],
  typeAddQuiz: null,
  typeAddMemb: null,
  adminTeams: [],
  addQuizzes: [],
  favTeamQuizzes: [],
  teamQuizId: null,
  currentQuiz: null,
  teamQuizTitle: "",
  addQuizStatus: "start",//"add",  "added"
  removeQuizStatus: "start",//"remvove",  "removed"
  teamQuizTitles: [],
  invStatus: "start", // "type" "invite", "invited"
  invUsername: null,
  invUserId: "",
  invUser: null,
  invUsers: [],
  membersToRemove: [],

  //-----Edit Team
  teamQuizTitleToRemove: "",
  teamQuizTitlesToRemove: [],
  currentQuizToRemove: null,
  teamQuizToRemoveId: null,
  currentUserType: null,
  // teamAddQuizType: null,
  // teamAddMemberType: null,
  editSettingsStatus: "show", // "edit"
  teamNameNew: "", //teamname

  //-----Team
  team: {},
  teamData: [],
  teamId: null, // id
  teamName: null, //teamname
  teamAdmin: "", //admin_id
  numOfQuizzes: 0, //num_of_quizzes
  numOfMemb: 1, //num_of_memb
  teamQuizzes: [],
  teamMembers: [],
  teamAdminMem: null,
  teamAdminNumTeams: null,
  numTeamMembers: 0,
  members: [],
  invited: [],
  teamMemberId: null,
  teamMemberName: "",
  teamMemberType: "", // "ADMIN" "ASST" "MEMB" "INV"
  teamMemberNewType: "", // "ASST" "MEMB"
  changeTeamMemberTypeStatus: "start", // "choose", "chosen"
  removeTeamMemberStatus: "start", // "choose", // "chosen" "removed"
  teamUsersId: null, //id in the table team_users


  //QUIZ

  quizStatus: "initial", //"adjust", "quiz", "result"
  quizLengthStatus: "long", // "long", "short"
  quiz: {},
  quizId: null,
  quizTitle: "", // "title"
  quizType: null, // type  "PUBLIC", "TEAM"
  oldQuizType: null,
  quizDescAuto: "",
  quizDescAuthor: null,
  quizAuthor: "",
  favQuizzes: [],
  favQuiz: [{}],
  favQuizId: null,
  favQuizStatus: false,
  fileUrl: null,
  fileName: "",  // file
  quizValues: [],
  quizRows: [],
  quizOptions: [],
  quizRowsEdit: [],
  quizValuesToUpdate: [],
  quizColumnsToInsert: [],
  quizRowsToInsert: [],
  quizColRowsToInsert: [],
  quizRowsToDelete: [],
  quizOptionsEdit: [],
  quizOptionsToUpdate: [],
  quizOptionsToInsert: [],
  quizOptionsToDelete: [],
  // quizOptionsToRemove: [],
  numForTempColId: 0, // for creating temperary id for quiz headers
  numForTempValId: 0, // for creating temperary id for values of quiz rows
  numForTempRowId: 0, // for creating temperary id for quiz rows
  numOfTasks: 0,
  timesTaken: 0, // times_taken
  comments: [],
  currUserComment: [],
  removeComments: false,
  recQuiz: 0, // rec_quiz (recommend quiz)
  unrecQuiz: 0, // unrec_quiz (not recemmend quiz)
  recom: "NONE", //"REC", "UNREC" (recommendation)
  recStatus: "nothing", // "rec" "comment", "done", "nothing"
  comment: null,
  questionFontSize: 1,
  answerFontSize: 1,

  //----------Create Quiz Table
  headers: ["#", "", ""],

  values: [
    ["", ""],
    ["", ""],
    ["", ""],
    ["", ""],
    ["", ""]
  ],

  columnNum: null,
  rowNum: null,
  tableFontSize: 1,
  cellSize: 1,

  //---------Adjust Quiz
  question: "- Select Question Option -",
  answer: "- Select Answer Option -",
  questionRU: "- Выбрать опцию для вопроса -",
  answerRU: "- Выбрать опцию для ответа -",
  answerIndex: 0,
  questionIndex: 0,
  answerOptions: [],
  taskStatus: "taskNumber", // "nextTask" "quizResult"
  taskNumber: 1,
  tasks: [],
  indexRightAnswer: 0,
  renderQuestion: "",
  renderAnswers: [],
  score: 0,
  storeScore: [],
  averageScore: 0,
  failedTasks: [],
  btnInact: false,


  // LIBRARY
  publicQuizzes: [],
  catQuizzes: [],
  catId: null,
  catName: null,
  catNameRU: null,
  cat: [],
  catRU: [],
  catNames: [{}],
  catArr: [],
  catArrRU: [],
  catLangQuizzes: [],
  catStatus: false, //changes whether to show all cat quizzes or only which belong to the selected language
  catNumOfQuizzes: 0,
  lang: [],
  langRU: [],
  langArr: [],
  langArrRU: [],
  langArrLang: [],
  langArrLangRU: [],
  language: null,
  languageRU: null,
  langId: null,
  langStatus: false,
  searchItem: "",
  itemArrLength: 5,
})
export default getInitialState