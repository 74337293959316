import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { QuizContext } from "../App";
import axios from "axios";
import Loading from "../components/Loading";
import Error from "./Error";
import AccordionTeam from "../components/accordions/AccordionTeam";
import TableTeamHeader from "../components/tables/TableTeamHeader";
import TextNotYourTeam from "../components/text/TextNotYourTeam";
import TextBanTeamAccess from "../components/text/TextBanTeamAccess";
import { banTeamAccess } from "../reducer/actions/groupActions/BanTeam";

const Team = () => {
  const quizContext = useContext(QuizContext);
  const { id } = useParams();
  const dispatch = quizContext.dispatch;

  // Get all Team information
  useEffect(() => {
    const fecthData = async () => {
      try {
        const res = await axios.get(`/team/page/${id}`);
        // console.log("res.data", res.data);
        const teamPage = res.data;
        // console.log("teamPage", teamPage);
        dispatch({ type: "fetchTeamPage", teamPage });
      } catch (err) {
        const error = err.response.data.message;
        console.log("fetchTeamPageErr:", error);
        dispatch({ type: "fetchTeamPageErr", error });
      }
    }
    fecthData();
  }, [id, dispatch]);

  const ban = banTeamAccess(
    quizContext.state.teamAdminMem,
    quizContext.state.teamAdminNumTeams,
    quizContext.state.numTeamMembers,
    quizContext.state
  );

  return (
    <div>
      {quizContext.state.loading
        ? <Loading />
        : quizContext.state.errorMySQL
          || quizContext.state.errorAuth
          || quizContext.state.error === "404"
          ? <Error />
          : quizContext.state.teamMembers.filter(el => el.user_id === quizContext.state.currentUser.id).length === 0
            ? <TextNotYourTeam />
            : ban === true
              ? <TextBanTeamAccess />
              : <>
                <TableTeamHeader />
                <AccordionTeam />
              </>
      }
    </div>
  )
}

export default Team