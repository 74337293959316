const getOTP = (state, action) => {
  const typedOTP = action.payload;
  return {
    ...state,
    typedOTP,
    alertStatus: null,
    errorSignUp: null,
    errorLogIn: null
  };
}

export default getOTP