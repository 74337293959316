import React, { useContext, useEffect } from "react";
import { QuizContext } from "../App";
import Card from "react-bootstrap/Card";
import Error from "../pages/Error";
import FormOTP from "./forms/FormOTP";
import ButtonCancelAndGoToPrevPage from "./buttons/ButtonCancelAndGoToPrevPage";
// import switchToGetOTP from "../reducer/actions/userActions/SwitchToGetOTP";
import submitSignUp from "../reducer/actions/userActions/SubmitSignUp";
import SwitchToGetOTPsignup from "../reducer/actions/userActions/SwitchToGetOTPsignup";


const AuthSignUpGetOTP = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const isRunning = quizContext.state.isRunning;
  const dispatch = quizContext.dispatch;
  const sec = quizContext.state.sec;

  useEffect(() => {
    let interval;
    if (isRunning) {
      let time = sec;
      interval = setInterval(() => {
        if (time > 0) {
          time -= 1;
          dispatch({ type: "setSecs", sec: time })
        }
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isRunning, dispatch, sec]);


  return (
    <>
      {quizContext.state.errorSignUp === "500"
        ? <Error />
        : <>
          <h2 className="header">
            CONFIRM EMAIL
          </h2>

          <Card
            data-bs-theme={theme}
            style={{
              boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px"
            }}
          >

            <Card.Body>Enter the OTP code sent to your email: <span style={{ fontWeight: "600" }}>{quizContext.state.email}</span>
            </Card.Body>

            <Card.Body style={{ textAlign: "center" }}>
              <FormOTP />
            </Card.Body>

            <Card.Body>
              <button
                className={`${theme}-main-btn`}
                onClick={(e) => {
                  e.preventDefault()
                  submitSignUp(quizContext.dispatch, quizContext.state)
                  quizContext.dispatch({ type: "submitSignUp" })
                }}
              >
                SUBMIT OTP
              </button>

              <ButtonCancelAndGoToPrevPage />

              {quizContext.state.wrongData === true || (quizContext.state.errorSignUp && quizContext.state.errorSignUp !== "500")
                ? <div
                  style={{
                    fontSize: "2rem",
                    marginTop: "25px",
                    color: "red",
                    fontWeight: "600"
                  }}
                >Wrong OTP code</div>
                : null
              }

            </Card.Body>
          </Card>


          <div>
            {quizContext.state.sec === 0
              ? <>
                <button
                  className="fourth-btn"
                  onClick={(e) => {
                    e.preventDefault()
                    SwitchToGetOTPsignup(quizContext.dispatch, quizContext.state)
                    quizContext.dispatch({ type: "SwitchToGetOTPsignup" })
                  }}
                >
                  RESEND OTP
                </button>
              </>
              : <>
                <p>Didn't receive the code? Check SPAM folder. Still nothing?<br />
                  Resend OTP in {quizContext.state.sec} seconds.</p>
              </>
            }
          </div>
        </>
      }
    </>
  )
}

export default AuthSignUpGetOTP