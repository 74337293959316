import React from "react";
import ButtonMyPage from "../buttons/ButtonMyPage";
import ButtonHome from "../buttons/ButtonHome";

const TextNotYourTeamQuiz = () => {
  return (
    <div style={{
      paddingBottom: "420px",
      marginTop: "40px",
      width: "100%",
      textAlign: "center",
      fontSize: "1.2rem"
    }}>
      <div style={{ fontSize: "3rem", fontWeight: "600" }}>КОМАНДНЫЙ квиз!</div>
      <div style={{ marginTop: "20px", fontSize: "1.3rem" }}>
        Его можно открыть только со страницы команды<br /> 
        или из вкладки ИЗБРАННЫЕ КВИЗЫ на Вашей странице.
      </div>

      <div
        style={{
          textAlign: "center",
          marginTop: "30px"
        }}
      >
        <ButtonMyPage />
        <ButtonHome />
      </div>
    </div>
  )
}

export default TextNotYourTeamQuiz