import React, { useContext } from "react";
import FormSelectQuestion from "./forms/FormSelectQuestion";
import FormSelectAnswer from "./forms/FormSelectAnswer";
import ButtonStartQuiz from "./buttons/ButtonStartQuiz";
import TextQuizHeader from "./text/TextQuizHeader";
import { QuizContext } from "../App";
import FormSelectQuizLength from "./forms/FormSelectQuizLength";
import { Card } from "react-bootstrap";

const QuizAdjust = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <div>
      <TextQuizHeader />
      <Card
        data-bs-theme={theme}
        style={{ boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px" }}
      >
        <div className={`${theme}-main-card`}>

          <FormSelectQuestion />
          <FormSelectAnswer />
          {quizContext.state.errorMessage === true
            && <p className="error">Set Question and Answer Options.<br />
              The values must be different.</p>
          }
          {quizContext.state.quizRows.length >= 8 && <div
            style={{
              display: "block",
              textAlign: "center",
              marginTop: "25px"
            }}
          >
            <FormSelectQuizLength />
          </div>
          }
          <div
            style={{
              display: "block",
              textAlign: "center",
              marginTop: "20px"
            }}
          >
            <ButtonStartQuiz />
          </div>
        </div>
      </Card>
    </div>
  )
}

export default QuizAdjust