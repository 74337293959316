//NavBarRU

const toggleLang = (state) => {

  const websiteLang = state.websiteLang === "ru"
    ? "en"
    : "ru"

  return {
    ...state,
    websiteLang
  }
}

export default toggleLang