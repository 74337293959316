import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import { QuizContext } from "../../App";
import FormSearchItem from "../forms/FormSearchItem";

const AccordionUserPublicQuizzes = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <div className={`${theme}-card-gray`} style={{ marginTop: "35px" }}>
    <Accordion data-bs-theme={theme}>
      <Accordion.Item eventKey="0">
        <Accordion.Header style={{ color: "black" }}>
          PUBLIC QUIZZES {quizContext.state.userPublicQuizzes.length}
        </Accordion.Header>
        <Accordion.Body className="accor">

          {quizContext.state.userPublicQuizzes.length > quizContext.state.itemArrLength && <FormSearchItem />}

          <Table
            striped hover
            variant={theme}
            style={{ width: "100%" }}
          >
            {!quizContext.state.userPublicQuizzes.length
              ? <tbody><tr><th style={{ color: "red" }}>
                Nothing to show yet
              </th ></tr></tbody>
              : <tbody>
                {quizContext.state.userPublicQuizzes
                  .filter((el) => quizContext.state.searchItem.toLowerCase() === ""
                    ? el
                    : el.title.toLowerCase().includes(quizContext.state.searchItem.toLowerCase()))
                  .map((el, i) => (
                    <tr key={i}>
                      <th style={{ textAlign: "left", width: "70%" }}>
                        <Link
                          to={`/quiz/${el.id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <button className={`${theme}-link-btn`}>
                            {el.title}
                          </button>
                        </Link>
                      </th>

                      <th style={{ textAlign: "right", width: "30%" }}>
                        <span className={`${theme}-rec`}>{el.rec_quiz}</span> / <span className="unrec">{el.unrec_quiz}</span>
                      </th>

                      <th style={{ textAlign: "right" }}>
                        {el.catname}
                      </th>
                    </tr>
                  ))}
              </tbody>
            }
          </Table>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </div>
  )
}

export default AccordionUserPublicQuizzes