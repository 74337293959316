import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { QuizContext } from "../../App";

const TableTeamHeader = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <>

      <h2 className="header">
        <span className="header-tq">КОМАНДА: </span>
        <span className="header-tq-title">
          {quizContext.state.team.teamname}
        </span>
      </h2>

      <div style={{ marginBottom: "20px" }}>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <th>
                <span style={{ fontWeight: "400" }}>Админ: </span>
                <Link
                  to={`/user/${quizContext.state.team.admin_id}`}
                  style={{
                    textDecoration: "none",
                    color: theme === "dark"
                      ? "#4da2f7"
                      : "#0a70d6",
                    fontWeight: "500"
                  }}
                  onClick={() => quizContext.dispatch({
                    type: "backToMainPage"
                  })}
                >
                  {quizContext.state.team.username}
                </Link>

              </th>
              <th style={{ textAlign: "right", width: "10%" }}>
                <Link
                  to={`/edit_team/${quizContext.state.team.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <img
                    src={theme === "dark"
                      ? require("../images/editW.png")
                      : require("../images/edit.png")
                    }
                    className={`${theme}-edit-icon`}
                    alt={"edit"}
                  />
                </Link>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default TableTeamHeader