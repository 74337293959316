export const getUsernameContact = (state, action) => {
  const usernameCont = action.payload;

  if (usernameCont
    && state.emailCont
    && state.subjectCont
    && state.messageCont) {
    return {
      ...state,
      usernameCont,
      formFilled: true,
      errorMessage: false
    }
  } else {
    return {
      ...state,
      usernameCont,
      formFilled: false,
      errorMessage: false
    }
  }
}

export const getEmailContact = (state, action) => {
  const emailCont = action.payload;

  if (state.usernameCont
    && emailCont
    && state.subjectCont
    && state.messageCont) {
    return {
      ...state,
      emailCont,
      formFilled: true,
      errorMessage: false
    }
  } else {
    return {
      ...state,
      emailCont,
      formFilled: false,
      errorMessage: false
    }
  }

}

export const getSubjectContact = (state, action) => {
  const subjectCont = action.payload;

  if (state.usernameCont
    && state.emailCont
    && subjectCont
    && state.messageCont) {
    return {
      ...state,
      subjectCont,
      formFilled: true,
      errorMessage: false
    }
  } else {
    return {
      ...state,
      subjectCont,
      formFilled: false,
      errorMessage: false
    }
  }


}

export const getMessageContact = (state, action) => {
  const messageCont = action.payload;

  if (state.usernameCont
    && state.emailCont
    && state.subjectCont
    && messageCont) {
    return {
      ...state,
      messageCont,
      formFilled: true,
      errorMessage: false
    }
  } else {
    return {
      ...state,
      messageCont,
      formFilled: false,
      errorMessage: false
    }
  }
}