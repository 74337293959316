// ModalAnotherQuiz, ModalEditedProfileSumbitted, ModalForAcceptTeamInv, ModalForCreateQuiz, ModalForDeclineTeamInv, ModalForDeleteTeam, ModalForDeleteQuiz, ModalForEditQuiz, ModalForFormCreateTeam, ModalInvitations, ModalMyTeams, ModalMyQuizzes, ModalNoQuizzesInCat, ModalSavedQuizzes, ModalSaveQuliz

const hideAlert = (state) => {

  if (state.alertStatus === "instruction" 
  || state.alertStatus === "contact") {
    return {
      ...state,
      alert: false,
      alertStatus: null
    }
  } else {
    return {
      ...state,
      alert: false,
      loading: true,
      alertStatus: null
    }
  }
}

export default hideAlert