import React, { useContext } from "react";
import { QuizContext } from "../../App";
import ButtonCancelAndGoToPrevPage from "./ButtonCancelAndGoToPrevPage";
import submitEditedQuizContents from "../../reducer/actions/quizActions/SubmitEditedQuizContents";

const ButtonsSubmitEditedQuizContents = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <>
      {quizContext.state.alertStatus === "changes made"
        ? <button
        className={`${theme}-main-btn`}
          onClick={() => quizContext.dispatch({
            type: "confirmEditedQuizContents"
          })}
        >
          CONFIRM
        </button>
        : <button className={`${theme}-main-btn-inactive`}>
          <span style={{ cursor: "crosshair" }}>
            CONFIRM
          </span>
        </button>
      }

      {quizContext.state.alertStatus === "changes confirmed"
        ? <button
        className={`${theme}-main-btn`}
          onClick={(e) => {
            e.preventDefault()
            submitEditedQuizContents(
              quizContext.dispatch,
              quizContext.state
            )
            quizContext.dispatch({
              type: "submitEditedQuizContents"
            })
          }}
        >
          SUBMIT
        </button>
        : <button className={`${theme}-main-btn-inactive`}>
          <span style={{ cursor: "crosshair" }}>
            SUBMIT
          </span>
        </button>
      }
      <ButtonCancelAndGoToPrevPage />
    </>
  )
}

export default ButtonsSubmitEditedQuizContents