import getInitialState from "../../../state/GetInitialState";

// NavBar, ButtonCancelAndGoToPrevPage, ButtonTeam, ButtonMyPage, ModalAnotherQuiz, TextNotYourTeam, TextNotYourQuiz

const backToQuizTitlePage = (state) => {

  const errorAuth = state.errorAuth;
  const errorMySQL = state.errorMySQL;
  const theme = state.theme;
  const isRussia = state.isRussia;
  const websiteLang = state.websiteLang;
  const teamData = state.teamData;

  return {
    ...getInitialState(),
    errorAuth,
    errorMySQL,
    theme,
    isRussia,
    websiteLang,
    teamData
  };

};

export default backToQuizTitlePage