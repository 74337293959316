import React, { useContext } from 'react'
import { QuizContext } from '../../App';
import Form from 'react-bootstrap/Form';
import { useParams } from 'react-router-dom';


const FormSelectQuizLang = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const { id } = useParams();

  return (
    <Form>
      {id === "22" && quizContext.state.langArrLangRU.filter((el, i) => quizContext.state.langArrLangRU.indexOf(el) === i).length > 1
        ? <Form.Select
          size="sm"
          data-bs-theme={theme}
          style={{
            border: theme === "dark"
              ? "1px solid #77797c"
              : "1px solid #c6c6c7"
          }}
          onChange={(e) => quizContext.dispatch({
            type: "filterCatQuizzes",
            payload: e.target.value
          })}
        >
          <option value="nothing selected">Выбрать Язык:</option>
          <option value="all">Все</option>
          {quizContext.state.langArrLangRU.filter((el, i) => quizContext.state.langArrLangRU.indexOf(el) === i).map((el, i) =>
            <option key={i} value={el}>{el}</option>
          )}
        </Form.Select>
        : id !== "22" && quizContext.state.langArrRU.filter((el, i) => quizContext.state.langArrRU.indexOf(el) === i).length > 1
          ? <Form.Select
            size="sm"
            data-bs-theme={theme}
            style={{
              border: theme === "dark"
                ? "1px solid #77797c"
                : "1px solid #c6c6c7"
            }}
            onChange={(e) => quizContext.dispatch({
              type: "filterCatQuizzes",
              payload: e.target.value
            })}
          >
            <option value="nothing selected">Выбрать Язык:</option>
            <option value="all">Все</option>
            {quizContext.state.langArrRU.filter((el, i) => quizContext.state.langArrRU.indexOf(el) === i).map((el, i) =>
              <option key={i} value={el}>{el}</option>
            )}
          </Form.Select>
          : null
      }
    </Form>
  )
}

export default FormSelectQuizLang