// FormTableCreateQuiz

const showModalAddRemoveColumn = (state, action) => {

  const columnNum = action.num;

  return {
    ...state,
    alertTable: true,
    alertStatus: "column",
    video: false,
    columnNum
  }
}


export default showModalAddRemoveColumn