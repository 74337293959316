import React, { useContext } from "react";
import { QuizContext } from "../../App";

const CardUserInvitations = () => {
  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const alertStatus = "invitations";

  return (
    <div
      className={`${theme}-card-user`}
      onClick={() => quizContext.dispatch({
        type: "showModal",
        alertStatus
      })}
    >
      INVITATIONS: {quizContext.state.userInvitations.length}
    </div>
  )
}

export default CardUserInvitations