import React, { useContext } from "react";
import { QuizContext } from "../../App";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";

const CardNavCreateQuiz = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Link to="/create_quiz" style={{ textDecoration: 'none' }}>
      <Card
        data-bs-theme={theme}
        className="card-home"
        onClick={() => quizContext.dispatch({
          type: "backToMainPage"
        })}
      >
        <img
          src={theme === "dark"
            ? require('../images/create.png')
            : require('../images/create1.png')
          }
          className="card-icon"
          alt={'Create_icon'}
        />

        <p className="card-text">
          CREATE QUIZ
        </p>
      </Card>
    </Link>
  )
}

export default CardNavCreateQuiz 