import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { QuizContext } from "../../App";
// import { Link } from "react-router-dom";

const ModalForSignedUp = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (

    <Modal
      size="sm"
      data-bs-theme={theme}
      show={quizContext.state.alert}
      onHide={() => quizContext.dispatch({
        type: "hideAlert"
      })}
    >
      <Modal.Header closeButton data-bs-theme={theme}
        className={`${theme}-modal-header`}
      >
        <Modal.Title>
          <span className={`${theme}-congrat`}>
            ПОЗДРАВЛЯЕМ!
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={`${theme}-modal-body`}
        style={{ textAlign: "center" }}
      >
        Вы успешно зарегистрировались на КВИЗАХ!<br />
        Теперь войдите в свой аккаунт.

        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <button
            className={`${theme}-main-btn`}
            onClick={() => quizContext.dispatch({
              type: "hideAlert"
            })}
          >
            OK
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ModalForSignedUp