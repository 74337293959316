import React, { useContext } from "react";
import { QuizContext } from "../App";
import Form from "react-bootstrap/Form";
import FormEmail from "./forms/FormEmail";
import FormPassword from "./forms/FormPassword";
import FormUsername from "./forms/FormUsername";
import submitSignUp from "../reducer/actions/userActions/SubmitSignUp";
import Error from "../pages/Error";
import ButtonCancelAndGoToPrevPage from "./buttons/ButtonCancelAndGoToPrevPage";
import ButtonSignUp from "./buttons/ButtonSignUp";
import { Card } from "react-bootstrap";
// import switchToGetOTPsignup from "../reducer/actions/userActions/SwitchToGetOTPsignup";

const AuthSignUp = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const errorSignUp = quizContext.state.errorSignUp === "MySQL error"
    ? "Oops! Something went wrong. Try again later"
    : quizContext.state.errorSignUp;

  return (
    <div>
      {quizContext.state.errorSignUp === "500"
        ? <Error />
        : <>
          <h2 className="header">
            СОЗДАТЬ АККАУНТ
          </h2>

          <Form
            onSubmit={(e) => {
              e.preventDefault()
              submitSignUp(quizContext.dispatch, quizContext.state)
              quizContext.dispatch({ type: "submitSignUp" })
            }}
          >
            <Card
              data-bs-theme={theme}
              style={{
                boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px"
              }}
            >
              <div className={`${theme}-main-card`}>
                {quizContext.state.errorSignUp !== null
                  && quizContext.state.errorSignUp !== "500"
                  && <p className="error">
                    {errorSignUp}
                  </p>
                }
                <div style={{ marginTop: "15px" }}><FormUsername /></div>
                <div style={{ margin: "20px 0" }}><FormEmail /></div>
                <div style={{ margin: "20px 0" }}><FormPassword /></div>

                <div style={{
                  textAlign: "center",
                  marginTop: "30px"
                }}
                >
                  <ButtonSignUp />
                  <ButtonCancelAndGoToPrevPage />
                </div>
              </div>
            </Card>

            <div
              style={{
                textAlign: "center",
                marginTop: "20px"
              }}>
              <p> Если у Вас <b>есть</b> аккаунт,<button
                className={`${theme}-fourth-btn`}
                onClick={() => quizContext.dispatch({ type: "switchToLogin" })}
              >
                ВОЙТИ
              </button></p>
            </div>
          </Form>
        </>
      }
    </div>
  )
}

export default AuthSignUp