import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import { QuizContext } from "../../App";
import addQuizToTeam from "../../reducer/actions/groupActions/AddQuizToTeam";

const FormTeamAddQuiz = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Form>
      <Form.Label
        style={{
          fontWeight: "600"
        }}
      >
        Добавить квиз
      </Form.Label>
      {quizContext.state.addQuizzes.length
        ? <>
          <Form.Select
            data-bs-theme={theme}
            style={{
              border: theme === "dark"
                ? "1px solid #77797c"
                : "1px solid #212529"
            }}
            onChange={(e) => quizContext.dispatch({
              type: "selectQuizForTeam",
              payload: e.target.value
            })}
          >
            <option value={"- Select Quiz -"}>- Выбрать квиз -</option>
            {quizContext.state.addQuizzes.map((el, i) =>
              <option key={i} value={el.title}>{el.title}</option>
            )}
          </Form.Select>

          <div
            style={{
              textAlign: "left",
              marginTop: "15px"
            }}
          >
            {quizContext.state.teamQuizTitle !== ""
              && quizContext.state.addQuizStatus === "add"
              && <>
                <span
                  style={{ fontSize: "1.2rem" }}
                >
                  {quizContext.state.teamQuizTitle}
                </span>
                <button
                  className={`${theme}-fourth-btn`}
                  onClick={(e) => {
                    e.preventDefault()
                    addQuizToTeam(
                      quizContext.dispatch,
                      quizContext.state
                    )
                    quizContext.dispatch({
                      type: "addQuizToTeam"
                    })
                  }}
                >
                  ДОБАВИТЬ
                </button>
                <button
                  className="third-btn"
                  onClick={() => quizContext.dispatch({
                    type: "notAddQuiz"
                  })}
                >
                  НЕТ
                </button>
              </>
            }
          </div>
        </>
        : <Form.Select
          style={{
            border: "1px solid #212529",
            color: "red"
          }}
        >
          <option>У Вас нет квизов с типом ЛИЧНЫЙ, чтобы добавить в команду</option>
        </Form.Select>
      }

      <div style={{ textAlign: "left", lineHeight: "0.5rem" }}>
        <p style={{ fontWeight: "600", marginTop: "20px" }}>
          Добавленные квизы:</p>
        {quizContext.state.teamQuizTitles.filter((el, i) =>
          quizContext.state.teamQuizTitles.indexOf(el) === i).map((el, i) =>
            <p key={i} style={{ marginLeft: "15px" }}>{el}</p>)}
      </div>

    </Form>
  )
}

export default FormTeamAddQuiz