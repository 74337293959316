// ModalAddRemoveRow

const addRow = (state) => {

  const num = state.rowNum;
  const newValue = Array(state.headers.length - 1).fill("");
  const values = [...state.values];
  values.splice(num, 0, newValue.flat());

    return {
      ...state,
      alertTable: false,
      alertStatus: null,
      values
    }
  }


export default addRow