import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { QuizContext } from "../../App";
import submitTeamMemberNewType from "../../reducer/actions/groupActions/SubmitTeamMemberNewType";
import ButtonCancelAndHideAlert from "../buttons/ButtonCancelAndHideAlert";

const ModalForAcceptTeamInv = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Modal
      // size="sm"
      data-bs-theme={theme}
      show={quizContext.state.alert}
      onHide={() => quizContext.dispatch({
        type: "hideAlert"
      })}
      centered
    >
      <Modal.Header closeButton data-bs-theme={theme}
        className={`${theme}-modal-header`}
      >
        <div>Вы собираетесь принять приглашение <br />
        в команду <span style={{fontWeight: "600"}}>{quizContext.state.teamName}</span></div>
      </Modal.Header>
      <Modal.Body
        className={`${theme}-modal-body`}
        style={{ alignItems: "center" }}
      >
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <button
            className={`${theme}-main-btn`}
            onClick={(e) => {
              e.preventDefault()
              submitTeamMemberNewType(
                quizContext.dispatch,
                quizContext.state
              )
              quizContext.dispatch({
                type: "submitTeamMemberNewType"
              })
            }}
          >
            ПРИНЯТЬ ПРИГЛАШЕНИЕ
          </button>

          <ButtonCancelAndHideAlert />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ModalForAcceptTeamInv