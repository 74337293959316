import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { QuizContext } from "../../App";

const ButtonDeleteQuiz = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const alertStatus = "delete quiz";

  return (
    <>
      {quizContext.state.quiz.type === "TEAM"
        ? <>
          <div>
            <span style={{ fontWeight: "600" }}>{quizContext.state.quiz.title}</span> — КОМАНДНЫЙ квиз<br />
            Чтобы удалить его, на странице РЕДАКТИРОВАТЬ КОМАНДУ удалите его из списка квизов команды.
          </div>
          <Link
            to={`/edit_team/${quizContext.state.quiz.team_id}`}
            style={{ textDecoration: 'none' }}
          >
            <button
              className={`${theme}-sec-btn`}
              style={{ marginTop: "10px" }}
            >
              РЕДАКТИРОВАТЬ КОМАНДУ
            </button>
          </Link>
        </>

        : quizContext.state.quizType === "PUBLIC"
          ? <>
            <div>
              <span style={{ fontWeight: "600" }}>{quizContext.state.quiz.title}</span> — ОБЩЕДОСТУПНЫЙ квиз.<br />
              Чтобы удалить его, измените тип квиза на ЛИЧНЫЙ.
            </div>
          </>

          : <button
            className={`${theme}-sec-btn`}
            onClick={() => quizContext.dispatch({
              type: "showModal",
              alertStatus
            })}
          >
            УДАЛИТЬ КВИЗ
          </button>
      }
    </>
  )
}

export default ButtonDeleteQuiz