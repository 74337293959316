import React, { useContext } from "react";
import { QuizContext } from "../../App";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import ButtonCancelAndGoToPrevPage from "./ButtonCancelAndGoToPrevPage";
import leaveTeam from "../../reducer/actions/groupActions/LeaveTeam";
import ModalForLeaveTeam from "../modals/ModalForLeaveTeam";

const ButtonsLeaveTeam = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <>
      {quizContext.state.teamQuizzes.filter(el => el.author_id === quizContext.state.currentUser.id).length !== 0
        ? <span>If you want to leave the team, first, remove all quizzes you shared to it.</span>
        : <Container fluid='md'>
          <Link
            to={`/user/${quizContext.state.currentUser.id}`}
            style={{ textDecoration: 'none' }}
          >
            <button
              className={`${theme}-sec-btn`}
              onClick={(e) => {
                e.preventDefault()
                leaveTeam(
                  quizContext.dispatch,
                  quizContext.state
                )
                quizContext.dispatch({
                  type: "leaveTeam"
                })
              }}
            >
              LEAVE TEAM
            </button>
          </Link>

          <ButtonCancelAndGoToPrevPage />

        </Container>
      }

      {quizContext.state.alertStatus === "left team"
        && <ModalForLeaveTeam />
      }
    </>
  )
}

export default ButtonsLeaveTeam