import hideAlert from "./actions/HideAlert";
import getQuizTitle from "./actions/quizActions/getQuizDataActions/GetQuizTitle";
import submitQuiz from "./actions/quizActions/SubmitQuiz";
import selectQuestion from "./actions/quizActions/getQuizDataActions/SelectQuestion";
import selectAnswer from "./actions/quizActions/getQuizDataActions/SelectAnswer";
import solveQuiz from "./actions/quizActions/SolveQuiz";
import gues from "./actions/quizActions/Gues";
import getNextTask from "./actions/quizActions/GetNextTask";
import backToAdjustQuiz from "./actions/quizActions/BackToAdjustQuiz";
import switchToSignup from "./actions/userActions/SwitchToSignup";
import switchToLogin from "./actions/userActions/SwitchToLogin";
import backToMainPage from "./actions/BackToMainPage";
import openCatQuizzes from "./actions/quizActions/OpenCatQuizzes";
import editQuizTitle from "./actions/quizActions/getQuizDataActions/EditQuizTitle";
import editQuizDesc from "./actions/quizActions/getQuizDataActions/EditQuizDesc";
import editQuizType from "./actions/quizActions/getQuizDataActions/EditQuizType";
import editQuizCat from "./actions/quizActions/getQuizDataActions/EditQuizCat";
import goToEditProfile from "./actions/userActions/GoToEditProfile";
import getTeamName from "./actions/groupActions/getGroupDataActions/GetTeamName";
import selectQuizForTeam from "./actions/groupActions/getGroupDataActions/SelectQuizForTeam";
import notAddQuiz from "./actions/groupActions/NotAddQuiz";
import setTypeAddQuiz from "./actions/groupActions/getGroupDataActions/SetTypeAddQuiz";
import setTypeAddMemb from "./actions/groupActions/getGroupDataActions/SetTypeAddMemb";
import getUserIdForSearch from "./actions/groupActions/getGroupDataActions/GetUserIdForSearch";
import notInvite from "./actions/groupActions/NotInvite";
import goToAcceptTeamInv from "./actions/groupActions/GoToAcceptTeamInv";
import goToDeclineTeamInv from "./actions/groupActions/GoToDeclineTeamInv";
import selectQuizToRemoveFromTeam from "./actions/groupActions/getGroupDataActions/SelectQuizToRemoveFromTeam";
import selectTeamMember from "./actions/groupActions/getGroupDataActions/SelectTeamMember";
import changeTeamMemberType from "./actions/groupActions/getGroupDataActions/ChangeTeamMemberType";
import goToEditNameSettings from "./actions/groupActions/GoToEditNameSettings";
import editTeamName from "./actions/groupActions/getGroupDataActions/EditTeamName";
import selectTeamMemberToRemove from "./actions/groupActions/getGroupDataActions/SelectTeamMemberToRemove";
import editQuizLang from "./actions/quizActions/getQuizDataActions/EditQuizLang";
import setNumOfLang from "./actions/quizActions/getQuizDataActions/SetNumOfLang";
import filterCatQuizzes from "./actions/quizActions/FilterCatQuizzes";
import showModal from "./actions/ShowModal";
import backToUserPage from "./actions/userActions/BackToUserPage";
import setRecommendQuiz from "./actions/recommendationActions/getRecomDataActions/SetRecommendQuiz";
import getComment from "./actions/recommendationActions/getRecomDataActions/GetComment";
import goToEditComment from "./actions/recommendationActions/GoToEditComment";
import goToNewQuiz from "./actions/quizActions/GoToNewQuiz";
import getOTP from "./actions/userActions/getUserDataActions/GetOTP";
import confirmPassword from "./actions/userActions/getUserDataActions/ConfirmPassword";
import getItemForSearch from "./actions/quizActions/getQuizDataActions/GetItemForSearch";
import runSavedQuiz from "./actions/quizActions/RunSavedQuiz";
import getOptionValue from "./actions/quizActions/getQuizDataActions/GetOptionValue";
import getRowValue from "./actions/quizActions/getQuizDataActions/GetRowValue";
import confirmEditedQuizContents from "./actions/quizActions/ConfirmEditedQuizContents";
import switchTabLeft from "./actions/SwitchTabLeft";
import switchTabRight from "./actions/SwitchTabRight";
import solveQuizLong from "./actions/quizActions/SolveQuizLong";
import getNextTaskLong from "./actions/quizActions/GetNextTaskLong";
import setQuizLength from "./actions/quizActions/getQuizDataActions/SetQuizLength";
import getHeader from "./actions/quizActions/getQuizDataActions/GetHeader";
import getValues from "./actions/quizActions/getQuizDataActions/GetValues";
import confirmQuizTable from "./actions/quizActions/ConfirmQuizTable";
import removeColumn from "./actions/quizActions/RemoveColumn";
import addRow from "./actions/quizActions/AddRow";
import showModalAddRemoveColumn from "./actions/quizActions/ShowModalAddRemoveColumn";
import showModalAddRemoveRow from "./actions/quizActions/ShowModalAddRemoveRow";
import addColumn from "./actions/quizActions/AddColumn";
import removeRow from "./actions/quizActions/RemoveRow";
import addRowEdit from "./actions/quizActions/AddRowEdit";
import addColumnEdit from "./actions/quizActions/AddColumnEdit";
import removeRowEdit from "./actions/quizActions/RemoveRowEdit";
import hideAlertEditTable from "./actions/quizActions/HideAlertEditTable";
import removeColumnEdit from "./actions/quizActions/RemoveColumnEdit";
import continueEditing from "./actions/quizActions/ContinueEditing";
import editUserName from "./actions/userActions/getUserDataActions/EditUserName";
import showInstruction from "./actions/quizActions/ShowInstruction";
import showModalStopQuiz from "./actions/quizActions/ShowModalStopQuiz";
import hideAlertStopQuiz from "./actions/quizActions/HideAlertStopQuiz";
import goToEditTeams from "./actions/userActions/GoToEditTeams";
import {
  getEmailContact,
  getMessageContact,
  getSubjectContact,
  getUsernameContact
} from "./actions/userActions/getUserDataActions/GetInfoContactSupport";
import showModalContactSupport from "./actions/userActions/ShowModalContactSupport";
import {
  getEmail,
  getPassword,
  getUsername
} from "./actions/userActions/getUserDataActions/GetEmailPasswordUsername";
import {
  increaseAnswerFontSize,
  increaseCellSize,
  increaseQuestionFontSize,
  increaseTabeFontSize,
  reduceAnswerFontSize,
  reduceCellSize,
  reduceQuestionFontSize,
  reduceTableFontSize
} from "./actions/quizActions/ResizeFontCell";
import toggleTheme from "./actions/ToggleTheme";
import cancelRecCom from "./actions/recommendationActions/CancelRecCom";
import cancelEditTeamSettings from "./actions/groupActions/CancelEditTeamSettings";
import setUserTheme from "./actions/userActions/getUserDataActions/SetUserTheme";
import switchToVideo from "./actions/SwitchToVideo";
import toggleLang from "./actions/ToggleLang";
import goToTeamQuiz from "./actions/groupActions/GoToTeamQuiz";
import goToFavQuiz from "./actions/quizActions/GoToFavQuiz";
import goToMyQuiz from "./actions/quizActions/GoToMyQuiz";
import backToQuizTitlePage from "./actions/quizActions/BackToQuizTitlePage";



const reducer = (state, action) => {

  switch (action.type) {

    // GLOBAL

    case "switchToVideo":
      return switchToVideo(state)

    case "toggleLang":
      return toggleLang(state)

    case "toggleTheme":
      return toggleTheme(state)

    case "backToMainPage":
      return backToMainPage(state)

    case "hideAlert":
      return hideAlert(state)

    case "showModal":
      return showModal(state, action)

    case "switchTabLeft":
      return switchTabLeft(state)

    case "switchTabRight":
      return switchTabRight(state)

    // CONTACT SUPPORT
    case "showModalContactSupport":
      return showModalContactSupport(state, action)

    case "getUsernameContact":
      return getUsernameContact(state, action)
    case "getEmailContact":
      return getEmailContact(state, action)
    case "getSubjectContact":
      return getSubjectContact(state, action)
    case "getMessageContact":
      return getMessageContact(state, action)

    case "submitContactSupport":
      return !action.error
        ? {
          ...state,
          messageSent: true,
          formFilled: false,
          error: action.error,
          errorMySQL: false,
          errorAuth: state.errorAuth
        }
        : {
          ...state,
          messageSent: false,
          error: action.error,
          errorMySQL: true,
          errorAuth: state.errorAuth
        }


    // FOR useEffect Check Server
    // --- jsx files: QuizCreate.js
    case "fetchCheckServer":
      return {
        ...state,
        loading: false,
        errorMySQL: false,
        errorAuth: false,
        error: null,
      }
    case "fetchCheckServerErr":
      return state.currentUser === null
        ? {
          ...state,
          loading: false,
          error: action.error,
          errorMySQL: false,
          errorAuth: false
        }
        : action.error === "Not authenticated"
          || action.error === "Token is not valid"
          ? {
            ...state,
            loading: false,
            error: action.error,
            errorMySQL: false,
            errorAuth: true
          }
          : {
            ...state,
            loading: false,
            error: action.error,
            errorMySQL: true,
            errorAuth: false
          }

    // FOR useEffect pages Country

    case "fetchCountry":
      return {
        ...state,
        isRussia: action.isRussia,
        websiteLang: action.isRussia
          ? "ru"
          : "en"
      }
    case "fetchCountryErr":
      return {
        ...state,
        isRussia: false,
        websiteLang: "en"
      }

    // FOR useEffect pages

    // --- jsx files: Quiz
    case "fetchQuizPage":
      return action.id === "unsaved"
        ? {
          ...state,
          loading: false,
          errorMySQL: false,
          error: null
        }
        : action.quizPage
          ? {
            ...state,
            loading: false,
            errorMySQL: false,
            error: null,
            quizPage: action.quizPage,
            quiz: action.quizPage.quiz,
            quizId: action.quizPage.quiz.id,
            quizTitle: action.quizPage.quiz.title,
            quizDescAuthor: action.quizPage.quiz.desc_author,
            quizType: action.quizPage.quiz.type,
            catName: action.quizPage.quiz.catname,
            catId: action.quizPage.quiz.cat_id,
            language: action.quizPage.quiz.lang,
            langId: action.quizPage.quiz.lang_id,
            timesTaken: action.quizPage.quiz.times_taken,
            quizOptions: action.quizPage.quiz.quizOptions,
            quizRows: action.quizPage.quiz.quizRows,
            comments: action.quizPage.comments,
            recom: action.quizPage.comments.length
              && state.currentUser !== null
              && action.quizPage.comments.filter(el => el.user_took_id === state.currentUser.id).length !== 0
              ? action.quizPage.comments.filter(el => el.user_took_id === state.currentUser.id)[0].recom
              : "NONE",
            comment: action.quizPage.comments.length
              && state.currentUser !== null
              && action.quizPage.comments.filter(el => el.user_took_id === state.currentUser.id).length !== 0
              ? action.quizPage.comments.filter(el => el.user_took_id === state.currentUser.id)[0].comment
              : null,
            currUserComment: action.quizPage.comments.length
              && state.currentUser !== null
              && action.quizPage.comments.filter(el => el.user_took_id === state.currentUser.id).length !== 0
              ? action.quizPage.comments.filter(el => el.user_took_id === state.currentUser.id)
              : [],
            favQuiz: action.quizPage.favQuiz,
            favQuizStatus: !action.quizPage.favQuiz.length
              ? false
              : state.currentUser !== null
                && action.quizPage.favQuiz.filter(el => el.renter_id === state.currentUser.id).length !== 0
                ? true
                : false,
            userTeams: action.quizPage.userTeams.filter(el => el.user_type !== "INV"),
            userInvitations: action.quizPage.userTeams.filter(el => el.user_type === "INV")
          }
          : {
            ...state,
            loading: false,
            errorMySQL: false,
            error: "404",
          }
    case "fetchQuizPageErr":
      return {
        ...state,
        loading: false,
        error: action.error,
        quizStatus: "initial",
        userTeams: [],
        userInvitations: [],
        comments: [],
        errorMySQL: true
      }


    case "fetchQuizEditPage":
      return action.quizEditPage
        ? {
          ...state,
          loading: false,
          errorMySQL: false,
          errorAuth: false,
          error: null,
          quizEditPage: action.quizEditPage,
          quiz: action.quizEditPage.quiz,
          quizId: action.quizEditPage.quiz.id,
          quizTitle: action.quizEditPage.quiz.title,
          quizDescAuthor: action.quizEditPage.quiz.desc_author,
          quizType: action.quizEditPage.quiz.type,
          oldQuizType: action.quizEditPage.quiz.type,
          catName: action.quizEditPage.quiz.catname,
          catNameRU: action.quizEditPage.quiz.catname_ru,
          catId: action.quizEditPage.quiz.cat_id,
          language: action.quizEditPage.quiz.lang,
          languageRU: action.quizEditPage.quiz.lang_ru,
          langId: action.quizEditPage.quiz.lang_id,
          timesTaken: action.quizEditPage.quiz.times_taken,
          quizOptionsEdit: ["#", ...action.quizEditPage.quiz.quizOptions],
          quizRowsEdit: action.quizEditPage.quiz.quizRows,
          quizStatus: "edit",
          lang: action.quizEditPage.lang,
          langArr: action.quizEditPage.lang.filter(el => el.lang !== "Multilanguage").map(el => el.lang).sort(),
          langArrRU: action.quizEditPage.lang.filter(el => el.lang_ru !== "Многоязычный").map(el => el.lang_ru).sort(),
          catNames: action.quizEditPage.catNames,
          catArr: action.quizEditPage.catNames.map(el => el.catname).sort(),
          catArrRU: action.quizEditPage.catNames.map(el => el.catname_ru).sort()
        }
        : {
          ...state,
          loading: false,
          errorMySQL: false,
          errorAuth: false,
          error: "404",
        }
    case "fetchQuizEditPageErr":
      return action.error === "Not authenticated"
        || action.error === "Token is not valid"
        ? {
          ...state,
          loading: false,
          error: action.error,
          lang: state.lang,
          catNames: state.catNames,
          errorMySQL: false,
          errorAuth: true
        }
        : {
          ...state,
          loading: false,
          error: action.error,
          lang: state.lang,
          catNames: state.catNames,
          errorMySQL: true,
          errorAuth: false
        }


    // --- jsx files: User
    case "fetchUserPage":
      return state.currentUser
        ? {
          ...state,
          loading: false,
          errorMySQL: false,
          errorAuth: false,
          error: null,
          userPage: action.userPage,
          currentUser: action.userPage.user,
          theme: action.userPage.user.theme
            ? action.userPage.user.theme
            : state.theme,
          userTheme: action.userPage.user.theme
            ? action.userPage.user.theme
            : "default",
          createdQuizzes: action.userPage.createdQuizzes,
          teamData: action.userPage.teamData,
          favQuizzes: action.userPage.favQuizzes,
          userTeams: action.userPage.userTeams.filter(el => el.user_type !== "INV"),
          userInvitations: action.userPage.userTeams.filter(el => el.user_type === "INV"),
          numTeamMembers: Math.max(...action.userPage.teamMembCount.map(el => el.num_of_memb))
        }
        : {
          ...state,
          loading: false,
          errorMySQL: false,
          errorAuth: false,
          error: null
        }
    case "fetchUserPageErr":
      return action.error === "Not authenticated"
        || action.error === "Token is not valid"
        ? {
          ...state,
          loading: false,
          error: action.error,
          createdQuizzes: [],
          userTeams: [],
          userInvitations: [],
          errorMySQL: false,
          errorAuth: true
        }
        : {
          ...state,
          loading: false,
          error: action.error,
          createdQuizzes: [],
          userTeams: [],
          userInvitations: [],
          errorMySQL: true,
          errorAuth: false
        }

    // --- jsx files: TeamEdit
    case "fetchTeamEditPage":
      return action.teamEditPage
        ? {
          ...state,
          loading: false,
          errorMySQL: false,
          errorAuth: false,
          error: null,
          teamEditPage: action.teamEditPage,

          team: action.teamEditPage.team,
          teamId: action.teamEditPage.team.id,
          teamName: action.teamEditPage.team.teamname,
          typeAddQuiz: action.teamEditPage.team.type_add_quiz,
          typeAddMemb: action.teamEditPage.team.type_add_memb,

          teamMembers: action.teamEditPage.teamMembers,
          members: action.teamEditPage.teamMembers.filter(el => el.user_type !== "INV"),
          invited: action.teamEditPage.teamMembers.filter(el => el.user_type === "INV"),
          currentUserType: action.teamEditPage.teamMembers.length
            ? [...action.teamEditPage.teamMembers].filter(el => el.user_id === state.currentUser.id)[0].user_type
            : "",

          addQuizzes: action.teamEditPage.addQuizzes,
          teamQuizzes: action.teamEditPage.teamQuizzes
        }
        : {
          ...state,
          loading: false,
          errorMySQL: false,
          errorAuth: false,
          error: "404",
        }
    case "fetchTeamEditPageErr":
      return action.error === "Not authenticated"
        || action.error === "Token is not valid"
        ? {
          ...state,
          loading: false,
          error: action.error,
          team: {},
          teamMembers: state.teamMembers,
          teamQuizzes: [],
          errorMySQL: false,
          errorAuth: true
        }
        : {
          ...state,
          loading: false,
          error: action.error,
          team: {},
          teamMembers: state.teamMembers,
          teamQuizzes: [],
          errorMySQL: true,
          errorAuth: false
        }


    case "fetchTeamPage":
      return action.teamPage
        ? {
          ...state,
          loading: false,
          errorMySQL: false,
          errorAuth: false,
          error: null,
          teamPage: action.teamPage,

          team: action.teamPage.team,
          teamId: action.teamPage.team.id,
          teamName: action.teamPage.team.teamname,
          typeAddQuiz: action.teamPage.team.type_add_quiz,
          typeAddMemb: action.teamPage.team.type_add_memb,
          teamAdminMem: action.teamPage.team.mem,
          teamAdminNumTeams: action.teamPage.team.num_teams,

          teamMembers: action.teamPage.teamMembers,
          members: action.teamPage.teamMembers.filter(el => el.user_type !== "INV"),
          invited: action.teamPage.teamMembers.filter(el => el.user_type === "INV"),
          numTeamMembers: action.teamPage.teamMembers.length,

          teamQuizzes: action.teamPage.teamQuizzes
        }
        : {
          ...state,
          loading: false,
          errorMySQL: false,
          errorAuth: false,
          error: "404",
        }
    case "fetchTeamPageErr":
      return action.error === "Not authenticated"
        || action.error === "Token is not valid"
        ? {
          ...state,
          loading: false,
          error: action.error,
          team: {},
          teamMembers: state.teamMembers,
          teamQuizzes: [],
          errorMySQL: false,
          errorAuth: true
        }
        : {
          ...state,
          loading: false,
          error: action.error,
          team: {},
          teamMembers: state.teamMembers,
          teamQuizzes: [],
          errorMySQL: true,
          errorAuth: false
        }

    case "goToTeamQuiz":
      return goToTeamQuiz(state)

      case "backToQuizTitlePage":
      return backToQuizTitlePage(state)


    // --- jsx files: Library
    case "fetchCategory":
      return {
        ...state,
        loading: false,
        errorMySQL: false,
        error: null,
        cat: action.category.cat,
        catRU: action.category.catRU,
        catArr: action.category.cat.map(el => el.catname).sort(),
        catArrRU: action.category.catRU.map(el => el.catname_ru).sort()
      }
    case "fetchCategoryErr":
      return {
        ...state,
        loading: false,
        error: action.error,
        cat: state.cat,
        errorMySQL: true
      }

    // --- jsx files: Category ---
    case "fetchCatQuizzes":
      return action.catQuizzes.length
        ? {
          ...state,
          loading: false,
          errorMySQL: false,
          error: null,
          catQuizzes: action.catQuizzes,
          langArr: action.catQuizzes.filter(el => el.lang !== "Multilanguage").map(el => el.lang).sort(),
          langArrRU: action.catQuizzes.filter(el => el.lang_ru !== "Многоязычный").map(el => el.lang_ru).sort(),
          langArrLang: ["Multilanguage"].concat(action.catQuizzes.filter(el => el.lang !== "Multilanguage").map(el => el.lang).sort()),
          langArrLangRU: ["Многоязычный"].concat(action.catQuizzes.filter(el => el.lang_ru !== "Многоязычный").map(el => el.lang_ru).sort()),
        }
        : {
          ...state,
          loading: false,
          errorMySQL: false,
          error: "404"
        }
    case "fetchCatQuizzesErr":
      return {
        ...state,
        loading: false,
        error: action.error,
        catQuizzes: [],
        errorMySQL: true
      }


    // --- jsx files: OtherUserPage ---
    case "fetchOtherUserPage":
      return action.otherUserPage.otherUser
        ? {
          ...state,
          loading: false,
          errorMySQL: false,
          error: null,
          otherUserPage: action.otherUserPage,
          otherUser: action.otherUserPage.otherUser,
          userPublicQuizzes: action.otherUserPage.userPublicQuizzes
        }
        : {
          ...state,
          loading: false,
          errorMySQL: false,
          error: "404",
        }
    case "fetchOtherUserPageErr":
      return {
        ...state,
        loading: false,
        error: action.error,
        errorMySQL: true
      }


    //Countdown stopwatch
    case "setSecs":
      return action.sec > 0
        ? {
          ...state,
          sec: action.sec
        }
        : {
          ...state,
          sec: action.sec,
          sentOTP: null
        }

    // USER 
    //---------auth
    case "switchToLogin":
      return switchToLogin(state)
    case "switchToSignup":
      return switchToSignup(state)


    case "switchToGetOTPsignup":
      return action.error === null
        ? {
          ...state,
          isRunning: true,
          errorMySQL: false,
          // enterData: false,
          // wrongData: false,
          sec: 90,
          auth: "getOTP",
          errorSignUp: action.error,
          sentOTP: action.sentOTP
        }
        : {
          ...state,
          errorSignUp: action.error,
          errorMySQL: true
        }


    case "switchToGetOTP":
      return !action.error && state.email !== ""
        ? {
          ...state,
          isRunning: true,
          errorMySQL: false,
          enterData: false,
          wrongData: false,
          sec: 90,
          passwordRecovery: "getOTP",
          errorSearchUser: null,
        }
        : !action.error && state.email === ""
          ? {
            ...state,
            enterData: true,
            errorMySQL: false,
            errorSearchUser: action.error,
          }
          : {
            ...state,
            errorSearchUser: action.error,
            wrongData: true,
            errorMySQL: true
          }

    case "switchToNewPassword":
      return action.error === null
        ? {
          ...state,
          passwordRecovery: "new password",
          isRunning: false,
          sec: 90,
          enterData: false,
          wrongData: false,
          errorMySQL: false,
          errorVerifyOTP: action.error
        }
        : {
          ...state,
          wrongData: true,
          isRunning: false,
          sec: 0,
          errorVerifyOTP: action.error,
          errorMySQL: true
        }

    case "getUsername":
      return getUsername(state, action)

    case "getEmail":
      return getEmail(state, action)
    case "getPassword":
      return getPassword(state, action)

    case "getOTP":
      return getOTP(state, action)
    case "confirmPassword":
      return confirmPassword(state, action)


    case "submitLogIn":
      return action.error === null
        ? {
          ...state,
          email: "",
          password: "",
          formFilled: false,
          userPageStatus: "main",
          currentUser: action.currentUser,

          errorLogIn: action.error,
          // alert: true,
          // alertStatus: "logged in",
          loading: true,
          enterData: false,
          wrongData: false,
          checkUser: false,
          errorMySQL: false,
          errorAuth: false
        }
        : {
          ...state,
          auth: "login",
          email: state.email,
          password: state.password,
          errorLogIn: action.error,
          enterData: false,
          wrongData: false,
          checkUser: false,
          errorMySQL: action.errorMySQL,
          errorAuth: false
        }

    case "submitSignUp":
      return action.error === null
        // && state.sentOTP === state.typedOTP
        ? {
          ...state,
          // isRunning: false,
          // sec: 90,
          alert: true,
          alertStatus: "signed up",
          auth: "login",
          username: "",
          email: "",
          password: "",
          formFilled: false,
          enterData: false,
          wrongData: false,
          errorSignUp: action.error,
          errorMySQL: false,
          // try: 1
        }
        : {
          ...state,
          // sentOTP: null,
          // auth: "getOTP",
          auth: "signup",
          username: state.username,
          email: state.email,
          password: state.password,
          errorSignUp: action.error,
          wrongData: false,
          errorMySQL: action.errorMySQL
          // isRunning: false,
          // sec: 0,
          // try: 2
        }

    case "submitLogOut":
      return action.error === null
        ? {
          ...state,
          auth: "login",
          alert: false,
          alertStatus: null,
          currentUser: null,
          quiz: [{}],
          quizTitle: "",
          quizRows: [],
          error: action.error,
          loading: true,
          errorMySQL: false
        }
        : {
          ...state,
          error: action.error,
          errorMySQL: true
        }

    case "submitNewPassword":
      return action.error === null && (state.password === "" || state.passwordConfirm === "")
        ? {
          ...state,
          enterData: true,
          wrongData: false,
          errorMySQL: false,
          errorNewPassword: action.error
        }
        : action.error === null && state.password !== state.passwordConfirm
          ? {
            ...state,
            enterData: false,
            wrongData: true,
            errorMySQL: false,
            errorNewPassword: action.error
          }
          : action.error === null
            ? {
              ...state,
              error: action.error,
              loading: true,
              auth: "login",
              enterData: false,
              wrongData: false,
              alert: true,
              errorMySQL: false,
              alertStatus: "password changed",
              errorNewPassword: action.error
            }
            : action.error === "Data is not complete"
              ? {
                ...state,
                passwordRecovery: "email",
                errorNewPassword: action.error

              }
              : {
                ...state,
                errorNewPassword: action.error,
                errorMySQL: true
              }

    //----MyPage
    case "goToEditProfile":
      return goToEditProfile(state)
    case "goToEditTeams":
      return goToEditTeams(state)
    case "editUserName":
      return editUserName(state, action)
    case "backToUserPage":
      return backToUserPage(state)

    //  --- jsx files: ModalForDeleteQuiz
    case "deleteQuiz":
      return !action.error
        ? {
          ...state,
          alert: false,
          error: action.error,
          errorMySQL: false,
          errorAuth: false,
          createdQuizzes: state.createdQuizzes.filter(el => el.id !== state.quizId),
          quizStatus: "initial",
          addQuizStatus: "start",
          removeQuizStatus: "start",
          invStatus: "start",
          changeTeamMemberTypeStatus: "start",
          removeTeamMemberStatus: "start",
          teamQuizTitles: [],
          teamQuizTitlesToRemove: []
        }
        : action.error === "Not authenticated"
          || action.error === "Token is not valid"
          ? {
            ...state,
            error: action.error,
            errorMySQL: false,
            errorAuth: true
          }
          : {
            ...state,
            error: action.error,
            errorMySQL: true,
            errorAuth: false
          }

    case "goToFavQuiz":
      return goToFavQuiz(state, action)

    case "goToMyQuiz":
      return goToMyQuiz(state, action)

    case "removeFavQuiz":
      return !action.error
        ? {
          ...state,
          favQuizId: action.favQuizId,
          error: action.error,
          errorMySQL: false,
          errorAuth: state.errorAuth,
          favQuizzes: state.favQuizzes.filter(el => el.id !== action.favQuizId),
          favQuizStatus: false
        }
        : {
          ...state,
          error: action.error,
          errorMySQL: true,
          errorAuth: state.errorAuth
        }

    //----MyPage Edit Profile

    case "setUserTheme":
      return setUserTheme(state, action)

    case "submitEditedProfile":
      return action.error === null
        ? {
          ...state,
          alert: true,
          loading: true,
          error: null,
          errorMySQL: false,
          errorAuth: false,
          currentUser: action.currentUser,
          submitEditedProfileStatus: true,
          alertStatus: "profile edited"
        }
        : action.error === "Not authenticated"
          || action.error === "Token is not valid"
          ? {
            ...state,
            alert: false,
            error: action.error,
            errorMySQL: false,
            errorAuth: true,
          }
          : action.error === "MySQL error"
            ? {
              ...state,
              alert: false,
              error: action.error,
              errorMySQL: true,
              errorAuth: false,
            }
            : {
              ...state,
              alert: false,
              error: "500",
              errorMySQL: false,
              errorAuth: false,
            }




    //----MyPage Edit Quiz

    case "editQuizTitle":
      return editQuizTitle(state, action)

    case "editQuizDesc":
      return editQuizDesc(state, action)

    case "getOptionValue":
      return getOptionValue(state, action)

    case "addColumnEdit":
      return addColumnEdit(state, action)

    case "removeColumnEdit":
      return removeColumnEdit(state, action)

    case "getRowValue":
      return getRowValue(state, action)

    case "addRowEdit":
      return addRowEdit(state)

    case "removeRowEdit":
      return removeRowEdit(state)



    case "hideAlertEditTable":
      return hideAlertEditTable(state)


    case "editQuizType":
      return editQuizType(state, action)

    case "editQuizCat":
      return editQuizCat(state, action)

    case "setNumOfLang":
      return setNumOfLang(state, action)

    case "editQuizLang":
      return editQuizLang(state, action)

    case "submitEditedQuiz":
      return (action.oldQuizType === "PRIVATE" && action.quizType === "PUBLIC") && (state.catId === null || state.langId === null)
        ? {
          ...state,
          errorMessage: true,
          errorMySQL: false,
          errorAuth: false,
          error: null
        }
        : !action.error
          ? {
            ...state,
            errorMessage: false,
            error: action.error,
            errorMySQL: false,
            errorAuth: false,
            alert: true,
            alertStatus: "edited quiz",
            // catName: null,
            // catId: null,
            // language: null,
            // langId: null,
          }
          : action.error === "Not authenticated"
            || action.error === "Token is not valid"
            ? {
              ...state,
              error: action.error,
              errorMySQL: false,
              errorAuth: true
            }
            : {
              ...state,
              error: action.error,
              errorMySQL: true,
              errorAuth: false
            }

    case "confirmEditedQuizContents":
      return confirmEditedQuizContents(state)


    case "submitEditedQuizContents":
      return !action.error
        ? {
          ...state,
          error: action.error,
          errorMySQL: false,
          errorAuth: false,
          alert: true,
          alertStatus: "edited quiz",
          quizOptionsToUpdate: [],
          quizOptionsToInsert: [],
          quizOptionsToDelete: [],
          quizValuesToUpdate: [],
          quizColumnsToInsert: [],
          quizRowsToInsert: [],
          quizColRowsToInsert: [],
          quizRowsToDelete: []
        }
        : action.error === "Not authenticated"
          || action.error === "Token is not valid"
          ? {
            ...state,
            error: action.error,
            errorMySQL: false,
            errorAuth: true
          }
          : {
            ...state,
            error: action.error,
            errorMySQL: true,
            errorAuth: false
          }

    // --- jsx files: ModalForEditQuiz ---

    case "continueEditing":
      return continueEditing(state)




    // TEAM

    //--------Create / edit Team

    //------------set / edit main team settings

    case "getTeamName":
      return getTeamName(state, action)

    // --- jsx files: CardCreateTeam ---
    case "setTypeAddQuiz":
      return setTypeAddQuiz(state, action)
    // --- jsx files: CardCreateTeam ---
    case "setTypeAddMemb":
      return setTypeAddMemb(state, action)

    // --- jsx files: ButtonSubmitCreatedTeam ---
    case "submitCreatedTeam":
      return !action.error
        ? {
          ...state,
          error: null,
          errorMySQL: false,
          errorAuth: false,
          alert: true,
          teamId: action.teamId
        }
        : action.error === "Not authenticated"
          || action.error === "Token is not valid"
          ? {
            ...state,
            error: action.error,
            errorMySQL: false,
            errorAuth: true
          }
          : {
            ...state,
            error: action.error,
            errorMySQL: true,
            errorAuth: false
          }


    case "goToEditNameSettings":
      return goToEditNameSettings(state)

    case "editTeamName":
      return editTeamName(state, action)

    // --- jsx files: ButtonSubmitEditedTeamSettings ---
    case "submitEditedTeamSettings":
      return !action.error
        ? {
          ...state,
          error: action.error,
          errorMySQL: false,
          errorAuth: false,
          editSettingsStatus: "show"
        }
        : action.error === "Not authenticated"
          || action.error === "Token is not valid"
          ? {
            ...state,
            error: action.error,
            editSettingsStatus: "edit",
            errorMySQL: false,
            errorAuth: true
          }
          : {
            ...state,
            error: action.error,
            editSettingsStatus: "edit",
            errorMySQL: true,
            errorAuth: false
          }

    case "cancelEditTeamSettings":
      return cancelEditTeamSettings(state, action)

    //---------add / remove team quiz
    case "selectQuizForTeam":
      return selectQuizForTeam(state, action)

    case "notAddQuiz":
      return notAddQuiz(state)

    // --- jsx files: FormAddQuizToTeam ---
    case "addQuizToTeam":
      return !action.error
        ? {
          ...state,
          error: action.error,
          errorMySQL: false,
          errorAuth: false,
          addQuizStatus: "added",
          teamQuizTitles: [...state.teamQuizTitles, state.teamQuizTitle],
          addQuizzes: state.addQuizzes.filter(el => el.title !== state.teamQuizTitle),
          teamQuizTitlesToRemove: state.teamQuizTitlesToRemove.filter(el => el !== state.teamQuizTitle)
        }
        : action.error === "Not authenticated"
          || action.error === "Token is not valid"
          ? {
            ...state,
            error: action.error,
            addQuizStatus: "start",
            errorMySQL: false,
            errorAuth: true,
          }
          : {
            ...state,
            error: action.error,
            addQuizStatus: "start",
            errorMySQL: true,
            errorAuth: false,
          }

    case "selectQuizToRemoveFromTeam":
      return selectQuizToRemoveFromTeam(state, action)

    case "removeQuizFromTeam":
      return !action.error
        ? {
          ...state,
          error: action.error,
          errorMySQL: false,
          errorAuth: false,
          removeQuizStatus: "removed",
          teamQuizTitlesToRemove: [...state.teamQuizTitlesToRemove, state.teamQuizTitleToRemove],
          teamQuizzes: state.teamQuizzes.filter(el => el.title !== state.teamQuizTitleToRemove),
          teamQuizTitles: state.teamQuizTitles.filter(el => el !== state.teamQuizTitleToRemove)
        }
        : action.error === "Not authenticated"
          || action.error === "Token is not valid"
          ? {
            ...state,
            error: action.error,
            errorMySQL: false,
            errorAuth: true
          }
          : {
            ...state,
            error: action.error,
            errorMySQL: true,
            errorAuth: false
          }

    //-----------------------set / edit team memebers

    case "getUserIdForSearch":
      return getUserIdForSearch(state, action)

    case "searchUser":
      return !action.error && action.invUser
        ? {
          ...state,
          invUser: action.invUser,
          invUsername: `${action.invUser.username} (ID ${action.invUser.id})`,
          invStatus: "invite",
          errorMySQL: false,
          errorAuth: false,
          error: action.error
        }
        : !action.error && !action.invUser
          ? {
            ...state,
            invUser: action.invUser,
            invStatus: "start",
            errorMySQL: false,
            errorAuth: false,
            error: action.error
          }
          : {
            ...state,
            error: action.error,
            errorAuth: false,
            errorMySQL: true
          }

    case "notInvite":
      return notInvite(state)

    case "inviteUser":
      return !action.error
        ? {
          ...state,
          error: action.error,
          errorMySQL: false,
          errorAuth: false,
          invUsers: [...state.invUsers, state.invUsername],
          membersToRemove: state.membersToRemove.filter(el => el !== state.invUsername),
          invStatus: "invited",
          invUserId: ""
        }
        : action.error === "Not authenticated"
          || action.error === "Token is not valid"
          ? {
            ...state,
            error: action.error,
            errorMySQL: false,
            errorAuth: true
          }
          : {
            ...state,
            error: action.error,
            errorMySQL: true,
            errorAuth: false
          }


    case "selectTeamMember":
      return selectTeamMember(state, action)

    case "changeTeamMemberType":
      return changeTeamMemberType(state, action)

    case "submitTeamMemberNewType":
      return !action.error
        ? {
          ...state,
          alert: false,
          alertStatus: null,
          error: action.error,
          errorMySQL: false,
          errorAuth: false,
          changeTeamMemberTypeStatus: "start"
        }
        : action.error === "Not authenticated"
          || action.error === "Token is not valid"
          ? {
            ...state,
            error: action.error,
            errorMySQL: false,
            errorAuth: true
          }
          : {
            ...state,
            error: action.error,
            errorMySQL: true,
            errorAuth: false
          }

    case "selectTeamMemberToRemove":
      return selectTeamMemberToRemove(state, action)

    case "removeMemberFromTeam":
      return !action.error
        ? {
          ...state,
          removeTeamMemberStatus: "choose",
          error: action.error,
          errorMySQL: false,
          errorAuth: false,
          alert: false,
          alertStatus: null,
          membersToRemove: [...state.membersToRemove, state.teamMemberName],
          invUsers: state.invUsers.filter(el => el !== state.teamMemberName)
        }
        : action.error === "Not authenticated"
          || action.error === "Token is not valid"
          ? {
            ...state,
            error: action.error,
            errorMySQL: false,
            errorAuth: true
          }
          : {
            ...state,
            error: action.error,
            errorMySQL: true,
            errorAuth: false
          }

    case "leaveTeam":
      return action.error === null
        ? {
          ...state,
          error: action.error,
          errorMySQL: false,
          errorAuth: false,
          alert: true,
          alertStatus: "left team",
        }
        : action.error === "Not authenticated"
          || action.error === "Token is not valid"
          ? {
            ...state,
            alert: false,
            alertStatus: null,
            error: action.error,
            errorMySQL: false,
            errorAuth: true,
          }
          : {
            ...state,
            alert: false,
            alertStatus: null,
            error: action.error,
            errorMySQL: true,
            errorAuth: false,
          }

    //----- accepting team invitaion

    case "goToAcceptTeamInv":
      return goToAcceptTeamInv(state, action)

    case "goToDeclineTeamInv":
      return goToDeclineTeamInv(state, action)


    //-------deleting team

    case "deleteTeam":
      return !action.error
        ? {
          ...state,
          alert: true,
          error: action.error,
          errorMySQL: false,
          errorAuth: false,
          userPageStatus: "main",
          // currentUser: action.currentUser
        }
        : action.error === "Not authenticated"
          || action.error === "Token is not valid"
          ? {
            ...state,
            error: action.error,
            errorMySQL: false,
            errorAuth: true
          }
          : {
            ...state,
            error: action.error,
            errorMySQL: true,
            errorAuth: false
          }


    // LIBRARY
    case "openCatQuizzes":
      return openCatQuizzes(state, action)

    // --- jsx files: FormSelectQluizLang ---
    case "filterCatQuizzes":
      return filterCatQuizzes(state, action)

    case "getItemForSearch":
      return getItemForSearch(state, action)


    // QUIZ
    //----------CreateQuiz
    case "getQuizTitle":
      return getQuizTitle(state, action)

    case "getQuizFile":
      return action.mistake
        ? {
          ...state,
          video: false,
          errorMessage: "file mistake",
          mistake: action.mistake,
          quizRows: [],
          quizOptions: [],
          numOfTasks: 0,
          fileName: action.fileName,
          fileUrl: action.fileUrl
        }
        : {
          ...state,
          video: false,
          errorMessage: false,
          mistake: null,
          quizStatus: "initial",
          quizRows: action.quizRows,
          quizOptions: action.quizOptions,
          numOfTasks: action.numOfTasks,
          fileName: action.fileName,
          fileUrl: action.fileUrl,
          quizTitle: action.quizTitle
        }

    //---Create Quiz Table
    case "getHeader":
      return getHeader(state, action)

    case "getValues":
      return getValues(state, action)

    case "confirmQuizTable":
      return confirmQuizTable(state)

    case "showInstruction":
      return showInstruction(state)

    case "showModalAddRemoveColumn":
      return showModalAddRemoveColumn(state, action)

    case "addColumn":
      return addColumn(state)

    case "removeColumn":
      return removeColumn(state)

    case "showModalAddRemoveRow":
      return showModalAddRemoveRow(state, action)

    case "addRow":
      return addRow(state)

    case "removeRow":
      return removeRow(state)

    case "reduceTableFontSize":
      return reduceTableFontSize(state)
    case "increaseTabeFontSize":
      return increaseTabeFontSize(state)
    case "reduceCellSize":
      return reduceCellSize(state)
    case "increaseCellSize":
      return increaseCellSize(state)




    // --------
    case "submitQuiz":
      return submitQuiz(state)

    case "submitQuizAndSave":
      return action.error === null
        ? {
          ...state,
          errorMessage: false,
          errorMySQL: false,
          errorAuth: false,
          video: false,
          quizId: action.quizId,
          error: action.error,
          alert: true,
          alertStatus: "saved quiz created"
        }
        : {
          ...state,
          quiz: {},
          quizStatus: "adjust",
          video: false,
          error: null,
          alert: true,
          alertStatus: "quiz created with error",
          errorMySQL: false,
          errorAuth: false
        }


    case "goToNewQuiz":
      return goToNewQuiz(state)

    //-----------Saved Quiz
    case "runSavedQuiz":
      return runSavedQuiz(state)

    //----------AdjustQuiz
    case "selectQuestion":
      return selectQuestion(state, action)
    case "selectAnswer":
      return selectAnswer(state, action)
    case "setQuizLength":
      return setQuizLength(state, action)
    case "solveQuiz":
      return solveQuiz(state)
    case "solveQuizLong":
      return solveQuizLong(state)
    case "showModalStopQuiz":
      return showModalStopQuiz(state)
    case "hideAlertStopQuiz":
      return hideAlertStopQuiz(state)

    //-----------Quiz
    case "gues":
      return gues(state, action)

    case "reduceQuestionFontSize":
      return reduceQuestionFontSize(state)
    case "increaseQuestionFontSize":
      return increaseQuestionFontSize(state)
    case "reduceAnswerFontSize":
      return reduceAnswerFontSize(state)
    case "increaseAnswerFontSize":
      return increaseAnswerFontSize(state)

    case "getNextTask":
      return getNextTask(state)
    case "getNextTaskLong":
      return getNextTaskLong(state)

    case "getResult":
      return action.quizId === "unsaved"
        && action.error === null
        ? {
          ...state,
          quizStatus: "result",
          quizId: action.quizId,
          btnInact: false,
          error: null,
          errorMySQL: false,
          errorAuth: false
        }

        : state.currentUser
          && state.quiz.id
          && action.quizId !== "unsaved"
          && state.quiz.type === "PUBLIC"
          && action.error === null
          ? {
            ...state,
            error: action.error,
            errorMySQL: false,
            errorAuth: false,
            quizStatus: "result",
            btnInact: false,
            timesTaken: action.timesTaken,
            recStatus: state.quiz.type === "PUBLIC"
              && state.currentUser.id !== state.quiz.author_id
              && state.currUserComment.length === 0
              ? "rec"
              : state.quiz.type === "PUBLIC"
                && state.currentUser.id !== state.quiz.author_id
                && state.currUserComment.length === 1
                ? "done"
                : "nothing"
          }

          : state.currentUser === null
            && state.quiz.id
            && action.quizId !== "unsaved"
            && state.quiz.type === "PUBLIC"
            && action.error === null
            ? {
              ...state,
              error: action.error,
              quizStatus: "result",
              btnInact: false,
              timesTaken: action.timesTaken,
              recStatus: "nothing",
              errorMySQL: false,
              errorAuth: state.errorAuth
            }
            : {
              ...state,
              error: action.error,
              quizStatus: "result",
              btnInact: false,
              timesTaken: state.timesTaken,
              recStatus: "nothing",
              errorMySQL: action.errorMySQL,
              errorAuth: state.errorAuth
            }

    //---------QuizRezult
    case "backToAdjustQuiz":
      return backToAdjustQuiz(state)

    case "addToFav":
      return !action.error
        ? {
          ...state,
          favQuizStatus: true,
          errorMySQL: false,
          errorAuth: false,
          error: action.error
        }
        : action.error === "Not authenticated"
          || action.error === "Token is not valid"
          ? {
            ...state,
            error: action.error,
            errorMySQL: false,
            errorAuth: true
          }
          : {
            ...state,
            error: action.error,
            errorMySQL: true,
            errorAuth: false
          }

    case "setRecommendQuiz":
      return setRecommendQuiz(state, action)

    case "getComment":
      return getComment(state, action)

    case "goToEditComment":
      return goToEditComment(state)

    case "cancelRecCom":
      return cancelRecCom(state)

    case "submitRecommendation":
      return !action.error
        ? {
          ...state,
          error: action.error,
          errorMySQL: false,
          errorAuth: false,
          recStatus: "done",
          recQuiz: action.recQuiz,
          unrecQuiz: action.unrecQuiz,
          removeComments: action.removeComments,
          quizStatus: action.quizStatus
        }
        : action.error === "Not authenticated"
          || action.error === "Token is not valid"
          ? {
            ...state,
            error: action.error,
            errorMySQL: false,
            errorAuth: true
          }
          : {
            ...state,
            error: action.error,
            errorMySQL: true,
            errorAuth: false
          }


    default:
      return state
  }
}

export default reducer