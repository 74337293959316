import axios from "axios";

const submitContactSupport = async (dispatch, state) => {

  let error = state.error;
  let errorMessage = state.errorMessage;

  const username = state.usernameCont;
  const email = state.emailCont;
  const subject = state.subjectCont;
  const message = state.messageCont;

  const inputs = {
    username,
    email,
    subject,
    message
  };

  try {
    await axios.post("/auth/support", inputs);
  } catch (err) {
    error = err.response.data.message;
    console.log("submitContactSupport err:", error);
  }

  return dispatch({
    type: "submitContactSupport",
    errorMessage,
    error
  });
}

export default submitContactSupport