import React, { useContext } from "react";
import { QuizContext } from "../App";
import User from "../components/User";
import EditProfile from "../components/EditProfile";
import { useParams } from "react-router-dom";
import OtherUserPage from "./OtherUserPage";
import TextBanTeamAccess from "../components/text/TextBanTeamAccess";
import LogInSignUp from "./LogInSignUp";

const UserPage = () => {

  const quizContext = useContext(QuizContext);
  const { id } = useParams();
  const currentUserId = quizContext.state.currentUser ? quizContext.state.currentUser.id : null
  const otherUserId = Number(id)

  return (
    <div>
      {otherUserId === currentUserId || !id
        ? <>
          {quizContext.state.currentUser
            && quizContext.state.userPageStatus === "edit profile"
            ? <EditProfile />
            : quizContext.state.currentUser
              && quizContext.state.userPageStatus === "edit teams"
              ? <TextBanTeamAccess />
              : quizContext.state.errorAuth
                ? <LogInSignUp />
                : <User />
          }
        </>
        : <OtherUserPage />
      }
    </div>
  )
}

export default UserPage