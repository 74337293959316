import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { QuizContext } from "../../App";

const CardUserStartTeam = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Link to={"/start_team"} style={{ textDecoration: 'none' }}>
      <div
        className={`${theme}-card-user`}
        onClick={() => quizContext.dispatch({
          type: "backToMainPage"
        })}
      >
        СОЗДАТЬ КОМАНДУ
      </div>
    </Link>

  )
}

export default CardUserStartTeam