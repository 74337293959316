import React, { useContext, useEffect } from 'react';
import { QuizContext } from '../App';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ErrorRU from './ErrorRU';
import FormSelectQuizLang from '../componentsRU/forms/FormSelectQuizLang';
import TableCategoryQuizzes from '../componentsRU/tables/TableCategoryQuizzes';
import Loading from '../components/Loading';
import FormSearchItem from '../componentsRU/forms/FormSearchItem';

const Category = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const { id } = useParams();
  const dispatch = quizContext.dispatch;

  //Get Category Quizzes
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`/library/publicquizzes/${id}`);
        // console.log("res.data", res.data)
        const catQuizzes = res.data
        // console.log("catQuizzes", catQuizzes)
        dispatch({ type: "fetchCatQuizzes", catQuizzes });
      } catch (err) {
        const error = err.response.data.message;
        console.log("Cat useEffect err", error);
        dispatch({ type: "fetchCatQuizzesErr", error });
      }
    }
    fetchData();
  }, [id, dispatch]);


  return (
    <div>
      {quizContext.state.loading
        ? <Loading />
        : quizContext.state.errorMySQL
          || quizContext.state.error === "404"
          ? <ErrorRU />
          : <>
            <h2 className="header">
              {quizContext.state.catQuizzes[0].catname_ru.toUpperCase()}
            </h2>


            {quizContext.state.langArr.filter((el, i) => quizContext.state.langArr.indexOf(el) === i).length >= 1 || quizContext.state.catQuizzes.length > quizContext.state.itemArrLength
              ? <>
                <Row className="justify-content-center" data-bs-theme={theme}>
                  <Col>
                    {quizContext.state.catQuizzes.length > quizContext.state.itemArrLength && <FormSearchItem />}
                  </Col>
                  <Col>
                    <FormSelectQuizLang />
                  </Col>
                </Row>
              </>
              : null
            }

            <div className="library">
              <TableCategoryQuizzes />
            </div>

          </>
      }
    </div>
  )
}

export default Category