import React, { useContext } from "react";
import { QuizContext } from '../../App';
import { Link, useParams } from "react-router-dom";

const ButtonTeam = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const { id } = useParams();

  return (
    <Link
      to={`/team/${id}`}
      style={{ textDecoration: "none" }}
    >
      <button
        className={`${theme}-main-btn`}
        onClick={() => quizContext.dispatch({ type: "backToMainPage" })}
      >
        КОМАНДА
      </button>
    </Link>
  )
}

export default ButtonTeam