import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { QuizContext } from "../App";
import TextNotYourQuiz from "./text/TextNotYourQuiz";
import AccordionComments from "./accordions/AccordionComments";
import TableQluizHeader from "./tables/TableQluizHeader";
import TableQuizCharacteristics from "./tables/TableQuizCharacteristics";
import TextBanTeamQuiz from "./text/TextBanTeamQuiz";
import TextUnsavedQuiz from "./text/TextUnsavedQuiz";
import { banTeamAccess } from "../reducer/actions/groupActions/BanTeam";
import ButtonsSocialMedia from "../components/buttons/ButtonsSocialMedia";
import { ButtonDownloadXLSXfile } from "./buttons/ButtonDownloadXLSXfile";
import TextNotYourTeamQuiz from "./text/TextNotYourTeamQuiz";

const QuizTitlePage = () => {

  const quizContext = useContext(QuizContext);
  const { id } = useParams();
  const teamId = quizContext.state.teamData.map(el => el.team_id);

  const ban = banTeamAccess(
    quizContext.state.quiz.mem,
    quizContext.state.quiz.num_teams,
    quizContext.state.quiz.num_of_memb,
    quizContext.state
  );

  // const desc = 

  return (
    <div>
      {id === "unsaved"
        ? <div>
          <TextUnsavedQuiz />
        </div>

        : quizContext.state.quiz.type === "PRIVATE"
          && (quizContext.state.currentUser === null
            || quizContext.state.quiz.author_id !== quizContext.state.currentUser.id)
          ? <TextNotYourQuiz />
          : quizContext.state.quiz.type === "TEAM"
            && (quizContext.state.currentUser === null
              || (quizContext.state.quiz.author_id !== quizContext.state.currentUser.id && quizContext.state.userTeams.filter((el) => teamId.includes(el.team_id)).length === 0))
            ? <TextNotYourTeamQuiz />

            : quizContext.state.quiz.type === "TEAM"
              && quizContext.state.currentUser.id !== quizContext.state.quiz.author_id
              && ban === true
              ? <TextBanTeamQuiz />

              : <div>
                <TableQluizHeader />
                <TableQuizCharacteristics />

                {(quizContext.state.currentUser 
                && quizContext.state.currentUser.id === quizContext.state.quiz.author_id)
                || quizContext.state.quiz.id === "unsaved"
                  ? <div style={{ textAlign: "right", marginTop: "15px" }}>
                    <ButtonDownloadXLSXfile />
                  </div>
                  : null
                }

                {quizContext.state.quiz.type === "PUBLIC"
                  && <div className="social-icons">
                    <ButtonsSocialMedia />
                  </div>
                }

                {quizContext.state.quiz.type === "PUBLIC"
                  && quizContext.state.comments.length
                  && quizContext.state.comments.filter(el => el.comment).length
                  ? <div style={{ marginTop: "40px" }}><AccordionComments /></div>
                  : null
                }
              </div >
      }
    </div>
  )
}

export default QuizTitlePage