import React, { useContext } from "react";
import { QuizContext } from "../../App";
import { Card } from "react-bootstrap";
import ButtonModalFullScreenTableEditQuiz from "../buttons/ButtonModalFullScreenTableEditQuiz";
import FormTableEditQuizContents from "../forms/FormTableEditQuizContents";
import TextMistake from "../text/TextMistake";
import ButtonsSubmitEditedQuizContents from "../buttons/ButtonsSubmitEditedQuizContents";

const CardQuizEditContents = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Card
      data-bs-theme={theme}
      style={{ boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px" }}
    >
      <div className={`${theme}-main-card`}>
        <div style={{ marginBottom: "10px" }}>
          <ButtonModalFullScreenTableEditQuiz />
        </div>

        <div className="quiz-table">
          <FormTableEditQuizContents />
        </div>
        <div style={{ textAlign: "center", marginTop: "30px" }}>
          {quizContext.state.errorMessage === "table mistake"
            && <TextMistake />
          }
          <ButtonsSubmitEditedQuizContents />
        </div>
      </div>
    </Card>
  )
}

export default CardQuizEditContents