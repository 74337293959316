import React, { useContext } from "react";
import { QuizContext } from "../App";
import ButtonQuizCreate from "../components/buttons/ButtonQuizCreate";
import ButtonHome from "../components/buttons/ButtonHome";
import ButtonQuizLibrary from "../components/buttons/ButtonQuizLibrary";
import { Link } from "react-router-dom";

const Error = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <div className={`${theme}-wrapper`}>
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        {quizContext.state.error === "404"
          ? <>
            <h2 className="error-h2">
              PAGE NOT FOUND
            </h2>
            <div style={{ marginTop: "25px" }}>
              <ButtonQuizLibrary />
            </div>
            <div style={{ marginTop: "15px" }}>
              <ButtonQuizCreate />
            </div>
            <div style={{ marginTop: "15px" }}>
              <ButtonHome />
            </div>
          </>

          : quizContext.state.error === "500"
            || quizContext.state.errorMySQL
            ? <>
              <h2 className="error-h2">
                Oops!
              </h2>
              <p className="error-p">
                Something went wrong on our end.<br />
                We’re doing our best to fix the issue.<br />
                Meanwhile, you still can create and take quizzes without saving them.
              </p>
              <div style={{ marginTop: "15px" }}>
                <ButtonQuizCreate />
              </div>
              <div style={{ marginTop: "15px" }}>
                <ButtonHome />
              </div>
            </>
            : quizContext.state.errorAuth
              ? <>
                <h2 className="error-h2">
                  AUTHENTICATION FAILURE
                </h2>
                <p className="error-p">
                  To solve the problem,<Link to="/login"><button
                    className={`${theme}-small-btn`}
                    onClick={() => quizContext.dispatch({ type: "switchToLogin" })}
                  >
                    LOG IN
                  </button></Link> anew.</p>
                <p className="error-p">Despite the issue,<br />
                  you still can use QUIZ LIBRARY<br />
                  and create quizzes without saving them.
                </p>
                <div style={{ marginTop: "25px" }}>
                  <ButtonQuizLibrary />
                </div>
                <div style={{ marginTop: "15px" }}>
                  <ButtonQuizCreate />
                </div>
                <div style={{ marginTop: "15px" }}>
                  <ButtonHome />
                </div>
              </>
              : <>
                <h2 className="error-h2">
                  PAGE NOT FOUND
                </h2>
                <div style={{ marginTop: "25px" }}>
                  <ButtonQuizLibrary />
                </div>
                <div style={{ marginTop: "15px" }}>
                  <ButtonQuizCreate />
                </div>
                <div style={{ marginTop: "15px" }}>
                  <ButtonHome />
                </div>
              </>
        }

      </div>
    </div>
  )
}

export default Error