import React, { useContext } from "react";
import { QuizContext } from "../../App";

const ButtonLogIn = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme

  return (
    <>
      {quizContext.state.formFilled === true
        ? <button
          className={`${theme}-main-btn`}
          type="submit"
        >
          ВОЙТИ
        </button>
        : <button className={`${theme}-main-btn-inactive`}>
          <span style={{ cursor: "crosshair" }}>
            ВОЙТИ
          </span>
        </button>
      }
    </>
  )
}

export default ButtonLogIn