import React, { useContext } from "react";
import { QuizContext } from "../../App";

const CardMembershipBronze = () => {

  const quizContext = useContext(QuizContext);

  return (

    <div className="card-membership">
      <div className="membership">
        {quizContext.state.currentUser.mem === "BRZ"
          && <span>&#9679;</span>} BRONZE
      </div>

      <div className="conditions">
        <p>
          <span className="tic">&#10003;</span> Access to Quiz Library
        </p>
        <p>
          <span className="tic">&#10003;</span> Creating quizzes
        </p>
        <p>
          <span className="tic">&#10003;</span> Joining teams
        </p>

        <p>
          <span className="tic">&#10003;</span> Starting up to <b>{quizContext.state.bronzeTeams}</b> teams<br /> up to <b>{quizContext.state.bronzeMembers}</b> members each
        </p>
      </div>

      <div className="price-area">

        <table style={{ width: "100%" }}><tbody>
          <tr>
            <th style={{ width: "50%" }}>
              <span className="price">${quizContext.state.bronzePayMonth}</span> <span style={{ fontWeight: "400", fontSize: "0.8rem" }}>a month</span> <br />
              <span className="price">${quizContext.state.bronzePayYear}</span> <span style={{ fontWeight: "400", fontSize: "0.8rem" }}>a year</span>
            </th>
            <th style={{ width: "50%" }}>
              <button className="buy-btn">BUY</button>
            </th>
          </tr>
        </tbody></table>
      </div>

      {/* <div className="price-area">
        <p className="free">FREE</p>

        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header style={{ color: "black" }}>
              If you meet the following conditions
            </Accordion.Header>
            <Accordion.Body>
              <p
                style={{
                  fontWeight: "400",
                  color: "#212529",
                  textAlign: "left"
                }}
              >
                <span className="tic-small">&#10003;</span> If you have at least 1000 rating points<br />
                <span className="tic-small">&#10003;</span> If you created at least 5 PUBLIC quizzes <br />
                <span className="tic-small">&#10003;</span> If each of those quizzes has at least 5 reactions,<br /> 80% of which are "recommended" </p>
              <p style={{ fontWeight: "600", color: "blue" }}>When all conditions are met,<br /> your membership plan upgrades automatically
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div> */}
    </div>

  )
}

export default CardMembershipBronze