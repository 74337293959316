import axios from "axios";

// FormAddQuizToTeam

const addQuizToTeam = async (dispatch, state) => {
  
  let error = state.error;

  try {
    await axios.post(`/team/addquiz/${state.teamQuizId}`, {
      team_id: state.teamId
    });
  } catch (err) {
    error = err.response.data.message;
    console.log("addQuizToTeam err:", err);
  }

  return dispatch({
    type: "addQuizToTeam",
    error,
  });
}

export default addQuizToTeam