import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { QuizContext } from "../../App";

const ButtonDeleteQuiz = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const alertStatus = "delete quiz";

  return (
    <>
      {quizContext.state.quiz.type === "TEAM"
        ? <>
          <div>
            <span style={{ fontWeight: "600" }}>{quizContext.state.quiz.title}</span> is a TEAM quiz.<br />
            To delete it, go to Edit Team Page, and remove it from the team.
          </div>
          <Link
            to={`/edit_team/${quizContext.state.quiz.team_id}`}
            style={{ textDecoration: 'none' }}
          >
            <button
              className={`${theme}-sec-btn`}
              style={{ marginTop: "10px" }}
            >
              EDIT TEAM
            </button>
          </Link>
        </>

        : quizContext.state.quizType === "PUBLIC"
          ? <>
            <div>
              <span style={{ fontWeight: "600" }}>{quizContext.state.quiz.title}</span> is a PUBLIC quiz.<br />
              To delete it, change its type to PRIVATE.
            </div>
          </>

          : <button
            className={`${theme}-sec-btn`}
            onClick={() => quizContext.dispatch({
              type: "showModal",
              alertStatus
            })}
          >
            DELETE QUIZ
          </button>
      }
    </>
  )
}

export default ButtonDeleteQuiz