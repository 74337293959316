// FormCommentQuiz

const getComment = (state, action) => {
  const comment = action.payload;
  return {
    ...state,
    comment,
  };
}

export default getComment