import React, { useContext } from "react";
import { QuizContext } from "../../App";
import { Link } from "react-router-dom";
import submitLogOut from "../../reducer/actions/userActions/SubmitLogOut";

const ButtonLogOut = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Link to="/" style={{ textDecoration: 'none' }}>
      <button
        className={`${theme}-sec-btn`}
        onClick={() => {
          submitLogOut(quizContext.dispatch, quizContext.state)
          quizContext.dispatch({ type: "submitLogOut" })
        }}
      >
        LOG OUT
      </button>
    </Link>
  )
}

export default ButtonLogOut