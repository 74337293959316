import getInitialState from "../../state/GetInitialState";

// NavBar, ButtonCancelAndGoToPrevPage, ButtonTeam, ButtonMyPage, ModalAnotherQuiz, TextNotYourTeam, TextNotYourQuiz

const backToMainPage = (state) => {

  const errorAuth = state.errorAuth;
  const errorMySQL = state.errorMySQL;
  const theme = state.theme;
  const isRussia = state.isRussia;
  const websiteLang = state.websiteLang;

  return {
    ...getInitialState(),
    errorAuth,
    errorMySQL,
    theme,
    isRussia,
    websiteLang
  };

};

export default backToMainPage