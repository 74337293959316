import React, { useContext } from "react";
import { QuizContext } from "../../App";

const ButtonAnotherQuiz = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const alertStatus = "another quiz";

  return (

    <button className={`${theme}-sec-btn`}
      onClick={() => quizContext.dispatch({
        type: "showModal",
        alertStatus
      })}
    >
      ANOTHER QUIZ
    </button>
  )
}

export default ButtonAnotherQuiz
