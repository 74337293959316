import React from "react";

const TextRequirementsTable = () => {

  return (
    <>

      <p>Вы можете заполнить таблицу прямо здесь или перейти в полноэкранный режим.</p>
      <p>
        <img
          src={require('../images/instruction-create-from-table-FS.jpg')}
          style={{ width: "100%" }}
          alt={'instr'}
        />
      </p>

      <p>Заполните заголовки столбцов.</p>
      <p>
        <img
          src={require('../images/instruction-create-from-table-headers.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>

      <p>Заполните значения в ячейках.</p>
      <p>
        <img
          src={require('../images/instruction-create-from-table-values.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>

      <p>Чтобы добавить строку, нажмите номер строки, после которой Вы хотите её добавить. Вы можете дабавить столько строк, сколько Вам нужно.</p>
      <p>
        <img
          src={require('../images/instruction-create-from-table-addRow.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>

      <p>Чтобы добавить столбец, нажмите номер столбца, после которого Вы хотите его добавить. Вы можете дабавить столько столбцов, сколько Вам нужно.</p>
      <p>
        <img
          src={require('../images/instruction-create-from-table-addColumn.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>

      <p>Чтобы удалить строку, нажмите на её номер. Квиз должен содержать не менее 5-ти строк, поэтому возможность удалить строку появляется только если таблица содержит более 5-ти строк.</p>
      <p>
        <img
          src={require('../images/instruction-create-from-table-removeRow.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>

      <p>Чтобы удалить столбец, нажмите на его номер. Квиз должен содержать не менее 2-х столбцов, поэтому возможность удалить столбец появляется только если таблица содержит более 2-х столбцов.</p>
      <p>
        <img
          src={require('../images/instruction-create-from-table-removeColumn.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>


    </>
  )
}

export default TextRequirementsTable