import getRandomIndices from './GetRandomIndices';

// SolveQuiz (an action file)

const generateAnswers = (state) => {

  const quizRows = state.quizRows;

  const quizValues = quizRows.map((ee, i) => Object.keys(ee).map(e => ({
    header: e,
    value: quizRows[i][e]
  })));

  return getRandomIndices(quizValues).map(el => {
    return el.map((elel) => {
      return [...quizValues[elel]];
    });
  });

}

export default generateAnswers