import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import { QuizContext } from "../../App";

const FormEditQuizType = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Form>
      {quizContext.state.quiz.type === "PUBLIC"
        ? <Form.Label style={{ textAlign: "center" }}><span style={{ color: "red", fontWeight: "700" }}>ВНИМАНИЕ!!!</span><br />Если Вы измените тип квиза с ОБЩЕДОСТУПНЫЙ на ЛИЧНЫЙ, Вы утратите все очки, которые Вам принёс этот квиз. Кроме того, будут удалены все комментарии и рекомендации без возможности восстановления в случае изменения типа обратно на ОБЩЕДОСТУПНЫЙ.
        </Form.Label>
        : <Form.Label style={{ textAlign: "center" }}>Если Вы измените тип квиза с ЛИЧНЫЙ на ОБЩЕДОСТУПНЫЙ, Вы заработаете 10 очков. Далее Вы будете получать по 1 очку, каждый раз, когда кто-то будет проходить Ваш квиз, и по 3 очка за каждую положительную рекомендацию Вашего квиза. Однако Вы будете терять по 3 очка за каждую отрицательную рекомендацию.
        </Form.Label>
      }
      <Form.Select
        data-bs-theme={theme}
        style={{
          border: theme === "dark"
            ? "1px solid #77797c"
            : "1px solid #212529"
        }}
        onChange={(e) => quizContext.dispatch({
          type: "editQuizType",
          payload: e.target.value
        })}
      >
        {quizContext.state.quizType === "PRIVATE"
          ? <>
            <option key={1} value="PRIVATE">ЛИЧНЫЙ (доступен только Вам)</option>
            <option key={2} value="PUBLIC">ОБЩЕДОСТУПНЫЙ (доступен всем в БИБЛИОТЕКЕ КВИЗОВ)</option>
          </>
          : <>
            <option key={2} value="PUBLIC">ОБЩЕДОСТУПНЫЙ (доступен всем в БИБЛИОТЕКЕ КВИЗОВ)</option>
            <option key={1} value="PRIVATE">ЛИЧНЫЙ (доступен только Вам)</option>
          </>
        }
      </Form.Select>
    </Form>
  )
}

export default FormEditQuizType