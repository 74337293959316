import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import { QuizContext } from "../../App";

const FormSetTheme = () => {

  const quizContext = useContext(QuizContext);

  return (
    <div key={"inline-radio"} className="mb-3">

    {quizContext.state.radioTheme.map((el, i) =>
      <Form.Check
        inline
        label={el.label}
        key={i}
        name="team member"
        type="radio"
        checked={
          quizContext.state.userTheme === el.value
            ? true : false}
        value={el.value}
        id={i + 1}
        onChange={(e) => quizContext.dispatch({
          type: "setUserTheme",
          payload: e.target.value
        })}
      />
    )}
  </div>
  )
}

export default FormSetTheme