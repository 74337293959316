// FormEditQuizTitle

const editQuizTitle = (state, action) => {
  const quizTitle = action.payload;
  return {
      ...state,
      errorMessage: false,
      alertStatus: "editing",
      quizTitle
  };
}

export default editQuizTitle