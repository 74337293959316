// TeamStart, Team, QuizTitlePage, TeamEdit

export const banTeamStart = (mem, numTeams, state) => {

  const ban = mem === "STD"
    || (mem === "STU" && numTeams >= state.membPlan.student.teams)
    || (mem === "BRZ" && numTeams >= state.membPlan.bronze.teams)
    || (mem === "SLV" && numTeams >= state.membPlan.silver.teams)
    || (mem === "GLD" && numTeams >= state.membPlan.gold.teams)
    || (mem === "GURU" && numTeams >= state.membPlan.guru.teams)
    ? true
    : false;

  return ban;
}; 


export const banTeamAccess = (mem, numTeams, numTeamMembs, state) => {

  const ban = (mem === "STD" && numTeams > 0)
  || (mem === "STU" && (numTeams > state.membPlan.student.teams || numTeamMembs > state.membPlan.student.members))
  || (mem === "BRZ" && (numTeams > state.membPlan.bronze.teams || numTeamMembs > state.membPlan.bronze.members))
  || (mem === "SLV" && (numTeams > state.membPlan.silver.teams || numTeamMembs > state.membPlan.silver.members))
  || (mem === "GLD" && (numTeams > state.membPlan.gold.teams || numTeamMembs > state.membPlan.gold.members))
  || (mem === "GURU" && (numTeams > state.membPlan.guru.team || numTeamMembs > state.membPlan.guru.members))
  ? true
  : false;

  return ban;
}


export const banInvUsers = (mem, numTeamMembs, state) => {

  const ban = (mem === "STD" && numTeamMembs > 0)
  || (mem === "STU" && numTeamMembs >= state.membPlan.student.members)
  || (mem === "BRZ" && numTeamMembs >= state.membPlan.bronze.members)
  || (mem === "SLV" && numTeamMembs >= state.membPlan.silver.members)
  || (mem === "GLD" && numTeamMembs >= state.membPlan.gold.members)
  || (mem === "GURU" && numTeamMembs >= state.membPlan.guru.members)
  ? true
  : false;

  return ban;
} 

