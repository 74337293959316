import React, { useContext } from "react";
import { QuizContext } from "../../App";
import {
  ButtonSubmitQuizAct,
  ButtonSubmitQuizAndSaveAct,
  ButtonSubmitQuizAndSaveInAct,
  ButtonSubmitQuizInAct
} from "./ButtonsSubmitQuiz";


const ButtonsSubmitQuizTable = () => {

  const quizContext = useContext(QuizContext);

  return (
    <>
      {quizContext.state.currentUser === null
        || quizContext.state.errorMySQL === true
        || quizContext.state.errorAuth === true
        ? <>
          <div>
            <span style={{ fontWeight: "600" }}>
              STEP 5:</span> submit your quiz
          </div>
          <div className="sub-quiz">

            {quizContext.state.alertStatus === "changes confirmed"
              ? <ButtonSubmitQuizAct />
              : <ButtonSubmitQuizInAct />
            }

          </div>
        </>
        : <>
          <div>
            <span style={{ fontWeight: "600" }}>
              STEP 5:</span> submit and save your quiz or submit it without saving
          </div>

          <div className="sub-quiz">
            {quizContext.state.alertStatus === "changes confirmed"
              ? <ButtonSubmitQuizAndSaveAct />
              : <ButtonSubmitQuizAndSaveInAct />
            }

            {quizContext.state.alertStatus === "changes confirmed"
              ? <ButtonSubmitQuizAct />
              : <ButtonSubmitQuizInAct />
            }
          </div>
        </>
      }
    </>
  )
}

export default ButtonsSubmitQuizTable