// FormCreateTeam

const getTeamName = (state, action) => {
  
  const teamName = action.payload;
  
  return {
      ...state,
      teamName,
      confirmed: false
  };
}

export default getTeamName