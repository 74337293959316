import React, { useContext } from "react";
// import Table from 'react-bootstrap/Table';
import { QuizContext } from "../../App";
import Accordion from "react-bootstrap/Accordion";
import TableMembershipPlans from "../tables/TableMembershipPlans";
import TabelEarnLosePoints from "../tables/TabelEarnLosePoints";

const AccordionFAQmembership = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  const vote = quizContext.state.quizPoints.vote;
  const changeType = quizContext.state.quizPoints.changeType;
  const numVote = quizContext.state.quizPoints.numVote;
  const votePercent = quizContext.state.quizPoints.votePercent;


  return (
    <Accordion
      data-bs-theme={theme}
      className="card-faq"
    >

      <Accordion.Item eventKey="0">
        <Accordion.Header style={{ color: "black" }}>
          Что может делать незарегистрированный пользователь?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>Вы можете пользоваться КВИЗАМИ без регистрации на сайте.</p>
          <p>Без создания аккаунта:</p>
          <p><span className="tic">&#10003;</span> Вы можете проходить квизы из БИБЛИОТЕКИ КВИЗОВ.</p>
          <p><span className="tic" style={{ color: "red" }}>&times;</span> Вы не можете добавлять квизы из БИБЛИОТЕКИ КВИЗОВ в ИЗБРАННЫЕ.</p>
          <p><span className="tic" style={{ color: "red" }}>&times;</span> Вы не можете рекомендовать/не рекомендовать и комментировать квизы из БИБЛИОТЕКИ КВИЗОВ.</p>
          <p><span className="tic">&#10003;</span> Вы можете создавать квизы.</p>
          <p><span className="tic">&#10003;</span> Вы можете скачивать на своё устройство квизы, которые Вы создали, в виде xlsx файлов.</p>
          <p><span className="tic" style={{ color: "red" }}>&times;</span> Вы не можете сохранять созданные Вами квизы на сайте, и чтобы пройти их, Вам придётся каждый раз загружать их заново.</p>
          <p><span className="tic" style={{ color: "red" }}>&times;</span> Вы не можете публиковать свои квизы в БИБЛИОТЕКЕ КВИЗОВ.</p>
          <p><span className="tic" style={{ color: "red" }}>&times;</span> Вы не можете вступать в команды.</p>
          <p><span className="tic" style={{ color: "red" }}>&times;</span> Вы не можете создавать команды.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1">
        <Accordion.Header>
          Что может делать зарегистрированный пользователь?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>Если Вы создадите аккаунт на КВИЗАХ:</p>
          <p><span className="tic">&#10003;</span> Вы можете проходить квизы из БИБЛИОТЕКИ КВИЗОВ.</p>
          <p><span className="tic">&#10003;</span> Вы можете добавлять квизы из БИБЛИОТЕКИ КВИЗОВ в ИЗБРАННЫЕ. Ссылки на эти квизы появятся в разделе ИЗБРАННЫЕ КВИЗЫ на Вашей страницы, и Вы получите лёгкий доступ к ним.</p>
          <p><span className="tic">&#10003;</span> Вы можете рекомендовать/не рекомендовать и комментировать квизы из БИБЛИОТЕКИ КВИЗОВ.</p>
          <p><span className="tic">&#10003;</span> Вы можете создавать квизы.</p>
          <p><span className="tic">&#10003;</span> Вы можете скачивать на своё устройство квизы, которые Вы создали, в виде xlsx файлов.</p>
          <p><span className="tic">&#10003;</span> Вы можете сохранять созданные Вами квизы на сайте и иметь лёгкий доступ к ним на своей странице в разделе МОИ КВИЗЫ.</p>
          <p><span className="tic">&#10003;</span> Вы можете публиковать свои квизы в БИБЛИОТЕКЕ КВИЗОВ.</p>
          <p><span className="tic">&#10003;</span> Вы можете вступать в неограниченное количество команд.</p>
          <p><span className="tic" style={{ color: "red" }}>&times;</span> Вы не можете создавать команды если Ваш статус пользоватля СТАНДАРТНЫЙ.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header>
          Что может делать пользователь с премиум статусом?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>5 премиум статусов пользоватля: УЧЕНИК, БРОНЗОВЫЙ, СЕРЕБРЯННЫЙ, ЗОЛОТОЙ, и ГУРУ.</p>
          <p>Вы можете получить любой из них БЕСПЛАТНО с помощью публикации своих квизов в БИБЛИОТЕКЕ КВИЗОВ и зарабатывании очков рейтинга автора квизов, когда другие пользователи проходят Ваши квизы и рекомнедуют их.</p>
          <p>Если у Вас премиум статус:</p>
          <p><span className="tic">&#10003;</span> Вы можете проходить квизы из БИБЛИОТЕКИ КВИЗОВ.</p>
          <p><span className="tic">&#10003;</span> Вы можете добавлять квизы из БИБЛИОТЕКИ КВИЗОВ в ИЗБРАННЫЕ. Ссылки на эти квизы появятся в разделе ИЗБРАННЫЕ КВИЗЫ на Вашей страницы, и Вы получите лёгкий доступ к ним.</p>
          <p><span className="tic">&#10003;</span> Вы можете рекомендовать/не рекомендовать и комментировать квизы из БИБЛИОТЕКИ КВИЗОВ.</p>
          <p><span className="tic">&#10003;</span> Вы можете создавать квизы.</p>
          <p><span className="tic">&#10003;</span> Вы можете скачивать на своё устройство квизы, которые Вы создали, в виде xlsx файлов.</p>
          <p><span className="tic">&#10003;</span> Вы можете сохранять созданные Вами квизы на сайте и иметь лёгкий доступ к ним на своей странице в разделе МОИ КВИЗЫ.</p>
          <p><span className="tic">&#10003;</span> Вы можете публиковать свои квизы в БИБЛИОТЕКЕ КВИЗОВ.</p>
          <p><span className="tic">&#10003;</span> Вы можете вступать в неограниченное количество команд.</p>
          <p><span className="tic">&#10003;</span> Вы можете создать столько команд, сколько позволяет Ваш премиум статус.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3">
        <Accordion.Header>
          Как повысить свой статус пользоватля?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>Публикуйте свои квизы в БИБЛИОТЕКЕ КВИЗОВ и зарабатывайте очки рейтига автора квизов:</p>
          <ul>
            <li>
              За публикацию своего квиз в БИБЛИОТЕКЕ КВИЗОВ, Вы зарабатываете {changeType} очков.
            </li>
            <li>
              Каждый раз, когда другие пользователи проходят Ваш квиз, Вы зарабатываете 1 очко.
            </li>
            <li>
              Каждый раз когда другие пользователи рекомендуют Ваш квиз, вы зарабатываете {vote} очка.
            </li>
          </ul>
          <p>Однако очки можно и терять:</p>
          <ul>
            <li>
              Если Вы удаляете свой квиз из БИБЛИОТЕКИ КВИЗОВ, вы утрачиваете все очки, которые этот квиз принёс Вам.
            </li>
            <li>
              Каждый раз, когда другие пользователе не рекомнедуют Ваш квиз, вы теряете {vote} очка.
            </li>
            <li>
              Если за Ваш квиз проголосовали {numVote} пользователей и больше и {votePercent}% или больше пользователей не рекомендуют Ваш квиз, Вы утрачиваете все очки, которые этот квиз принёс Вам, а сам квиз автоматически удаляется из БИБЛИОТЕКИ КВИЗОВ (его тип мняется на ЛИЧНЫЙ, и Вы сами можете продожать пользоваться им).
            </li>
          </ul>

          <p>В зависимости от количества опубликованных квизов в БИБЛИОТЕКЕ КВИЗОВ и количествА очков рейтинга автора квизов, Ваш статус пользователя повышается/понижается автоматически.</p>
          <p>Если Ваш статус пользователя понизиться, доступ к комндам, которые Вы создали, может быть заблокирован, если их количество больше количества, предусмотренного Вашим нынешним статусом. Чтобы разблокировать доступ, нужно повысить свой статус пользователя, либо сократить количество команд (функуция редактирования команд остаётся доступной)</p>

          <TableMembershipPlans />
          <TabelEarnLosePoints />

        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default AccordionFAQmembership