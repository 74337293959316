import React, { useContext } from "react";
import { QuizContext } from "../../App";

// functions file: ResizeFontCell.js

const ButtonsResizeFontCell = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <div>
      {/* <Row className="justify-content-center">
        <Col sm={6}> */}
          <span>Шрифт:</span>
          {quizContext.state.tableFontSize === 1
            ? <button className={`${theme}-up-down-btn-inactive`}>
              <span style={{ cursor: "crosshair" }}>
                &#9660;
              </span>
            </button>
            : <button className={`${theme}-up-down-btn`}
              onClick={() => quizContext.dispatch({
                type: "reduceTableFontSize"
              })}
            >
              &#9660;
            </button>
          }
          {quizContext.state.tableFontSize === 3
            ? <button className={`${theme}-up-down-btn-inactive`}>
              <span style={{ cursor: "crosshair" }}>
                &#9650;
              </span>
            </button>
            : <button className={`${theme}-up-down-btn`}
              onClick={() => quizContext.dispatch({
                type: "increaseTabeFontSize"
              })}
            >
              &#9650;
            </button>
          }
        {/* </Col>
        <Col sm={6}> */}
          <span style={{marginLeft: "15px"}}>Ячейка:</span>
          {quizContext.state.cellSize === 1
            ? <button className={`${theme}-up-down-btn-inactive`}>
              <span style={{ cursor: "crosshair" }}>
                &#9660;
              </span>
            </button>
            : <button className={`${theme}-up-down-btn`}
              onClick={() => quizContext.dispatch({
                type: "reduceCellSize"
              })}
            >
              &#9660;
            </button>
          }
          {quizContext.state.cellSize === 10
            ? <button className={`${theme}-up-down-btn-inactive`}>
              <span style={{ cursor: "crosshair" }}>
                &#9650;
              </span>
            </button>
            : <button className={`${theme}-up-down-btn`}
              onClick={() => quizContext.dispatch({
                type: "increaseCellSize"
              })}
            >
              &#9650;
            </button>
          }
        {/* </Col>
      </Row> */}
    </div>
  )
}

export default ButtonsResizeFontCell