import React, { useContext, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { QuizContext } from "../../App";

const ButtonModalFailedTasks = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button
        className={`${theme}-main-btn`}
        onClick={handleShow}
      >
        TASKS YOU FAILED
      </button>


      <Modal
        size="lg"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton data-bs-theme={theme}
          className={`${theme}-modal-header`}
        />
        <Modal.Body
          className={`${theme}-modal-body`}
          style={{
            maxHeight: "700px",
            overflowX: "hidden",
            padding: "3%"
          }}
        >
          <Table
            striped
            variant={theme}
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th>
                  Question
                </th>
                <th>
                  Right Answer
                </th>
              </tr>
            </thead>
            <tbody>
              {quizContext.state.failedTasks.map((el, i) => (
                <tr key={i}>
                  <td style={{textAlign: "left"}}>
                    {el.question}
                  </td>
                  <td style={{textAlign: "left"}}>
                    {el.answer}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal >
    </>
  )
}

export default ButtonModalFailedTasks