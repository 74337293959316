import getInitialState from "../../../state/GetInitialState";

// TableTeamQuizzes

const goToTeamQuiz = (state) => {

  const errorAuth = state.errorAuth;
  const errorMySQL = state.errorMySQL;
  const theme = state.theme;
  const isRussia = state.isRussia;
  const websiteLang = state.websiteLang;
  const teamData = [{team_id: state.teamId, teamname: state.teamName}];

  return {
    ...getInitialState(),
    errorAuth,
    errorMySQL,
    theme,
    isRussia,
    websiteLang,
    teamData
  };
}

export default goToTeamQuiz