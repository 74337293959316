import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { QuizContext } from "../../App";
import { Card } from "react-bootstrap";

const CardNavQuizLibrary = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Link
      to="/quiz_library"
      style={{ textDecoration: 'none' }}
    >
      <Card
        data-bs-theme={theme}
        className="card-home"
      >
        <img
          src={theme === "dark"
            ? require('../images/library.png')
            : require('../images/library1.png')
          }
          className="card-icon"
          alt={'Storage_icon'}
        />
        <p className="card-text">
          БИБЛИОТЕКА КВИЗОВ
        </p>
      </Card>
    </Link>
  )
}

export default CardNavQuizLibrary