import axios from "axios";

// FormTeamRemoveQuiz, FormTeamRemoveYourQuiz

const removeQuizFromTeam = async (dispatch, state) => {
  
  let error = state.error;
  const teamId = state.team.id;
  const teamQuizToRemoveId = state.teamQuizToRemoveId;
  const authorId = state.teamQuizzes.filter((el, i) => el.id === teamQuizToRemoveId)[0].author_id;

  try {
    await axios.post(`/team/removequiz/${teamQuizToRemoveId}`, {
      team_id: teamId,
      author_id: authorId
    });
  } catch (err) {
    error = err.response.data.message;
    console.log("removeQuizFromTeam err", error);
  }

  return dispatch({
    type: "removeQuizFromTeam",
    error
  });
}

export default removeQuizFromTeam