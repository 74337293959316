import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { QuizContext } from "../../App";
import { Link } from "react-router-dom";

const ModalForStartTeam = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (

    <Modal
      data-bs-theme={theme}
      show={quizContext.state.alert}
    >
      <Modal.Header className={`${theme}-modal-header`}>
        <Modal.Title>
          <span className={`${theme}-congrat`}>
            ПОЗДРАВЛЯЕМ!
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={`${theme}-modal-body`}
        style={{ textAlign: "center" }}
      >
        <p>
          <span style={{ fontSize: "1.2rem", fontWeight: "600" }}>Вы создали команду <span className={`${theme}-congrat`}>
            {quizContext.state.teamName}
          </span></span><br />
          Чтобы перейти на страницу команды,<br />
          нажимте кнопку КОМАНДА.<br />
          Чтобы добавить в команду квизы и пригласить пользователей,<br />
          нажмите кнопку РЕДАКТИРОВАТЬ КОМАНДУ.
        </p>
        <div>
          <Link
            to={`/team/${quizContext.state.teamId}`}
            style={{ textDecoration: "none" }}
          >
            <button
              className={`${theme}-main-btn`}
              onClick={() => quizContext.dispatch({
                type: "backToMainPage"
              })}
            >
              КОМАНДА
            </button>
          </Link>
          <Link
            to={`/edit_team/${quizContext.state.teamId}`}
            style={{ textDecoration: "none" }}
          >
            <button
              className={`${theme}-main-btn`}
              onClick={() => quizContext.dispatch({
                type: "backToMainPage"
              })}
            >
              РЕДАКТИРОВАТЬ КОМАНДУ
            </button>
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ModalForStartTeam