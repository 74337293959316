import axios from "axios";

const submitNewPassword = async (dispatch, state) => {

  let error = state.errorNewPassword;

  const inputs = {
    email: state.email,
    otp: state.typedOTP,
    password: state.password
  };

  if (state.password !== "" 
  && state.passwordConfirm !== "" 
  && state.password === state.passwordConfirm) {

    try {
      await axios.post(`/auth/password`, inputs)
    } catch (err) {
      error = err.response.data.message;
      console.log("submitNewPassword err:", error);
    }
    return dispatch({
      type: "submitNewPassword",
      error
    })

  } else {
    return dispatch({
      type: "submitNewPassword",
      error
    })
  }
}

export default submitNewPassword