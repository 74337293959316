import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import { QuizContext } from "../../App";
import removeMemberFromTeam from "../../reducer/actions/groupActions/RemoveMemberFromTeam";

const FormTeamRemoveMember = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Form>
      <Form.Label
        style={{
          fontWeight: "600",
          marginTop: "10px"
        }}
      >
        Remove member from the team
      </Form.Label>

      <Form.Select
        data-bs-theme={theme}
        style={{
          border: theme === "dark"
            ? "1px solid #77797c"
            : "1px solid #212529"
        }}
        onChange={(e) => quizContext.dispatch({
          type: "selectTeamMemberToRemove",
          payload: e.target.value
        })}
      >
        <option value={"- Select Member -"}>- Select Member -</option>
        {quizContext.state.teamMembers
          .filter(el => el.user_type !== "ADMIN")
          .map((el, i) =>
            <option key={i} value={el.username}>
              {el.username} (ID {el.user_id}) — {el.user_type === "MEMB"
                ? "member"
                : el.user_type === "ASST"
                  ? "assistant"
                  : "invited"
              }
            </option>
          )}
      </Form.Select>

      {quizContext.state.teamMemberName !== "" && quizContext.state.removeTeamMemberStatus === "chosen" &&
        <div
          style={{
            textAlign: "left",
            marginTop: "15px"
          }}
        >
          {quizContext.state.teamQuizzes.filter(el => el.author_id === quizContext.state.teamMemberId).length !== 0
            ? <span>You'll be able to remove {quizContext.state.teamMemberName} after you remove quizzes they shared to the team</span>
            : <>
              <span
                style={{ fontSize: "1.2rem" }}
              >
                {quizContext.state.teamMemberName} — {quizContext.state.teamMemberType === "MEMB"
                  ? "member"
                  : quizContext.state.teamMemberType === "ASST"
                    ? "assistant"
                    : "invited"
                }
              </span>
              <button
                className={`${theme}-fourth-btn`}
                onClick={(e) => {
                  e.preventDefault()
                  removeMemberFromTeam(
                    quizContext.dispatch,
                    quizContext.state
                  )
                  quizContext.dispatch({
                    type: "removeMemberFromTeam"
                  })
                }}
              >
                REMOVE
              </button>
              <button
                className="third-btn"
                onClick={() => quizContext.dispatch({
                  type: "notInvite"
                })}
              >
                NO
              </button>
            </>
          }
        </div>
      }

      <div style={{ textAlign: "left", lineHeight: "0.5rem" }}>
        <p style={{ fontWeight: "600", marginTop: "20px" }}>
          Removed users:</p>
        {quizContext.state.membersToRemove.filter((el, i) =>
          quizContext.state.membersToRemove.indexOf(el) === i).map((el, i) =>
            <p key={i} style={{ marginLeft: "15px" }}>{el}</p>
          )}
      </div>
    </Form>
  )
}

export default FormTeamRemoveMember