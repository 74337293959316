import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { QuizContext } from "../../App";

const ModalStopQuiz = () => {
  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (

    <Modal
      size="sm"
      data-bs-theme={theme}
      show={quizContext.state.alertStopQuiz}
      onHide={() => quizContext.dispatch({
        type: "hideAlertStopQuiz"
      })}
    >
      <Modal.Header closeButton data-bs-theme={theme}
        className={`${theme}-modal-header`}
      >
        Вы собираетесь остановить прохождение квиза.<br />
        Вы уверены?
      </Modal.Header>
      <Modal.Body
        className={`${theme}-modal-body`}
      >
        <div style={{ textAlign: "center", marginTop: "5px" }}>
          <button
            className={`${theme}-sec-btn`}
            onClick={() => quizContext.dispatch({
              type: "backToAdjustQuiz"
            })}
          >
            ДА
          </button>
          <button
            className={`${theme}-main-btn`}
            onClick={() => quizContext.dispatch({
              type: "hideAlertStopQuiz"
            })}
          >
            НЕТ
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ModalStopQuiz