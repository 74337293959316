import React, { useContext, useEffect } from "react";
import { QuizContext } from "../App";
import axios from "axios";
import Table from 'react-bootstrap/Table';
import CardUserMyQuizzes from "./cards/CardUserMyQuizzes";
import CardUserCreateQuiz from "./cards/CardUserCreateQuiz";
import CardUserMyTeams from "./cards/CardUserMyTeams";
import TextUserHeader from "./text/TextUserHeader";
import CardUserFavQuizzes from "./cards/CardUserFavQuizzes";
import ModalMyQuizzes from "./modals/ModalMyQuizzes";
import ModalFavQuizzes from "./modals/ModalFavQuizzes";
import ModalMyTeams from "./modals/ModalMyTeams";
import CardUserInvitations from "./cards/CardUserInvitations";
import ModalInvitations from "./modals/ModalInvitations";
import CardUserStartTeam from "./cards/CardUserStartTeam";
import ModalForAcceptTeamInv from "./modals/ModalForAcceptTeamInv";
import ModalForDeclineTeamInv from "./modals/ModalForDeclineTeamInv";
import Loading from "./Loading";
import Error from "../pages/Error";
import ModalLogOut from "./modals/ModalLogOut";
import { banTeamAccess } from "../reducer/actions/groupActions/BanTeam";
import ButtonsEditProfileLogOut from "./buttons/ButtonsEditProfileLogOut";
import ButtonsSocialMedia from "../components/buttons/ButtonsSocialMedia";
import moreTeams from "../reducer/actions/groupActions/MoreTeams";


const User = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const dispatch = quizContext.dispatch;
  const userId = quizContext.state.currentUser.id;

  //Get all the info for the page
  useEffect(() => {
    const fecthData = async () => {
      try {
        const res = await axios.get(`/user/page/${userId}`);
        // console.log("res.data", res.data);
        const userPage = res.data;
        // console.log("userPage", userPage);
        dispatch({ type: "fetchUserPage", userPage });
      } catch (err) {
        const error = err.response.data.message;
        console.log("fetchUserPageErr:", err);
        dispatch({ type: "fetchUserPageErr", error });
      }
    }
    fecthData();
  }, [userId, dispatch, quizContext.state.alert]);

  const ban = banTeamAccess(
    quizContext.state.currentUser.mem,
    quizContext.state.currentUser.num_teams,
    quizContext.state.numTeamMembers,
    quizContext.state
  );

  const more = moreTeams(
    quizContext.state.currentUser.mem,
    quizContext.state.currentUser.num_teams,
    quizContext.state
  );

  return (
    <div>
      {quizContext.state.loading
        ? <Loading />
        : quizContext.state.error
          ? <Error />
          : <>
            <TextUserHeader />
            {ban === true
              && <p className="error">Доступ к созданным Вами командам заблокирован <button
                className="details-btn"
                onClick={() => quizContext.dispatch({
                  type: "goToEditTeams"
                })}
              >
                ПОДРОБНО
              </button>
              </p>
            }
            {more.status === true
              && <p className={`${theme}-rec`} style={{textAlign: "center"}}>
                {more.message}
              </p>
            }
            <Table
              striped hover
              variant={theme}
              className="table"
              style={{
                border: theme === "dark"
                  ? "1px solid black"
                  : "1px solid #dadada",
              }}
            >
              <tbody>
                <tr><th className="user-cards">
                  <CardUserMyQuizzes />
                </th></tr>
                <tr><th className="user-cards">
                  <CardUserFavQuizzes />
                </th></tr>
                <tr><th className="user-cards">
                  <CardUserCreateQuiz />
                </th></tr>
                <tr><th className="user-cards">
                  <CardUserMyTeams />
                </th></tr>
                <tr><th className="user-cards">
                  <CardUserInvitations />
                </th></tr>
                <tr><th className="user-cards">
                  <CardUserStartTeam />
                </th></tr>
              </tbody>
            </Table>

            <div style={{ marginTop: "10px" }}>
              <ButtonsEditProfileLogOut />
            </div>

            <div className="social-icons">
              <ButtonsSocialMedia />
            </div>

            {quizContext.state.alertStatus === "log out"
              && <ModalLogOut />}
            {quizContext.state.alertStatus === "my quizzes"
              && <ModalMyQuizzes />}
            {quizContext.state.alertStatus === "favorite quizzes"
              && <ModalFavQuizzes />}
            {quizContext.state.alertStatus === "my teams"
              && <ModalMyTeams />}
            {quizContext.state.alertStatus === "invitations"
              && <ModalInvitations />}
            {quizContext.state.alertStatus === "accept inv"
              && <ModalForAcceptTeamInv />}
            {quizContext.state.alertStatus === "decline inv"
              && <ModalForDeclineTeamInv />}
          </>
      }
    </div>
  )
}

export default User