import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { QuizContext } from "../../App";
import deleteQuiz from "../../reducer/actions/quizActions/DeleteQuiz";
import { Link } from "react-router-dom";
import ButtonCancelAndHideAlert from "../buttons/ButtonCancelAndHideAlert";

const ModalForDeleteQuiz = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Modal
      data-bs-theme={theme}
      show={quizContext.state.alert}
      onHide={() => quizContext.dispatch({
        type: "hideAlert"
      })}
    >
      <Modal.Header closeButton data-bs-theme={theme}
        className={`${theme}-modal-header`}
      >
        <Modal.Title>
          <div
            style={{
              color: "red",
              fontWeight: "700"
            }}
          >
            ВНИМАНИЕ!
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={`${theme}-modal-body`}
        style={{ textAlign: "center" }}
      >
        Вы собираетесь удалить квиз <br />
        <span style={{ fontWeight: "600" }}>{quizContext.state.quizTitle}</span>! <br />
        Вы уверены?

        <div style={{ textAlign: "center", marginTop: "30px" }}>

          <Link
            to={`/user/${quizContext.state.currentUser.id}`}
            style={{ textDecoration: 'none', color: "#212529" }}
          >
            <button
              className={`${theme}-sec-btn`}
              onClick={(e) => {
                // e.preventDefault()
                deleteQuiz(
                  quizContext.dispatch,
                  quizContext.state
                )
                quizContext.dispatch({
                  type: "deleteQuiz"
                })
              }}
            >
              УДАЛИТЬ
            </button>
          </Link>

          <ButtonCancelAndHideAlert />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ModalForDeleteQuiz