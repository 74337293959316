import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import { QuizContext } from "../../App";
import removeQuizFromTeam from "../../reducer/actions/groupActions/RemoveQuizFromTeam";

const FormTeamRemoveYourQuiz = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Form>
      <Card.Body>
        <Form.Label
          style={{
            fontWeight: "600"
          }}
        >
          Удалить свой квиз из списка квизов команды
        </Form.Label>

        <Form.Select
          data-bs-theme={theme}
          style={{
            border: theme === "dark"
              ? "1px solid #77797c"
              : "1px solid #212529"
          }}
          onChange={(e) => quizContext.dispatch({
            type: "selectQuizToRemoveFromTeam",
            payload: e.target.value
          })}
        >

          <option value={"- Select Quiz -"}>- Выбарть квиз -</option>
          {quizContext.state.teamQuizzes.filter(el => el.author_id === quizContext.state.currentUser.id).map((el, i) =>
            <option key={i} value={el.title}>{el.title}</option>
          )}
        </Form.Select>

        <div
          style={{
            textAlign: "left",
            marginTop: "15px"
          }}
        >
          {quizContext.state.teamQuizTitleToRemove !== "" && quizContext.state.removeQuizStatus === "remvove" &&
            <>
              <span
                style={{ fontSize: "1.4rem" }}
              >
                {quizContext.state.teamQuizTitleToRemove}
              </span>
              <button
                className={`${theme}-fourth-btn`}
                onClick={(e) => {
                  e.preventDefault()
                  removeQuizFromTeam(
                    quizContext.dispatch,
                    quizContext.state
                  )
                  quizContext.dispatch({
                    type: "removeQuizFromTeam"
                  })
                }}
              >
                УДАЛИТЬ
              </button>
              <button
                className="third-btn"
                onClick={() => quizContext.dispatch({
                  type: "notAddQuiz"
                })}
              >
                НЕТ
              </button>
            </>
          }

          <p
            style={{
              fontWeight: "600",
              marginTop: "20px"
            }}
          >
            Удалённые квизы:
          </p>
          {quizContext.state.teamQuizTitlesToRemove.filter((el, i) =>
            quizContext.state.teamQuizTitlesToRemove.indexOf(el) === i).map((el, i) =>
              <p key={i} style={{ marginLeft: "15px" }}>{el}</p>
            )}
        </div>
      </Card.Body>
    </Form>
  )
}

export default FormTeamRemoveYourQuiz