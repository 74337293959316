// ModalAddRemoveColumn

const addColumn = (state) => {
  const num = state.columnNum + 1;
  const headers = [...state.headers];
  headers.splice(num, 0, "");

  const values = state.values;
  values.map((el) => {
    if (el.length + 1 < headers.length)
      el.splice(num - 1, 0, "");
    return el
  });

  return {
    ...state,
    alertTable: false,
    alertStatus: null,
    headers,
    values
  }
}

export default addColumn