import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import { QuizContext } from "../../App";

const FormTeamGetName = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Form style={{ textAlign: "center" }}>
      <Form.Label>
        Give your team a name
      </Form.Label>
      <Form.Control
        type="text"
        data-bs-theme={theme}
        style={{
          border: theme === "dark"
            ? "1px solid #77797c"
            : "1px solid #212529",
          textAlign: "center"
        }}
        placeholder="Team Name"
        onChange={(e) => quizContext.dispatch({
          type: "getTeamName",
          payload: e.target.value
        })}
      />
    </Form>
  )
}

export default FormTeamGetName