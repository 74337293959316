// ButtonsSubmitQuiz

const submitQuiz = (state) => {

  return {
    ...state,
    quiz: {},
    quizStatus: "adjust",
    error: null,
    alert: true,
    video: false,
    alertStatus: "unsaved quiz created",
    errorMySQL: false
  }
}

export default submitQuiz