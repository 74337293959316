// FormAddQuizToTeam

const selectQuizForTeam = (state, action) => {

  if (action.payload !== "- Select Quiz -") {
    const teamQuizTitle = action.payload;
    const currentQuiz = state.addQuizzes.filter(el => el.title === teamQuizTitle);
    const teamQuizId = currentQuiz[0].id

    return {
      ...state,
      teamQuizTitle,
      currentQuiz,
      teamQuizId,
      addQuizStatus: "add",
      error: null,
    }
  } else {
    return state
  }
}

export default selectQuizForTeam