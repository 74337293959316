import React, { useContext } from "react";
import { QuizContext } from "../../App";
import submitEditedQuiz from "../../reducer/actions/quizActions/SubmitEditedQuiz";
import ButtonCancelAndGoToPrevPage from "./ButtonCancelAndGoToPrevPage";


const ButtonsSubmitEditedQuiz = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const lackOfData = ((quizContext.state.oldQuizType === "PRIVATE" && quizContext.state.quizType === "PUBLIC") && (quizContext.state.catId === null || quizContext.state.langId === null))
    ? true
    : false

  const noChanges = quizContext.state.quiz.title === quizContext.state.quizTitle
    && quizContext.state.quiz.type === quizContext.state.quizType
    && (quizContext.state.quiz.desc_author === quizContext.state.quizDescAuthor || !quizContext.state.quizDescAuthor.length)
    && quizContext.state.quiz.cat_id === quizContext.state.catId
    && quizContext.state.quiz.lang_id === quizContext.state.langId
    ? true
    : false


  return (
    <div>
      {lackOfData === true
          || noChanges === true
          ? <button className={`${theme}-main-btn-inactive`}>
            <span style={{ cursor: "crosshair" }}>
              SUBMIT
            </span>
          </button>
          : <button
            className={`${theme}-main-btn`}
            onClick={(e) => {
              e.preventDefault()
              submitEditedQuiz(
                quizContext.dispatch,
                quizContext.state
              )
              quizContext.dispatch({
                type: "submitEditedQuiz"
              })
            }}
          >
            SUBMIT
          </button>
      }
      <ButtonCancelAndGoToPrevPage />

    </div>
  )
}

export default ButtonsSubmitEditedQuiz