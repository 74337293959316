import React, { useContext } from "react";
import { QuizContext } from "../../App";
import ButtonModalFailedTasks from "../buttons/ButtonModalFailedTasks";

const TextSolvedTasks = () => {

  const quizContext = useContext(QuizContext);

  const score = quizContext.state.averageScore === 11
    || quizContext.state.averageScore === 12
    || quizContext.state.averageScore === 13
    || quizContext.state.averageScore === 14
    ? quizContext.state.averageScore
    : Number(quizContext.state.averageScore.toString().split("").pop());

  const word = score === 1
    ? "задание"
    : score === 2 || score === 3 || score === 4
      ? "задания"
      : "заданий";

  return (
    <>
      <span>
        Вы выполнили {quizContext.state.averageScore} {word}<br /> из {quizContext.state.numOfTasks} <br />с первого раза
      </span>
      {quizContext.state.averageScore !== quizContext.state.numOfTasks
        && <div style={{ marginTop: "15px" }}>
          <ButtonModalFailedTasks />
        </div>
      }
      <hr />
      <h3 style={{ fontWeight: "600" }}>
        {quizContext.state.averageScore / quizContext.state.taskNumber === 1
          ? "ВЕЛИКОЛЕПНО!!!"
          : quizContext.state.averageScore / quizContext.state.taskNumber < 1
            && quizContext.state.averageScore / quizContext.state.taskNumber >= 0.8
            ? "ОЧЕНЬ ХОРОШО!"
            : quizContext.state.averageScore / quizContext.state.taskNumber < 0.8
              && quizContext.state.averageScore / quizContext.state.taskNumber >= 0.6
              ? "НЕПЛОХО"
              : "ЕСТЬ НАД ЧЕМ ПОРАБОТАТЬ"
        }
      </h3>
      <hr />
    </>
  )
}

export default TextSolvedTasks