import React, { useContext } from "react";
import { QuizContext } from "../../App";

const ButtonCancelAndStay = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <button
      className={`${theme}-sec-btn`}
      onClick={() => quizContext.dispatch({
        type: "backToMainPage"
      })}
    >
      ОТМЕНИТЬ
    </button>
  )
}

export default ButtonCancelAndStay