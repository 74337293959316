import React, { useContext } from "react";
import { QuizContext } from "../App";
import TextQuizHeader from "./text/TextQuizHeader";
import {
  TaskNum,
  ButtonNextTask,
  ButtonResult
} from "./buttons/ButtonsTaskResult";
import ButtonsResizeQuizFont from "./buttons/ButtonsResizeQuizFont ";
import ModalStopQuiz from "./modals/ModalStopQuiz";

const QuizRun = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  const qFSize = quizContext.state.questionFontSize;
  const aFSize = quizContext.state.answerFontSize;

  return (
    <div style={{ paddingBottom: "200px" }}>
      <TextQuizHeader />

      <div>
        {quizContext.state.taskStatus === "taskNumber" ?
          <TaskNum />
          : quizContext.state.taskStatus === "nextTask" ?
            <ButtonNextTask />
            : quizContext.state.taskStatus === "quizResult" ?
              <ButtonResult />
              : null}
      </div>

      <div
        className={`${theme}-btn-question`}
        style={{ fontSize: `${qFSize}.2rem` }}
      >
        {quizContext.state.renderQuestion}
      </div>

      {quizContext.state.renderAnswers.map((el, i) => (
        quizContext.state.btnInact
          ? <button
            key={el.id}
            className={`${theme}-btn-answer ${el.isGivenAnswer}`}
            style={{ fontSize: `${aFSize}.2rem` }}
          >
            {el.value}
          </button>
          : <button
            key={el.id}
            className={`${theme}-btn-answer ${el.isGivenAnswer}`}
            style={{ fontSize: `${aFSize}.2rem` }}
            onClick={() => {
              return quizContext.dispatch({
                type: "gues",
                i,
                id: el.id,
                value: el.value
              })
            }}
          >
            {el.value}
          </button>
      ))}

      <ButtonsResizeQuizFont />

      {quizContext.state.alertStopQuiz
        && <ModalStopQuiz />}

    </div>
  )
}

export default QuizRun