// Library

const openCatQuizzes = (state, action) => {

  const catName = action.el.catname;
  const catId = action.el.id;
  const catQuizzes = state.publicQuizzes.filter(e => e.catname === catName);

  return {
    ...state,
    loading: true,
    catQuizzes,
    catName,
    catId,
  }

}

export default openCatQuizzes