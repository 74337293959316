import axios from "axios";

// AuthLogIn

const submitLogIn = async (dispatch, state) => {

  let error = state.error;
  let currentUser = state.currentUser;

  console.log("submitLogIn error 1:", error);

  const inputs = {
    email: state.email,
    password: state.password,
  };

  try {
    const res = await axios.post("/auth/login", inputs);
    currentUser = res.data;
  } catch (err) {
    error = err.response.data.message;
    console.log("submitLogIn err:", error);
  };


  localStorage.setItem("user", JSON.stringify(currentUser));


  const errorMySQL = error === "User not found"
    || error === "Wrong email or password"
    ? false
    : true;

    console.log("submitLogIn error 2:", error);

  return dispatch({
    type: "submitLogIn",
    currentUser,
    errorMySQL,
    error
  });

};

export default submitLogIn