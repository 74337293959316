import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { QuizContext } from "../../App";
import removeMemberFromTeam from "../../reducer/actions/groupActions/RemoveMemberFromTeam";
import ButtonCancelAndHideAlert from "../buttons/ButtonCancelAndHideAlert";

const ModalForDeclineTeamInv = () => {
  
  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Modal
      data-bs-theme={theme}
      show={quizContext.state.alert}
      onHide={() => quizContext.dispatch({
        type: "hideAlert"
      })}
      centered
    >
      <Modal.Header closeButton data-bs-theme={theme}
        className={`${theme}-modal-header`}
      >
        <Modal.Title>
          <span
            style={{
              color: "red",
              fontWeight: "700"
            }}
          >
            ВНИМАНИЕ!
          </span></Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={`${theme}-modal-body`}
        style={{ alignItems: "center" }}
      >
        Вы собираетесь отклонить приглашение в команду <span style={{ fontWeight: "600" }}>{quizContext.state.teamName}</span><br />
        Вы уверены?
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <ButtonCancelAndHideAlert />

          <button
            className={`${theme}-sec-btn`}
            onClick={(e) => {
              e.preventDefault()
              removeMemberFromTeam(
                quizContext.dispatch,
                quizContext.state
              )
              quizContext.dispatch({
                type: "removeMemberFromTeam"
              })
            }}
          >
            ОТКЛОНИТЬ ПРИГЛАШЕНИЕ
          </button>
        </div>

      </Modal.Body>
    </Modal>
  )
}

export default ModalForDeclineTeamInv