import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Image from 'react-bootstrap/Image';
import { QuizContext } from "../App";


const NavBar = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  const currPageURL = window.location.href;
  // const idQ = quizContext.state.quiz.id;
  const idU = quizContext.state.currentUser !== null
    ? quizContext.state.currentUser.id
    : null;
  const homeDev = "https://dev.quizzes.ekatrin.info/";
  const home = "https://quizzes.ekatrin.info/";
  // const quizDev = `https://dev.quizzes.ekatrin.info/quiz/${idQ}`;
  // const quiz = `https://quizzes.ekatrin.info/quiz/${idQ}`;
  const userDev = `https://dev.quizzes.ekatrin.info/user/${idU}`;
  const user = `https://quizzes.ekatrin.info/user/${idU}`;
  const loginDev = "https://dev.quizzes.ekatrin.info/login";
  const login = "https://quizzes.ekatrin.info/login";
  const creQuizDev = "https://dev.quizzes.ekatrin.info/create_quiz";
  const creQuiz = "https://quizzes.ekatrin.info/create_quiz";
  const libraryDev = "https://dev.quizzes.ekatrin.info/quiz_library";
  const library = "https://quizzes.ekatrin.info/quiz_library";
  const helpDev = "https://dev.quizzes.ekatrin.info/help";
  const help = "https://quizzes.ekatrin.info/help";


  return (
    <Navbar
      className="justify-content-center"
      bg="dark"
    >

      <Navbar.Brand>
        <NavLink
          to={!quizContext.state.currentUser
            ? "/"
            : `/user/${idU}`
          }
          style={{
            color: "white",
            textDecoration: "none"

          }}
        >
          Quizzes
        </NavLink>
      </Navbar.Brand>

      <Nav>
        {currPageURL === homeDev || currPageURL === home
          ? <NavLink className="nav-btn-act">
            <Image fluid
              src={theme === "dark"
                ? require('./images/home.png')
                : require('./images/homeNav.png')
              }
              alt="Home"
            />
          </NavLink>
          : <NavLink
            to="/"
            className="nav-btn"
          >
            <Image fluid
              src={theme === "dark"
                ? require('./images/home.png')
                : require('./images/homeNav.png')
              }
              alt="Home"
              onClick={() => quizContext.dispatch({
                type: "backToMainPage"
              })}
            />
          </NavLink>
        }

        {currPageURL === loginDev
          || currPageURL === login
          ? <NavLink className="nav-btn-act">
            <Image fluid
              src={theme === "dark"
                ? require('./images/userOut.png')
                : require('./images/userOutNav.png')
              }
              alt="User"
            />
          </NavLink>

          : (currPageURL === userDev
            || currPageURL === user)
            && !quizContext.state.errorAuth
            ? <NavLink className="nav-btn-act">
              <Image fluid
                src={theme === "dark"
                  ? require('./images/userIn.png')
                  : require('./images/userInNav.png')
                }
                alt="User"
              />
            </NavLink>

            : (currPageURL === userDev
              || currPageURL === user)
              && quizContext.state.errorAuth
              ? <NavLink className="nav-btn-act">
                <Image fluid
                  src={theme === "dark"
                    ? require('./images/userErr.png')
                    : require('./images/userErrNav.png')
                  }
                  alt="User"
                />
              </NavLink>

              : !quizContext.state.currentUser
                && (currPageURL !== loginDev && currPageURL !== login)
                ? <NavLink
                  to="/login"
                  className="nav-btn">
                  <Image fluid
                    src={theme === "dark"
                      ? require('./images/userOut.png')
                      : require('./images/userOutNav.png')
                    }
                    alt="User"
                    onClick={() => quizContext.dispatch({
                      type: "backToMainPage"
                    })}
                  />
                </NavLink>

                : quizContext.state.currentUser
                  && (currPageURL !== loginDev && currPageURL !== login)
                  && quizContext.state.errorAuth
                  ? <NavLink
                    to="/login"
                    className="nav-btn">
                    <Image fluid
                      src={theme === "dark"
                        ? require('./images/userErr.png')
                        : require('./images/userErrNav.png')
                      }
                      alt="User"
                      onClick={() => quizContext.dispatch({
                        type: "switchToLogin"
                      })}
                    />
                  </NavLink>
                  : quizContext.state.currentUser
                    && (currPageURL !== loginDev && currPageURL !== login)
                    && quizContext.state.errorMySQL
                    ? <NavLink
                      to="/create_quiz"
                      className="nav-btn">
                      <Image fluid
                        src={theme === "dark"
                          ? require('./images/userErr.png')
                          : require('./images/userErrNav.png')
                        }
                        alt="User"
                        onClick={() => quizContext.dispatch({
                          type: "backToMainPage"
                        })}
                      />
                    </NavLink>
                    : <NavLink
                      to={`/user/${idU}`}
                      className="nav-btn"
                    >
                      <Image fluid
                        src={theme === "dark"
                          ? require('./images/userIn.png')
                          : require('./images/userInNav.png')
                        }
                        alt="User"
                        onClick={() => quizContext.dispatch({
                          type: "backToMainPage"
                        })}
                      />
                    </NavLink>
        }

        {currPageURL === creQuizDev || currPageURL === creQuiz
          ? <NavLink className="nav-btn-act">
            <Image fluid
              src={theme === "dark"
                ? require('./images/create.png')
                : require('./images/createNav.png')
              }
              alt="Create Quiz"
            />
          </NavLink>
          : <NavLink
            to="/create_quiz"
            className="nav-btn"
          >
            <Image fluid
              src={theme === "dark"
                ? require('./images/create.png')
                : require('./images/createNav.png')
              }
              alt="Create Quiz"
              onClick={() => quizContext.dispatch({
                type: "backToMainPage"
              })}
            />
          </NavLink>
        }

        {currPageURL === libraryDev || currPageURL === library
          ? <NavLink className="nav-btn-act">
            <Image fluid
              src={theme === "dark"
                ? require('./images/library.png')
                : require('./images/libraryNav.png')
              }
              alt="Quiz Library"
            />
          </NavLink>
          : <NavLink
            to="/quiz_library"
            className="nav-btn"
          >
            <Image fluid
              src={theme === "dark"
                ? require('./images/library.png')
                : require('./images/libraryNav.png')
              }
              alt="Quiz Library"
              onClick={() => quizContext.dispatch({
                type: "backToMainPage"
              })}
            />
          </NavLink>
        }

        {currPageURL === helpDev || currPageURL === help
          ? <NavLink className="nav-btn-act">
            <Image fluid
              src={theme === "dark"
                ? require('./images/help.png')
                : require('./images/helpNav.png')
              }
              alt="FAQ"
            />
          </NavLink>
          : <NavLink
            to="/help"
            className="nav-btn"
          >
            <Image fluid
              src={theme === "dark"
                ? require('./images/help.png')
                : require('./images/helpNav.png')
              }
              alt="FAQ"
              onClick={() => quizContext.dispatch({
                type: "backToMainPage"
              })}
            />
          </NavLink>
        }
      </Nav>

      <div style={{ width: "2.5em", marginLeft: "0.7em" }}>
        <Image fluid
          src={theme === "dark"
            ? require('./images/themeDark.png')
            : require('./images/themeLight.png')
          }
          alt="Light/Dark"
          onClick={() => quizContext.dispatch({
            type: "toggleTheme"
          })}
        />
      </div>

      {quizContext.state.isRussia
        ? <div style={{ width: "1em", marginLeft: "1em" }}>
          <Image fluid
            src={quizContext.state.websiteLang === "ru"
              ? require('./images/langRU.png')
              : require('./images/langEN.png')
            }
            alt="language"
            onClick={() => quizContext.dispatch({
              type: "toggleLang"
            })}
          />
        </div>
        : null
      }

    </Navbar>
  )
}

export default NavBar