import React, { useContext } from "react";
import Accordion from "react-bootstrap/Accordion";
import TextRequirementsXLSX from "../text/TextRequirementsXLSX";
import TextRequirementsTable from "../text/TextRequirementsTable";
import { QuizContext } from "../../App";
import TextHowToEditQuiz from "../text/TextHowToEditQuiz";

const AccordionFAQquizzes = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Accordion
      data-bs-theme={theme}
      className="card-faq"
    >

      <Accordion.Item eventKey="0">
        <Accordion.Header style={{ color: "black" }}>
          How to create a quiz?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>Open CREATE QUIZ page.</p>

          <p><img src={require('../images/instruction-uploadFile-1.jpg')} style={{ width: "100%", }} alt={'instr'} /></p>

          <p>You have two options:</p>

          <ol>
            <li>To create a quiz from an xlsx file</li>
            <p><img src={require('../images/instruction-create-from-xlsx.jpg')} style={{ width: "100%", }} alt={'instr'} /></p>
            <li>To create a quiz from the table on the website.</li>
            <p><img src={require('../images/instruction-create-from-table.jpg')} style={{ width: "100%", }} alt={'instr'} /></p>
          </ol>

          <p>If you are not authorized, you’ll be able to use the quiz you’ve created, but won’t be able to save it.</p>
          <p>If you’re authorized, you have a choice to save your quiz or take it without saving.</p>
          <p>If you save your quiz, it gets an automatically generated description, which you can change later on the EDIT QUIZ page.</p>
          <p>If you save your quiz, it’ll be saved as PRIVATE by default. It means that only you can see it and take it. Later you can change the quiz type to PUBLIC or TEAM.</p>
          <p>A PUBLIC quiz is available in the QUIZ LIBRARY to all authorized and unauthorized users.</p>
          <p>A TEAM quiz is available only to the members of the team you’ve added it to.</p>
          <p>One quiz can be either PRIVATE, or PUBLIC, or TEAM. You can add one quiz to one team only.</p>
          <p>You can change the quiz type of any of your PUBLIC and TEAM quizzes to PRIVATE anytime and vice versa.</p>
          <p>To change quiz status from PUBLIC to TEAM or from TEAM to PUBLIC, you have to change it to PRIVATE first.</p>

        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1">
        <Accordion.Header>
          How to prepare an xlsx file for a quiz?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <TextRequirementsXLSX />
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header>
          How to fill in the table for a quiz on the website?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <TextRequirementsTable />
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3">
        <Accordion.Header>
          How to edit a quiz?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <TextHowToEditQuiz />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default AccordionFAQquizzes