import axios from 'axios';

// ModalFavQuizzes

const removeFavQuiz = async (dispatch, state, el) => {
  let error = state.error
  const favQuizId = el;
 
  try {
    await axios.delete(`/quiz/removefavquiz/${favQuizId}`);
  } catch (err) {
    error = err.response.data.message;
    console.log("removeFavQuiz err", error);
  }
  
  return dispatch({
    type: "removeFavQuiz",
    error,
    favQuizId
  });
}

export default removeFavQuiz