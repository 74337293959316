import axios from "axios";

const submitEditedQuizContents = async (dispatch, state) => {

  let error = state.error;

  const forDescAuto = [...state.quizOptionsEdit];
  forDescAuto.shift();

  const quizOptionsToUpdate = state.quizOptionsToUpdate;
  const quizOptionsToInsert = state.quizOptionsToInsert;
  const quizOptionsToDelete = state.quizOptionsToDelete;
  const quizValuesToUpdate = state.quizValuesToUpdate;
  const quizColumnsToInsert = state.quizColumnsToInsert;
  const quizRowsToInsert = state.quizRowsToInsert;
  const quizColRowsToInsert = state.quizColRowsToInsert;
  const quizRowsToDelete = state.quizRowsToDelete;


  try {
    await axios.post(`/quiz/updatequizcontents/${state.quizId}`, {
      quizOptionsToUpdate,
      quizOptionsToInsert,
      quizOptionsToDelete,
      quizValuesToUpdate,
      quizColumnsToInsert,
      quizRowsToInsert,
      quizColRowsToInsert,
      quizRowsToDelete
    })
  } catch (err) {
    error = err.response.data.message;
    console.log("submitEditedQuizContents err:", error);
  }


  return dispatch({
    type: "submitEditedQuizContents",
    error
  });
}

export default submitEditedQuizContents