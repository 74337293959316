// FormEmail FormPassword

export const getEmail = (state, action) => {
  const email = action.payload;

  if ((state.auth === "login"
    && email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
    && state.password)
    || (state.auth === "signup"
      && state.username
      && email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
      && state.password)) {
    return {
      ...state,
      email,
      formFilled: true,
      errorSignUp: null,
      errorLogIn: null,
      enterData: false,
      wrongData: false,
      checkUser: false,
      errorNewPassword: null
    };
  } else {
    return {
      ...state,
      email,
      formFilled: false,
      errorSignUp: null,
      errorLogIn: null,
      enterData: false,
      wrongData: false,
      checkUser: false
    };
  }
}

export const getPassword = (state, action) => {
  const password = action.payload;

  if ((state.auth === "login"
    && state.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
    && password)
    || (state.auth === "signup"
      && state.username
      && state.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
      && password)) {
    return {
      ...state,
      password,
      formFilled: true,
      errorSignUp: null,
      errorLogIn: null,
      enterData: false,
      wrongData: false,
      checkUser: false
    };
  } else {
    return {
      ...state,
      password,
      formFilled: false,
      errorSignUp: null,
      errorLogIn: null,
      enterData: false,
      wrongData: false,
      checkUser: false
    };
  }
}

// FormUsername

export const getUsername = (state, action) => {
  const username = action.payload;

  if (state.auth === "signup"
    && username
    && state.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
    && state.password) {
    return {
      ...state,
      username,
      formFilled: true,
      errorSignUp: null,
      errorLogIn: null,
      enterData: false,
      wrongData: false,
      checkUser: false
    };
  } else {
    return {
      ...state,
      username,
      formFilled: false,
      errorSignUp: null,
      errorLogIn: null,
      enterData: false,
      wrongData: false,
      checkUser: false
    };

  }

}