import axios from "axios";

// AuthSignUp

const submitSignUp = async (dispatch, state) => {

  let error = state.errorSignUp;

  // if (state.sentOTP === state.typedOTP) {

  const inputs = {
    username: state.username,
    email: state.email,
    password: state.password,
    score: state.userScore,
    mem: state.userMem,
    payment: state.userPayment,
    num_pub_quizzes: state.userNumPubQuizzes,
    num_teams: state.userNumTeams
  };

  try {
    await axios.post("/auth/signup", inputs);
  } catch (err) {
    error = err.response.data.message;
    console.log("submitSignUp err:", error);
  }

  const errorMySQL = error === "Data is not correct"
    || error === "User already exists"
    ? false
    : true;

  return dispatch({
    type: "submitSignUp",
    errorMySQL,
    error
  })
  // } else {
  //   return dispatch({
  //     type: "submitSignUp",
  //     error
  //   })
  // }
}

export default submitSignUp