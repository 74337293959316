import axios from "axios";

// FormTeamInviteUsers

const searchUser = async (dispatch, state) => {
  const invUserId = state.invUserId;
  let error = state.error;
  let invUser = state.invUser;

  if (invUserId !== null) {

    const inputs = {
      id: invUserId,
    };

    try {
      const res = await axios.post("/team/finduser", inputs);
      invUser = res.data;
    } catch (err) {
      error = err.response.data.message;
      console.log("searchUser err:", error);
    };

    if (invUser.length) {
      return dispatch({
        type: "searchUser",
        invUser: invUser[0],
        error
      });
    } else {
      return dispatch({
        type: "searchUser",
        invUser: {id: "not found", username: "not found"},
        error
      })
    }
  } else {
    return dispatch({
      type: "searchUser",
      invUser: null,
      error
    });
  }

}

export default searchUser