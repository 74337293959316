import React from "react";
import TableMembershipPlans from "../tables/TableMembershipPlans";
import ButtonMyPage from "../buttons/ButtonMyPage";
import TabelEarnLosePoints from "../tables/TabelEarnLosePoints";

const TextBanTeamStart = () => {

  return (
    <div>
      <div style={{ textAlign: "center", marginTop: "30px" }}>
        <h3 style={{ fontWeight: "600" }}>Для того чтобы создать команду,<br /> повысьте свой статус пользователя.</h3>
        <p>
          Чтобы сделать это,<br />
          добавляйте квизы в БИБЛИОТЕКУ КВИЗОВ<br />
          и зарабатывайте очки рейтинга автора квизов.</p>
      </div>

      <TableMembershipPlans />
      <TabelEarnLosePoints />

      <div className="col-md-12 text-center" style={{ marginTop: "35px" }}>
        <ButtonMyPage />
      </div>
    </div>
  )
}

export default TextBanTeamStart