import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import { QuizContext } from "../../App";
import { Accordion } from "react-bootstrap";

const AccordionTeamQuizEdit = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (

    <Accordion data-bs-theme={theme}
      style={{
        marginTop: "10px",
        marginBottom: "30px",
      }}
    >
      <Accordion.Item eventKey="0">
        <Accordion.Header>Choose quiz to edit</Accordion.Header>
        <Accordion.Body>
          <Table
            striped hover
            variant={theme}
            style={{
              width: "100%",
              overflowX: "hidden",
              maxHeight: "300px"
            }}
          >
            <tbody>
              {quizContext.state.teamQuizzes.filter((el, i) =>
                el.author_id === quizContext.state.currentUser.id
              ).map((el, i) =>
                <tr key={i}>
                  <th>
                    <Link
                      to={`/edit_quiz/${el.id}`}
                      style={{
                        textDecoration: "none",
                        color: theme === "dark"
                          ? "#4da2f7"
                          : "#0a70d6",
                        fontWeight: "500"
                      }}
                    >
                      {el.title}
                    </Link>
                  </th>
                </tr>
              )}
            </tbody>
          </Table>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default AccordionTeamQuizEdit