// FormEditTeamName

const editTeamName = (state, action) => {

const teamName = action.payload;
const teamId = state.team.id;

  return {
  ...state,
  teamName,
  teamId
  }
}

export default editTeamName