// FormEditQuizLang

const editQuizLang = (state, action) => {

  const language = action.payload;

  if (language !== "- Select language -") {
    const currentLangEN = state.lang.filter(el => el.lang === language);
    const currentLangRU = state.lang.filter(el => el.lang_ru === language);
    const currentLang = currentLangEN.concat(currentLangRU)
    const langId = currentLang[0].id;

    return {
      ...state,
      errorMessage: false,
      alertStatus: "editing",
      langId,
      language
    };

  } else {
    return {
      ...state,
      errorMessage: false
    }
  }
}

export default editQuizLang