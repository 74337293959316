import React, { useContext } from "react";
import { Image } from "react-bootstrap";
import { QuizContext } from "../../App";


const TextAndImgForVideoHomePage = () => {

  const quizContext = useContext(QuizContext);

  return (
    <div style={{ margin: "0.8em" }}>
      <h3 className="video-h3" style={{textAlign: "left"}}>
        Turn any data into a quiz<br /> 
        and memorize huge amounts of information<br />
        in a gamelike way.
      </h3>
      <table><tbody><tr>
        <td style={{ width: "25%", padding: "0.15em" }}>
          <Image fluid
            src={require('../videos/forVideoHomePageSpanish.jpg')}
            width="100%"
            height="auto"
            className="img-video-home"
            alt="Quiz. Spanish"
          />
        </td>
        <td style={{ width: "25%", padding: "0.15em" }}>
          <Image fluid
            src={require('../videos/forVideoHomePageCountries.jpg')}
            width="100%"
            height="auto"
            className="img-video-home"
            alt="Quiz. Countries"
          />

        </td>
        <td style={{ width: "25%", padding: "0.15em" }}>
          <Image fluid
            src={require('../videos/forVideoHomePageKeys.jpg')}
            width="100%"
            height="auto"
            className="img-video-home"
            alt="Quiz. Hot Keys"
          />

        </td>
        <td style={{ width: "25%", padding: "0.15em" }}>
          <Image fluid
            src={require('../videos/forVideoHomePagePlanets.jpg')}
            width="100%"
            height="auto"
            className="img-video-home"
            alt="Quiz. Planets"
          />

        </td>
      </tr></tbody></table>

      <div style={{ marginTop: "0.8em" }}>
        <button
          className="light-main-btn"
          onClick={() => quizContext.dispatch({
            type: "switchToVideo"
          })}
        >
          VIDEO
        </button>
      </div>

    </div>
  )
}

export default TextAndImgForVideoHomePage