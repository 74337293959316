// FormEditUsername

const editUserName = (state, action) => {
  
  const username = action.payload;

  return {
    ...state,
    username
  };
}

export default editUserName