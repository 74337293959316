import React, { useContext } from "react";
import { QuizContext } from "../../App";
import submitEditedTeamSettings from "../../reducer/actions/groupActions/SubmitEditedTeamSettings";

const ButtonSubmitEditedTeamSettings = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <>
      {quizContext.state.team.teamname === quizContext.state.teamName
        && quizContext.state.team.type_add_quiz === quizContext.state.typeAddQuiz
        && quizContext.state.team.type_add_memb === quizContext.state.typeAddMemb
        ? <button
          className={`${theme}-main-btn-inactive`}
        >
          <span style={{ cursor: "crosshair" }}>
            SUBMIT
          </span>
        </button>
        : <button
          className={`${theme}-main-btn`}
          onClick={(e) => {
            e.preventDefault()
            submitEditedTeamSettings(
              quizContext.dispatch,
              quizContext.state
            )
            quizContext.dispatch({
              type: "submitEditedTeamSettings"
            })
          }}
        >
          SUBMIT
        </button>
      }
      <button
      className={`${theme}-sec-btn`}
      onClick={() => quizContext.dispatch({
        type: "cancelEditTeamSettings"
      })}
    >
      CANCEL
    </button>
    </>
  )
}

export default ButtonSubmitEditedTeamSettings