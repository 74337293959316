import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { QuizContext } from "../../App";
// import deleteQuiz from "../../reducer/actions/quizActions/DeleteQuiz";
// import { Link } from "react-router-dom";
import ButtonLogOut from "../buttons/ButtonLogOut";
import ButtonCancelAndStay from "../buttons/ButtonCancelAndStay";

const ModalLogOut = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Modal
      size="sm"
      data-bs-theme={theme}
      show={quizContext.state.alert}
      onHide={() => quizContext.dispatch({
        type: "hideAlert"
      })}
    >
      <Modal.Header closeButton data-bs-theme={theme}
        className={`${theme}-modal-header`}
      >
        Вы собираетесь выйти из своего аккаунта.<br />
        Вы уверены?
      </Modal.Header>
      <Modal.Body
        className={`${theme}-modal-body`}
      >
        <div style={{
          textAlign: "center",
          marginTop: "10px"
        }}>
          <ButtonLogOut />
          <ButtonCancelAndStay />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ModalLogOut