import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import getQuizFile from "../../reducer/actions/quizActions/getQuizDataActions/GetQuizFile";
import { QuizContext } from "../../App";


const FormQuizFile = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Form>
      <Form.Label>
        <span style={{ fontWeight: "600" }}>
          ШАГ 2:</span> загрузите xlsx файл, который Вы подготовили
      </Form.Label>
      <Form.Control
        type="file"
        data-bs-theme={theme}
        style={{
          border: theme === "dark"
            ? "1px solid #77797c"
            : "1px solid #212529"
        }}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        onChange={(e) => getQuizFile(
          e.target.files[0],
          quizContext.dispatch,
          quizContext.state
        )}
      />
    </Form >
  )
}

export default FormQuizFile
