import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { QuizContext } from "../../App";
import { Card } from "react-bootstrap";

const CardNavLogInSignUp = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;


  return (
    <>
      <Link to="/login" style={{ textDecoration: 'none' }}>
      <Card
          data-bs-theme={theme}
          className="card-home"
        >
          <img
            src={theme === "dark"
              ? require('../images/user.png')
              : require('../images/user1.png')
            }
            className="card-icon"
            alt={'User_icon'}
          />
          <p className="card-text">
            <button
              className={`${theme}-card-btn`}
              onClick={() => quizContext.dispatch({ 
                type: "switchToLogin" 
              })}
            >
              LOG IN
            </button>
            <button
              className={`${theme}-card-btn`}
              onClick={() => quizContext.dispatch({ 
                type: "switchToSignup" 
              })}
            >
              SIGN UP
            </button>
          </p>

        </Card>
      </Link>
    </>
  )
}

export default CardNavLogInSignUp