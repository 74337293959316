import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { QuizContext } from "../../App";

const ModalInstruction = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Modal
      size="sm"
      data-bs-theme={theme}
      show={quizContext.state.alert}
      onHide={() => quizContext.dispatch({
        type: "hideAlert"
      })}
      centered
    >
      <Modal.Header closeButton
        data-bs-theme={theme}
        className={`${theme}-modal-header`}
      >
        INSTRUCTION
      </Modal.Header>
      <Modal.Body className={`${theme}-modal-body`}>
        <ol>
          <li>The quiz can have as many columns, as you want,<br/> but not fewer than 2.</li>
          <li>The value of each column header must be unique.</li>
          <li>The quiz can have as many rows, as you want,<br/> but not fewer than 5.</li>
          <li>Each cell of a column must contain unique value.</li>
          <li>The columns must be of the same length.</li>
        </ol>

        <div style={{ textAlign: "center", marginTop: "5px" }}>
          <button
            className={`${theme}-main-btn`}
            onClick={() => quizContext.dispatch({
              type: "hideAlert"
            })}
          >
            GOT IT
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ModalInstruction