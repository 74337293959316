import React from "react";

const TextRequirementsTable = () => {

  return (
    <>

      <p>You can fill in the table right here or in the full-screen mode.</p>
      <p>
        <img
          src={require('../images/instruction-create-from-table-FS.jpg')}
          style={{ width: "100%" }}
          alt={'instr'}
        />
      </p>

      <p>Fill in column headers.</p>
      <p>
        <img
          src={require('../images/instruction-create-from-table-headers.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>

      <p>Fill in values of the columns.</p>
      <p>
        <img
          src={require('../images/instruction-create-from-table-values.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>

      <p>To add a row, click on the row number after which you want to add it. You can add as many rows as you want.</p>
      <p>
        <img
          src={require('../images/instruction-create-from-table-addRow.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>

      <p>To add a column, click on the column number after which you want to add it. You can add as many columns as you want.</p>
      <p>
        <img
          src={require('../images/instruction-create-from-table-addColumn.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>

      <p>To remove a row, click on the row number which you want to remove. The quiz must have at least 5 rows. So, you're able to remove a row only when there are more than 5 of them.</p>
      <p>
        <img
          src={require('../images/instruction-create-from-table-removeRow.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>

      <p>To remove a column, click on the column number which you want to remove. The quiz must have at least 2 columns. So, you’re able to remove a column only when there are more than 2 of them.</p>
      <p>
        <img
          src={require('../images/instruction-create-from-table-removeColumn.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>


    </>
  )
}

export default TextRequirementsTable