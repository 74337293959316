import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import { QuizContext } from "../../App";

const FormEditQuizTitle = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Form.Control
      type="text"
      data-bs-theme={theme}
      style={{
        textAlign: 'center',
        border: theme === "dark"
          ? "1px solid #77797c"
          : "1px solid #212529"
      }}
      value={quizContext.state.quizTitle}
      onChange={(e) => quizContext.dispatch({
        type: "editQuizTitle",
        payload: e.target.value
      })}
    />
  )
}

export default FormEditQuizTitle