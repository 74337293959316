import React, { useContext } from "react";
import { QuizContext } from "../../App";
import Accordion from "react-bootstrap/Accordion";

const AccordionFAQlibrary = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const changeType = quizContext.state.quizPoints.changeType;

  return (
    <Accordion
      data-bs-theme={theme}
      className="card-faq"
    >

      <Accordion.Item eventKey="0">
        <Accordion.Header style={{ color: "black" }}>
          Как проходить квизы из БИБЛИОТЕКИ КВИЗОВ?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>Перейдите в БИБЛИОТЕКУ КВИЗОВ:</p>

          <p><img src={require('../images/instruction-QuizLibrary-1.jpg')} style={{ width: "100%", }} alt={'instr'} /></p>

          <p>Выберете категорию и нажмите. Выберете квиз и нажмите. Вы перейдете на титульную страницу квиза. Здесь Вы можете ознакомиться с описанием квиза, посмотреть, сколько раз квиз был пройден и сколько пользовталей рекомендуют и не рекомендуют этот квиз, а так же прочесть комментарии.</p>
          <p>Нажмете кнопку кнопку ОТКРЫТЬ КВИЗ</p>
          <p>Если Вы авторизированный пользователь, после того как Вы пройдёте квиз, Вы можете добавить его в ИЗБРАННЫЕ, и иметь ссылку быстрого доступа к квизу на своей странице в разделе ИЗБРАННЫЕ КВИЗЫ. Кроме того, Вы можете проголосовать за квиз, поставив отметку рекомендуете ли Вы этот квиз другим пользователям или нет, и написать комментарий.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1" >
        <Accordion.Header>
          Как опубликовать свой квиз в БИБЛИОТЕКЕ КВИЗОВ?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>Публикуя свой квиз в БИБЛИОТЕКЕ КВИЗОВ, Вы зарабатываете {changeType} очков рейтинга автора квизов.</p>
          <p>Профиль &#8594; МОИ КВИЗЫ &#8594; {theme === "dark"
            ? <img
              src={require('../images/editW.png')}
              style={{ width: "15px" }}
              alt={'edit'} />
            : <img
              src={require('../images/edit.png')}
              style={{ width: "15px" }}
              alt={'edit'} />} &#8594; Редактировать тип квиза: изменить ЛИЧНЫЙ на ОБЩЕДОСТУПНЫЙ &#8594; Выбрать категорию &#8594; Выбрать язык</p>
          <p>Если Вы выберете категорию "Языки", Вам нужно будет указать содержит ли Ваш квиз один язык (к примеру, слово и его толкование на том же язке) или несколько языков (к примеру, слово и его перевод на другой язык).</p>
          <p>Меняя тип квиза на ОБЩЕДОСТУПНЫЙ, подумайте о том, чтобы отредактировать название квиза и его описание, которое было сгенерировано автоматически, когда Вы создавали квиз и содержит только информацию о количестве опций и их названиях (заголовки столбцов в Вашем xlsx файле или в таблице, которую Вы заполнили на сайте).</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2" >
        <Accordion.Header>
          Как удалить квиз из БИБЛИОТЕКИ КВИЗОВ?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>Если Вы удалите квиз из БИБЛИОТЕКИ КВИЗОВ, Вы потеряете все очки, которые этот квиз Вам принёс. Кроме того, все рекомнедации и комментарии будут удалены без возможности восстановления в случае, если в будущем Вы снова опубликуете квиз в БИБЛИОТЕКЕ КВИЗОВ.</p>
          <p>Профиль &#8594; МОИ КВИЗЫ &#8594; {theme === "dark"
            ? <img
              src={require('../images/editW.png')}
              style={{ width: "15px" }}
              alt={'edit'} />
            : <img
              src={require('../images/edit.png')}
              style={{ width: "15px" }}
              alt={'edit'} />} &#8594; Редактировать тип квиза: изменить ОБЩЕДОСТУПНЫЙ на ЛИЧНЫЙ </p>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default AccordionFAQlibrary