// ButtonAnotherQuiz, ButtonsSubmitEditedQuiz, CardUserInvitations, CardUserMyTeams, CardUserMyQuizzes, CardUserSavedQuizzes, Library

const showModal = (state, action) => {

  const alertStatus = action.alertStatus;

  return {
    ...state,
    alert: true,
    alertStatus
  }
}

export default showModal