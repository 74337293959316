// FormRemoveTeamMember

const selectTeamMemberToRemove = (state, action) => {

  if (action.payload !== "- Select Member -") {
    let teamMemberName = action.payload;
    const teamMemberId = state.teamMembers.filter(el => el.username === teamMemberName)[0].user_id;
    const teamMemberType = state.teamMembers.filter(el => el.username === teamMemberName)[0].user_type;
    const teamUsersId = state.teamMembers.filter(el => el.username === teamMemberName)[0].id;

    teamMemberName = `${action.payload} (ID ${teamMemberId})`;
    
    return {
      ...state,
      error: null,
      teamMemberName,
      teamMemberId,
      teamMemberType,
      teamUsersId,
      removeTeamMemberStatus: "chosen"
    }
  } else {
    return state
  }
}

export default selectTeamMemberToRemove