// FormQuizTitle

const getQuizTitle = (state, action) => {

  const quizTitle = action.payload;

  // const reg = /header/

  const checkVaules = state.values.filter(el => el === "").length
    ? false
    : true;

    const checkHeaders = state.headers.filter((el) => el === "").length
    ? false
    : true;

  const checkQuizTitle = quizTitle === "" ? false : true;

  if (!checkQuizTitle || !checkHeaders || !checkVaules) {
    return {
      ...state,
      quizStatus: "initial",
      quizTitle,
      alertStatus: null,
      video: false,
      errorMessage: false
    };
  } else {
    return {
      ...state,
      quizStatus: "initial",
      quizTitle,
      alertStatus: "changes made",
      video: false,
      errorMessage: false
    };
  }
}

export default getQuizTitle