// FormTableCreateQuiz

const showModalAddRemoveRow = (state, action) => {
  
  const rowNum = action.num;

  return {
    ...state,
    alertTable: true,
    alertStatus: "row",
    video: false,
    rowNum
  }
}

export default showModalAddRemoveRow