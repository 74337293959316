import React, { useContext } from "react";
import { QuizContext } from "../../App";
import ButtonModalPrepareQuizFile from "../buttons/ButtonModalPrepareQuizFile";

const TextMistake = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <>
      {quizContext.state.errorMessage === "table mistake"
        ? <p
          style={{
            textAlign: "center",
            color: theme === "dark"
              ? "white"
              : "black"
          }}
        >
          <span
            style={{
              fontWeight: "600",
              color: "red",
              fontSize: "1.2rem"
            }}>
            ТАБЛИЦА СОДЕРЖИТЬ ОШИБКИ
          </span><br />
          {quizContext.state.mistake} <br />
          <span style={{color: "red"}}>Внимательно прочтите</span> <span
            style={{
              color: theme === "dark"
                ? "#4da2f7"
                : "#0a70d6",
              cursor: "pointer",
              fontWeight: "700",
              textDecoration: "underline"
            }}
            onClick={() => quizContext.dispatch({
              type: "showInstruction"
            })}
          >ИНСТРУКЦИЮ</span>.
        </p>
        : quizContext.state.errorMessage === "file mistake"
          ? <p
            style={{
              textAlign: "center",
              color: theme === "dark"
                ? "white"
                : "black"
            }}
          >
            <span
              style={{
                fontWeight: "600",
                color: "red"
              }}>Файл
              <span style={{ fontSize: "1.2rem" }}>
                {quizContext.state.fileName}
              </span>
              <br />
              содержит ОШИБКИ.
            </span><br />
            {quizContext.state.mistake} <br />
            <span style={{ color: "red" }}>Внимательно прочтите</span><br />
            <ButtonModalPrepareQuizFile />
          </p>
          : null
      }
    </>
  )
}

export default TextMistake