const descAuth = (desc) => {
  const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  const url = desc.match(urlRegex);

  if (url && url.length === 1) {
    const descArr = desc.split(" ");
    const urlInd = descArr.indexOf(url[0]);
    const ind = urlInd - 1
    const word = descArr[ind];

    descArr.splice(ind, 2, `<a href=" ${url[0]} " target="_blank" rel="noopener noreferrer">${word}</a>`);

    return descArr.join(" ");
  } else if (url && url.length === 2) {
    const descArr = desc.split(" ");

    const urlInd1 = descArr.indexOf(url[0]);
    const ind1 = urlInd1 - 1
    const word1 = descArr[ind1];

    descArr.splice(ind1, 2, `<a href=" ${url[0]} " target="_blank" rel="noopener noreferrer">${word1}</a>`);


    const urlInd2 = descArr.indexOf(url[1]);
    const ind2 = urlInd2 - 1
    const word2 = descArr[ind2];

    descArr.splice(ind2, 2, `<a href=" ${url[1]} " target="_blank" rel="noopener noreferrer">${word2}</a>`);

    return descArr.join(" ");

  } else {
    return desc
  }

}

export default descAuth