import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { QuizContext } from "../../App";


const ModalAddRemoveColumn = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <>
      {quizContext.state.quizStatus === "edit"
        ? <>
          <Modal
            size="sm"
            data-bs-theme={theme}
            show={quizContext.state.alertTable}
            onHide={() => quizContext.dispatch({
              type: "hideAlertEditTable"
            })}
          >
            <Modal.Header closeButton data-bs-theme={theme}
              className={`${theme}-modal-header`}
            />

            <Modal.Body className={`${theme}-modal-body`}>

              {quizContext.state.quizOptionsEdit.length > 3
                &&

                <div style={{ textAlign: "center" }}>
                  <p><span className="error">ВНИМАНИЕ!!!</span><br /> Вы не сможете восстановить удалённый столбец.</p>
                  <button
                    className="col-row-remove-btn"
                    onClick={() => quizContext.dispatch({ type: "removeColumnEdit" })}
                  >УДАЛИТЬ СТОЛБЕЦ {quizContext.state.columnNum} </button>
                </div>

              }

            </Modal.Body>
          </Modal>
        </>

        : <>
          <Modal
            show={quizContext.state.alertTable}
            onHide={() => quizContext.dispatch({
              type: "hideAlertEditTable"
            })}
          >
            <Modal.Header closeButton data-bs-theme={theme}
              className={`${theme}-modal-header`} />
            <Modal.Body className={`${theme}-modal-body`}>

              <div style={{ textAlign: "center", marginTop: "15px" }}>
                <button
                  className="col-row-add-btn"
                  onClick={() => quizContext.dispatch({ type: "addColumn" })}
                >ДОБАВИТЬ СТОЛБЕЦ</button>
                <p style={{ fontSize: "1.2rem", marginTop: "15px" }}>после столбца {quizContext.state.columnNum}</p>
              </div>
              {quizContext.state.headers.length > 3
                ? <div style={{ textAlign: "center" }}>
                  <hr />
                  <p><span className="error">ВНИМАНИЕ!!!</span><br /> Вы не сможете восстановить удалённый столбец.</p>
                  <button
                    className="col-row-remove-btn"
                    onClick={() => quizContext.dispatch({ type: "removeColumn" })}
                  >УДАЛИТЬ СТОЛБЕЦ {quizContext.state.columnNum} </button>
                </div>
                : null
              }
            </Modal.Body>
          </Modal>
        </>
      }
    </>
  )
}

export default ModalAddRemoveColumn