// ButtonsSubmitEditedQuizContents

import findMistake from "./FindMistake";

const confirmEditedQuizContents = (state) => {

  const quizOptionsForChecking = state.quizOptionsEdit.map((el, i) => el.header)
  quizOptionsForChecking.shift();

  const quizRowsForChecking = state.quizRowsEdit.map((row) => {
    let keys = [];
    let values = [];
    let newObj = {};
    Object.keys(row).map((k) => {
      keys.push(row[k][1].header);
      values.push(row[k][0]);
      return k;
    })
    keys.forEach((key, i) => newObj[key] = values[i]);
    return newObj;
  });

  const mistake = findMistake(quizOptionsForChecking, quizRowsForChecking);

  if (mistake) {
    return {
      ...state,
      alertStatus: null,
      errorMessage: "table mistake",
      mistake
    }
  } else {


    // const regVal = /tempValId/;
    const regRow = /tempRowId/;
    const regCol = /tempColId/;


    const quizOptionsToUpdate = [];
    const quizOptionsToInsert = [];
    const quizOptionsToDelete = state.quizOptionsToDelete;

    const quizOptionsEdite = [...state.quizOptionsEdit];
    quizOptionsEdite.filter(el => el.changes)
      .map((el) => {
        let chosen;
        if (!regCol.test(el.id)) {
          chosen = {
            id: el.id,
            header: el.header,
            quiz_id: el.quiz_id
          };
          return quizOptionsToUpdate.push(chosen);
        }
        if (regCol.test(el.id)) {
          chosen = {
            header: el.header,
            quiz_id: el.quiz_id
          };
          return quizOptionsToInsert.push(chosen);
        }
        return chosen
      });


    const quizValuesToUpdate = [];
    const quizColumnsToInsert = [];
    const quizRowsToInsert = [];
    const quizColRowsToInsert = [];

    const quizRowsEdit = [...state.quizRowsEdit]
    quizRowsEdit.map((el, i) => {
      let ind;
      let chosen;
      let chosenRow;
      let chosenColRow;
      Object.keys(el).map((key, ii) => {
        ind = key
        if (!regRow.test(el[key][1].quiz_row_id) && !regCol.test(el[key][1].quiz_col_id)) {
          if (el[key][1].changes === "changed") {

            chosen = {
              id: el[key][1].id,
              value: el[key][0],
              quiz_row_id: el[key][1].quiz_row_id,
              quiz_col_id: el[key][1].quiz_col_id,
              quiz_id: el[key][1].quiz_id
            }
            return quizValuesToUpdate.push(chosen);
          }
        }
        if (!regRow.test(el[key][1].quiz_row_id) && regCol.test(el[key][1].quiz_col_id)) {

          chosen = {
            [el[key][1].header]: {
              value: el[key][0],
              quiz_row_id: el[key][1].quiz_row_id,
              quiz_id: el[key][1].quiz_id
            }
          }

          return quizColumnsToInsert.push(chosen)
        }
        return el
      });
      if (regRow.test(el[ind][1].quiz_row_id)) {
        const generateChosenRow = Object.keys(el).map((key) => {
          return {
            [el[key][1].header]: {
              value: el[key][0],
              quiz_col_id: el[key][1].quiz_col_id,
              quiz_id: el[key][1].quiz_id
            }
          }
        });
        chosenRow = Object.assign(...generateChosenRow);
        const generateChosenColRow = Object.keys(el).map((key) => {
          return {
            [el[key][1].header]: {
              value: el[key][0],
              quiz_id: el[key][1].quiz_id,
              // header: el[key][1].header
            }
          }
        });

        const testing = Object.keys(el).map((key) => {
          return el[key][1].quiz_col_id;
        });



        chosenColRow = Object.assign(...generateChosenColRow);

        if (regCol.test(testing)) {

          quizColRowsToInsert.push(chosenColRow)
        } else {
          quizRowsToInsert.push(chosenRow)
        }
      }
      return el
    });

    const quizRowsToDelete = [...state.quizRowsToDelete];

    return {
      ...state,
      alertStatus: "changes confirmed",
      quizOptionsToUpdate,
      quizOptionsToInsert,
      quizOptionsToDelete,
      quizValuesToUpdate,
      quizColumnsToInsert,
      quizRowsToInsert,
      quizColRowsToInsert,
      quizRowsToDelete
    }
  }
}

export default confirmEditedQuizContents