// ModalAddRemoveRow

const addRowEdit = (state) => {

  const num = state.quizRowsEdit.length;
  const tempValId = state.numForTempValId + 1;
  const numForTempRowId = state.numForTempRowId + 1;
  const quizRow = state.quizRowsEdit[0];

  const generateNewRow = Object.keys(quizRow).map((el, i) => {
    const val = {
      [el]: ["",
        {
          id: "tempValId" + (tempValId + i),
          quiz_id: quizRow[el][1].quiz_id,
          quiz_col_id: quizRow[el][1].quiz_col_id,
          quiz_row_id: "tempRowId" + (numForTempRowId),
          header: quizRow[el][1].header,
          new: "new row"
        }
      ]
    }
    return val
  });

  const newRowValue = Object.assign(...generateNewRow);

  const quizRowsEdit = [...state.quizRowsEdit];
  quizRowsEdit.splice(num, 0, newRowValue);

  const numForTempValId = tempValId + (Object.keys(quizRow).length - 1);

  return {
    ...state,
    alertTable: false,
    alertStatus: "changes made",
    numForTempValId,
    numForTempRowId,
    quizRowsEdit
  }
}

export default addRowEdit