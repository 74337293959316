import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { QuizContext } from "../../App";
import removeFavQuiz from "../../reducer/actions/quizActions/RemoveFavQuiz";
import FormSearchItem from "../forms/FormSearchItem";

const ModalFavQuizzes = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Modal
      data-bs-theme={theme}
      show={quizContext.state.alert}
      onHide={() => quizContext.dispatch({
        type: "hideAlert"
      })}
    >
      <Modal.Header closeButton data-bs-theme={theme}
        className={`${theme}-modal-header`}>
        ИЗБРАННЫЕ КВИЗЫ
      </Modal.Header>
      <Modal.Body
        className={`${theme}-modal-body`}
        style={{
          maxHeight: "400px",
          overflowX: "hidden",
        }}
      >

        {quizContext.state.favQuizzes.length > quizContext.state.itemArrLength
          && <FormSearchItem />}

        <Table
          striped
          style={{ width: "100%" }}
        >
          {!quizContext.state.favQuizzes.length
            ? <tbody><tr><th style={{ color: "red" }}>
              Вы пока не добавили ни одного квиза в ИЗБРАННЫЕ
            </th ></tr></tbody>
            : <tbody>
              {quizContext.state.favQuizzes
                .filter((el) => quizContext.state.searchItem.toLowerCase() === ""
                  ? el
                  : el.title.toLowerCase().includes(quizContext.state.searchItem.toLowerCase()))
                .map((el, i) => (
                  <tr key={i}>
                    <th style={{ textAlign: "left", width: "80%" }}>
                      <Link
                        to={`/quiz/${el.quiz_id}`}
                        style={{
                          textDecoration: "none",
                          color: theme === "dark"
                            ? "#4da2f7"
                            : "#0a70d6"
                        }}
                        onClick={() => quizContext.dispatch({
                          type: "goToFavQuiz",
                          el
                        })}
                      >
                        {theme === "dark"
                          ? <img
                            src={el.type === "PUBLIC"
                              ? require("../images/publicW.png")
                              : el.type === "TEAM"
                                ? require("../images/teamW.png")
                                : require("../images/privateW.png")
                            }
                            style={{
                              width: "15px",
                              marginBottom: "3px",
                              marginRight: "5px"
                            }}
                            alt={"type"}
                          />
                          : <img
                            src={el.type === "PUBLIC"
                              ? require("../images/public.png")
                              : el.type === "TEAM"
                                ? require("../images/team.png")
                                : require("../images/private.png")
                            }
                            style={{
                              width: "15px",
                              marginBottom: "3px",
                              marginRight: "5px"
                            }}
                            alt={"type"}
                          />}  {el.title}
                      </Link>
                    </th>

                    <th style={{ textAlign: "right" }}>
                      {theme === "dark"
                        ? <img
                          src={require("../images/deleteW.png")}
                          style={{ width: "20px" }}
                          alt={"delete"}
                          onClick={(e) => {
                            e.preventDefault()
                            removeFavQuiz(
                              quizContext.dispatch,
                              quizContext.state,
                              el.id
                            )
                            quizContext.dispatch({
                              type: "removeFavQuiz"
                            })
                          }}
                        />
                        : <img
                          src={require("../images/delete.png")}
                          style={{ width: "20px" }}
                          alt={"delete"}
                          onClick={(e) => {
                            e.preventDefault()
                            removeFavQuiz(
                              quizContext.dispatch,
                              quizContext.state,
                              el.id
                            )
                            quizContext.dispatch({
                              type: "removeFavQuiz"
                            })
                          }}
                        />}
                    </th>
                  </tr>
                ))}
            </tbody>
          }
        </Table>
      </Modal.Body>
    </Modal>
  )
}

export default ModalFavQuizzes