import React from "react";

const TextRequirementsXLSX = () => {

  return (
    <div>
      <p>Prepare an xlsx file, using any spreadsheet software.</p>

      <p>
        <img
          src={require('../images/instruction-create-from-xlsx-software.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>

      <p>1. The first row of each column must contain the column headers. Otherwise, you quiz won’t work.</p>

      <p>
        <img
          src={require('../images/instruction-xlsx-requirements-1.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>

      <p>2. The value of each row in the column must be unique.</p>

      <p>
        <img
          src={require('../images/instruction-xlsx-requirements-2.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>

      <p>By any means, different rows MUST NOT contain the same data. Otherwise, when taking the quiz, you’ll face a problem:</p>

      <p>
        <img
          src={require('../images/instruction-xlsx-requirements-3.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>

      <p>Which of the answers is right? See?</p>
      <p>To avoid such an issue, combine data like this:</p>

      <p>
        <img
          src={require('../images/instruction-xlsx-requirements-4.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>

      <p>(Here, we combine expressions, which give the same result.)</p>
      <p>or add unique data like this:</p>

      <p>
        <img
          src={require('../images/instruction-xlsx-requirements-5.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>

      <p>(There are many countries on each continent, but each country has a capital with a unique name.)</p>
      <p>3. The columns must be at least 6 rows long (including the headers). Otherwise, your quiz won’t work.</p>
      <p>4. The columns must be of the same length. Otherwise, your quiz won’t work.</p>
      <p>5. A quiz must contain at least 2 columns. Otherwise, your quiz won’t work.</p>
      <p>6. A quiz must have only 1 sheet. Otherwise, your quiz won’t work.</p>

      <p>
        <img
          src={require('../images/instruction-xlsx-requirements-6.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>

      <p>7. A quiz can have as many columns as you want, just not fewer than 2.</p>

      <p>
        <img
          src={require('../images/instruction-xlsx-requirements-7.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>

      <p>Surely, taking a quiz you use only 2 options, that is, only 2 columns. However, you can choose any 2 options in any combination. Thus, adding more columns (with the relevant data, of course) makes it so like having multiple quizzes in one. Something like this:</p>

      <p>
        <img
          src={require('../images/instruction-xlsx-requirements-8.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>

    </div>
  )
}

export default TextRequirementsXLSX