import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import TextSolvedTasks from "./text/TextSolvedTasks";
import ButtonOnceAgain from "./buttons/ButtonOnceAgain";
import TextQuizHeader from "./text/TextQuizHeader";
import { QuizContext } from "../App";
import ButtonQuizAddFavorite from "./buttons/ButtonQuizAddFavorite";
import ButtonAnotherQuiz from "./buttons/ButtonAnotherQuiz";
import ModalAnotherQuiz from "./modals/ModalAnotherQuiz";
import FormRecommendQuiz from "./forms/FormRecommendQuiz";
import FormCommentQuiz from "./forms/FormCommentQuiz";
import ButtonSubmitRecommendation from "./buttons/ButtonSubmitRecommendation";
import TextRecommendationResult from "./text/TextRecommendationResult";
import ButtonsSocialMedia from "./buttons/ButtonsSocialMedia";
import { Card } from "react-bootstrap";
import { ButtonDownloadXLSXfile } from "./buttons/ButtonDownloadXLSXfile";

const QuizResult = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const { id } = useParams();
  const comrec = quizContext.state.quiz.type === "PUBLIC"
    && quizContext.state.currentUser !== null
    && !quizContext.state.errorAuth
    && quizContext.state.currentUser.id !== quizContext.state.quiz.author_id
    ? true
    : false;

  return (
    <div>
      <TextQuizHeader />
      <Card
        data-bs-theme={theme}
        className="card-quiz-result"
        style={{ background: theme === "light" && "#fdfdfd"}}
      >
        <TextSolvedTasks />
        <div>
          <ButtonOnceAgain />
          <ButtonAnotherQuiz />
        </div>
        <div
          style={{
            textAlign: "center",
            marginTop: "10px",
          }}
        >
          {quizContext.state.currentUser
            && id !== "unsaved"
            && !quizContext.state.errorAuth
            ? <ButtonQuizAddFavorite />
            : null
          }
        </div>

      </Card>

      {(quizContext.state.currentUser
        && quizContext.state.currentUser.id === quizContext.state.quiz.author_id)
        || id === "unsaved"
        ? <div style={{ textAlign: "right", marginTop: "15px" }}>
          <ButtonDownloadXLSXfile />
        </div>
        : null
      }

      {quizContext.state.quiz.type === "PUBLIC"
        && <div className="social-icons">
          <ButtonsSocialMedia />
        </div>
      }

      {comrec && quizContext.state.recStatus === "rec"

        ? <div className={`${theme}-card-recommend`}>

          <FormRecommendQuiz />
          {quizContext.state.recom === "REC"
            || quizContext.state.recom === "UNREC"
            ? <FormCommentQuiz />
            : null
          }
          <div style={{ textAlign: "center" }}>
            <ButtonSubmitRecommendation />
          </div>
        </div>

        : comrec && quizContext.state.recStatus === "done"
          ? <div className={`${theme}-card-recommend`}>
            <TextRecommendationResult />
          </div>
          : null
      }


      {/*-------------------------------------- Modals */}
      {quizContext.state.alertStatus === "another quiz"
        && <ModalAnotherQuiz />
      }
    </div>
  )
}

export default QuizResult