import React, { useContext } from "react";
import { QuizContext } from "../../App";
import submitRecommendation from "../../reducer/actions/recommendationActions/SubmitRecommendation";


const ButtonSubmitRecommendation = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <>
      {quizContext.state.recom === "REC" || quizContext.state.recom === "UNREC"

        ? <div>
          <button
            className={`${theme}-main-btn`}
            onClick={(e) => {
              e.preventDefault()
              submitRecommendation(
                quizContext.dispatch,
                quizContext.state
              )
              quizContext.dispatch({
                type: "submitRecommendation"
              })
            }}
          >
            SUBMIT
          </button>
          <button
            className={`${theme}-sec-btn`}
            onClick={() => quizContext.dispatch({
              type: "cancelRecCom"
            })}
          >
            CANCEL
          </button>
        </div>
        : <button
          className={`${theme}-main-btn-inactive`}
        >
          <span style={{ cursor: "crosshair" }}>
            SUBMIT
          </span>
        </button>
      }
    </>
  )
}

export default ButtonSubmitRecommendation