import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { QuizContext } from "../../App";

const ModalInstruction = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Modal
      size="sm"
      data-bs-theme={theme}
      show={quizContext.state.alert}
      onHide={() => quizContext.dispatch({
        type: "hideAlert"
      })}
      centered
    >
      <Modal.Header closeButton
        data-bs-theme={theme}
        className={`${theme}-modal-header`}
      >
        ИНСТРУКЦИЯ
      </Modal.Header>
      <Modal.Body className={`${theme}-modal-body`}>
        <ol>
          <li>Квиз может иметь столько столбцов, сколько Вам нужно,<br/> но не меньше 2-х.</li>
          <li>Каждый заголовок столбца должен быть уникальным.</li>
          <li>Квиз может иметь столько строк, сколько Вам нужно,<br/> но не меньше 5-ти.</li>
          <li>Значение в каждой ячейке столбца должно быть уникальным.</li>
          <li>Все столбцы должны содержать одинаковое количество строк.</li>
        </ol>

        <div style={{ textAlign: "center", marginTop: "5px" }}>
          <button
            className={`${theme}-main-btn`}
            onClick={() => quizContext.dispatch({
              type: "hideAlert"
            })}
          >
            ЯСНО
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ModalInstruction