import React, { useContext } from "react";
import { QuizContext } from "../../App";
import addToFav from "../../reducer/actions/quizActions/AddToFav";

const ButtonQuizAddFavorite = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const userFavQuiz = quizContext.state.currentUser && quizContext.state.favQuiz.length
    ? quizContext.state.favQuiz.filter((el) =>
      el.renter_id === quizContext.state.currentUser.id
    )
    : [];
  const favQuizId = userFavQuiz.length
    ? userFavQuiz[0].id
    : null;

  return (

    <>
      {quizContext.state.favQuizStatus === false
        ? <button className={`${theme}-sec-small-btn`}
          onClick={(e) => {
            e.preventDefault()
            addToFav(
              quizContext.dispatch,
              quizContext.state,
              favQuizId
            )
            quizContext.dispatch({
              type: "addToFav"
            })
          }}
        >
          ДОБАВИТЬ В ИЗБРАННЫЕ
        </button>

        : <span style={{fontSize: "0.8rem"}}>ИЗБРАННЫЙ</span>
      }
    </>


  )
}

export default ButtonQuizAddFavorite