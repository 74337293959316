// AuthSignUp, QuizCreate, CardNavLogInSignUp

const switchToLogin = (state) => {

  localStorage.setItem("user", null);

  return {
    ...state,
    loading: true,
    auth: "login",
    video: false,
    currentUser: null,
    error: null
  }
}

export default switchToLogin