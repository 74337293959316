import React, { useContext } from 'react';
import Accordion from "react-bootstrap/Accordion";
import FormEditUsername from './forms/FormEditUsername';
import ButtonsSubmitEditedProfile from './buttons/ButtonsSubmitEditedProfile';
import ModalEditedProfileSumbitted from './modals/ModalEditedProfileSumbitted';
import { QuizContext } from '../App';
import Error from '../pages/Error';
import ButtonChangePassword from './buttons/ButtonChangePassword';
import FormSetTheme from './forms/FormSetTheme';
// import ButtonLogOut from './buttons/ButtonLogOut';

const EditProfile = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <div>
      {quizContext.state.error
        ? <Error />
        : <>
          <h2 className="header">
            РЕДАКТИРОВАТЬ ПРОФИЛЬ
          </h2>

          <Accordion
            data-bs-theme={theme}
            style={{
              boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
              textAlign: "center"
            }}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Редактировать имя
              </Accordion.Header>
              <Accordion.Body>
                <div style={{margin: "20px 10px"}}><FormEditUsername /></div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Редактировать тему
              </Accordion.Header>
              <Accordion.Body>
              <div style={{marginTop: "20px"}}><FormSetTheme /></div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>
                Изменить пароль
              </Accordion.Header>
              <Accordion.Body>
              <div style={{margin: "10px"}}><ButtonChangePassword /></div>
              </Accordion.Body>
            </Accordion.Item>

            {/* <Accordion.Item eventKey="3">
              <Accordion.Header style={{ color: "black" }}>
                Log out
              </Accordion.Header>
              <Accordion.Body>
                <ButtonLogOut />
              </Accordion.Body>
            </Accordion.Item> */}

          </Accordion>

          <div
            style={{
              textAlign: "center",
              marginTop: "35px"
            }}
          >
            <ButtonsSubmitEditedProfile />
          </div>


          {quizContext.state.alertStatus === "profile edited"
            && <ModalEditedProfileSumbitted />
          }
        </>
      }
    </div>
  )
}

export default EditProfile