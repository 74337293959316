import React, { useContext } from "react";
import { QuizContext } from "../../App";

const TextHowToEditQuiz = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <>
    <p>Нажмите МОИ КВИЗЫ на своей странице.</p>
      <p>
        <img
          src={require('../images/instruction-edit-quiz-clickQuizzes.jpg')}
          style={{ width: "100%" }}
          alt={'instr'}
        />
      </p>

      <p>Нажмите {theme === "dark"
            ? <img
              src={require('../images/editW.png')}
              style={{ width: "15px" }}
              alt={'edit'} />
            : <img
              src={require('../images/edit.png')}
              style={{ width: "15px" }}
              alt={'edit'} />} символ напротив названия квиза, который Вы собираетесь редактировать.</p>
      <p>
        <img
          src={require('../images/instruction-edit-quiz-clickEditList.jpg')}
          style={{ width: "100%" }}
          alt={'instr'}
        />
      </p>

      <p>Также Вы можете перейти на страницу редактирования квиза с титульной страницы квиза.</p>
      <p>
        <img
          src={require('../images/instruction-edit-quiz-goFromPage.jpg')}
          style={{ width: "100%" }}
          alt={'instr'}
        />
      </p>

      <p>Во вкладке редактирования параметров квиза, вы можете изменить название квиза, его описание и тип.</p>
      <p>
        <img
          src={require('../images/instruction-edit-quiz-settings.jpg')}
          style={{ width: "100%" }}
          alt={'instr'}
        />
      </p>

      <p>Если Вы измените тип с ЛИЧНЫЙ на ОБЩЕДОСТУПНЫЙ, Вы должны добавить категорию и язык квиза.</p>
      <p>
        <img
          src={require('../images/instruction-edit-quiz-settingsToPublic.jpg')}
          style={{ width: "100%" }}
          alt={'instr'}
        />
      </p>

      <p>Когда всё готово, сохраните отредактированные параметры квиза.</p>
      <p>
        <img
          src={require('../images/instruction-edit-quiz-settingsSubmit.jpg')}
          style={{ width: "100%" }}
          alt={'instr'}
        />
      </p>

      <p>После того как Вы сохранили отредактированные параметры квиза, Вы можете перейти на страницу квиза или продожить редактирование.</p>
      <p>
        <img
          src={require('../images/instruction-edit-quiz-settingsSubmitted.jpg')}
          style={{ width: "100%" }}
          alt={'instr'}
        />
      </p>

      <p>Вы можете редактировать квиз прямо здесь или перейти в полноэкранный режим. Редактирование квиза позволяет исправлять опечатки и другие ошибки и даже добавлять и удалять строки и столбцы.</p>
      <p>
        <img
          src={require('../images/instruction-edit-quiz-contents.jpg')}
          style={{ width: "100%" }}
          alt={'instr'}
        />
      </p>

      <p>Когда всё готово, отправьте квиз на проверку.</p>
      <p>
        <img
          src={require('../images/instruction-edit-quiz-contentsConfirm.jpg')}
          style={{ width: "100%" }}
          alt={'instr'}
        />
      </p>

      <p>После проверки квиза, сохраните его.</p>
      <p>
        <img
          src={require('../images/instruction-edit-quiz-contentsSubmit.jpg')}
          style={{ width: "100%" }}
          alt={'instr'}
        />
      </p>

      <p>После того как Вы сохранили отредактированный квиз, Вы можете перейти на страницу квиза или продожить редактирование.</p>
      <p>
        <img
          src={require('../images/instruction-edit-quiz-contentsSubmitted.jpg')}
          style={{ width: "100%" }}
          alt={'instr'}
        />
      </p>

    </>
  )
}

export default TextHowToEditQuiz