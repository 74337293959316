import React, { useContext, useEffect } from "react";
import { QuizContext } from "../App";
import { Link } from "react-router-dom";
import axios from "axios";
import ModalForDeleteQuiz from "../components/modals/ModalForDeleteQuiz";
import ModalForEditQuiz from "../components/modals/ModalForEditQuiz";
import { useParams } from "react-router-dom";
import TextNotYourQuiz from "../components/text/TextNotYourQuiz";
import Error from "./Error";
import Loading from "../components/Loading";
import ButtonsTabEdit from "../components/buttons/ButtonsTabEdit";
import ModalAddRemoveColumn from "../components/modals/ModalAddRemoveColumn";
import ModalAddRemoveRow from "../components/modals/ModalAddRemoveRow";
import AccordionQuizEditSettings from "../components/accordions/AccordionQuizEditSettings";
import ModalInstruction from "../components/modals/ModalInstruction";
import CardQuizEditContents from "../components/cards/CardQuizEditContents";

const QuizEdit = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const dispatch = quizContext.dispatch;
  const { id } = useParams();

  //Get all info for Quiz Edit 
  useEffect(() => {
    const fecthData = async () => {
      try {
        const res = await axios.get(`/quiz/edit/${id}`);
        // console.log("res.data:", res.data);
        const quizEditPage = res.data;
        // console.log("quizEditPage:", quizEditPage);
        dispatch({ type: "fetchQuizEditPage", quizEditPage });
      } catch (err) {
        const error = err.response.data.message;
        console.log("fetchQuizEditPageErr:", error);
        dispatch({ type: "fetchQuizEditPageErr", error });
      }
    }
    fecthData();
  }, [id, dispatch, quizContext.state.alert]);


  return (
    <div>
      {quizContext.state.loading
        ? <Loading />
        : quizContext.state.errorMySQL
          || quizContext.state.errorAuth
          || quizContext.state.error === "404"
          ? <Error />
          : quizContext.state.quiz.type === 'PRIVATE'
            && (quizContext.state.currentUser === null || quizContext.state.quiz.author_id !== quizContext.state.currentUser.id)
            ? <TextNotYourQuiz />
            : <>
              <h2 className="header">
                <span className="header-tq">EDIT QUIZ:</span> <Link
                  to={`/quiz/${quizContext.state.quiz.id}`}
                  style={{
                    textDecoration: 'none',
                    color: theme === "dark"
                      ? "#4da2f7"
                      : "#0a70d6",
                    cursor: "pointer"
                  }}
                >
                  <span className="header-tq-title">{quizContext.state.quizTitle}</span>
                </Link>
              </h2>

              <div className="tab">
                <ButtonsTabEdit />
              </div>

              {quizContext.state.tabStatus === "left"
                ? <AccordionQuizEditSettings />
                : <CardQuizEditContents />
              }
            </>
      }
      {
        quizContext.state.alertStatus === "delete quiz"
          ? <ModalForDeleteQuiz />
          : quizContext.state.alertStatus === "column"
            ? <ModalAddRemoveColumn />
            : quizContext.state.alertStatus === "row"
              ? <ModalAddRemoveRow />
              : quizContext.state.alertStatus === "edited quiz"
                ? <ModalForEditQuiz />
                : quizContext.state.alertStatus === "instruction"
                  ? <ModalInstruction />
                  : null
      }


    </div>
  )
}

export default QuizEdit