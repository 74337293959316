import React, { useContext } from "react";
import { QuizContext } from "../App";
import PasswordRecoveryEmail from "../components/PasswordRecoveryEmail";
import PasswordRecoveryGetOTP from "../components/PasswordRecoveryGetOTP";
import PasswordRecoveryNewPassword from "../components/PasswordRecoveryNewPassword";

const PasswordRecovery = () => {

  const quizContext = useContext(QuizContext);

  return (
    <div className="wrapper">
      {quizContext.state.passwordRecovery === "getOTP"
          ? <PasswordRecoveryGetOTP />
          : quizContext.state.passwordRecovery === "new password"
            ? <PasswordRecoveryNewPassword />
            : <PasswordRecoveryEmail />
      }     
    </div>
  )
}

export default PasswordRecovery