// FormEditTeamMemberType 

const changeTeamMemberType = (state, action) => {
  
  const teamMemberNewType = action.payload;
  
  return {
    ...state,
    teamMemberNewType
  }
}

export default changeTeamMemberType