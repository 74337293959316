import axios from "axios";

// FormTeamInviteUsers

const inviteUser = async (dispatch, state) => {
  
  let error = state.error;

  try {
    await axios.post('/team/addmemb', {
      team_id: state.teamId,
      user_id: state.invUser.id,
      user_type: "INV"
    });
  } catch (err) {
    error = err.response.data.message;
    console.log("inviteUser err:", error);
  }

  return dispatch({
    type: "inviteUser",
    error
  });
}

export default inviteUser