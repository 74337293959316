// FormSelectQluizLang

const filterCatQuizzes = (state, action) => {

  const selectedLang = action.payload;
  const catLangQuizzesEN = state.catQuizzes.filter(el => el.lang === selectedLang);

  const catLangQuizzesRU = state.catQuizzes.filter(el => el.lang_ru === selectedLang);

  const catLangQuizzes = catLangQuizzesEN.concat(catLangQuizzesRU);

  // console.log("catLangQuizzes", catLangQuizzes)

  if (selectedLang === "nothing selected" || selectedLang === "all") {
    return {
      ...state,
      catQuizzes: state.catQuizzes,
      catStatus: false
    }
  } else {
    return {
      ...state,
      catLangQuizzes,
      catStatus: true
    }
  }
}

export default filterCatQuizzes