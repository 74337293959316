// FormEditTeamMemberType

const selectTeamMember = (state, action) => {

  if (action.payload !== "- Select Member -") {
    const teamMemberName = action.payload;
    const teamMemberType = state.teamMembers.filter(el => el.username === teamMemberName)[0].user_type;
    const teamUsersId = state.teamMembers.filter(el => el.username === teamMemberName)[0].id;
    
    return {
      ...state,
      teamMemberName,
      teamMemberType,
      teamUsersId,
      changeTeamMemberTypeStatus: "chosen"
    }
  } else {
    return state
  }
}

export default selectTeamMember