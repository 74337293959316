import React, { useContext } from "react";
import { QuizContext } from "../../App";
import VideoForHomePage from "../videos/VideoForHomePage";

const TextHomeHeader = () => {

  const quizContext = useContext(QuizContext);

  return (
    <div style={{ textAlign: "center" }}>
      <h2 className="home-header">
        Добро пожаловать<br /> на КВИЗЫ!
      </h2>

      {/* <div>
        Turn any data into a quiz and memorize huge amounts of information in a gamelike way.
      </div> */}

      <VideoForHomePage />

      {!quizContext.state.currentUser
        && <div style={{fontSize: "0.7rem"}}>
          Попробуйте КВИЗЫ без регистрации.
        </div>
      }
    </div>
  )
}

export default TextHomeHeader