import React, { useContext } from "react";
import video from "./CreateQuizFromFileRU.mp4";
import { Card } from "react-bootstrap";
import { QuizContext } from "../../App";

const VideoCreateQuizFromFile = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <div style={{ margin: "25px 0" }}>
      {quizContext.state.video
        ? <Card
          data-bs-theme={theme}
          className="card-home"
          style={{

            width: "100%",
            height: "auto",
            padding: "0.2%",
            background: "white"
          }}
        >
          <video
            width="100%"
            height="auto"
            style={{ borderRadius: "3px" }}
            controls
          >
            <source src={video} type="video/mp4" />
          </video>


        </Card>
        : <div style={{ textAlign: "center" }}>
          <button
            className={`${theme}-video-btn`}
            onClick={() => quizContext.dispatch({
              type: "switchToVideo"
            })}
          >
            ВИДЕО: Как создать квиз из xlsx файла
          </button>
        </div>
      }
    </div>
  )
}

export default VideoCreateQuizFromFile