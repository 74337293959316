// FormTeamAddQuiz, FormTeamRemoveQuiz, FormTeamRemoveYourQuiz

const notAddQuiz = (state) => {
  return {
    ...state,
    teamQuizTitle: "",
    teamQuizTitleToRemove: "",
  }
}

export default notAddQuiz