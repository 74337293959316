import axios from 'axios';
import getUserMembPlan from '../userActions/GetUserMembPlan';

// ModalForDeleteQuiz

const deleteQuiz = async (dispatch, state) => {

  let error = state.error;
  const quizId = state.quizId;

  const userScore = state.quiz.type === "PUBLIC"
    ? state.quiz.score - (10 + ((state.quiz.rec_quiz * 3) - (state.quiz.unrec_quiz * 3)) + state.quiz.times_taken)
    : state.quiz.score;

  const userNumPubQuizzes = state.quiz.type === "PUBLIC"
    ? state.quiz.num_pub_quizzes - 1
    : state.quiz.num_pub_quizzes;

  const userMem = state.quiz.payment === "FALSE"
    ? getUserMembPlan(userNumPubQuizzes, userScore, state.quiz.mem, state)
    : state.quiz.mem;

  try {
    await axios.post(`/quiz/delete/${quizId}`, {
      score: userScore,
      mem: userMem,
      num_pub_quizzes: userNumPubQuizzes
    });

  } catch (err) {
    error = err.response.data.message;
    console.log("deleteQuiz err:", error);
  }

  return dispatch({
    type: "deleteQuiz",
    error
  });
}

export default deleteQuiz