import generateAnswers from "./GenerateAnswers";

// ButtonStartQuiz

const solveQuiz = (state) => {
  
  if (state.question === "- Select Question Option -" 
  || state.answer === "- Select Answer Option -"
  || state.question === state.answer) {
    return { ...state, errorMessage: true };
  } else {

    const questionIndex = state.quizOptions.indexOf(state.question);
    const answerIndex = state.quizOptions.indexOf(state.answer);
    const taskNumber = 1;
    const answerOptions = generateAnswers(state);
    const tasks = [...answerOptions[taskNumber - 1]]
    const indexRightAnswer = Math.floor(Math.random() * 4);
    const renderQuestion = tasks[indexRightAnswer][questionIndex].value;
    let renderAnswers = [];

    for (let i = 0; i < tasks.length; i++) {
      renderAnswers.push({ value: tasks[i][answerIndex].value, isGivenAnswer: "", id: i })
    }

    return {
      ...state,
      alert: false,
      errorMessage: false,
      alertStatus: null,
      quizStatus: "quiz",
      taskStatus: "taskNumber",
      taskNumber: taskNumber,
      answerOptions: answerOptions,
      questionIndex: questionIndex,
      answerIndex: answerIndex,
      tasks: tasks,
      indexRightAnswer: indexRightAnswer,
      renderQuestion: renderQuestion,
      renderAnswers: renderAnswers,
      score: 0,
      storeScore: [],
      averageScore: 0,
      failedTasks: [],
    }
  }
}

export default solveQuiz