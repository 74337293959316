import React, { useContext } from "react";
import { QuizContext } from "../../App";
import { Link } from "react-router-dom";
import Table from 'react-bootstrap/Table';

const TableTeamQuizzes = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const currentUserInv = quizContext.state.invited.filter((el, i) => el.user_id === quizContext.state.currentUser.id).length === 1
    ? true
    : false

  return (
    <>
      {!quizContext.state.teamQuizzes.length
        ? <div style={{ color: "red" }}>Пока не добавлен ни один квиз</div>
        : <Table
          striped hover
          variant={theme}
          style={{
            width: "100%",
            maxHeight: "400px",
            overflowX: "hidden",
          }}
        >
          <tbody>
            {quizContext.state.teamQuizzes
              .filter((el) => quizContext.state.searchItem.toLowerCase() === ""
                ? el
                : el.title.toLowerCase().includes(quizContext.state.searchItem.toLowerCase()))
              .map((el, i) => (

                <tr key={i}>

                  {!currentUserInv
                    ? <th style={{ textAlign: "left" }}>
                      <Link
                        to={`/quiz/${el.id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <button
                          className={`${theme}-link-btn`}
                          onClick={() => quizContext.dispatch({
                            type: "goToTeamQuiz"
                          })}
                        >
                          {el.title}
                        </button>
                      </Link>
                    </th>
                    : <th style={{ textAlign: "left" }}>
                      {el.title}
                    </th>
                  }
                </tr>
              ))}
          </tbody>
        </Table>
      }
    </>
  )
}

export default TableTeamQuizzes