// TextUserHeader

const goToEditProfile = (state) => {

  return {
    ...state,
    userPageStatus: "edit profile",
    username: state.currentUser.username
  }
}

export default goToEditProfile