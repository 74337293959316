// FormPasswordConfirm

const confirmPassword = (state, action) => {
  const passwordConfirm = action.payload;
  return {
    ...state,
    passwordConfirm,
    errorSignUp: null,
    errorLogIn: null,
    enterData: false,
    wrongData: false,
    checkUser: false
  };
}

export default confirmPassword