import React from "react";
import AccordionFAQmembership from "../components/accordions/AccordionFAQmembership";
import AccordionFAQquizzes from "../components/accordions/AccordionFAQquizzes";
import AccordionFAQlibrary from "../components/accordions/AccordionFAQlibrary";
import AccordionFAQteams from "../components/accordions/AccordionFAQteams";

const Help = () => {

  return (
    <div style={{ paddingBottom: "100px"}}>
      <h2 className="header">FAQ</h2>
      <div className="faq-div">
        Membership:
      </div>
      <AccordionFAQmembership />

      <div className="faq-div">
        Quizzes:
      </div>
      <AccordionFAQquizzes />

      <div className="faq-div">
        Quiz Library:
      </div>
      <AccordionFAQlibrary />

      <div className="faq-div">
        Teams:
      </div>
      <AccordionFAQteams />

    </div>
  )
}

export default Help