import generateAnswersLong from "./GenerateAnswersLong";

// ButtonStartQuiz

const solveQuizLong = (state) => {
  
  if (state.question === "- Select Question Option -" 
  || state.answer === "- Select Answer Option -"
  || state.question === state.answer) {
    return { ...state, errorMessage: true };
  } else {

    const questionIndex = state.quizOptions.indexOf(state.question);
    const answerIndex = state.quizOptions.indexOf(state.answer);
    const taskNumber = 1;
    const answerOptions = generateAnswersLong(state);
    const tasks = [...answerOptions[taskNumber - 1]]
    const indexRightAnswer = 0;
    const renderQuestion = tasks[indexRightAnswer][questionIndex].value;
    let getAnswers = [];

    for (let i = 0; i < tasks.length; i++) {
      getAnswers.push({ value: tasks[i][answerIndex].value, isGivenAnswer: "", id: i })
    }

    const renderAnswers = getAnswers.sort(() => 0.5 - Math.random());

    return {
      ...state,
      alert: false,
      errorMessage: false,
      alertStatus: null,
      quizStatus: "quiz",
      taskStatus: "taskNumber",
      taskNumber: taskNumber,
      answerOptions: answerOptions,
      questionIndex: questionIndex,
      answerIndex: answerIndex,
      tasks: tasks,
      indexRightAnswer: indexRightAnswer,
      renderQuestion: renderQuestion,
      renderAnswers: renderAnswers,
      score: 0,
      storeScore: [],
      averageScore: 0,
      failedTasks: [],
    }
  }
}

export default solveQuizLong