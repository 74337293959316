import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import { QuizContext } from "../../App";

const FormSelectQuizLength = () => {

  const quizContext = useContext(QuizContext);


  // const score = quizContext.state.averageScore === 11
  // || quizContext.state.averageScore === 12
  // || quizContext.state.averageScore === 13
  // || quizContext.state.averageScore === 14
  // ? quizContext.state.averageScore
  // : Number(quizContext.state.averageScore.toString().split("").pop());

  const tasksLong = quizContext.state.quizRows.length === 11
    || quizContext.state.quizRows.length === 12
    || quizContext.state.quizRows.length === 13
    || quizContext.state.quizRows.length === 14
    ? quizContext.state.quizRows.length
    : Number(quizContext.state.quizRows.length.toString().split("").pop());
  const wordLong = tasksLong === 1
    ? "задание"
    : tasksLong === 2 || tasksLong === 3 || tasksLong === 4
      ? "задания"
      : "заданий";

  const tasksShort = Math.floor(quizContext.state.quizRows.length / 4) === 11
    || Math.floor(quizContext.state.quizRows.length / 4) === 12
    || Math.floor(quizContext.state.quizRows.length / 4) === 13
    || Math.floor(quizContext.state.quizRows.length / 4) === 14
    ? Math.floor(quizContext.state.quizRows.length / 4)
    : Number(Math.floor(quizContext.state.quizRows.length / 4).toString().split("").pop());

  const wordShort = tasksShort === 1
    ? "задание"
    : tasksShort === 2 || tasksShort === 3 || tasksShort === 4
      ? "задания"
      : "заданий";

  const longQuiz = `${quizContext.state.quizRows.length} ${wordLong}`;
  const shortQuiz = `${Math.floor(quizContext.state.quizRows.length / 4)} ${wordShort}`;

  return (
    <div key={"inline-radio"} className="mb-3">

      <table style={{ width: "100%" }}><tbody><tr>
        <td>
          <Form>
            <Form.Label>Длинный квиз:</Form.Label><br />
            <Form.Check
              inline
              label={longQuiz}
              name="length"
              type="radio"
              value="long"
              checked={quizContext.state.quizLengthStatus === "long" ? true : false}
              id={1}
              onChange={(e) => quizContext.dispatch({
                type: "setQuizLength",
                payload: e.target.value
              })}
            />
          </Form>
        </td>
        <td>
          <Form>
            <Form.Label>Короткий квиз:</Form.Label><br />
            <Form.Check
              inline
              label={shortQuiz}
              name="length"
              type="radio"
              value="short"
              checked={quizContext.state.quizLengthStatus === "short" ? true : false}
              id={2}
              onChange={(e) => quizContext.dispatch({
                type: "setQuizLength",
                payload: e.target.value
              })}
            />
          </Form>
        </td>
      </tr></tbody></table>
    </div>
  )
}

export default FormSelectQuizLength