import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { QuizContext } from "../../App";
// import Badge from 'react-bootstrap/Badge';

const ModalAddRemoveRow = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <>
      {quizContext.state.quizStatus === "edit"
        ? <>
          <Modal
          size="sm"
          data-bs-theme={theme}
            show={quizContext.state.alertTable}
            onHide={() => quizContext.dispatch({
              type: "hideAlertEditTable"
            })}
          >
            <Modal.Header closeButton data-bs-theme={theme}
              className={`${theme}-modal-header`}  />

            <Modal.Body className={`${theme}-modal-body`}>

              {quizContext.state.quizRowsEdit.length > 5
                &&
                <div style={{ textAlign: "center" }}>
                  <p><span className="error">WARNING!!!</span><br /> You won't be able to restore the removed row.</p>
                  <button
                    className="col-row-remove-btn"
                    onClick={() => quizContext.dispatch({ type: "removeRowEdit" })}
                  >REMOVE ROW {quizContext.state.rowNum} </button>
                </div>

              }

            </Modal.Body>
          </Modal>
        </>

        : <>
          <Modal
            show={quizContext.state.alertTable}
            onHide={() => quizContext.dispatch({
              type: "hideAlertEditTable"
            })}
          >
            <Modal.Header closeButton data-bs-theme={theme}
              className={`${theme}-modal-header`}  />
            <Modal.Body className={`${theme}-modal-body`}>

            <div style={{ textAlign: "center" }}>
                <button
                  className="col-row-add-btn"
                  onClick={() => quizContext.dispatch({ type: "addRow" })}
                >ADD ROW</button>
                <p style={{ fontSize: "1.2rem", marginTop: "15px" }}>after row {quizContext.state.rowNum}</p>
              </div>
              {quizContext.state.values.length > 5
                ? <div style={{ textAlign: "center" }}>
                  <hr />
                  <p><span className="error">WARNING!!!</span><br /> You won't be able to restore the removed row.</p>
                  <button
                    className="col-row-remove-btn"
                    onClick={() => quizContext.dispatch({ type: "removeRow" })}
                  >REMOVE ROW {quizContext.state.rowNum} </button>
                </div>
                : null
              }


              {/* <div><Badge
                bg="success"
                style={{ cursor: "pointer", fontWeight: "700" }}
                onClick={() => quizContext.dispatch({ type: "addRow" })}
              >Add Row</Badge> after row {quizContext.state.rowNum}.</div>

              {quizContext.state.values.length > 5
                && <>
                  <div style={{ marginTop: "25px" }}><Badge
                    bg="danger"
                    style={{ cursor: "pointer" }}
                    onClick={() => quizContext.dispatch({ type: "removeRow" })}
                  >Remove Row {quizContext.state.rowNum}</Badge></div>
                  <div><span className="error">WARNING!!!</span> You won't be able to restore the removed row.</div>
                </>
              } */}

            </Modal.Body>

          </Modal>
        </>
      }
    </>
  )
}

export default ModalAddRemoveRow