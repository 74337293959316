import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { QuizContext } from "../App";
import Loading from "../components/Loading";
import ButtonsSocialMedia from "../components/buttons/ButtonsSocialMedia";
import CardNavQuizLibrary from "../componentsRU/cards/CardNavQuizLibrary";
import CardNavCreateQuiz from "../componentsRU/cards/CardNavCreateQuiz";
import CardNavUser from "../componentsRU/cards/CardNavUser";
import CardNavFAQ from "../componentsRU/cards/CardNavFAQ";
import CardNavLogInSignUp from "../componentsRU/cards/CardNavLogInSignUp";
import TextHomeHeader from "../componentsRU/text/TextHomeHeader";

const HomeRU = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const dispatch = quizContext.dispatch;

  // Check Server
  useEffect(() => {
    const fetchData = async () => {
      if (quizContext.state.currentUser) {
        try {
          const res = await axios.get("/auth/check");
          // console.log("res.data", res.data)
          const checkServer = res.data
          // console.log("checkServer", checkServer)
          dispatch({ type: "fetchCheckServer", checkServer });

        } catch (err) {
          const error = err.response.data.message;
          console.log("Check Server error:", error);
          dispatch({ type: "fetchCheckServerErr", error });
        }
      } else {
        dispatch({ type: "fetchCheckServer" });
      }
    }
    fetchData();
  }, [dispatch, quizContext.state.currentUser]);

  return (
    <div>

      {quizContext.state.loading
        ? <Loading />
        : <>

          {/* <VideoForHomePage /> */}

          {quizContext.state.errorAuth
            && <p style={{ textAlign: "center", marginTop: "15px" }}>
              <span className="error">ОШИБКА АУТЕНТИФИКАЦИИ</span><br />
                Чтобы решить проблему,<Link to="/login"><button
                className={`${theme}-small-btn`}
                onClick={() => quizContext.dispatch({ type: "switchToLogin" })}
              >
                ВОЙДИТЕ
              </button></Link> заново.<br />
              Несмотря на проблему, Вы по-прежнему можете пользоваться БИБЛИОТЕКОЙ КВИЗОВ и создавать квизы, не сохраняя их.
            </p>
          }

          <TextHomeHeader />

          <Row className="justify-content-center"
            style={{
              marginTop: quizContext.state.currentUser
                ? "20px"
                : "0"
            }}
          >
            <Col xs={6} sm={6} md={6} lg={6}>
              <CardNavQuizLibrary />
            </Col>
            <Col xs={6} sm={6} md={6} lg={6}>
              <CardNavCreateQuiz />
            </Col>
          </Row>
          <Row className="justify-content-center"
            // style={{ marginTop: "10px" }}
          >
            <Col xs={6} sm={6} md={6} lg={6}>
              {!quizContext.state.currentUser
                || (quizContext.state.errorAuth && quizContext.state.currentUser)
                ? <CardNavLogInSignUp />
                : <CardNavUser />
              }
            </Col>
            <Col xs={6} sm={6} md={6} lg={6}>
              <CardNavFAQ />
            </Col>
          </Row>

          <div className="social-icons">
            <ButtonsSocialMedia />
          </div>
        </>
      }
    </div >
  )
}

export default HomeRU