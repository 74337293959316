import React, { useContext } from "react";
import { QuizContext } from "../../App";

const ButtonAddColumn = () => {

  const quizContext = useContext(QuizContext);

  return (
    <button
      className="add-col-row-btn"
      onClick={() => quizContext.dispatch({ type: "addColumnEdit" })}
    >
      add column
    </button>
  )
}

export default ButtonAddColumn