import React, { useContext } from "react";
import Table from 'react-bootstrap/Table';
import { QuizContext } from "../../App";

const TabelEarnLosePoints = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  const vote = quizContext.state.quizPoints.vote;
  const changeType = quizContext.state.quizPoints.changeType;
  const numVote = quizContext.state.quizPoints.numVote;
  const votePercent = quizContext.state.quizPoints.votePercent;

  return (
    <div style={{ overflowX: "auto" }}>
      <div className="data-table">
        <Table
          striped
          variant={theme}
          className="table table-bordered"
        >
          <thead>
            <tr style={{ textAlign: "center" }}>
              <th colSpan="2" style={{ borderRight: "1px solid black", width: "50%" }}>ОЧКИ <span style={{ fontSize: "1.2rem" }}>+</span></th>
              <th colSpan="2">ОЧКИ <span style={{ fontSize: "1.2rem" }}>-</span></th>
            </tr>
            <tr style={{ fontSize: "0.9rem", textAlign: "center" }}>
              <th>Условие</th>
              <th style={{ borderRight: "1px solid black" }}>Очки</th>
              <th>Условие</th>
              <th>Очки</th>
            </tr>
          </thead>
          <tbody style={{ textAlign: "center", verticalAlign: "middle" }}>
            <tr>
              <td className="table-fontsize">ЛИЧНЫЙ<br /> &#8595;<br /> ОБЩЕДОСТУПНЫЙ</td>
              <td style={{ fontSize: "1.5rem", borderRight: "1px solid black" }}>+ {changeType}</td>
              <td className="table-fontsize">ОБЩЕДОСТУПНЫЙ<br /> &#8595;<br /> ЛИЧНЫЙ</td>
              <td>
                <span style={{ fontSize: "1.5rem" }}>- все очки,</span><br />
                <span style={{ fontSize: "0.8rem" }}>набранные за квиз</span>
              </td>
            </tr>
            <tr>
              <td className="table-fontsize">Рекомнедован</td>
              <td style={{ borderRight: "1px solid black" }}>
                <span style={{ fontSize: "1.5rem" }}>+ {vote}</span><br />
                <span style={{ fontSize: "0.8rem" }}>За каждый голос</span>
              </td>
              <td className="table-fontsize">Не рекомендован</td>
              <td>
                <span style={{ fontSize: "1.5rem" }}>- {vote}</span><br />
                <span style={{ fontSize: "0.8rem" }}>За каждый голос</span>
              </td>
            </tr>
            <tr>
              <td className="table-fontsize">Прохождение</td>
              <td style={{ borderRight: "1px solid black" }}>
                <span style={{ fontSize: "1.5rem" }}>+ 1</span><br />
                <span style={{ fontSize: "0.8rem" }}>каждый раз<br /> когда другие пользователи проходят Ваш квиз</span>
              </td>
              <td className="table-fontsize">
                &gt;= {votePercent}% не рекомендован<br />
                если<br />
                &gt;= {numVote} голосов<br />
              </td>
              <td>
                <span style={{ fontSize: "1.5rem" }}>- все очки</span><br />
                <span style={{ fontSize: "0.8rem" }}>набранные за квиз.<br />
                  Тип квиза меняется на ЛИЧНЫЙ</span>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default TabelEarnLosePoints