import React, { useContext } from "react";
import { Image } from "react-bootstrap";
import { QuizContext } from "../../App";


const TextAndImgForVideoHomePage = () => {

  const quizContext = useContext(QuizContext);

  return (
    <div style={{ margin: "0.8em" }}>
      <h3 className="video-h3" style={{textAlign: "left"}}>
        Создавайте из любых данных квизы<br />
        и запоминайте огромные объёмы информации<br />
        в игровой форме.
      </h3>
      <table><tbody><tr>
        <td style={{ width: "25%", padding: "0.15em" }}>
          <Image fluid
            src={require('../videos/forVideoHomePageChemistry.jpg')}
            width="100%"
            height="auto"
            className="img-video-home"
            alt="Квиз. Химия"
          />
        </td>
        <td style={{ width: "25%", padding: "0.15em" }}>
          <Image fluid
            src={require('../videos/forVideoHomePageThai.jpg')}
            width="100%"
            height="auto"
            className="img-video-home"
            alt="Квиз. Тайский язык"
          />

        </td>
        <td style={{ width: "25%", padding: "0.15em" }}>
          <Image fluid
            src={require('../videos/forVideoHomePageMath.jpg')}
            width="100%"
            height="auto"
            className="img-video-home"
            alt="Квиз. Математика"
          />

        </td>
        <td style={{ width: "25%", padding: "0.15em" }}>
          <Image fluid
            src={require('../videos/forVideoHomePageRus.jpg')}
            width="100%"
            height="auto"
            className="img-video-home"
            alt="Квиз. Части речи"
          />

        </td>
      </tr></tbody></table>

      <div style={{ marginTop: "0.8em" }}>
        <button
          className="light-main-btn"
          onClick={() => quizContext.dispatch({
            type: "switchToVideo"
          })}
        >
          ВИДЕО
        </button>
      </div>

    </div>
  )
}

export default TextAndImgForVideoHomePage