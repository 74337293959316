// User

const moreTeams = (mem, numTeams, state) => {

  const status = (mem === "STU" && numTeams < state.membPlan.student.teams)
    || (mem === "BRZ" && numTeams < state.membPlan.bronze.teams)
    || (mem === "SLV" && numTeams < state.membPlan.silver.teams)
    || (mem === "GLD" && numTeams < state.membPlan.gold.teams)
    || (mem === "GURU" && numTeams < state.membPlan.guru.teams)
    ? true
    : false;

  const num = status && mem === "STU"
    ? state.membPlan.student.teams - numTeams
    : status && mem === "BRZ"
      ? state.membPlan.bronze.teams - numTeams
      : status && mem === "SLV"
        ? state.membPlan.silver.teams - numTeams
        : status && mem === "GLD"
          ? state.membPlan.gold.teams - numTeams
          : status && mem === "GURU"
            ? state.membPlan.guru.teams - numTeams
            : 0;

  const numMemb = mem === "STU"
    ? state.membPlan.student.members
    : mem === "BRZ"
      ? state.membPlan.bronze.members
      : mem === "SLV"
        ? state.membPlan.silver.members
        : mem === "GLD"
          ? state.membPlan.gold.members
          : mem === "GURU"
            ? state.membPlan.guru.members
            : 0;

  const more = numTeams > 0
    ? "more"
    : "";

  const moreRU = numTeams > 0
    ? "ещё"
    : "";

  const teams = num > 1
    ? "teams"
    : "team";

  const teamsRU = num === 1
    ? "команду"
    : num === 2 || num === 3 || num === 4
      ? "команды"
      : "команд";

  const each = num > 1
    ? "each"
    : "";

    const eachRU = num > 1
    ? "в каждой"
    : "";

  const message = `You can start ${num} ${more} ${teams} of ${numMemb} members ${each}`
  const messageRU = `Вы можете создать ${moreRU} ${num} ${teamsRU} по ${numMemb} членов ${eachRU}`

  return {
    status,
    message,
    messageRU
  }
}

export default moreTeams