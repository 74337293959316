// FormSelectQuestion

const selectQuestion = (state, action) => {
    const question = action.payload;
    return { 
      ...state,
      errorMessage: false,
      question
     };
}

export default selectQuestion