import React, { useContext } from "react";
import { QuizContext } from "../../App";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  // GabShareButton,
  // HatenaShareButton,
  // InstapaperShareButton,
  // LineShareButton,
  LinkedinShareButton,
  LivejournalIcon,
  LinkedinIcon,
  LivejournalShareButton,
  MailruShareButton,
  MailruIcon,
  OKShareButton,
  OKIcon,
  // PinterestShareButton,
  // PinterestIcon,
  // PocketShareButton,
  RedditShareButton,
  RedditIcon,
  TelegramShareButton,
  TelegramIcon,
  TumblrShareButton,
  TumblrIcon,
  TwitterShareButton,
  TwitterIcon,
  ViberShareButton,
  ViberIcon,
  VKShareButton,
  VKIcon,
  WhatsappShareButton,
  WhatsappIcon,
  // WorkplaceShareButton,
} from "react-share";
// import { Col, Row } from "react-bootstrap";

// import {
//   FacebookMessengerIcon,
//   GabIcon,
//   HatenaIcon,
//   InstapaperIcon,
//   LineIcon,
//   LivejournalIcon,
//   PocketIcon,
//   WeiboIcon,
//   WorkplaceIcon,
//   XIcon,
// } from "react-share";

const ButtonsSocialMedia = () => {

  const quizContext = useContext(QuizContext);

  const currentPageURL = window.location.href;

  const title = Object.keys(quizContext.state.quiz).length !== 0
    ? ` QUIZ: ${quizContext.state.quiz.title} `
    : quizContext.state.userPage
      ? ` QUIZZES. User: ${quizContext.state.currentUser.username} `
      : quizContext.state.otherUserPage
        ? ` QUIZZES. User: ${quizContext.state.otherUser.username} `
        : " QUIZZES ";

  const quizDesc = " Turn any data into a quiz and memorize huge amounts of information in a gamelike way. "


  const titleRU = Object.keys(quizContext.state.quiz).length !== 0
    ? ` КВИЗ: ${quizContext.state.quiz.title} `
    : quizContext.state.userPage
      ? ` КВИЗЫ. Пользователь: ${quizContext.state.currentUser.username} `
      : quizContext.state.otherUserPage
        ? ` КВИЗЫ. Пользователь: ${quizContext.state.otherUser.username} `
        : " КВИЗЫ ";

  const quizDescRU = " Создавайте из любых данных квизы и запоминайте огромные объёмы информации в игровой форме. "


  return (
    <div>
      {quizContext.state.isRussia
        ? <div
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >

          <EmailShareButton url={currentPageURL} subject={titleRU}>
            <EmailIcon round size={23} />
          </EmailShareButton>

          <MailruShareButton url={currentPageURL} title={titleRU} caption={quizDescRU}>
            <MailruIcon round size={23} />
          </MailruShareButton>

          <OKShareButton url={currentPageURL} title={titleRU} caption={quizDescRU}>
            <OKIcon round size={23} />
          </OKShareButton>

          <TumblrShareButton url={currentPageURL} title={titleRU} caption={quizDescRU}>
            <TumblrIcon round size={23} />
          </TumblrShareButton>

          <ViberShareButton url={currentPageURL} title={titleRU}>
            <ViberIcon round size={23} />
          </ViberShareButton>

          <TelegramShareButton url={currentPageURL} title={titleRU}>
            <TelegramIcon round size={23} />
          </TelegramShareButton>

          <RedditShareButton url={currentPageURL} title={titleRU}>
            <RedditIcon round size={23} />
          </RedditShareButton>

          <LivejournalShareButton url={currentPageURL} title={titleRU}>
            <LivejournalIcon round size={23} />
          </LivejournalShareButton>

          <VKShareButton url={currentPageURL} title={titleRU}>
            <VKIcon round size={23} />
          </VKShareButton>

          <WhatsappShareButton url={currentPageURL} title={titleRU} separator={quizDescRU} >
            <WhatsappIcon round size={23} />
          </WhatsappShareButton>
        </div>
        : <div
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >

          <EmailShareButton url={currentPageURL} subject={title}>
            <EmailIcon round size={23} />
          </EmailShareButton>

          <FacebookShareButton url={currentPageURL} hashtag="#quiz">
            <FacebookIcon round size={23} />
          </FacebookShareButton>

          <LinkedinShareButton url={currentPageURL} title={title} summary={quizDesc}>
            <LinkedinIcon round size={23} />
          </LinkedinShareButton>

          <TumblrShareButton url={currentPageURL} title={title} caption={quizDesc}>
            <TumblrIcon round size={23} />
          </TumblrShareButton>

          <RedditShareButton url={currentPageURL} title={title}>
            <RedditIcon round size={23} />
          </RedditShareButton>

          <TelegramShareButton url={currentPageURL} title={title}>
            <TelegramIcon round size={23} />
          </TelegramShareButton>

          <TwitterShareButton url={currentPageURL} title={title}>
            <TwitterIcon round size={23} />
          </TwitterShareButton>

          <ViberShareButton url={currentPageURL} title={title}>
            <ViberIcon round size={23} />
          </ViberShareButton>

          <VKShareButton url={currentPageURL} title={title}>
            <VKIcon round size={23} />
          </VKShareButton>

          <WhatsappShareButton url={currentPageURL} title={title} separator={quizDesc} >
            <WhatsappIcon round size={23} />
          </WhatsappShareButton>
        </div>
      }
    </div>
  )
}

export default ButtonsSocialMedia