import React, { useContext, useEffect } from "react";
import { QuizContext } from "../App";
import Card from "react-bootstrap/Card";
import Error from "../pages/Error";
import FormOTP from "./forms/FormOTP";
import ButtonCancelAndGoToPrevPage from "./buttons/ButtonCancelAndGoToPrevPage";
import switchToGetOTP from "../reducer/actions/userActions/SwitchToGetOTP";
import switchToNewPassword from "../reducer/actions/userActions/SwitchToNewPassword";

const PasswordRecoveryGetOTP = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const isRunning = quizContext.state.isRunning;
  const dispatch = quizContext.dispatch;
  const sec = quizContext.state.sec;

  useEffect(() => {
    let interval;
    if (isRunning) {
      let time = sec;
      interval = setInterval(() => {
        if (time > 0) {
          time -= 1;
          dispatch({ type: "setSecs", sec: time })
        }
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isRunning, dispatch, sec]);


  return (
    <div>
      {quizContext.state.errorVerifyOTP === "500"
        ? <Error />
        : <>
          <h2 className="header">
            EMAIL VERIFICATION
          </h2>

          <Card
            data-bs-theme={theme}
            style={{
              padding: "25px",
              boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px"
            }}>
            <p style={{ textAlign: "center" }}>Enter the OTP code sent to your email: <span style={{ fontWeight: "600" }}>{quizContext.state.email}</span></p>
            <div style={{ margin: "5px 0 30px 0", textAlign: "center" }}>
              <FormOTP />
            </div>
            <div style={{ textAlign: "center" }}>

              <button
                className={`${theme}-main-btn`}
                onClick={(e) => {
                  e.preventDefault()
                  switchToNewPassword(quizContext.dispatch, quizContext.state)
                  quizContext.dispatch({ type: "switchToNewPassword" })
                }}
              >
                SUBMIT OTP
              </button>

              <ButtonCancelAndGoToPrevPage />



              {quizContext.state.errorVerifyOTP && quizContext.state.errorVerifyOTP !== "500"
                && <div
                  style={{
                    fontSize: "2rem",
                    marginTop: "25px",
                    color: "red",
                    fontWeight: "600"
                  }}
                >Wrong OTP code</div>
              }

            </div>
          </Card>


          <div>
            {quizContext.state.sec === 0
              ? <div style={{ textAlign: "center", marginTop: "25px" }}>
                <button
                  className={`${theme}-main-btn`}
                  onClick={(e) => {
                    e.preventDefault()
                    switchToGetOTP(quizContext.dispatch, quizContext.state)
                    quizContext.dispatch({ type: "switchToGetOTP" })
                  }}
                >
                  RESEND OTP
                </button>
              </div>
              : <div style={{ textAlign: "center", marginTop: "25px" }}>
                Didn't receive the code?<br />
                Check SPAM folder.<br />
                Still nothing?<br />
                Resend OTP in {quizContext.state.sec} seconds.
              </div>
            }
          </div>
        </>
      }
    </div>
  )
}

export default PasswordRecoveryGetOTP