import React, { useContext } from "react";
import { QuizContext } from "../../App";
import ButtonMyPage from "../buttons/ButtonMyPage";
import ButtonQuizLibrary from "../buttons/ButtonQuizLibrary";
import ButtonQuizCreate from "../buttons/ButtonQuizCreate";

const TextUnsavedQuiz = () => {

  const quizContext = useContext(QuizContext);

  return (
    <div style={{
      paddingBottom: "450px",
      marginTop: "40px",
      width: "100%",
      textAlign: "center",
      fontSize: "1.5rem"
    }}>
      <div style={{
        fontSize: "2.5rem",
        fontWeight: "600",
        lineHeight: "2.5rem",
        color: "red"
      }}>
        Вы не сохранили<br />
        свой квиз
      </div>

      <div style={{marginTop: "25px"}}>Он больше не существует</div>

      <div
        style={{
          textAlign: "center",
          marginTop: "20px"
        }}
      >
        <ButtonQuizLibrary />
      </div>

      <div
        style={{
          textAlign: "center",
          marginTop: "10px"
        }}
      >
        <ButtonQuizCreate />
      </div>

      <div
        style={{
          textAlign: "center",
          marginTop: "10px"
        }}
      >
        {quizContext.state.currentUser !== null
          && <ButtonMyPage />
        }
      </div>
    </div>
  )
}

export default TextUnsavedQuiz