import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import { QuizContext } from "../../App";
import removeQuizFromTeam from "../../reducer/actions/groupActions/RemoveQuizFromTeam";

const FormTeamRemoveQuiz = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Form>

      <Form.Label
        style={{
          fontWeight: "600",
          marginTop: "10px"
        }}
      >
        Remove quizzes from the team
      </Form.Label>

      <Form.Select
        data-bs-theme={theme}
        style={{
          border: theme === "dark"
            ? "1px solid #77797c"
            : "1px solid #212529"
        }}
        onChange={(e) => quizContext.dispatch({
          type: "selectQuizToRemoveFromTeam",
          payload: e.target.value
        })}
      >

        <option value={"- Select Quiz -"}>- Select Quiz -</option>
        {quizContext.state.teamQuizzes.map((el, i) =>
          <option key={i} value={el.title}>{el.title} — contributed by {el.username}</option>
        )}
      </Form.Select>

      <div
        style={{
          textAlign: "left",
          marginTop: "15px"
        }}
      >
        {quizContext.state.teamQuizTitleToRemove !== ""
          && quizContext.state.removeQuizStatus === "remvove"
          && <>
            <span
              style={{ fontSize: "1.2rem" }}
            >
              {quizContext.state.teamQuizTitleToRemove}
            </span>
            <button
              className={`${theme}-fourth-btn`}
              onClick={(e) => {
                e.preventDefault()
                removeQuizFromTeam(
                  quizContext.dispatch,
                  quizContext.state
                )
                quizContext.dispatch({
                  type: "removeQuizFromTeam"
                })
              }}
            >
              REMOVE
            </button>
            <button
              className="third-btn"
              onClick={() => quizContext.dispatch({
                type: "notAddQuiz"
              })}
            >
              NO
            </button>
          </>
        }

        <div style={{ textAlign: "left", lineHeight: "0.5rem" }}>
          <p style={{ fontWeight: "600", marginTop: "20px" }}>
            Removed quizzes:
          </p>
          {quizContext.state.teamQuizTitlesToRemove.filter((el, i) =>
            quizContext.state.teamQuizTitlesToRemove.indexOf(el) === i).map((el, i) =>
              <p key={i} style={{ marginLeft: "15px" }}>{el}</p>
            )}
        </div>
      </div>

    </Form>
  )
}
export default FormTeamRemoveQuiz