// ButtonsTab

const switchTabRight = (state) => {
  return {
    ...state,
    tabStatus: "right",
    video: false,
  }
}

export default switchTabRight