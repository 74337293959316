import React, { useContext } from "react";
import { QuizContext } from "../../App";

const TextHowToEditQuiz = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <>
    <p>Click MY QUIZZES on your page.</p>
      <p>
        <img
          src={require('../images/instruction-edit-quiz-clickQuizzes.jpg')}
          style={{ width: "100%" }}
          alt={'instr'}
        />
      </p>

      <p>Click the {theme === "dark"
            ? <img
              src={require('../images/editW.png')}
              style={{ width: "15px" }}
              alt={'edit'} />
            : <img
              src={require('../images/edit.png')}
              style={{ width: "15px" }}
              alt={'edit'} />} symbol opposite to the name of the quiz you want to edit.</p>
      <p>
        <img
          src={require('../images/instruction-edit-quiz-clickEditList.jpg')}
          style={{ width: "100%" }}
          alt={'instr'}
        />
      </p>

      <p>Also, you can go to edit your quiz from its title page.</p>
      <p>
        <img
          src={require('../images/instruction-edit-quiz-goFromPage.jpg')}
          style={{ width: "100%" }}
          alt={'instr'}
        />
      </p>

      <p>Editing quiz settings, you can change quiz title, description and type.</p>
      <p>
        <img
          src={require('../images/instruction-edit-quiz-settings.jpg')}
          style={{ width: "100%" }}
          alt={'instr'}
        />
      </p>

      <p>If you change the type from PRIVATE to PUBLIC, you must add quiz category and language.</p>
      <p>
        <img
          src={require('../images/instruction-edit-quiz-settingsToPublic.jpg')}
          style={{ width: "100%" }}
          alt={'instr'}
        />
      </p>

      <p>After you're done, submit the edited quiz settings.</p>
      <p>
        <img
          src={require('../images/instruction-edit-quiz-settingsSubmit.jpg')}
          style={{ width: "100%" }}
          alt={'instr'}
        />
      </p>

      <p>After you submitted the edited quiz settings, you can go to the quiz or edit quiz contents.</p>
      <p>
        <img
          src={require('../images/instruction-edit-quiz-settingsSubmitted.jpg')}
          style={{ width: "100%" }}
          alt={'instr'}
        />
      </p>

      <p>You can edit quiz contents right here or in the full-screen mode. You can correct typos and other issues and even add and remove rows and columns.</p>
      <p>
        <img
          src={require('../images/instruction-edit-quiz-contents.jpg')}
          style={{ width: "100%" }}
          alt={'instr'}
        />
      </p>

      <p>When you're done, confirm the changes.</p>
      <p>
        <img
          src={require('../images/instruction-edit-quiz-contentsConfirm.jpg')}
          style={{ width: "100%" }}
          alt={'instr'}
        />
      </p>

      <p>After confirming the changes, submit the edited quiz.</p>
      <p>
        <img
          src={require('../images/instruction-edit-quiz-contentsSubmit.jpg')}
          style={{ width: "100%" }}
          alt={'instr'}
        />
      </p>

      <p>After you submitted the edited quiz contents, you can go to the quiz or edit quiz settings.</p>
      <p>
        <img
          src={require('../images/instruction-edit-quiz-contentsSubmitted.jpg')}
          style={{ width: "100%" }}
          alt={'instr'}
        />
      </p>

    </>
  )
}

export default TextHowToEditQuiz