// FormEditQuizDesc

const editQuizDesc = (state, action) => {
  let quizDescAuthor = action.payload;

  const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  const url = quizDescAuthor.match(urlRegex);

  if (url) {

    if (url.length > 2) {
      const descArr = quizDescAuthor.split(" ");
      const ind = descArr.indexOf(url[2]);
      const descArr1 = descArr.slice(0, ind);
      const desc2 = descArr.slice(ind + 1);

      const descArr2 = desc2.filter(el => !el.match(urlRegex));
      quizDescAuthor = descArr1.concat(descArr2).join(" ");
    }

    return {
      ...state,
      quizDescAuthor,
      alertStatus: "editingDesc",
      errorMessage: false
    };

  } else {

    return {
      ...state,
      quizDescAuthor,
      alertStatus: "editing",
      errorMessage: false
    };
  }
}

export default editQuizDesc