import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { QuizContext } from "../../App";
import ButtonCancelAndHideAlert from "../buttons/ButtonCancelAndHideAlert";
import FormContact from "../forms/FormContact";
import ButtonSubmitContactSupport from "../buttons/ButtonSubmitContactSupport";

const ModalContact = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Modal
      data-bs-theme={theme}
      show={quizContext.state.alert}
      onHide={() => quizContext.dispatch({
        type: "hideAlert"
      })}
    >
      <Modal.Header closeButton data-bs-theme={theme}
        className={`${theme}-modal-header`}
      >
        Contact Customer Support
      </Modal.Header>
      <Modal.Body className={`${theme}-modal-body`}>

        {quizContext.state.messageSent === false
          ? <>
            <FormContact />
            {quizContext.state.errorMySQL
              && <p className="error">Something went wrong. Try again later.</p>
            }
          </>
          : <p>Your message has been sent.<br /> We'll get back to you ASAP.</p>
        }

        {quizContext.state.messageSent === false
          ? <>
            <div style={{ textAlign: "center", marginTop: "15px" }}>
              <ButtonSubmitContactSupport /> <ButtonCancelAndHideAlert />
            </div>
          </>
          : <div style={{ textAlign: "right", marginTop: "15px" }}>
            <button
              className="main-btn"
              // style={{ marginTop: "0", textAlign: "right" }}
              onClick={() => quizContext.dispatch({
                type: "hideAlert"
              })}
            >
              OK
            </button>
          </div>
        }
      </Modal.Body>
    </Modal>
  )
}

export default ModalContact