import React, { useContext } from "react";
import { QuizContext } from "../../App";

const ButtonStartQuiz = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <>
      {quizContext.state.quizLengthStatus === "long"
        ? <button
          className={`${theme}-main-btn`}
          onClick={() => quizContext.dispatch({ type: "solveQuizLong" })}
        >
          START QUIZ
        </button>
        : <button
          className={`${theme}-main-btn`}
          onClick={() => quizContext.dispatch({ type: "solveQuiz" })}
        >
          START QUIZ
        </button>
      }
    </>
  )

}

export default ButtonStartQuiz