// CardCreateTeam

const setTypeAddMemb = (state, action) => {
 
  const typeAddMemb = action.payload;

  return {
    ...state,
    typeAddMemb,
  };
}

export default setTypeAddMemb