import React, { useContext } from "react";
import { QuizContext } from "../../App";

const TextOtherUserHeader = () => {

  const quizContext = useContext(QuizContext);
  const date = new Date(quizContext.state.otherUser.joined_at);
  const year = date.getFullYear();
  const monthName = date.toLocaleString('ru-RU', { month: 'long' });

  return (
    <>
      <h2 className="header">{quizContext.state.otherUser.username}</h2>

      <p>
        ID: <span style={{ fontWeight: "600" }}>{quizContext.state.otherUser.id}</span><br />
        Аккаунт создан: <span style={{ fontWeight: "600" }}>{monthName} {year}</span><br />
        Рейтинг автора квизов: <span style={{ fontWeight: "600" }}>{quizContext.state.otherUser.score}</span>
      </p>
    </>
  )
}

export default TextOtherUserHeader