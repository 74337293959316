import React, { useContext } from "react";
import { QuizContext } from "../../App";

const ButtonOnceAgain = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (

    <button className={`${theme}-main-btn`}
      onClick={() => quizContext.dispatch({
        type: "backToAdjustQuiz"
      })}
    >
      ONCE AGAIN
    </button>
  )
}

export default ButtonOnceAgain