import axios from "axios";

// ButtonsSubmitEditedProfile

const submitEditedProfile = async (dispatch, state) => {

  let error = state.error;
  let currentUser = state.currentUser;
  const userId = state.currentUser.id;
  let username = state.username;
  const theme = state.userTheme === "default"
    ? null
    : state.userTheme;

  if (username === "") {
    username = state.currentUser.username
  } else {
    username = state.username
  }

  try {
    const res = await axios.post(`/user/update/${userId}`, {
      username,
      theme
    });
    currentUser = res.data;
  } catch (err) {
    error = err.response.data.message;
    console.log("submitEditedProfileErr err", error);
  }

  return dispatch({
    type: "submitEditedProfile",
    currentUser,
    error
  });
}

export default submitEditedProfile