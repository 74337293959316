import getRandomIndicesLong from './GetRandomIndicesLong';

// SolveQuiz (an action file)

const generateAnswersLong = (state) => {

  const quizRows = state.quizRows;

  const quizValues = quizRows.map((ee, i) => Object.keys(ee).map(e => ({
    header: e,
    value: quizRows[i][e]
  })));

  return getRandomIndicesLong(quizValues).map(el => {
    return el.map((elel) => {
      return [...quizValues[elel]];
    });
  });

}

export default generateAnswersLong