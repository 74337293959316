import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import { QuizContext } from "../../App";

const FormQuizTitle = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const name = quizContext.state.quizTitle
    ? quizContext.state.quizTitle
    : "";

  return (
    <Form>
      <Form.Label>
        {quizContext.state.tabStatus === "left"
          ? <><span
            style={{ fontWeight: "600" }}
          >ШАГ 3:</span> дайте название квизу</>
          : <><span
            style={{ fontWeight: "600" }}
          >ШАГ 2:</span> дайте название квизу</>
        }
      </Form.Label>
      {quizContext.state.fileName
        ? <Form.Control
          type="text"
          data-bs-theme={theme}
          style={{
            border: theme === "dark"
              ? "1px solid #77797c"
              : "1px solid #212529",
            textAlign: "center"
          }}
          value={name}
          onChange={(e) => quizContext.dispatch({
            type: "getQuizTitle",
            payload: e.target.value
          })}
        />
        : <Form.Control
          type="text"
          data-bs-theme={theme}
          style={{
            border: theme === "dark"
              ? "1px solid #77797c"
              : "1px solid #212529",
            textAlign: "center"
          }}
          placeholder="Название Квиза"
          value={name}
          onChange={(e) => quizContext.dispatch({
            type: "getQuizTitle",
            payload: e.target.value
          })}
        />

      }
    </Form >
  )
}

export default FormQuizTitle