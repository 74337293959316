import axios from "axios";

// ButtonsSubmitQuiz

const submitQuizAndSave = async (dispatch, state) => {

  let error = state.error;
  let newQuiz;

  const userId = state.currentUser.id

  try {
    newQuiz = await axios.post('/quiz/add', {
      title: state.quizTitle,
      type: "PRIVATE",
      times_taken: 0,
      rec_quiz: 0,
      unrec_quiz: 0,
      author_id: userId,
      quizOptions: state.quizOptions,
      quizRows: state.quizRows
    });
  } catch (err) {
    error = err.response.data.message;
    console.log("submitQuizAndSave err:", error);
  }

  const quizId = newQuiz.data.id

  return dispatch({
    type: "submitQuizAndSave",
    quizId,
    error
  });
};

export default submitQuizAndSave