// GenerateAnswersLong (an action file)


const getRandomIndicesLong = (data) => {

  const dataIndices = Array.from({ length: data.length }, (v, i) => i);
  const indices = [...dataIndices];

  const shuffledIndices = indices.sort(() => 0.5 - Math.random());

  let adjustedIndices;
  let last;
  if (indices.length % 2 === 0) {
    adjustedIndices = [...shuffledIndices];
    last = adjustedIndices.pop();
  } else {
    adjustedIndices = [...shuffledIndices];
  }

  const newIndices = indices.length % 2 === 0
    ? adjustedIndices.concat(adjustedIndices).concat(adjustedIndices).concat(adjustedIndices).concat([last, adjustedIndices[0], adjustedIndices[1], adjustedIndices[2]])
    : adjustedIndices.concat(adjustedIndices).concat(adjustedIndices).concat(adjustedIndices);

  const finalIndices = [];
  while (newIndices.length >= 4) {
    finalIndices.push(newIndices.splice(0, 4));
  }

  return finalIndices;
}
export default getRandomIndicesLong