import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import { QuizContext } from "../../App";
// import switchToGetOTPsignup from "../../reducer/actions/userActions/SwitchToGetOTPsignup";

const FormEmail = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (

    // <Form onSubmit={(e) => {
    //   e.preventDefault()
    //   switchToGetOTPsignup(quizContext.dispatch, quizContext.state)
    //   quizContext.dispatch({ type: "switchToGetOTPsignup" })
    // }}>
    <Form.Control
      type="email"
      required
      data-bs-theme={theme}
      style={{
        border: theme === "dark"
            ? "1px solid #77797c"
            : "1px solid #212529",
      }}
      placeholder="Email"
      onChange={(e) => quizContext.dispatch({
        type: "getEmail",
        payload: e.target.value
      })}
    />
    // </Form>
  )
}

export default FormEmail