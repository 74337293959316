import React, { useContext } from "react";
import Table from 'react-bootstrap/Table';
import { QuizContext } from "../../App";

const TabelEarnLosePoints = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  const vote = quizContext.state.quizPoints.vote;
  const changeType = quizContext.state.quizPoints.changeType;
  const numVote = quizContext.state.quizPoints.numVote;
  const votePercent = quizContext.state.quizPoints.votePercent;

  return (
    <div style={{ overflowX: "auto" }}>
      <div className="data-table">
        <Table
          striped
          variant={theme}
          className="table table-bordered"
        >
          <thead>
            <tr style={{ textAlign: "center" }}>
              <th colSpan="2" style={{ borderRight: "1px solid black", width: "50%" }}>POINTS <span style={{ fontSize: "1.2rem" }}>+</span></th>
              <th colSpan="2">POINTS <span style={{ fontSize: "1.2rem" }}>-</span></th>
            </tr>
            <tr style={{ fontSize: "0.9rem", textAlign: "center" }}>
              <th>Condition</th>
              <th style={{ borderRight: "1px solid black" }}>Points</th>
              <th>Condition</th>
              <th>Points</th>
            </tr>
          </thead>
          <tbody style={{ textAlign: "center", verticalAlign: "middle" }}>
            <tr>
              <td className="table-fontsize">PRIVATE<br /> &#8595;<br /> PUBLIC</td>
              <td style={{ fontSize: "1.5rem", borderRight: "1px solid black" }}>+ {changeType}</td>
              <td className="table-fontsize">PUBLIC<br /> &#8595;<br /> PRIVATE</td>
              <td>
                <span style={{ fontSize: "1.5rem" }}>- all points</span><br />
                <span style={{ fontSize: "0.8rem" }}>the quiz has gained</span>
              </td>
            </tr>
            <tr>
              <td className="table-fontsize">Upvote</td>
              <td style={{ borderRight: "1px solid black" }}>
                <span style={{ fontSize: "1.5rem" }}>+ {vote}</span><br />
                <span style={{ fontSize: "0.8rem" }}>for each upvote</span>
              </td>
              <td className="table-fontsize">Downvote</td>
              <td>
                <span style={{ fontSize: "1.5rem" }}>- {vote}</span><br />
                <span style={{ fontSize: "0.8rem" }}>for each downvote</span>
              </td>
            </tr>
            <tr>
              <td className="table-fontsize">Times taken</td>
              <td style={{ borderRight: "1px solid black" }}>
                <span style={{ fontSize: "1.5rem" }}>+ 1</span><br />
                <span style={{ fontSize: "0.8rem" }}>each time<br /> other users take the quiz</span>
              </td>
              <td className="table-fontsize">
                &gt;= {votePercent}% of downvotes<br />
                out of<br />
                &gt;= {numVote} votes<br />
              </td>
              <td>
                <span style={{ fontSize: "1.5rem" }}>- all points</span><br />
                <span style={{ fontSize: "0.8rem" }}>the quiz has gained.<br />
                  The quiz turns PRIVATE</span>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default TabelEarnLosePoints