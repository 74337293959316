import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { QuizContext } from "../App";
import Loading from "../components/Loading";
import ButtonsSocialMedia from "../components/buttons/ButtonsSocialMedia";
import ErrorRU from "./ErrorRU";
import TextOtherUserHeader from "../componentsRU/text/TextOtherUserHeader";
import AccordionUserPublicQuizzes from "../componentsRU/accordions/AccordionUserPublicQuizzes";

const OtherUserPage = () => {

  const quizContext = useContext(QuizContext);
  const dispatch = quizContext.dispatch;
  const { id } = useParams();

  //Get Other User
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`/user/other/${id}`);
        // console.log("res.data", res.data);
        const otherUserPage = res.data;
        // console.log("otherUserPage", otherUserPage);
        dispatch({ type: "fetchOtherUserPage", otherUserPage });

      } catch (err) {
        const error = err.response.data.message;
        console.log("otherUser useEffect err", error);
        dispatch({ type: "fetchOtherUserPageErr", error });
      }
    }
    fetchData();
  }, [id, dispatch]);

  return (
    <div style={{paddingBottom: "300px"}}>
      {quizContext.state.loading
        ? <Loading />
        : quizContext.state.errorMySQL
          || quizContext.state.error === "404"
          ? <ErrorRU />
          : <>
            <TextOtherUserHeader />
            <AccordionUserPublicQuizzes />
            <div className="social-icons">
              <ButtonsSocialMedia />
            </div>
          </>
      }
    </div>
  )
}

export default OtherUserPage