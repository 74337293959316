import React, { useContext } from "react";
import { QuizContext } from "../../App";
// import submitLogOut from "../../reducer/actions/userActions/SubmitLogOut";
// import { Link } from "react-router-dom";
// import Badge from 'react-bootstrap/Badge';

const TextUserHeader = () => {

  const quizContext = useContext(QuizContext);

  // const alertStatus = "log out";
  const date = new Date(quizContext.state.currentUser.joined_at);
  const year = date.getFullYear();
  const monthName = date.toLocaleString('ru-RU', { month: 'long' });
  const score = quizContext.state.createdQuizzes.length
    ? quizContext.state.createdQuizzes[0].score
    : 0;

  const userMembPlan = quizContext.state.currentUser.mem === "ADM"
    ? "АДМИН"
    : quizContext.state.currentUser.mem === "STU"
      ? "УЧЕНИК"
      : quizContext.state.currentUser.mem === "BRZ"
        ? "БРОНЗОВЫЙ"
        : quizContext.state.currentUser.mem === "SLV"
          ? "СЕРЕБРЯННЫЙ"
          : quizContext.state.currentUser.mem === "GLD"
            ? "ЗОЛОТОЙ"
            : quizContext.state.currentUser.mem === "GURU"
              ? "ГУРУ"
              : "СТАНДАРТНЫЙ"


  return (
    <>
      <h2 className="header">
        {quizContext.state.currentUser.username}
      </h2>
      <p>
        ID: <span style={{ fontWeight: "600" }}>{quizContext.state.currentUser.id}</span><br />
        Аккаунт создан: <span style={{ fontWeight: "600" }}>{monthName} {year}</span><br />
        Рейтинг автора квизов: <span style={{ fontWeight: "600" }}>{score}</span><br />
        Статус пользователя: <span style={{ fontWeight: "600" }}>{userMembPlan}</span>
      </p>
    </>
  )
}

export default TextUserHeader