import React, { useContext } from "react";
import { QuizContext } from "../../App";

const CardUserMyTeams = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const alertStatus = "my teams";

  return (
    <div
      className={`${theme}-card-user`}
      onClick={() => quizContext.dispatch({
        type: "showModal",
        alertStatus
      })}
    >
      MY TEAMS: {quizContext.state.userTeams.length}
    </div>
  )
}

export default CardUserMyTeams