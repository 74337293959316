import React, { useContext } from "react";
import { QuizContext } from "../../App";
import { Card } from "react-bootstrap";
import ButtonModalPrepareQuizFile from "../buttons/ButtonModalPrepareQuizFile";
import TextMistake from "../text/TextMistake";
import FormQuizFile from "../forms/FormQuizFile";
import FormQuizTitle from "../forms/FormQuizTitle";
import ButtonsSubmitQuizFile from "../buttons/ButtonsSubmitQuizFile";


const CardQuizCreateFromFile = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (

    <Card
      data-bs-theme={theme}
      style={{ boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px" }}
    >
      <div className={`${theme}-main-card`}>

        <div>
          <span style={{ fontWeight: "600" }}>
            STEP 1:</span> prepare an xlsx file for your quiz
        </div>

        <div
          style={{
            textAlign: "center",
            marginTop: "5px"
          }}
        >
          <ButtonModalPrepareQuizFile />
        </div>

        <div
          style={{ marginTop: "25px" }}
        >
          {quizContext.state.errorMessage === "file mistake"
            && <div><TextMistake /></div>
          }
          <FormQuizFile />
        </div>

        <div style={{ marginTop: "25px" }}>
          <FormQuizTitle />
        </div>

        <div style={{ marginTop: "25px" }}>
          <ButtonsSubmitQuizFile />
        </div>

      </div>
    </Card>
  )
}

export default CardQuizCreateFromFile