import React, { useContext } from "react";
import { QuizContext } from "../../App";

const CardUserFavQuizzes = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const alertStatus = "favorite quizzes";

  return (
    <div
      className={`${theme}-card-user`}
      onClick={() => quizContext.dispatch({
        type: "showModal",
        alertStatus
      })}
    >
      ИЗБРАННЫЕ КВИЗЫ: {quizContext.state.favQuizzes.length}
    </div>
  )
}

export default CardUserFavQuizzes