import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import { QuizContext } from "../../App";
import submitTeamMemberNewType from "../../reducer/actions/groupActions/SubmitTeamMemberNewType";

const FormTeamEditMemberType = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Form>
      <Form.Label
        style={{
          fontWeight: "600",
          marginTop: "10px"
        }}
      >
        Edit membership type
      </Form.Label>

      <Form.Select
        data-bs-theme={theme}
        style={{
          border: theme === "dark"
            ? "1px solid #77797c"
            : "1px solid #212529"
        }}
        onChange={(e) => quizContext.dispatch({
          type: "selectTeamMember",
          payload: e.target.value
        })}
      >
        <option value="- Select Member -">- Select Member -</option>
        {quizContext.state.teamMembers
          .filter(el => el.user_type !== "ADMIN" && el.user_type !== "INV")
          .map((el, i) =>
            <option key={i} value={el.username}>
              {el.username} (ID {el.user_id}) — {el.user_type === "MEMB" ? "member" : "assistant"}
            </option>
          )}
      </Form.Select>

      <div
        style={{
          textAlign: "left",
          marginTop: "15px"
        }}
      >
        {quizContext.state.teamMemberName !== "" && quizContext.state.changeTeamMemberTypeStatus === "chosen"
          && <>
            <label style={{ fontWeight: "600", fontSize: "1.2rem" }}>
              {quizContext.state.teamMemberName}
            </label>
            <select
              style={{
                width: "25%",
                marginLeft: "20px",
                background: theme === "dark"
                  ? "#212529"
                  : "white",
                border: theme === "dark"
                  ? "1px solid #77797c"
                  : "1px solid gray",
                color: theme === "dark"
                  ? "#d0d2d4"
                  : "#212529",
                borderRadius: "10%",
                padding: "0.5% 1%"
              }}
              onChange={(e) => quizContext.dispatch({
                type: "changeTeamMemberType",
                payload: e.target.value
              })}

            >
              <option value={quizContext.state.teamMemberType}>
                {quizContext.state.teamMemberType === "MEMB" ? "member" : "assistant"}
              </option>
              <option
                value={quizContext.state.teamMemberType === "MEMB" ? "ASST" : "MEMB"}
              >
                {quizContext.state.teamMemberType === "MEMB" ? "assistant" : "member"}
              </option>

            </select>

            <button
              className={`${theme}-fourth-btn`}
              onClick={(e) => {
                e.preventDefault()
                submitTeamMemberNewType(
                  quizContext.dispatch,
                  quizContext.state
                )
                quizContext.dispatch({
                  type: "submitTeamMemberNewType"
                })
              }}
            >
              SUBMIT
            </button>
          </>
        }
      </div>
    </Form>
  )
}

export default FormTeamEditMemberType
