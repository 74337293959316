import axios from "axios";
import getUserMembPlan from "../userActions/GetUserMembPlan";

// ButtonsSubmitEditedQuiz

const submitEditedQuiz = async (dispatch, state) => {

  let error = state.error;


  const changeType = state.quizPoints.changeType;
  const vote = state.quizPoints.vote;
  const quizDescAuthor = !state.quizDescAuthor || !state.quizDescAuthor.length  
    ? null
    : state.quizDescAuthor

  const quizType = state.quizType;
  const oldQuizType = state.oldQuizType;
  const oldUserScore = state.quiz.score;
  const catId = quizType === "PRIVATE"
    ? null
    : state.catId;
  const langId = quizType === "PRIVATE"
    ? null
    : state.langId

  const userScore = oldQuizType === "PRIVATE" && quizType === "PUBLIC"
    ? oldUserScore + changeType
    : oldQuizType === "PUBLIC" && quizType === "PRIVATE"
      ? oldUserScore - (changeType + ((state.quiz.rec_quiz * vote) - (state.quiz.unrec_quiz * vote)) + state.quiz.times_taken)
      : oldUserScore;

  const timesTaken = quizType === "PRIVATE" ? 0 : state.quiz.times_taken;
  const recQuiz = quizType === "PRIVATE" ? 0 : state.quiz.rec_quiz;
  const unrecQuiz = quizType === "PRIVATE" ? 0 : state.quiz.unrec_quiz;

  const userNumPubQuizzes = oldQuizType === "PRIVATE" && quizType === "PUBLIC"
    ? state.quiz.num_pub_quizzes + 1
    : oldQuizType === "PUBLIC" && quizType === "PRIVATE"
      ? state.quiz.num_pub_quizzes - 1
      : state.quiz.num_pub_quizzes;

  const userMem = state.quiz.payment === "FALSE"
    ? getUserMembPlan(userNumPubQuizzes, userScore, state.quiz.mem, state)
    : state.quiz.mem;

  if ((oldQuizType === "PRIVATE" && quizType === "PUBLIC") && (catId === null || langId === null)) {
    return dispatch({
      type: "submitEditedQuiz"
    });
  }

  try {
    await axios.post(`/quiz/update/${state.quizId}`, {
      title: state.quizTitle,
      type: quizType,
      cat_id: catId,
      lang_id: langId,
      desc_author: quizDescAuthor,
      times_taken: timesTaken,
      rec_quiz: recQuiz,
      unrec_quiz: unrecQuiz,
      oldQuizType,
      score: userScore,
      mem: userMem,
      num_pub_quizzes: userNumPubQuizzes
    });
  } catch (err) {
    error = err.response.data.message;
    console.log("submitEditedQuiz err:", error);
  }

  return dispatch({
    type: "submitEditedQuiz",
    quizType,
    oldQuizType,
    error
  });
}

export default submitEditedQuiz