// FormSelectAnswer

const selectAnswer = (state, action) => {
  const answer = action.payload;
  return { 
    ...state,
    errorMessage: false,
    answer 
  };
}

export default selectAnswer