import React, { useContext } from "react";
import Card from "react-bootstrap/Card";
import { QuizContext } from "../../App";
import ButtonCancelAndGoToPrevPage from "../buttons/ButtonCancelAndGoToPrevPage";
import ButtonSubmitCreatedTeam from "../buttons/ButtonSubmitCreatedTeam";
import FormTeamSetTypeAddQuiz from "../forms/FormTeamSetTypeAddQuiz";
import FormTeamSetTypeAddMemb from "../forms/FormTeamSetTypeAddMemb";
import FormTeamGetName from "../forms/FormTeamGetName";

const CardCreateTeam = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Card
      data-bs-theme={theme}
      style={{ boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px" }}
    >
      <div className={`${theme}-main-card`}>

        <FormTeamGetName />

        <div style={{ textAlign: "center" }}>
          <p style={{ fontWeight: "600", marginTop: "20px" }}>
            Кто может добавлять квизы в эту команду?
          </p>
          <FormTeamSetTypeAddQuiz />
        </div>
        <hr />
        <div style={{ textAlign: "center" }}>
          <p style={{ fontWeight: "600" }}>
            Кто может приглашать пользователей в команду?
          </p>
          <FormTeamSetTypeAddMemb />
        </div>
        <hr />

        <div style={{ textAlign: "center" }}>
          <ButtonSubmitCreatedTeam />
          <ButtonCancelAndGoToPrevPage />
        </div>

        {quizContext.state.error
          && <p style={{ color: "red" }}>{quizContext.state.error}</p>}

      </div>
    </Card>
  )
}

export default CardCreateTeam