import getInitialState from "../../../state/GetInitialState";

// TableTeamQuizzes

const goToMyQuiz = (state, action) => {

  const errorAuth = state.errorAuth;
  const errorMySQL = state.errorMySQL;
  const theme = state.theme;
  const isRussia = state.isRussia;
  const websiteLang = state.websiteLang;

  const quizId = action.el.id;
  const teamData = state.teamData.filter(el => el.quiz_id === quizId);

  return {
    ...getInitialState(),
    errorAuth,
    errorMySQL,
    theme,
    isRussia,
    websiteLang,
    teamData
  };

}

export default goToMyQuiz