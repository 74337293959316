import React from "react";
import { Card } from "react-bootstrap";

const TextQuizEditAddLink = () => {
  return (
    <Card style={{ margin: "5px 5px 15px 5px", padding: "10px" }}>
      <p style={{ textAlign: "center", fontWeight: "600" }}>КАК ДОБАВИТЬ ССЫЛКУ В ОПИСАНИЕ КВИЗА</p>
      <p>Вставьте URL <span style={{ fontWeight: "700" }}>после</span> слова, которое Вы хотите сделать ссылкой.</p>
      <p><span style={{ fontWeight: "700" }}>Пример:</span> Английские слова к уроку-5 https://lingust.ru/english/english-lessons/lesson5 с переводом на русский и транскрибцией.</p>
      <p><span style={{ fontWeight: "700" }}>Результат:</span> Английские слова к <a href="https://lingust.ru/english/english-lessons/lesson5" target="_blank" rel="noopener noreferrer">уроку-5</a> с переводом на русский и транскрибцией.</p>
      <p style={{ textAlign: "center" }}><span style={{ fontWeight: "600", color: "red", }}>ВНИМАНИЕ!!!</span><br />
        Вы можете добавить в описание квиза <span style={{ fontWeight: "600", color: "red" }}>не более 2-х ссылок</span>.</p>
    </Card>
  )
}

export default TextQuizEditAddLink