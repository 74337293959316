import React, { useContext } from "react";
import { QuizContext } from "../../App";
import {
  ButtonSubmitQuizAct,
  ButtonSubmitQuizAndSaveAct,
  ButtonSubmitQuizAndSaveInAct,
  ButtonSubmitQuizInAct
} from "./ButtonsSubmitQuiz";

const ButtonsSubmitQuizFile = () => {

  const quizContext = useContext(QuizContext);

  return (
    <>
      {quizContext.state.currentUser === null
        || quizContext.state.errorMySQL
        || quizContext.state.errorAuth
        ? <>
          <div style={{ marginTop: "25px" }}>
            <span style={{ fontWeight: "600" }}>
              STEP 4:</span> submit your quiz
          </div>
          <div className="sub-quiz">
            {quizContext.state.quizTitle === ""
              || !quizContext.state.fileUrl
              || quizContext.state.errorMessage
              ? <ButtonSubmitQuizInAct />
              : <ButtonSubmitQuizAct />
            }
          </div>
        </>
        : <>
          <div style={{ marginTop: "25px" }}>
            <span style={{ fontWeight: "600" }}>
              STEP 4:</span> submit and save your quiz or submit it without saving
          </div>
          <div className="sub-quiz">
            {quizContext.state.quizTitle === ""
              || !quizContext.state.fileUrl
              || quizContext.state.errorMessage
              ? <ButtonSubmitQuizAndSaveInAct />
              : <ButtonSubmitQuizAndSaveAct />
            }

            {quizContext.state.quizTitle === ""
              || !quizContext.state.fileUrl
              || quizContext.state.errorMessage
              ? <ButtonSubmitQuizInAct />
              : <ButtonSubmitQuizAct />
            }
          </div>
        </>
      }
    </>
  )
}

export default ButtonsSubmitQuizFile