// ModalAddRemoveColumn

const addColumnEdit = (state) => {

  const num = state.quizOptionsEdit.length;

  const numForTempColId = state.numForTempColId + 1;
  const tempColId = "tempColId" + numForTempColId;
  const tempValId = state.numForTempValId + 1;

  const quizOptionsEdit = [...state.quizOptionsEdit];
  const quizId = state.quizOptionsEdit[1].quiz_id;

  const newOptionsValue = { id: tempColId, header: "", quiz_id: quizId }
  quizOptionsEdit.splice(num, 0, newOptionsValue);

  const quizRows = [...state.quizRowsEdit];

  const quizRowsEdit = quizRows.map((el, i) => {

    if (Object.keys(el).length + 1 < quizOptionsEdit.length)

      el[tempColId] = ["",
        {
          id: "tempValId" + (tempValId + i),
          quiz_id: el[Object.keys(el)[0]][1].quiz_id,
          quiz_col_id: tempColId,
          quiz_row_id: el[Object.keys(el)[0]][1].quiz_row_id,
          header: "",
          new: "new col value"
        }
      ];
    return el
  });

  const numForTempValId = tempValId + (Object.keys(quizRowsEdit).length - 1);

  return {
    ...state,
    alertTable: false,
    alertStatus: "changes made",
    numForTempColId,
    numForTempValId,
    quizOptionsEdit,
    quizRowsEdit
  }
}

export default addColumnEdit