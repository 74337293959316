// ButtonOnceAgain

const backToAdjustQuiz = (state) => {

  return {
    ...state,
    quizStatus: "adjust",
    score: 0,
    storeScore: [],
    averageScore: 0,
    failedTasks: [],
    btnInact: false,
    alertStopQuiz: false,
    quizLengthStatus: state.quizLengthStatus
  }
}

export default backToAdjustQuiz