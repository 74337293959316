import React, { useContext } from "react";
import { QuizContext } from "../../App";
import { Link } from "react-router-dom";

const ButtonQuizCreate = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;


  return (
    <Link to="/create_quiz">
      <button
        className={`${theme}-main-btn`}
        onClick={() => quizContext.dispatch({
          type: "backToMainPage"
        })}
      >
        СОЗДАТЬ КВИЗ
      </button>
    </Link>
  )
}

export default ButtonQuizCreate