// ModalEditedProfileSumbitted

const backToUserPage = (state) => {
  
  return {
    ...state, 
    alert: false, 
    submitEditedProfileStatus: false, 
    userPageStatus: "main",
    alertStatus: null
  }
}

export default backToUserPage