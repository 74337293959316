import React, { useContext } from "react";
import { QuizContext } from "../../App";
import { Card } from "react-bootstrap";
import ButtonModalFullScreenTableCreateQuiz from "../buttons/ButtonModalFullScreenTableCreateQuiz";
import FormTableCreateQuiz from "../forms/FormTableCreateQuiz";
import FormQuizTitle from "../forms/FormQuizTitle";
import ButtonConfirmQuizTable from "../buttons/ButtonConfirmQuizTable";
import { ButtonCreateQuizDownloadXLSXfile } from "../buttons/ButtonDownloadXLSXfile";
import ButtonsSubmitQuizTable from "../buttons/ButtonsSubmitQuizTable";

const CardQuizCreateFromTable = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Card
      data-bs-theme={theme}
      style={{ boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px" }}
    >
      <div className={`${theme}-main-card`}>
        
        <div style={{ marginBottom: "0.5rem" }}>
          <span style={{ fontWeight: "600" }}>
            ШАГ 1:</span> заполните таблицу<br />
          <ButtonModalFullScreenTableCreateQuiz />
        </div>

        <div className="quiz-table">
          <FormTableCreateQuiz />
        </div>

        <div style={{ marginTop: "20px" }}>
          <FormQuizTitle />
        </div>

        <div style={{ marginTop: "15px" }}>
          <ButtonConfirmQuizTable />
        </div>

        <div style={{ marginTop: "15px" }}>
          <ButtonCreateQuizDownloadXLSXfile />
        </div>

        <div style={{ marginTop: "15px" }}>
          <ButtonsSubmitQuizTable />
        </div>
        
      </div>
    </Card>
  )
}

export default CardQuizCreateFromTable