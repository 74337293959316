import React from "react";
import { Card } from "react-bootstrap";

const TextQuizEditAddLink = () => {
  return (
    <Card style={{ margin: "5px 5px 15px 5px", padding: "10px" }}>
       <p style={{ textAlign: "center", fontWeight: "600" }}>HOW TO ADD A LINK TO THE QUIZ DESCRIPTION</p>
      <p>Put the URL <span style={{ fontWeight: "700" }}>after</span> the word you want to turn into a link.</p>
      <p><span style={{ fontWeight: "700" }}>Example:</span> Spanish words for lesson-5 https://lingust.com/espa%C3%B1ol/espa%C3%B1ol-lecciones/lecci%C3%B3n5 with the translation in English and transcription.</p>
      <p><span style={{ fontWeight: "700" }}>Result:</span> Spanish words for <a href="https://lingust.com/espa%C3%B1ol/espa%C3%B1ol-lecciones/lecci%C3%B3n5" target="_blank" rel="noopener noreferrer">lesson-5</a> with the translation in English and transcription.</p>
      <p style={{ textAlign: "center" }}><span style={{ fontWeight: "600", color: "red", }}>WARNING!!!</span><br />
      You may add <span style={{ fontWeight: "600", color: "red" }}>up to 2 links</span> to the quiz description.</p>
    </Card>
  )
}

export default TextQuizEditAddLink