import React from "react";
import TableMembershipPlans from "../tables/TableMembershipPlans";
import ButtonMyPage from "../buttons/ButtonMyPage";
import TabelEarnLosePoints from "../tables/TabelEarnLosePoints";

const TextBanTeamStart = () => {

  return (
    <div>
      <div style={{ textAlign: "center", marginTop: "30px" }}>
        <h3 style={{ fontWeight: "600" }}>To start a team,<br /> UPGRADE your membership plan.</h3>
        <p>
          To do it,<br />
          contribute quizzes to the QUIZ LIBRARY<br />
          and earn contribution rating points.</p>
      </div>

      <TableMembershipPlans />
      <TabelEarnLosePoints />

      <div className="col-md-12 text-center" style={{ marginTop: "35px" }}>
        <ButtonMyPage />
      </div>
    </div>
  )
}

export default TextBanTeamStart