import axios from "axios";

const switchToGetOTPsignup = async (dispatch, state) => {

  const sentOTP = (Math.floor(1000 + Math.random() * 9000)).toString();
  let error = state.error;
  const username = state.username;
  const email = state.email;
  const password = state.password;

  const inputs = {
    email,
    username,
    password,
    sentOTP
  };

  try {
    await axios.post("/auth/confirmemail", inputs);
  } catch (err) {
    error = err.response.data.message;
    console.log("switchToGetOTPsignup err:", error);
  };

  return dispatch({
    type: "switchToGetOTPsignup",
    sentOTP,
    error
  });

}

export default switchToGetOTPsignup




// if (state.email === "") {
//   return { ...state, enterData: true }
// } else if (!state.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
//   return { ...state, wrongData: true }
// } else {
//   return {
//     ...state,
//     isRunning: true,
//     sec: 90,
//     passwordRecovery: "getOTP",
//     sentOTP
//   }
// }




