// ModalForEditQuiz

const continueEditing = (state) => {
  if (state.tabStatus === "left") {
    return {
      ...state,
      alert: false,
      alertStatus: null,
      loading: true,
      tabStatus: "right"
    }
  } else {
    return {
      ...state,
      alert: false,
      alertStatus: null,
      loading: true,
      tabStatus: "left"
    }
  }
}

export default continueEditing