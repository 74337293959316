import React, { useContext } from "react";
import { QuizContext } from "../../App";
import { Link } from "react-router-dom";
import Table from 'react-bootstrap/Table';

const TableTeamMembers = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <>
      {!quizContext.state.members.length
        ? <div style={{ color: "red" }}>Пока никого нет</div>
        : <Table
          striped hover
          variant={theme}
          style={{
            width: "100%",
            maxHeight: "400px",
            overflowX: "hidden",
          }}
        >
          <tbody>
            {quizContext.state.members
              .filter((el) => quizContext.state.searchItem.toLowerCase() === ""
                ? el
                : el.username.toLowerCase().includes(quizContext.state.searchItem.toLowerCase()))
              .map((el, i) => (

                <tr key={i}>
                  <th style={{ textAlign: "left" }}>
                    <Link
                      to={`/user/${el.user_id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <button
                        className={`${theme}-link-btn`}
                        onClick={() => quizContext.dispatch({
                          type: "backToMainPage"
                        })}
                      >
                        {el.username}
                      </button>
                    </Link>
                  </th>
                  <th style={{ textAlign: "right", fontWeight: "500" }}>
                    {el.user_type === "ADMIN"
                      ? <span>админ</span>
                      : el.user_type === "ASST"
                        ? <span>ассистент</span>
                        : <span>участник</span>
                    }
                  </th>
                </tr>
              ))}
          </tbody>
        </Table>
      }
    </>
  )
}

export default TableTeamMembers