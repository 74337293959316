import React, { useContext } from 'react'
import { QuizContext } from '../../App';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';

const TableCategoryQuizzes = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <div className="table">
      <Table
        striped hover
        variant={theme}

        style={{
          border: theme === "dark"
            ? "1px solid black"
            : "1px solid #dadada"
        }}
      >
        <thead>
          <tr>
            <th style={{ fontWeight: "500", width: "40%" }}>
              Quiz Title
            </th>
            <th style={{ fontWeight: "500", width: "25%" }}>
              Language
            </th>
            <th style={{ fontWeight: "500", width: "10%" }}>
              Rating
            </th>
            <th style={{ fontWeight: "500", width: "25%" }}>
              Added by
            </th>
          </tr>
        </thead>
        {quizContext.state.catStatus === false
          ? <tbody className="table-cat-body">
            {quizContext.state.catQuizzes
              .filter((el) => quizContext.state.searchItem.toLowerCase() === ""
                ? el
                : el.title.toLowerCase().includes(quizContext.state.searchItem.toLowerCase()))
              .map((el, i) => (
                <tr key={i}>
                  <th style={{ textAlign: "left" }}>
                    <Link
                      to={`/quiz/${el.id}`}
                      style={{
                        textDecoration: "none",
                        color: theme === "dark"
                          ? "#4da2f7"
                          : "#0a70d6",
                        fontWeight: "600"
                      }}
                    >
                      <span
                        onClick={() => quizContext.dispatch({
                          type: "backToMainPage"
                        })}>
                        {el.title}
                      </span>
                    </Link>
                  </th>
                  <th style={{ fontWeight: "300" }}>
                    {el.lang}
                  </th>
                  <th style={{ fontWeight: "300" }}>
                    <span className={`${theme}-rec`}>{el.rec_quiz}</span> / <span className="unrec">{el.unrec_quiz}</span>
                  </th>
                  <th style={{ fontWeight: "500", textAlign: "center" }}>
                    <Link
                      to={`/user/${el.author_id}`}
                      style={{
                        textDecoration: "none",
                        color: theme === "dark"
                          ? "#4da2f7"
                          : "#0a70d6"
                      }}
                    >
                      <span
                        onClick={() => quizContext.dispatch({
                          type: "backToMainPage"
                        })}>
                        {el.username}
                      </span>
                    </Link>
                  </th>
                </tr>
              )
              )}
          </tbody>
          : <tbody>
            {quizContext.state.catLangQuizzes
              .filter((el) => quizContext.state.searchItem.toLowerCase() === ""
                ? el
                : el.title.toLowerCase().includes(quizContext.state.searchItem.toLowerCase()))
              .map((el, i) => (
                <tr key={i}>
                  <th>
                    <Link
                      to={`/quiz/${el.id}`}
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <span
                        onClick={() => quizContext.dispatch({
                          type: "backToMainPage"
                        })}>
                        {el.title}
                      </span>
                    </Link>
                  </th>
                  <th style={{ fontWeight: "300" }}>
                    {el.lang}
                  </th>
                  <th style={{ fontWeight: "300" }}>
                    <span className="rec">{el.rec_quiz}</span> / <span className="unrec">{el.unrec_quiz}</span>
                  </th>
                  <th style={{ fontWeight: "500" }}>
                    <Link
                      to={`/user/${el.author_id}`}
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <span
                        onClick={() => quizContext.dispatch({
                          type: "backToMainPage"
                        })}>
                        {el.username}
                      </span>
                    </Link>
                  </th>
                </tr>
              )
              )}
          </tbody>
        }
      </Table>
    </div>
  )
}

export default TableCategoryQuizzes