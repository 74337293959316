import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { QuizContext } from "../../App";

const ModalEditedProfileSumbitted = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Modal
      size="sm"
      data-bs-theme={theme}
      show={quizContext.state.alert}
      onHide={() => quizContext.dispatch({
        type: "hideAlert"
      })}
    >
      <Modal.Header closeButton data-bs-theme={theme}
        className={`${theme}-modal-header`}
      >
        <Modal.Title>
          <span className={`${theme}-congrat`}>
            CONGRATULATIONS!
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={`${theme}-modal-body`}
        style={{ textAlign: "center" }}
      >
        Your profile has been updated

        <div style={{ margin: "20px 0 5px 0" }}>
          <button
            className={`${theme}-main-btn`}
            onClick={() => quizContext.dispatch({
              type: "backToUserPage"
            })}
          >
            OKAY
          </button>

        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ModalEditedProfileSumbitted