import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { QuizContext } from "../../App";
import { Link } from "react-router-dom";

const ModalForNewPassword = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Modal
      size="sm"
      data-bs-theme={theme}
      show={quizContext.state.alert}
    >
      <Modal.Header className={`${theme}-modal-header`}>
        <Modal.Title>
          <span className={`${theme}-congrat`}>
            Wellcome Home!
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={`${theme}-modal-body`}
        style={{ textAlign: "center" }}
      >
        You've changed the password successfully


        <div style={{ margin: "20px 0 5px 0" }}>

          <Link
            to="/login"
            style={{ textDecoration: 'none' }}
          >
            <button
              className={`${theme}-main-btn`}
              onClick={() => quizContext.dispatch({
                type: "backToMainPage"
              })}
            >
              LOG IN
            </button>
          </Link>


        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ModalForNewPassword