// FormTableCreateQuiz

const getValues = (state, action) => {

  const values = state.values.map((val, i) => {
    const newValue = val.map((el, ii) => {
      return i === action.key && ii === action.objKey ? el = action.payload : el;
    });
    return newValue;
  });


  const checkVaules = values.filter(el => el === "").length
    ? false
    : true;

  const checkHeaders = state.headers.filter((el) => el === "").length
    ? false
    : true;

  const checkQuizTitle = state.quizTitle === "" ? false : true;

  if (!checkQuizTitle || !checkHeaders || !checkVaules) {
    return {
      ...state,
      errorMessage: false,
      alertStatus: null,
      video: false,
      values
    };
  } else {
    return {
      ...state,
      errorMessage: false,
      alertStatus: "changes made",
      video: false,
      values
    };
  };


}

export default getValues