import React, { useContext } from "react";
import { QuizContext } from "../../App";
import { Link } from "react-router-dom";
import TableMembershipPlans from "../tables/TableMembershipPlans";
import ButtonMyPage from "../buttons/ButtonMyPage";
import TabelEarnLosePoints from "../tables/TabelEarnLosePoints";

const TextBanTeamAccess = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <>
      {quizContext.state.team.admin_id === quizContext.state.currentUser.id
        || quizContext.state.userPageStatus === "edit teams"
        ? <>
          <h2 className="header">Access to Your Teams Has Been Blocked</h2>
          <div style={{ textAlign: "center" }}>
            <p>The number of your PUBLIC quizzes and/or contribution rating points went down. It is not enough for continuing using the website membership plan you had previously. Therefore, the access to the teams you’ve started has been blocked. However, you’re allowed to edit your teams.</p>

            <p>To unblock the access to your teams, contribute quizzes to the QUIZ LIBRARY and earn contribution rating points.</p>
          </div>
          <TableMembershipPlans />
          <TabelEarnLosePoints />
          <div style={{ marginTop: "15px", textAlign: "center" }}>
            <ButtonMyPage />
          </div>
        </>
        : <div style={{
          paddingBottom: "200px",
          marginTop: "40px",
          width: "100%",
          textAlign: "center",
          fontSize: "1.2rem"
        }}>
          <div style={{
            fontSize: "3rem",
            fontWeight: "600",
            lineHeight: "3.5rem",
          }}>
            <span style={{ color: "red" }}>The team</span><br />
            {quizContext.state.teamName.toUpperCase()}<br />
            <span style={{ color: "red" }}>is unavailable</span></div>

          <div style={{ marginTop: "35px" }}>To restore the access to the team,<br />
            the team admin <Link
              to={`/user/${quizContext.state.team.admin_id}`}
              style={{
                textDecoration: "none",
                color: theme === "dark"
                  ? "#4da2f7"
                  : "#0a70d6",
                fontWeight: "600"
              }}
              onClick={() => quizContext.dispatch({
                type: "backToMainPage"
              })}
            >
              {quizContext.state.team.username}
            </Link><br />
            has to upgrade their website membership plan.</div>
          <div style={{ marginTop: "35px" }}>
            You can help them do it<br />
            by taking their PUBLIC quizzes,<br />
            which will earn them contribution rating points,<br />
            needed to upgrade their website membership plan.
          </div>
          <div style={{ marginTop: "35px", textAlign: "center" }}>
            <ButtonMyPage />
          </div>
        </div>
      }

    </>
  )
}

export default TextBanTeamAccess