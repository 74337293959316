import React, { useContext } from "react";
import { QuizContext } from "../../App";

const ButtonsTabEdit = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const styleLink = {
    fontSize: "0.8rem",
    marginBottom: "20px",
    background: theme === "dark"
      ? "#171a1c"
      : "white",
    color: theme === "dark"
      ? "#4da2f7"
      : "#0a70d6"
  };
  const styleOn = {
    fontSize: "0.8rem",
    marginBottom: "20px",
    background: theme === "dark"
      ? "#171a1c"
      : "white",
    color: theme === "dark"
      ? "#d0d2d4"
      : "#212529"
  };

  return (
    <>

      {quizContext.state.tabStatus === "right"
        ? <>
          <button
            className="tab-btn-link-left"
            style={styleLink}
            onClick={() => quizContext.dispatch({
              type: "switchTabLeft"
            })}
          >
            Редактировать параметры
          </button>
          <button
            className="tab-btn-on-right"
            style={styleOn}
          >
            Редактировать квиз
          </button>
        </>
        : <>
          <button
            className="tab-btn-on-left"
            style={styleOn}>
            Редактировать параметры
          </button>
          <button
            className="tab-btn-link-right"
            style={styleLink}
            onClick={() => quizContext.dispatch({
              type: "switchTabRight"
            })}
          >
            Редактировать квиз
          </button>
        </>

      }

    </>
  )
}

export default ButtonsTabEdit