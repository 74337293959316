import React, { useContext } from "react";
import Accordion from "react-bootstrap/Accordion";
import TableMembershipPlans from "../tables/TableMembershipPlans";
import TabelEarnLosePoints from "../tables/TabelEarnLosePoints";
import { QuizContext } from "../../App";

const AccordionFAQteams = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Accordion
      data-bs-theme={theme}
      className="card-faq"
      style={{ marginBottom: "80px" }}
    >
      <Accordion.Item eventKey="0">
        <Accordion.Header style={{ color: "black" }}>
          Зачем нужны команды?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>Квизы могут быть 3-х типов: ЛИЧНЫЙ, ОБЩЕДОСТУПНЫЙ, и КОМАНДНЫЙ.</p>
          <p>ЛИЧНЫЙ квиз доступен только для его автора. ОБЩЕДОСТУПНЫЙ квиз доступен для всех зарегистрированных и незарегистрированных пользователей.</p>
          <p>Но что если Вы создали квиз не только для себя, но и не для всех пользователей сайта?</p>
          <p>Команды решают эту проблему. Они позволяют использовать квиз как ЛИЧНЫЙ, но несколькими пользователям, членами команды.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1">
        <Accordion.Header>
          Кому нужны команды?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>Команды удобны для любого пользователя, но не у каждого может возникнуть необходимость создавать их.</p>
          <p>Кому нужно создавать команды?</p>
          <ol>
            <li>Учащимся и студентам, которые хотят вместе со своими одноклассниками/однокурсниками "грызть гранит науки".</li>
            <li>Преподавателям и репетиторам, создающим уникальные образовательные программы только для своих учеников.</li>
            <li>Руководителям предприятий и подразделений, которым нужно, чтобы их подчиненные хорошо знали ассортимент, скажем, ресторанного меню, магазина, склада и т.п.</li>
          </ol>
          <p>Только пользователи с премиум статусом могут создавать команды.</p>
          <p>5 премиум статусов пользоватля: УЧЕНИК, БРОНЗОВЫЙ, СЕРЕБРЯННЫЙ, ЗОЛОТОЙ, и ГУРУ.</p>
          <p>Вы можете получить любой из них БЕСПЛАТНО с помощью публикации своих квизов в БИБЛИОТЕКЕ КВИЗОВ и зарабатывании очков рейтинга автора квизов, когда другие пользователи проходят Ваши квизы и рекомнедуют их.</p>

          <TableMembershipPlans />
          <TabelEarnLosePoints />

        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header>
          Как создать команду?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>Профиль &#8594; СОЗДАТЬ КОМАНДУ</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3">
        <Accordion.Header>
          Как вступить в команду?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>Чтобы вступить в команду, Вам необходимо получить приглашение от админа комнады или от члена команды, который имеет на это разрешение админа. Чтобы Вам можно было отправить приглашение, Вам необходимо сообщить свой ID тому, кто сможет пригласить Вам.</p>

          <p><img src={require('../images/instruction-joinTeam.jpg')} style={{ width: "100%", }} alt={'instr'} /></p>

          <p>Принять или отклонить приглашение в команду:</p>
          <p>Профиль &#8594; ПРИГЛАШЕНИЯ &#8594; &#9989; (принять) или &#10060; (отклонить)</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="4">
        <Accordion.Header>
          Как приглашать пользователей в команду?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>Для того чтобы пригласить пользвателя в команду, Вам необходимо заранее узнать у этого пользвателя его ID.</p>
          <p>Профиль &#8594; МОИ КОМАНДЫ &#8594; {theme === "dark"
            ? <img
              src={require('../images/editW.png')}
              style={{ width: "15px" }}
              alt={'edit'} />
            : <img
              src={require('../images/edit.png')}
              style={{ width: "15px" }}
              alt={'edit'} />} &#8594; Редактировать членов команды &#8594; Пригласите пользователей в свою команду</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="5">
        <Accordion.Header>
          Как добавлять квизы в команду?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>Вы можете добавлять в команды только квизы, которые не являются ОБЩЕДОСТУПНЫМИ. Один квиз можно добавить в несколько команд.</p>
          <p>Профиль &#8594; МОИ КОМАНДЫ &#8594; {theme === "dark"
            ? <img
              src={require('../images/editW.png')}
              style={{ width: "15px" }}
              alt={'edit'} />
            : <img
              src={require('../images/edit.png')}
              style={{ width: "15px" }}
              alt={'edit'} />} &#8594; Редактировать квизы команды &#8594; Добавить квиз</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="6">
        <Accordion.Header>
          Что такоей тип членства в команде, и как его изменить?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>3 типа членства в команде: админ, ассистент, и участник.</p>
          <p>Если Вы создаёте команду, Вы становитесь её админом.</p>
          <p>Админ может:</p>
          <ol>
            <li>изменять установки команды;</li>
            <li>добавлять свои собственные квизы в команду и редактировать КОМАНДНЫЕ квизы, которые он добавил;</li>
            <li>удалять из списка квизов команды свои собственные квизы и квизы, добавленные другими членами команды;</li>
            <li>приглашать и исключать пользователей из команды;</li>
            <li>изменять тип членства в команде других членов команды;</li>
            <li>удалить команду.</li>
          </ol>
          <p>После того, как пользователь принимает приглашение в команду, он по умолчанию получает тип членства "участник".</p>
          <p>Админ команды может изменить тип членства любого члена команды с "участник" на "ассистент" и наоборот.</p>
          <p>Профиль &#8594; МОИ КОМАНДЫ &#8594; {theme === "dark"
            ? <img
              src={require('../images/editW.png')}
              style={{ width: "15px" }}
              alt={'edit'} />
            : <img
              src={require('../images/edit.png')}
              style={{ width: "15px" }}
              alt={'edit'} />} &#8594; Редактировать членов команды &#8594; Редактировать тип членства в команде</p>
          <p>По умолчанию, участники и ассистенты могут удалять из списка квизов команды свои квизы, которые они добавили и уходить из команды.</p>
          <p>Изменяя установки команды, админ может определить права участников и ассистентов:</p>
          <ol>
            <li>право добавлять в команду свои собственные квизы и редактировать их;</li>
            <li>право приглашать пользователей в команду.</li>
          </ol>
          <p>Профиль &#8594; МОИ КОМАНДЫ &#8594; {theme === "dark"
            ? <img
              src={require('../images/editW.png')}
              style={{ width: "15px" }}
              alt={'edit'} />
            : <img
              src={require('../images/edit.png')}
              style={{ width: "15px" }}
              alt={'edit'} />} &#8594; Редактировать название и установки команды &#8594; РЕДАКТИРОВАТЬ</p>
          <p>Админ не может делегировать:</p>
          <ol>
            <li>право удалять из списка квизов команды квизы других членов команды; </li>
            <li>право изменять тип членства в команде; </li>
            <li>право исключать пользователей из команды;</li>
            <li>право удалить команду.</li>
          </ol>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="7">
        <Accordion.Header>
          Как удалить команду?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>Только админ может удалить команду и только после удаления из списка квизов команды всех квизов и исключения из команды всех пользователей.</p>
          <p>Профиль &#8594; МОИ КОМАНДЫ &#8594; {theme === "dark"
            ? <img
              src={require('../images/editW.png')}
              style={{ width: "15px" }}
              alt={'edit'} />
            : <img
              src={require('../images/edit.png')}
              style={{ width: "15px" }}
              alt={'edit'} />} &#8594; Удалить команду</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="8">
        <Accordion.Header>
          Как уйти из команды?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>Админ команды не может уйти из команды. Остальные пользователи могут уйти из команды после удаления из списка квизов команды всех своих квизов, которые они добавили в команду.</p>
          <p>Профиль &#8594; МОИ КОМАНДЫ &#8594; {theme === "dark"
            ? <img
              src={require('../images/editW.png')}
              style={{ width: "15px" }}
              alt={'edit'} />
            : <img
              src={require('../images/edit.png')}
              style={{ width: "15px" }}
              alt={'edit'} />} &#8594; Редактировать членов команды &#8594; УЙТИ ИЗ КОМАНДЫ</p>
        </Accordion.Body>
      </Accordion.Item>

    </Accordion>
  )
}

export default AccordionFAQteams