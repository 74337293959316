import React from "react";

const TextRequirementsXLSX = () => {

  return (
    <div>
      <p>Подготовьте xlsx файл с помощью любого программного обеспечения, для работы с электронными таблицами</p>

      <p>
        <img
          src={require('../images/instruction-create-from-xlsx-software.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>

      <p>1. Первая строка каждого столбца должна содержать заголовок столбца. Иначе Ваш квиз не будет работать.</p>

      <p>
        <img
          src={require('../images/instruction-xlsx-requirements-1.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>

      <p>2. Значение каждой строки столбца должно быть уникальным.</p>

      <p>
        <img
          src={require('../images/instruction-xlsx-requirements-2.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>

      <p>Разные строки одного столбца НИ В КОЕМ СЛУЧАЕ не должны содержать одинаковые данные. Иначе при прохождении квиза Вы столкнётесь с проблемой:</p>

      <p>
        <img
          src={require('../images/instruction-xlsx-requirements-3.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>

      <p>Какой из ответов правильный? Видите?</p>
      <p>Чтобы избежать такую проблему, можно объединить данные вот так:</p>

      <p>
        <img
          src={require('../images/instruction-xlsx-requirements-4.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>

      <p>(Здесь мы объединяем в одну строку выражения, которые дают одинаковый результат)</p>
      <p>или добавить уникальные данные вот так:</p>

      <p>
        <img
          src={require('../images/instruction-xlsx-requirements-5.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>

      <p>(На одном континенте много стран, но название столицы каждой страны уникально)</p>
      <p>3. Столбец должен иметь не менее 6 строк (включая строку с заголовками). Иначе Ваш квиз не будет работать.</p>
      <p>4. Все столбцы должны иметь одинаковое количетсво строк. Иначе Ваш квиз не будет работать.</p>
      <p>5. Квиз должен содержать как минимум 2 столбца. Иначе Ваш он не будет работать.</p>
      <p>6. В квизе должен быть только 1 лист. Иначе Ваш он не будет работать.</p>

      <p>
        <img
          src={require('../images/instruction-xlsx-requirements-6.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>

      <p>7. Ваш квиз может содержать столько столбцов, сколько Вам нужно, но не менее 2-х.</p>

      <p>
        <img
          src={require('../images/instruction-xlsx-requirements-7.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>

      <p>Да, проходя квиз, вы используете только 2 опции, то есть, только 2 столбца. Однако Вы можете выбрать любые 2 опции в любом сочетании. Таким образом, добавляя больше столбцов (с соотвтестующими данными, размуеется) Вы создаёте несколько квизов в одном. Как здесь:</p>

      <p>
        <img
          src={require('../images/instruction-xlsx-requirements-8.jpg')}
          style={{ width: "100%", }}
          alt={'instr'}
        />
      </p>

    </div>
  )
}

export default TextRequirementsXLSX