import React, { useContext } from "react";
import { QuizContext } from "../../App";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";


const CardNavFAQ = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Link to="/help" style={{ textDecoration: 'none' }}>
      <Card
        data-bs-theme={theme}
        className="card-home"
        onClick={() => quizContext.dispatch({
          type: "backToMainPage"
        })}
      >
        <img
          src={theme === "dark"
            ? require('../images/help.png')
            : require('../images/help1.png')
          }
          className="card-icon"
          alt={'Help_icon'}
        />
        <p className="card-text">
          FAQ
        </p>
      </Card>
    </Link>
  )
}

export default CardNavFAQ