import React, { useContext } from "react";
import Table from 'react-bootstrap/Table';
import { QuizContext } from "../../App";

const TableMembershipPlans = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <div style={{ overflowX: "auto" }}>
      <div className="data-table">
        <Table
          striped
          variant={theme}
          className="table table-bordered"
        >
          <thead>
            <tr style={{ fontSize: "0.9rem" }}>
              <th className="table-fontsize">Membership Plan</th>
              <th className="table-fontsize">Number of teams</th>
              <th className="table-fontsize">Number of team members</th>
              <th className="table-fontsize">Number of quizzes to contribute</th>
              <th className="table-fontsize">Number of points to earn</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="table-fontsize">STUDENT</td>
              <td className="table-fontsize">max {quizContext.state.membPlan.student.teams}</td>
              <td className="table-fontsize">max {quizContext.state.membPlan.student.members}</td>
              <td className="table-fontsize">min {quizContext.state.membPlan.student.quizzes}</td>
              <td className="table-fontsize">min {quizContext.state.membPlan.student.points}</td>
            </tr>
            <tr>
              <td className="table-fontsize">BRONZE</td>
              <td className="table-fontsize">max {quizContext.state.membPlan.bronze.teams}</td>
              <td className="table-fontsize">max {quizContext.state.membPlan.bronze.members}</td>
              <td className="table-fontsize">min {quizContext.state.membPlan.bronze.quizzes}</td>
              <td className="table-fontsize">min {quizContext.state.membPlan.bronze.points}</td>
            </tr>
            <tr>
              <td className="table-fontsize">SILVER</td>
              <td className="table-fontsize">max {quizContext.state.membPlan.silver.teams}</td>
              <td className="table-fontsize">max {quizContext.state.membPlan.silver.members}</td>
              <td className="table-fontsize">min {quizContext.state.membPlan.silver.quizzes}</td>
              <td className="table-fontsize">min {quizContext.state.membPlan.silver.points}</td>
            </tr>
            <tr>
              <td className="table-fontsize">GOLD</td>
              <td className="table-fontsize">max {quizContext.state.membPlan.gold.teams}</td>
              <td className="table-fontsize">max {quizContext.state.membPlan.gold.members}</td>
              <td className="table-fontsize">min {quizContext.state.membPlan.gold.quizzes}</td>
              <td className="table-fontsize">min {quizContext.state.membPlan.gold.points}</td>
            </tr>
            <tr>
              <td className="table-fontsize">GURU</td>
              <td className="table-fontsize">max {quizContext.state.membPlan.guru.teams}</td>
              <td className="table-fontsize">max {quizContext.state.membPlan.guru.members}</td>
              <td className="table-fontsize">min {quizContext.state.membPlan.guru.quizzes}</td>
              <td className="table-fontsize">min {quizContext.state.membPlan.guru.points}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default TableMembershipPlans