import React, { useContext, useEffect } from "react";
import { QuizContext } from "../App";
import axios from "axios";
import Loading from "../components/Loading";
import Error from "./Error";
import TableLibrary from "../componentsRU/tables/TableLibrary";

const LibraryRU = () => {

  const quizContext = useContext(QuizContext);
  const dispatch = quizContext.dispatch;

  //Get Categories
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/library/category");
        // console.log("res.data", res.data)
        const category = res.data
        // console.log("category:", category)
        dispatch({ type: "fetchCategory", category });

      } catch (err) {
        const error = err.response.data.message;
        console.log("Cat useEffect err", error);
        dispatch({ type: "fetchCategoryErr", error });
      }
    }
    fetchData();
  }, [dispatch, quizContext.state.alert]);

  return (
    <div>
      {quizContext.state.loading
        ? <Loading />
        : quizContext.state.errorMySQL
          ? <Error />
          : <>
            <h2 className="header">
              БИБЛИОТЕКА КВИЗОВ
            </h2>

            <div>
              <TableLibrary />
            </div>
          </>
      }
    </div>
  )
}

export default LibraryRU