import React from "react";
import ButtonQuizLibrary from "../buttons/ButtonQuizLibrary";
import ButtonQuizCreate from "../buttons/ButtonQuizCreate";

const TextNotYourQuiz = () => {

  return (
    <div style={{
      paddingBottom: "350px",
      marginTop: "40px",
      width: "100%",
      textAlign: "center",
      fontSize: "1.2rem"
    }}>

      <div style={{ fontSize: "3rem", fontWeight: "600" }}>Sorry!</div>

      <div style={{ marginTop: "20px" }}>
        This is a private quiz.<br />
        You can't open it.</div>
      <div style={{ marginTop: "20px" }}>
        Search for available quizzes in QUIZ LIBRARY<br />
        or create your own quiz.
      </div>

      <div
        style={{
          textAlign: "center",
          marginTop: "30px"
        }}
      >
        <ButtonQuizLibrary />
      </div>

      <div
        style={{
          textAlign: "center",
          marginTop: "20px"
        }}
      >
        <ButtonQuizCreate />
      </div>
    </div>
  )
}

export default TextNotYourQuiz