import React, { useContext } from "react";
import { QuizContext } from "../../App";
import { useParams } from "react-router-dom";
import getResult from "../../reducer/actions/quizActions/GetResult"



export const TaskNum = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (

    <div
      className={`${theme}-btn-tasks`}
      style={{
        background: "none",
        border: theme === "dark"
          ? "1px solid #171a1c"
          : "1px solid white",
        paddingLeft: "0",
        textAlign: "left"
      }}
    >
      Task {quizContext.state.taskNumber}
    </div>
  )
}

export const ButtonNextTask = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <>
      {quizContext.state.quizLengthStatus === "long"
        ? <button
          className={`${theme}-btn-tasks`}
          onClick={() => quizContext.dispatch({ type: "getNextTaskLong" })}
        >
          Next Task
        </button>
        : <button
          className={`${theme}-btn-tasks`}
          onClick={() => quizContext.dispatch({ type: "getNextTask" })}
        >
          Next Task
        </button>
      }
    </>
  )
}


export const ButtonResult = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const { id } = useParams();

  return (
    <>
      <button
        className={`${theme}-btn-tasks`}
        onClick={(e) => {
          e.preventDefault()
          getResult(
            quizContext.dispatch,
            quizContext.state,
            id
          )
          quizContext.dispatch({
            type: "getResult"
          })
        }}
      >
        Result
      </button>
    </>
  )
}