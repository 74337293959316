import React, { useContext } from "react";
import Accordion from "react-bootstrap/Accordion";
import TableMembershipPlans from "../tables/TableMembershipPlans";
import TabelEarnLosePoints from "../tables/TabelEarnLosePoints";
import { QuizContext } from "../../App";

const AccordionFAQteams = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Accordion
      data-bs-theme={theme}
      className="card-faq"
      style={{ marginBottom: "80px" }}
    >
      <Accordion.Item eventKey="0">
        <Accordion.Header style={{ color: "black" }}>
          What are teams?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>There are three quiz types: PRIVATE, PUBLIC, and TEAM.</p>
          <p>A PRIVATE quiz is available to its creator only. A PUBLIC quiz is available to all authorized and unauthorized users.</p>
          <p>But what if you create a quiz not for yourself only, yet, not for everybody either?</p>
          <p>Teams solve this problem. They allow a quiz to be used same as PRIVATE but by several people, members of the team.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1">
        <Accordion.Header>
          Who needs teams?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>Teams are useful to anyone, but not everyone needs to start a team.</p>
          <p>So who needs?</p>
          <ol>
            <li>Students who want to hone their knowledge together with their classmates.</li>
            <li>Educators who have developed unique programs for their students only.</li>
            <li>Managers who need their employees to know by heart long lists, say, of a restaurant menu or a store assortment or anything else of the same kind.</li>
          </ol>
          <p>Only the users with premium membership plans can start teams.</p>
          <p>There are 5 premium membership plans: STUDENT, BRONZE, SILVER, GOLD, and GURU.</p>
          <p>You can get any of the premium membership plans FOR FREE by contributing quizzes to the QUIZ LIBRARY and earning contribution rating points.</p>

          <TableMembershipPlans />
          <TabelEarnLosePoints />

        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header>
          How to start a team?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>Profile &#8594; START TEAM</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3">
        <Accordion.Header>
          How to join a team?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>To join a team, you have to receive an invitation from the team admin or its members if they have the admin’s permission to do it. To make them able to invite you, provide them with your ID.</p>

          <p><img src={require('../images/instruction-joinTeam.jpg')} style={{ width: "100%", }} alt={'instr'} /></p>

          <p>To accept or decline the invitation:</p>
          <p>Profile &#8594; INVITATIONS &#8594; &#9989; (to accept) or &#10060; (to decline)</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="4">
        <Accordion.Header>
          How to invite users to a team?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>To invite users to a team, you need to know their ID. So ask them to provide you with their ID beforehand.</p>
          <p>Profile &#8594; MY TEAMS &#8594; {theme === "dark"
            ? <img
              src={require('../images/editW.png')}
              style={{ width: "15px" }}
              alt={'edit'} />
            : <img
              src={require('../images/edit.png')}
              style={{ width: "15px" }}
              alt={'edit'} />} &#8594; Edit team members &#8594; Invite users to the team</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="5">
        <Accordion.Header>
          How to add quizzes to a team?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>You can add to a team your quizzes that are not PUBLIC. One quiz you can add to several teams.</p>
          <p>Profile &#8594; MY TEAMS &#8594; {theme === "dark"
            ? <img
              src={require('../images/editW.png')}
              style={{ width: "15px" }}
              alt={'edit'} />
            : <img
              src={require('../images/edit.png')}
              style={{ width: "15px" }}
              alt={'edit'} />} &#8594; Edit team quizzes &#8594; Add quizzes to the team</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="6">
        <Accordion.Header>
          What is a team membership status, and how to change it?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>There are three types of team membership status: admin, assistant, and member.</p>
          <p>If you start the team, you become its admin.</p>
          <p>An admin can:</p>
          <ol>
            <li>change team settings;</li>
            <li>add their own PRIVATE quizzes to the team and edit TEAM quizzes they added;</li>
            <li>remove from the team their own quizzes and quizzes, added by other members;</li>
            <li>invite and remove users from the team;</li>
            <li>change other users’ team membership status;</li>
            <li>delete the team.</li>
          </ol>
          <p>After an invited user accepts the invitation, they get member status by default.</p>
          <p>The team admin can change the team membership status of any user from member to assistant and vice versa.</p>
          <p>Profile &#8594; MY TEAMS &#8594; {theme === "dark"
            ? <img
              src={require('../images/editW.png')}
              style={{ width: "15px" }}
              alt={'edit'} />
            : <img
              src={require('../images/edit.png')}
              style={{ width: "15px" }}
              alt={'edit'} />} &#8594; Edit team members &#8594; Edit membership type</p>
          <p>By default, members and assistants can remove their own quizzes they added to the team and leave the team.</p>
          <p>By changing team settings, the admin can determine the rights of members and assistants:</p>
          <ol>
            <li>the right to add quizzes to the team and edit TEAM quizzes they added;</li>
            <li>the right to invite users to the team.</li>
          </ol>
          <p>Profile &#8594; MY TEAMS &#8594; {theme === "dark"
            ? <img
              src={require('../images/editW.png')}
              style={{ width: "15px" }}
              alt={'edit'} />
            : <img
              src={require('../images/edit.png')}
              style={{ width: "15px" }}
              alt={'edit'} />} &#8594; Edit team name and settings &#8594; EDIT</p>
          <p>The admin can’t delegate:</p>
          <ol>
            <li>the right to remove other users’ quizzes; </li>
            <li>the right to change team membership type; </li>
            <li>the right to remove users from the team;</li>
            <li>the right to delete the team.</li>
          </ol>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="7">
        <Accordion.Header>
          How to delete a team?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>Only the team admin can delete the team, and only after removing all quizzes and all users from the team.</p>
          <p>Profile &#8594; MY TEAMS &#8594; {theme === "dark"
            ? <img
              src={require('../images/editW.png')}
              style={{ width: "15px" }}
              alt={'edit'} />
            : <img
              src={require('../images/edit.png')}
              style={{ width: "15px" }}
              alt={'edit'} />} &#8594; Delete team</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="8">
        <Accordion.Header>
          How to leave a team?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>The team admin cannot leave the team. Other users can leave the team after removing from the team all the quizzes they added to the team.</p>
          <p>Profile &#8594; MY TEAMS &#8594; {theme === "dark"
            ? <img
              src={require('../images/editW.png')}
              style={{ width: "15px" }}
              alt={'edit'} />
            : <img
              src={require('../images/edit.png')}
              style={{ width: "15px" }}
              alt={'edit'} />} &#8594; Edit team members &#8594; LEAVE TEAM</p>
        </Accordion.Body>
      </Accordion.Item>

    </Accordion>
  )
}

export default AccordionFAQteams