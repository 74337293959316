// GetQuizFile (an action file)

const findMistake = (quizOptions, quizRows) => {

  if (quizOptions.length < 2 || quizRows.length < 5) {

    return "The quiz must have at least 2 columns and 5 rows.";
  } else {

    //check if all rows have the same length
    const checkRowsLength = () => {
      const getKeys = quizRows.map((key) =>
        Object.keys(key).length
      );
      return getKeys.filter((el, i) =>
        getKeys.indexOf(el) === i);
    };

    if (checkRowsLength().length > 1) {

      return "The columns must be of the same length."

    } else if (quizOptions.length !== checkRowsLength()[0]) {

      //check if options have the required length

      return "Each column must have a unique header."
    } else {

      // check if there are empty or duplicate values in options
      const checkForEmpty = quizOptions.filter(el => el.toString().trim() === "");
      const lowCaseOptions = quizOptions.map((val) => val.toString().toLowerCase());
      const checkForDuplicates = lowCaseOptions.filter((el, i) => lowCaseOptions.indexOf(el) === i);

      if (checkForEmpty.length || checkForDuplicates.length < quizOptions.length) {

        return "Each column header must have a unique value and can’t be empty."
      } else {

        // check if there are empty or duplicate values in rows
        const rowsForCheck = quizOptions.map((h, hi) => {
          return { [h]: quizRows.map((val, i) => val[h]) }
        });
        const checkRowsContents = rowsForCheck.map((val) => {
          const checkForEmpty = Object.values(val)[0].filter(el => el.toString().trim() === "");
          const lowCaseRows = Object.values(val)[0].map((val) => val.toString().toLowerCase());
          const checkForDuplicates = lowCaseRows.filter((el, i) => lowCaseRows.indexOf(el) === i);
          if (checkForEmpty.length || checkForDuplicates.length < Object.values(val)[0].length) {
            return true
          } else {
            return false
          };
        });
        if (checkRowsContents.filter(el => el === true).length) {
          return "Each cell of a column must have a unique value and can’t be empty."
        } else {
          return null
        };
      };
    };
  };
};

export default findMistake