import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { QuizContext } from "../../App";
import { Link } from "react-router-dom";

const ModalForEditQuiz = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (

    <Modal
      data-bs-theme={theme}
      show={quizContext.state.alert}
    >
      <Modal.Header className={`${theme}-modal-header`}>
        <Modal.Title>
          <span className={`${theme}-congrat`}>
            ПОЗДРАВЛЯЕМ!
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={`${theme}-modal-body`}
        style={{ textAlign: "center" }}
      >
        Квиз<br />
        <span
          style={{
            fontWeight: "600",
            fontSize: "1.2rem"
          }}
        >
          {quizContext.state.quizTitle}</span><br />
        обновлён

        <div style={{ textAlign: "center", marginTop: "30px" }}>

          <Link
            to={`/quiz/${quizContext.state.quizId}`}
            style={{ textDecoration: 'none' }}
          >
            <button
              className={`${theme}-main-btn`}
              // style={{ marginTop: "0", width: "70%" }}
              onClick={() => quizContext.dispatch({
                type: "backToMainPage"
              })}
            >
              ОТКРЫТЬ КВИЗ
            </button>
          </Link>

          <button
            className={`${theme}-sec-btn`}
            // style={{ marginTop: "10px", width: "70%" }}
            onClick={() => quizContext.dispatch({
              type: "continueEditing"
            })}
          >
            ПРОДОЛЖИТЬ РЕДАКТИРОВАТЬ
          </button>

          {quizContext.state.quiz.type === "TEAM"
            && <Link
              to={`/edit_team/${quizContext.state.quiz.team_id}`}
              style={{ textDecoration: 'none' }}
            >
              <button
                className="sec-btn"
                style={{ marginTop: "10px", width: "70%" }}
                onClick={() => quizContext.dispatch({
                  type: "hideAlert"
                })}
              >
                РЕДАКТИРОВАТЬ КОМАНДУ
              </button>
            </Link>
          }

        </div>

        <div style={{
          margin: "25px 0 5px 0",
          fontSize: "0.7rem",
          padding: "0"
        }}
        >
          *Вы можете скачать обновленный квиз в виде xlsx файла на странице квиза
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ModalForEditQuiz