import React, { useContext } from "react";
import { QuizContext } from "../App";
import Card from "react-bootstrap/Card";
import FormEmail from "./forms/FormEmail";
import ButtonCancelAndGoToPrevPage from "./buttons/ButtonCancelAndGoToPrevPage";
import switchToGetOTP from "../reducer/actions/userActions/SwitchToGetOTP";
import Error from "../pages/Error";


const PasswordRecoveryEmail = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <div>
      {quizContext.state.errorSearchUser === "500"
        ? <Error />
        : <>
          <h2 className="header">
            ENTER YOUR EMAIL
          </h2>
          <Card
            data-bs-theme={theme}
            style={{
              boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px"
            }}
          >
            {quizContext.state.errorSearchUser !== null
              && quizContext.state.errorSearchUser !== "500"
              ? <p className="error">{quizContext.state.errorSearchUser}</p>
              : quizContext.state.errorNewPassword === "Data is not complete"
                ? <p className="error">Data is not complete. Try again</p>
                : null
            }

            <Card.Body style={{ marginTop: "20px" }}><FormEmail /></Card.Body>

            <Card.Body>

              <div style={{ textAlign: "center" }}>
                <button
                  className={`${theme}-main-btn`}
                  onClick={(e) => {
                    e.preventDefault()
                    switchToGetOTP(quizContext.dispatch, quizContext.state)
                    quizContext.dispatch({ type: "switchToGetOTP" })
                  }}
                >
                  VERIFY EMAIL
                </button>

                <ButtonCancelAndGoToPrevPage />
              </div>

            </Card.Body>
          </Card>
        </>
      }
    </div>
  )
}

export default PasswordRecoveryEmail