// FormEditQuizType

const editQuizType = (state, action) => {
  const quizType = action.payload;

  // let catId = state.catId;
  // let teamId = state.teamId;
  // let langId = state.langId;

  // if(quizType === "PRIVATE") {
  //   catId = null;
  //   teamId = null;
  //   langId = null;
  // }
  
  return {
      ...state,
      errorMessage: false,
      alertStatus: "editing",
      quizType,
      // catId,
      // teamId,
      // langId,
  };
}

export default editQuizType