import React from "react";
import ButtonQuizLibrary from "../buttons/ButtonQuizLibrary";
import ButtonQuizCreate from "../buttons/ButtonQuizCreate";

const TextBanTeamQuiz = () => {

  return (
    <div style={{
      paddingBottom: "320px",
      marginTop: "40px",
      width: "100%",
      textAlign: "center",
      fontSize: "1.2rem"
    }}>

      <div style={{
        fontSize: "3rem",
        fontWeight: "600",
        lineHeight: "3rem",
        color: "red"
      }}>
        Access to this quiz<br />
        is blocked
      </div>
      <div style={{ marginTop: "10px" }}>
        until the team admin<br />
        upgrades their website membership plan.
      </div>
      <div style={{ marginTop: "35px" }}>
        Search for available quizzes in QUIZ LIBRARY<br />
        or create your own quiz.
      </div>

      <div
        style={{
          textAlign: "center",
          marginTop: "20px"
        }}
      >
        <ButtonQuizLibrary />
      </div>

      <div
        style={{
          textAlign: "center",
          marginTop: "10px"
        }}
      >
        <ButtonQuizCreate />
      </div>
    </div>
  )
}

export default TextBanTeamQuiz