// FormSetTheme

const setUserTheme = (state, action) => {

  const userTheme = action.payload;

  const theme = userTheme;

  return {
    ...state,
    userTheme,
    theme
  }
}

export default setUserTheme