import axios from "axios";

// ButtonsLeaveTeam

const leaveTeam = async (dispatch, state) => {
  let error = state.error;
  const teamUsersId = state.teamMembers.filter(el => el.user_id === state.currentUser.id)[0].id
  const teamId = state.team.id;

  try {
    await axios.post(`/team/leave/${teamUsersId}`, {
      teamId
    });
  } catch (err) {
    error = err.response.data.message;
    console.log("leaveTeam err:", error);
  }


  return dispatch({
    type: "leaveTeam",
    error
  });
}

export default leaveTeam