import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { QuizContext } from "../../App";

const CardUserCreateQuiz = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Link to="/create_quiz" style={{ textDecoration: 'none' }}>
      <div
        className={`${theme}-card-user`}
        onClick={() => quizContext.dispatch({
          type: "backToMainPage"
        })}
      >
        CREATE QUIZ
      </div>
    </Link>
  )
}

export default CardUserCreateQuiz 