import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { QuizContext } from "../../App";
import ButtonQuizCreate from "../buttons/ButtonQuizCreate";
import ButtonMyPage from "../buttons/ButtonMyPage";
import ButtonQuizLibrary from "../buttons/ButtonQuizLibrary";

const ModalAnotherQuiz = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Modal
    size="sm"
    data-bs-theme={theme}
      show={quizContext.state.alert}
      onHide={() => quizContext.dispatch({
        type: "hideAlert"
      })}
    >
      <Modal.Header closeButton data-bs-theme={theme}
        className={`${theme}-modal-header`}
      />
      <Modal.Body className={`${theme}-modal-body`}>
        <div className="col-md-12 text-center">
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <ButtonQuizLibrary />
          </div>

          <div style={{ marginTop: "30px", textAlign: "center" }}>
            <ButtonQuizCreate />
          </div>

          {quizContext.state.currentUser !== null
            && <div style={{ margin: "30px 0 20px 0", textAlign: "center" }}>
              <ButtonMyPage />
            </div>
          }
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ModalAnotherQuiz