import React, { useContext } from "react";
// import Table from 'react-bootstrap/Table';
import { QuizContext } from "../../App";
import Accordion from "react-bootstrap/Accordion";
import TableMembershipPlans from "../tables/TableMembershipPlans";
import TabelEarnLosePoints from "../tables/TabelEarnLosePoints";

const AccordionFAQmembership = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  const vote = quizContext.state.quizPoints.vote;
  const changeType = quizContext.state.quizPoints.changeType;
  const numVote = quizContext.state.quizPoints.numVote;
  const votePercent = quizContext.state.quizPoints.votePercent;


  return (
    <Accordion
      data-bs-theme={theme}
      className="card-faq"
    >

      <Accordion.Item eventKey="0">
        <Accordion.Header style={{ color: "black" }}>
          What can an unauthorized user do?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>You can try QUIZZES without registration.</p>
          <p>Without making an account:</p>
          <p><span className="tic">&#10003;</span> You can take quizzes stored in the QUIZ LIBRARY.</p>
          <p><span className="tic" style={{ color: "red" }}>&times;</span> You cannot save the links to the QUIZ LIBRARY quizzes you use most.</p>
          <p><span className="tic" style={{ color: "red" }}>&times;</span> You cannot upvote/downvote and comment on the QUIZ LIBRARY quizzes.</p>
          <p><span className="tic">&#10003;</span> You can create quizzes.</p>
          <p><span className="tic">&#10003;</span> You can download on your device the quiz you created as an xlsx file.</p>
          <p><span className="tic" style={{ color: "red" }}>&times;</span> You cannot save the quizzes you created and have to upload them anew every time you want to take one.</p>
          <p><span className="tic" style={{ color: "red" }}>&times;</span> You cannot contribute your quizzes to the QUIZ LIBRARY.</p>
          <p><span className="tic" style={{ color: "red" }}>&times;</span> You cannot join teams.</p>
          <p><span className="tic" style={{ color: "red" }}>&times;</span> You cannot start teams.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1">
        <Accordion.Header>
          What can an authorized user do?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>If you register on QUIZZES:</p>
          <p><span className="tic">&#10003;</span> You can take quizzes stored in the QUIZ LIBRARY.</p>
          <p><span className="tic">&#10003;</span> You can add QUIZ LIBRARY quizzes you use the most to FAVORITE. The links to those quizzes will appear on your profile page, and you’ll have easy access to them.</p>
          <p><span className="tic">&#10003;</span> You can upvote/downvote and comment on the QUIZ LIBRARY quizzes.</p>
          <p><span className="tic">&#10003;</span> You can create quizzes.</p>
          <p><span className="tic">&#10003;</span> You can download on your device the quiz you created as an xlsx file.</p>
          <p><span className="tic">&#10003;</span> You can save the quizzes you created and have easy access to them from your profile page.</p>
          <p><span className="tic">&#10003;</span> You can contribute your quizzes to the QUIZ LIBRARY.</p>
          <p><span className="tic">&#10003;</span> You can join an unlimited number of teams.</p>
          <p><span className="tic" style={{ color: "red" }}>&times;</span> You cannot start teams if your membership plan is STANDARD.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header>
          What can a premium user do?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>There are 5 premium membership plans: STUDENT, BRONZE, SILVER, GOLD, and GURU.</p>
          <p>You can get any of them FOR FREE by contributing quizzes to the QUIZ LIBRARY.</p>
          <p>If you get any of those membership plans:</p>
          <p><span className="tic">&#10003;</span> You can take quizzes stored in the QUIZ LIBRARY.</p>
          <p><span className="tic">&#10003;</span> You can save the links to the QUIZ LIBRARY quizzes you use the most on your profile page to have easy access to them.</p>
          <p><span className="tic">&#10003;</span> You can upvote/downvote and comment on the QUIZ LIBRARY quizzes.</p>
          <p><span className="tic">&#10003;</span> You can create quizzes.</p>
          <p><span className="tic">&#10003;</span> You can download on your device the quiz you created as an xlsx file.</p>
          <p><span className="tic">&#10003;</span> You can save the quizzes you created and have easy access to them from your profile page.</p>
          <p><span className="tic">&#10003;</span> You can contribute your quizzes to the QUIZ LIBRARY.</p>
          <p><span className="tic">&#10003;</span> You can join an unlimited number of teams.</p>
          <p><span className="tic">&#10003;</span> You can start as many teams as your premium membership plan allows.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3">
        <Accordion.Header>
          How to upgrade your membership plan?
        </Accordion.Header>
        <Accordion.Body className="accor">
          <p>Contribute the quizzes you created to the QUIZ LIBRARY and earn contribution rating points:</p>
          <ul>
            <li>
              Contributing a quiz to the QUIZ LIBRARY, you earn {changeType} points.
            </li>
            <li>
              Each time other users take the quiz you contributed, you earn 1 point.
            </li>
            <li>
              Each time other users upvote your quiz, you earn {vote} points.
            </li>
          </ul>
          <p>However, you can lose points as well:</p>
          <ul>
            <li>
              If you remove your quiz from the QUIZ LIBRARY, you lose all the points the quiz earned you.
            </li>
            <li>
              Each time other users downvote your quiz, you lose {vote} points.
            </li>
            <li>
              If your quiz has {numVote} or more votes and {votePercent}% or more of the users downvoted it, you lose all the points the quiz earned you, and your quiz gets removed from the QUIZ LIBRARY.
            </li>
          </ul>

          <p>As soon as you contribute enough quizzes and earn enough points for a certain premium membership plan, it upgrades automatically.</p>
          <p>It also downgrades automatically when the number of quizzes you contributed and/or contribution rating points goes below the requirements of your membership plan. If it happens, the teams you’ve started get blocked until you upgrade your membership plan.</p>

          <TableMembershipPlans />
          <TabelEarnLosePoints />

        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default AccordionFAQmembership