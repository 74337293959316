import React, { useContext } from "react";
import { QuizContext } from "../../App";

const ButtonAddRow = () => {

  const quizContext = useContext(QuizContext);

  return (
    <button
      className="add-col-row-btn"
      onClick={() => quizContext.dispatch({ type: "addRowEdit" })}
    >
      добавить строку
    </button>
  )
}

export default ButtonAddRow