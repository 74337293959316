// FormEditQuizCat

const editQuizCat = (state, action) => {

  const catName = action.payload;

  if (catName !== "- Select Category -") {
    const currentCatEn = state.catNames.filter(el => el.catname === catName);
    const currentCatRU = state.catNames.filter(el => el.catname_ru === catName);
    const currentCat = currentCatEn.concat(currentCatRU);
    const catId = currentCat[0].id;

    return {
      ...state,
      catId,
      catName,
      alertStatus: "editing",
      errorMessage: false
    };

  } else {
    return {
      ...state,
      errorMessage: false
    }
  }

}

export default editQuizCat