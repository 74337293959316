import axios from "axios";

// ButtonsSubmitEditedProfile

const submitLogOut = async (dispatch, state) => {

  let error = state.error;

  try {
    await axios.post("/auth/logout");
  } catch (err) {
    error = err.response.data.message;
    console.log("submitLogOut err:", error);
  };

  return dispatch({
    type: "submitLogOut",
    error
  });
};

export default submitLogOut