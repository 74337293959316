import React, { useContext } from "react";
import { QuizContext } from "../../App";
import * as XLSX from "xlsx/xlsx.mjs";

export const ButtonCreateQuizDownloadXLSXfile = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  const handleQuizFileDownload = () => {
    const quizRows = quizContext.state.quizRows;
    const quizTitle = quizContext.state.quizTitle.replaceAll(" ", "-");

    let wb = XLSX.utils.book_new(),
    ws = XLSX.utils.json_to_sheet(quizRows);

    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    XLSX.writeFile(wb, `${quizTitle}.xlsx`);
  }

  return (
    <>
      <div>
        *<span style={{ fontWeight: "600" }}>
          STEP 4:</span> you can download an xlsx file of your quiz to your computer <span style={{ fontStyle: "italic" }}>(*optional)</span>
      </div>
      <div style={{
        textAlign: "center",
        marginTop: "5px"
      }}
      >
        {quizContext.state.alertStatus === "changes confirmed"
          ? <button
            className={`${theme}-main-btn`}
            onClick={handleQuizFileDownload}
          >
            DOWNLOAD XLSX FILE
          </button>

          : <button
            className={`${theme}-main-btn-inactive`}
          >
            <span style={{ cursor: "crosshair" }}>
              DOWNLOAD XLSX FILE
            </span>
          </button>
        }
      </div>
    </>
  )
}

export const ButtonDownloadXLSXfile = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  const handleQuizFileDownload = () => {
    const quizRows = quizContext.state.quizRows;
    const quizTitle = quizContext.state.quizTitle.replaceAll(" ", "-");

    let wb = XLSX.utils.book_new(),
    ws = XLSX.utils.json_to_sheet(quizRows);

    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    XLSX.writeFile(wb, `${quizTitle}.xlsx`);
  }

  return (
    <div style={{fontSize: "0.8rem"}}>
      Download quiz xlsx file <button
      className={`${theme}-download-btn`}
      onClick={handleQuizFileDownload}
    >
      DOWNLOAD
    </button>
    </div>
  )
}