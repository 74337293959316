// QuizRun

const gues = (state, action) => {
  const num = action.i;
  const id = action.id;
  const value = action.value;

  const indexRightAnswer = state.indexRightAnswer;
  const renderAnswers = state.renderAnswers;
  const renderQuestion = state.renderQuestion;
  let score = state.score;
  let storeScore = [...state.storeScore];
  const taskNumber = state.taskNumber;
  const answerOptions = [...state.answerOptions];
  let averageScore = state.averageScore;
  let taskStatus = state.taskStatus;
  let btnInact = state.btnInact;

  let failedTasks = state.failedTasks;

  if (id !== indexRightAnswer) {
    renderAnswers[num].isGivenAnswer = "wrong-answer";
    score = score - 1;
  } else {
    renderAnswers[num].isGivenAnswer = "right-answer";
    score = score + 5;
    storeScore = [...state.storeScore, score];
    btnInact = true;

    if (score < 5) {
      failedTasks = [
        ...state.failedTasks,
        {question: renderQuestion,
        answer: value}
      ];
    }

    if (taskNumber === answerOptions.length) {
      averageScore = storeScore.filter(el => el === 5).length;
      taskStatus = "quizResult";

    } else {
      taskStatus = "nextTask";
    }
  }

  return {
    ...state,
    score,
    storeScore,
    averageScore,
    taskStatus,
    failedTasks,
    btnInact
  }
}

export default gues