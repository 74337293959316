import React, { useContext } from "react";
import { QuizContext } from "../../App";

const CardUserMyQuizzes = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;
  const alertStatus = "my quizzes";

  return (
    <div
      className={`${theme}-card-user`}
      onClick={() => quizContext.dispatch({
        type: "showModal",
        alertStatus
      })}
    >
      МОИ КВИЗЫ: {quizContext.state.createdQuizzes.length}
    </div>
  )
}

export default CardUserMyQuizzes