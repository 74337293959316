import axios from "axios";
import getUserMembPlan from "../userActions/GetUserMembPlan";

// ButtonSubmitRecommendation

const submitRecommendation = async (dispatch, state) => {

  const changeType = state.quizPoints.changeType;
  const numVote = state.quizPoints.numVote;
  const vote = state.quizPoints.vote;
  const votePercent = state.quizPoints.votePercent;

  const authorId = state.quiz.author_id;
  const comId = state.currUserComment.length
    ? state.currUserComment[0].id
    : "newComment";
  let error = state.error;
  const recom = state.recom === null
    && state.comments.filter(el => el.user_took_id === state.currentUser.id).length === 0
    ? "NONE"
    : state.recom === null
      && state.comments.filter(el => el.user_took_id === state.currentUser.id).length !== 0
      ? state.comments.filter(el => el.user_took_id === state.currentUser.id)[0].recom
      : state.recom

  const oldRecom = state.comments.filter(el => el.user_took_id === state.currentUser.id).length !== 0
    ? state.comments.filter(el => el.user_took_id === state.currentUser.id)[0].recom
    : "NONE";

  const recQuiz = oldRecom === "NONE" && recom === "REC"
    ? state.quiz.rec_quiz + 1
    : oldRecom === "UNREC" && recom === "REC"
      ? state.quiz.rec_quiz + 1
      : oldRecom === "REC" && recom === "UNREC"
        ? state.quiz.rec_quiz - 1
        : state.quiz.rec_quiz;
  const unrecQuiz = oldRecom === "NONE" && recom === "UNREC"
    ? state.quiz.unrec_quiz + 1
    : oldRecom === "REC" && recom === "UNREC"
      ? state.quiz.unrec_quiz + 1
      : oldRecom === "UNREC" && recom === "REC"
        ? state.quiz.unrec_quiz - 1
        : state.quiz.unrec_quiz;

  const recSum = recQuiz + unrecQuiz;
  const lostPoints = changeType + state.timesTaken + (state.quiz.rec_quiz * vote) + vote - (state.quiz.unrec_quiz * vote);
  const quizScore = state.quiz.type === "PUBLIC"
    && lostPoints > 10
    ? lostPoints
    : 10;

  const quizNewData = recSum >= numVote && ((unrecQuiz * 100) / recSum) >= votePercent
    ? {
      type: "PRIVATE",
      lang_id: null,
      cat_id: null,
      rec_quiz: 0,
      unrec_quiz: 0,
      times_taken: 0,
      num_pub_quizzes: state.quiz.num_pub_quizzes - 1
    }
    : {
      type: state.quiz.type,
      lang_id: state.quiz.lang_id,
      cat_id: state.quiz.cat_id,
      rec_quiz: recQuiz,
      unrec_quiz: unrecQuiz,
      times_taken: state.timesTaken,
      num_pub_quizzes: state.quiz.num_pub_quizzes
    };

  const authorScore = recSum >= numVote && ((unrecQuiz * 100) / recSum) >= votePercent
    ? state.quiz.score - quizScore
    : oldRecom === "NONE" && recom === "UNREC"
      ? state.quiz.score - vote
      : oldRecom === "REC" && recom === "UNREC"
        ? state.quiz.score - vote
        : oldRecom === "UNREC" && recom === "REC"
          ? state.quiz.score + vote
          : oldRecom === "NONE" && recom === "REC"
            ? state.quiz.score + vote
            : state.quiz.score

  // const currUserComment = state.currUserComment;

  const authorNumPubQuizzes = quizNewData.num_pub_quizzes;

  const authorMem = state.quiz.type === "PUBLIC"
    && state.currentUser !== null
    && state.quiz.author_id !== state.currentUser.id
    && state.quiz.payment === "FALSE"
    ? getUserMembPlan(authorNumPubQuizzes, authorScore, state.quiz.mem, state)
    : state.quiz.mem;

  const removeComments = recSum >= numVote && ((unrecQuiz * 100) / recSum) >= votePercent
    ? true
    : false;

  const quizStatus = removeComments === true
    ? "initial"
    : "result"

  if (state.currentUser.id) {
    try {
      await axios.post(`/quiz/rec/${state.quizId}`, {
        rec_quiz: quizNewData.rec_quiz,
        unrec_quiz: quizNewData.unrec_quiz,
        type: quizNewData.type,
        lang_id: quizNewData.lang_id,
        cat_id: quizNewData.cat_id,
        times_taken: quizNewData.times_taken,
        comId,
        taken_quiz_id: state.quiz.id,
        user_took_id: state.currentUser.id,
        recom,
        comment: state.comment,
        removeComments,
        author_id: authorId,
        score: authorScore,
        mem: authorMem,
        num_pub_quizzes: quizNewData.num_pub_quizzes
      });
    } catch (err) {
      error = err.response.data.message;
      console.log("submitRecommendation err:", error);
    }
  }

  return dispatch({
    type: "submitRecommendation",
    error,
    recQuiz,
    unrecQuiz,
    removeComments,
    quizStatus
  })
}

export default submitRecommendation