import React, { useContext } from "react";
import { QuizContext } from "../../App";

const FormOTP = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <form>
      <input
        type="text"
        inputMode="numeric"
        autoComplete="one-time-code"
        maxLength="4"
        size="10"
        required
        style={{
          border: theme === "dark"
          ? "1px solid #77797c"
          : "1px solid #212529",
          borderRadius: "7px",
          textAlign: "center",
          letterSpacing: "2rem",
          textIndent: "2rem",
          fontSize: "1.5rem"
        }}
        // placeholder="----"
        onChange={(e) => quizContext.dispatch({
          type: "getOTP",
          payload: e.target.value
        })}
      />
    </form>
  )
}

export default FormOTP