import axios from "axios";

// ButtonSubmitEditedTeamSettings

const submitEditedTeamSettings = async (dispatch, state) => {

  let error = state.error;
  const teamName = state.teamName;
  const typeAddQuiz = state.typeAddQuiz === null
  ? state.team.type_add_quiz
  : state.typeAddQuiz
  const typeAddMemb = state.typeAddMemb === null
  ? state.team.type_add_memb
  : state.typeAddMemb

  try {
    await axios.post(`/team/update/${state.teamId}`, {
      teamname: teamName,
      type_add_quiz: typeAddQuiz,
      type_add_memb: typeAddMemb
    });
  } catch (err) {
    error = err.response.data.message;
    console.log("submitEditedTeamSettings err:", error);
  }

  return dispatch({
    type: "submitEditedTeamSettings",
    error
  });
}

export default submitEditedTeamSettings