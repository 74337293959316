// NavBar.js

const toggleTheme = (state) => {

  const theme = state.theme === "light"
    ? "dark"
    : "light";

  return {
    ...state,
    theme
  }
}

export default toggleTheme