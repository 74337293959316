// FormTeamRemoveQuiz, FormTeamRemoveYourQuiz

const selectQuizToRemoveFromTeam = (state, action) => {

  if (action.payload !== "- Select Quiz -") {
    const teamQuizTitleToRemove = action.payload;
    const currentQuizToRemove = state.teamQuizzes.filter(el => el.title === teamQuizTitleToRemove);
    const teamQuizToRemoveId = currentQuizToRemove[0].id;

    return {
      ...state,
      error: null,
      teamQuizTitleToRemove,
      currentQuizToRemove,
      teamQuizToRemoveId,
      removeQuizStatus: "remvove"
    }
  } else {
    return state
  }
}

export default selectQuizToRemoveFromTeam