import axios from "axios";

const switchToNewPassword = async (dispatch, state) => {

  let error = state.error;
  const typedOTP = state.typedOTP;
  const email = state.email;
  let verifiedData = state.verifiedData;

  const inputs = {
    email,
    otp: typedOTP,
  };
 
  try {
    const res = await axios.post("/auth/checkotp", inputs);
    verifiedData = res.data;
  } catch (err) {
    error = err.response.data.message;
    console.log("switchToNewPassword err:", error);
  };

  return dispatch({
    type: "switchToNewPassword",
    verifiedData,
    error
  });
}

export default switchToNewPassword

//   if (sentOTP === typedOTP) {
//     return {
//       ...state,
//       passwordRecovery: "new password",
//       isRunning: false,
//       sec: 90,
//       enterData: false,
//       wrongData: false,
//     }
//   } else {
//     return {
//       ...state,
//       wrongData: true,
//     }
//   }
// }