import React, { useContext } from "react";
import video from "./videoForHomePageRU.mp4";
import poster from "./posterHomeRU.jpg";
import { Card } from "react-bootstrap";
import { QuizContext } from "../../App";
import TextAndImgForVideoHomePage from "../text/TextAndImgForVideoHomePage";

const VideoForHomePage = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Card
      data-bs-theme={theme}
      className="card-home"
      style={{
        margin: "10px 0 5px 0",
        width: "100%",
        height: "auto",
        padding: "0.2%",
        background: "white"
      }}
    >

      {quizContext.state.video
        ? <video
          width="100%"
          height="auto"
          style={{ borderRadius: "3px" }}
          poster={poster}
          controls
        // autoPlay={true}
        // loop={true}
        >
          <source src={video} type="video/mp4" />
          <source src={poster} alt="Turn any data into a quiz and memorize huge amounts of information in a gamelike way" />
        </video>
        : <TextAndImgForVideoHomePage />
      }

    </Card>
  )
}

export default VideoForHomePage