import React, { useContext } from "react";
import { QuizContext } from "../../App";
import { Col, Form, Row } from "react-bootstrap";

const FormContact = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Form>
      <Row style={{ marginBottom: "15px" }}>
        <Col>
          {quizContext.state.currentUser === null
            ? <Form.Control
              type="text"
              required
              data-bs-theme={theme}
              style={{
                border: theme === "dark"
                  ? "1px solid #77797c"
                  : "1px solid #212529",
              }}
              placeholder="Username"
              onChange={(e) => quizContext.dispatch({
                type: "getUsernameContact",
                payload: e.target.value
              })}
            />
            : <Form.Control
              type="text"
              required
              data-bs-theme={theme}
              style={{
                border: theme === "dark"
                  ? "1px solid #77797c"
                  : "1px solid #212529",
              }}
              value={quizContext.state.usernameCont}
              onChange={(e) => quizContext.dispatch({
                type: "getUsernameContact",
                payload: e.target.value
              })}
            />
          }
        </Col>
        <Col>
          {quizContext.state.currentUser === null
            ? <Form.Control
              type="email"
              required
              data-bs-theme={theme}
              style={{
                border: theme === "dark"
                  ? "1px solid #77797c"
                  : "1px solid #212529",
              }}
              placeholder="Email"
              onChange={(e) => quizContext.dispatch({
                type: "getEmailContact",
                payload: e.target.value
              })}
            />
            : <Form.Control
              type="email"
              required
              data-bs-theme={theme}
              style={{
                border: theme === "dark"
                  ? "1px solid #77797c"
                  : "1px solid #212529",
              }}
              value={quizContext.state.emailCont}
              onChange={(e) => quizContext.dispatch({
                type: "getEmailContact",
                payload: e.target.value
              })}
            />
          }
        </Col>
      </Row>

      <div style={{ marginBottom: "15px" }}>
        <Form.Control
          type="email"
          required
          data-bs-theme={theme}
          style={{
            border: theme === "dark"
              ? "1px solid #77797c"
              : "1px solid #212529",
          }}
          placeholder="Subject"
          onChange={(e) => quizContext.dispatch({
            type: "getSubjectContact",
            payload: e.target.value
          })}
        />
      </div>

      <div style={{ marginBottom: "15px" }}>
        <Form.Control
          type="text"
          required
          as="textarea"
          rows={3}
          data-bs-theme={theme}
          style={{
            border: theme === "dark"
              ? "1px solid #77797c"
              : "1px solid #212529",
          }}
          placeholder="Describe the problem"
          onChange={(e) => quizContext.dispatch({
            type: "getMessageContact",
            payload: e.target.value
          })}
        />
      </div>
    </Form>
  )
}

export default FormContact