import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import { QuizContext } from "../../App";

const FormEditQuizType = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;

  return (
    <Form>
      {quizContext.state.quiz.type === "PUBLIC"
        ? <Form.Label style={{ textAlign: "center" }}><span style={{ color: "red", fontWeight: "700" }}>WARNING!!!</span><br />Turning the quiz type from PUBLIC to PRIVATE will lose you all the points the quiz earned you. Also, all the recommendations and comments to this quiz will be permanently deleted. So, if you turn the quiz into PUBLIC again, you’ll have to earn points from scratch.</Form.Label>
        : <Form.Label style={{ textAlign: "center" }}>If you turn the quiz type from PRIVATE to PUBLIC, you’ll earn 10 points. Further, you’ll earn 1 point every time users take the quiz and 3 points every time a user recommends the quiz to other users. However, you’ll lose 3 points every time a user does not recommend the quiz. </Form.Label>
      }
      <Form.Select
        data-bs-theme={theme}
        style={{
          border: theme === "dark"
            ? "1px solid #77797c"
            : "1px solid #212529"
        }}
        onChange={(e) => quizContext.dispatch({
          type: "editQuizType",
          payload: e.target.value
        })}
      >
        {quizContext.state.quizType === "PRIVATE"
          ? <>
            <option key={1} value="PRIVATE">PRIVATE (available for you only)</option>
            <option key={2} value="PUBLIC">PUBLIC (available for everyone in the Quiz Library)</option>
          </>
          : <>
            <option key={2} value="PUBLIC">PUBLIC (available for everyone in the Quiz Library)</option>
            <option key={1} value="PRIVATE">PRIVATE (available for you only)</option>
          </>
        }
      </Form.Select>
    </Form>
  )
}

export default FormEditQuizType