import axios from "axios";

// FormTeamEditMemberType, ModalForAcceptTeamInv

const submitTeamMemberNewType = async (dispatch, state) => {

  if (state.alertStatus === "accept inv") {
    let error = state.error;

    try {
      await axios.post(`/team/updatememb/${state.teamUsersId}`, {
        user_type: "MEMB",
      })
    } catch (err) {
      error = err.response.data.message;
      console.log("submitTeamMemberNewType err:", error);
    }

    return dispatch({
      type: "submitTeamMemberNewType",
      error
    });
  } else {

    let error = state.error;
    const teamMemberType = state.teamMemberNewType === ""
      ? state.teamMemberType
      : state.teamMemberNewType

    try {
      await axios.post(`/team/updatememb/${state.teamUsersId}`, {
        user_type: teamMemberType
      })
    } catch (err) {
      error = err.response.data.message;
      console.log("submitTeamMemberNewType err:", error);
    }

    return dispatch({
      type: "submitTeamMemberNewType",
      error
    });
  }
}

export default submitTeamMemberNewType