// FormTableCreateQuiz

const getHeader = (state, action) => {


  const headers = state.headers.map((el, i) => {
    return i === action.key ? action.payload : el;
  });

  const checkVaules = state.values.filter(el => el === "").length
    ? false
    : true;

  const checkHeaders = headers.filter((el) => el === "").length
    ? false
    : true;

  const checkQuizTitle = state.quizTitle === "" ? false : true;

  if (!checkQuizTitle || !checkHeaders || !checkVaules) {
    return {
      ...state,
      errorMessage: false,
      alertStatus: null,
      video: false,
      headers
    };
  } else {
    return {
      ...state,
      errorMessage: false,
      alertStatus: "changes made",
      video: false,
      headers
    };
  };
}

export default getHeader