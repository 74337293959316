import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import Table from 'react-bootstrap/Table';
import { QuizContext } from "../../App";
import ModalAddRemoveColumn from "../modals/ModalAddRemoveColumn";
import ModalAddRemoveRow from "../modals/ModalAddRemoveRow";

const FormTableCreateQuiz = () => {

  const quizContext = useContext(QuizContext);
  const theme = quizContext.state.theme;


  return (
    <>
      {/* <div>To add/remove a column/row, click its number</div> */}
      <Table
        className="table w-auto"
        variant={theme}
        style={{
          margin: "0 auto 0 auto",
          width: "100%",
          border: "1px solid #babfc4",
        }}
      >
        <tbody>
          <tr>
            <th
              colSpan={quizContext.state.headers.length + 1}
              style={{ textAlign: "left", fontSize: "0.8rem", fontWeight: "400" }}
            >
              Чтобы добавить/удалить столбец/строку нажмите её номер.
            </th>
          </tr>
          <tr>
            {quizContext.state.headers.map((el, i) =>
              <td key={i} style={{ border: "1px solid #babfc4", textAlign: "center", cursor: "pointer" }}>
                {el === "#"
                  ? null
                  : <button className={`${theme}-col-num-btn`}
                    onClick={() => quizContext.dispatch({
                      type: "showModalAddRemoveColumn",
                      num: i
                    })}
                  >
                    {i}
                  </button>
                }
              </td>)}
          </tr>
          <tr>
            {quizContext.state.headers.map((el, i) =>
              <th key={i} style={{ border: "2px solid #babfc4" }}>
                {el === "#"
                  ? <>{el}</>
                  : <Form.Control
                    type="text"
                    size="sm"
                    data-bs-theme={theme}
                    value={el}
                    placeholder="Заголовок"
                    style={{
                      textAlign: "center",
                      border: "none",
                      fontWeight: "600",
                      width: "auto"
                    }}
                    onChange={(e) => quizContext.dispatch({
                      type: "getHeader",
                      payload: e.target.value,
                      key: i
                    })}
                  />
                }
              </th>
            )}
          </tr>
        {/* </tbody>
        <tbody> */}
          {quizContext.state.values.map((v, vi) =>
            <tr key={vi} style={{ border: "1px solid #babfc4" }}>
              {quizContext.state.headers.map((h, hi) =>
                <td key={hi} style={{ border: "1px solid #babfc4" }}>
                  {h === "#"
                    ? <button className={`${theme}-row-num-btn`}
                      onClick={() => quizContext.dispatch({
                        type: "showModalAddRemoveRow",
                        num: vi + 1
                      })}
                    >
                      {vi + 1}
                    </button>
                    :
                    <Form.Control
                      type="text"
                      as="textarea"
                      size="sm"
                      data-bs-theme={theme}
                      rows={1}
                      placeholder="Значение"
                      value={v[hi - 1]}
                      style={{
                        textAlign: "left",
                        border: "none",
                        width: "auto",
                        minWidth: "100%"
                      }}
                      onChange={(e) => quizContext.dispatch({
                        type: "getValues",
                        payload: e.target.value,
                        key: vi,
                        objKey: hi - 1,
                      })}
                    />
                  }
                </td>
              )}
            </tr>
          )}
        </tbody>
      </Table>

      {quizContext.state.alertStatus === "column"
        ? <ModalAddRemoveColumn />
        : quizContext.state.alertStatus === "row"
          ? <ModalAddRemoveRow />
          : null
      }
    </>
  )
}

export default FormTableCreateQuiz