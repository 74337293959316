import React from "react";
import AccordionFAQmembership from "../componentsRU/accordions/AccordionFAQmembership";
import AccordionFAQquizzes from "../componentsRU/accordions/AccordionFAQquizzes";
import AccordionFAQlibrary from "../componentsRU/accordions/AccordionFAQlibrary";
import AccordionFAQteams from "../componentsRU/accordions/AccordionFAQteams";

const Help = () => {

  return (
    <div style={{ paddingBottom: "100px"}}>
      <h2 className="header">Часто задаваемые вопросы</h2>
      <div className="faq-div">
        Статус пользоватля:
      </div>
      <AccordionFAQmembership />

      <div className="faq-div">
        Квизы:
      </div>
      <AccordionFAQquizzes />

      <div className="faq-div">
        Библиотека квизов:
      </div>
      <AccordionFAQlibrary />

      <div className="faq-div">
        Команды:
      </div>
      <AccordionFAQteams />

    </div>
  )
}

export default Help