import React, { useContext } from "react";
import { QuizContext } from "../../App";
import ButtonModalFailedTasks from "../buttons/ButtonModalFailedTasks";

const TextSolvedTasks = () => {

  const quizContext = useContext(QuizContext);

  return (
    <>
      <span>
        You've solved {quizContext.state.averageScore} {quizContext.state.averageScore === 1 ? "task" : "tasks"}<br /> out of {quizContext.state.numOfTasks} <br />on the first try
      </span>
      {quizContext.state.averageScore !== quizContext.state.numOfTasks
        && <div style={{marginTop: "15px"}}>
          <ButtonModalFailedTasks />
        </div>
      }
      <hr />
      <h3 style={{ fontWeight: "600" }}>
        {quizContext.state.averageScore / quizContext.state.taskNumber === 1 ?
          "AWESOME!!!"
          : quizContext.state.averageScore / quizContext.state.taskNumber < 1 && quizContext.state.averageScore / quizContext.state.taskNumber >= 0.8 ?
            "VERY GOOD!"
            : quizContext.state.averageScore / quizContext.state.taskNumber < 0.8 && quizContext.state.averageScore / quizContext.state.taskNumber >= 0.6 ?
              "NOT BAD!"
              : "KEEP PRACTICING!"
        }
      </h3>
      <hr />
    </>
  )
}

export default TextSolvedTasks